const paths = {
  login: "/login",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  groups: "/groups",
  users: "/users",
  organizations: "/organizations",
  devices: "/devices",
  updates: "/updates",
  softwareUpdate: "software",
  factoryUpdate: "factory",
  factoryTypeUpdate: "factory-type",
  troubleshooting: "troubleshooting",
  troubleshootingAdmin: "/troubleshooting/admin",
  troubleshootingDeeplink: "troubleshooting/deeplink",
};

export default paths;
