import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import DashboardGrid from "components/DashboardGrid";
import CustomHidden from "components/common/CustomHidden";
import ModalWrapper from "components/common/ModalWrapper";
import ConnectionIcon from "components/icons/ConnectionIcon";
import DeviceModelIcon from "components/icons/DeviceModel";
import paths from "config/paths";
import { getFirstLetterToUpperCase } from "helpers";
import { DeviceModel } from "models/Heater";
import ParametersData from "models/Parameters";
import { UpdateType } from "models/Update";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateDetails as UpdateDetailType } from "services/heater";
import {
  DASHBOARD_ACTIONS,
  createGetUpdatesSelectorByType,
  dashboardDeviceGroup,
  dashboardDeviceModel,
  dashboardDeviceRemoteAccess,
} from "store/dashboard";
import {
  dashboardEnergyMeter,
  dashboardOperationHoursHeating,
} from "store/dashboardParameters";
import { createGroupNameByIdSelector } from "store/group";
import uiSlice from "store/ui";
import {
  UPDATE_ACTIONS,
  getFactorySettingsTypesAsSelectOptions,
} from "store/updates";
import FactorySettingsItem from "./FactorySettingItem";
import SoftwareUpdatesItem from "./SoftwareUpdatesItem";

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
  content: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  measure: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.body2.fontSize,
    marginLeft: "4px",
  },
  selected: {
    backgroundColor: "#FF5200 !important",
    color: "#FCFCFD !important",
  },
  select: {
    margin: "10px 0",
  },
  fileIcon: {
    color: "#9D9DAF !important",
  },
  fileIconSelected: {
    color: "#FCFCFD !important",
  },
  disabledIcon: {
    opacity: "0.5 !important",
  },
  button: {
    marginLeft: "16px",
    textTransform: "none",
  },
}));

type Prop = {
  id: number;
};

function GeneralInformationGrid({ id }: Prop) {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUpdateType, setModalUpdateType] =
    useState<UpdateType>("softwareUpdates");
  const [selectedUpdate, setSelectedUpdate] = useState("");
  const model = useSelector(dashboardDeviceModel);
  const remoteAccess = useSelector(dashboardDeviceRemoteAccess);
  const operationHoursHeating = useSelector(dashboardOperationHoursHeating);
  const energyMeter = useSelector(dashboardEnergyMeter);
  const group = useSelector(dashboardDeviceGroup);
  const getGroupName = useMemo(
    () => createGroupNameByIdSelector(Number(group)),
    [group]
  );
  const groupName = useSelector(getGroupName);
  const getUpdates = useMemo(
    () => createGetUpdatesSelectorByType(modalUpdateType),
    [modalUpdateType]
  );
  const updates = useSelector(getUpdates);
  const typeOptions = useSelector(getFactorySettingsTypesAsSelectOptions);
  const [selectedType, setSelectedType] = useState(typeOptions[0]?.value);

  const isFactorySettings = useMemo(
    () => modalUpdateType === "factorySettings",
    [modalUpdateType]
  );

  useEffect(() => {
    if (isFactorySettings) {
      dispatch({
        type: UPDATE_ACTIONS.INIT_FACTORY_SETTING_TYPE,
      });
    }
  }, [dispatch, isFactorySettings, modalUpdateType]);

  useEffect(() => {
    setSelectedType(typeOptions[0]?.value);
  }, [typeOptions]);

  const filteredUpdates = useMemo(() => {
    if (isFactorySettings) {
      return updates.filter(
        (update) => update.typeId && update.typeId.toString() === selectedType
      );
    }

    return updates;
  }, [isFactorySettings, selectedType, updates]);

  const onOpenModal = useCallback((type: UpdateType) => {
    setIsModalOpen(true);
    setModalUpdateType(type);
  }, []);

  const onViewGroupClick = useCallback(() => {
    if (group) {
      dispatch(uiSlice.actions.setScrollToGroupId(group));
      navigate(paths.groups);
    }
  }, [dispatch, group, navigate]);

  const onSelectUpdate = useCallback(
    (update: UpdateDetailType) => {
      if (!isFactorySettings) {
        setSelectedUpdate(update.key);
      } else if (update.id) {
        setSelectedUpdate(update.id);
      }
    },
    [isFactorySettings]
  );

  const getIsUpdateSelected = useCallback(
    (update: UpdateDetailType) =>
      isFactorySettings
        ? selectedUpdate === update?.id
        : selectedUpdate === update.key,
    [isFactorySettings, selectedUpdate]
  );

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedUpdate("");
  }, []);

  const onUpdateClick = useCallback(
    (type: UpdateType) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_UPDATES_BY_TYPE_URL,
        payload: { key: selectedUpdate, deviceId: id, type },
      });
      onClose();
    },
    [dispatch, onClose, id, selectedUpdate]
  );

  return (
    <DashboardGrid
      title={t("modules.dashboard.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <>
          <CustomHidden size="md" hideType="down">
            <Grid item xs={6} md={4}>
              <Typography className={styles.gridLabel} variant="body2">
                {getFirstLetterToUpperCase(
                  t("modules.dashboard.grid.generalInformation.model")
                )}
              </Typography>
              <DeviceModelIcon model={DeviceModel[model]} />
            </Grid>

            <Grid item xs={6} md={8}>
              <Typography className={styles.gridLabel} variant="body2">
                {getFirstLetterToUpperCase(
                  t("modules.dashboard.grid.generalInformation.remoteAccess")
                )}
              </Typography>
              <ConnectionIcon
                type={remoteAccess ? "success" : "error"}
                label={
                  remoteAccess
                    ? t("modules.dashboard.grid.generalInformation.available")
                    : t("modules.dashboard.grid.generalInformation.unavailable")
                }
              />
            </Grid>
          </CustomHidden>

          <CustomHidden size="md" hideType="up">
            <Grid item xs={8} md={8}>
              <Typography className={styles.gridLabel} variant="body2">
                {getFirstLetterToUpperCase(
                  t("modules.dashboard.grid.generalInformation.remoteAccess")
                )}
              </Typography>
              <ConnectionIcon
                type={remoteAccess ? "success" : "error"}
                label={
                  remoteAccess
                    ? t("modules.dashboard.grid.generalInformation.available")
                    : t("modules.dashboard.grid.generalInformation.unavailable")
                }
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography className={styles.gridLabel} variant="body2">
                {getFirstLetterToUpperCase(
                  t("modules.dashboard.grid.generalInformation.model")
                )}
              </Typography>
              <DeviceModelIcon model={DeviceModel[model]} />
            </Grid>
          </CustomHidden>

          <Grid item xs={8} md={4}>
            <Typography className={styles.gridLabel} variant="body2">
              {getFirstLetterToUpperCase(
                t("modules.dashboard.grid.generalInformation.operationHours")
              )}
            </Typography>
            <Typography className={styles.content} variant="body1">
              {operationHoursHeating}{" "}
              <span className={styles.measure}>
                {ParametersData.OperationHoursHeating.unit}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={4} md={8}>
            <Typography className={styles.gridLabel} variant="body2">
              {getFirstLetterToUpperCase(
                t("modules.dashboard.grid.generalInformation.energyMeter")
              )}
            </Typography>
            <Typography className={styles.content} variant="body1">
              {energyMeter}{" "}
              <span className={styles.measure}>
                {ParametersData.EnergyMeter.unit}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={8} md={4}>
            <SoftwareUpdatesItem onOpenModal={onOpenModal} />
          </Grid>
          <Grid item xs={4} md={4}>
            <FactorySettingsItem onOpenModal={onOpenModal} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography className={styles.gridLabel} variant="body2">
              {getFirstLetterToUpperCase(
                t("modules.dashboard.grid.generalInformation.group")
              )}
            </Typography>
            <Typography className={styles.content} variant="body1">
              {groupName || "-"}
              {group && (
                <Button
                  className={styles.button}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={onViewGroupClick}
                >
                  {t("modules.dashboard.grid.generalInformation.viewGroup")}
                </Button>
              )}
            </Typography>
          </Grid>
          <ModalWrapper
            isOpen={isModalOpen}
            onCancelAction={onClose}
            onOkAction={() => onUpdateClick(modalUpdateType)}
            title={t(`modals.${modalUpdateType}.title`)}
            contentLabel={t(`modals.${modalUpdateType}.subtitle`)}
            content={
              <>
                {isFactorySettings && selectedType && (
                  <>
                    <Typography color="textSecondary">
                      {t("modals.factorySettings.type")}
                    </Typography>
                    <Select
                      className={styles.select}
                      label="Type"
                      name="role"
                      variant="standard"
                      fullWidth
                      value={selectedType}
                      onChange={(e) => {
                        setSelectedType(e.target.value);
                        setSelectedUpdate("");
                      }}
                    >
                      {typeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}

                <List>
                  {filteredUpdates.map((update) => (
                    <ListItemButton
                      classes={{ selected: styles.selected }}
                      key={update.key}
                      selected={getIsUpdateSelected(update)}
                      onClick={() => onSelectUpdate(update)}
                    >
                      <ListItemIcon>
                        <InsertDriveFileIcon
                          className={classNames(styles.fileIcon, {
                            [styles.fileIconSelected]:
                              getIsUpdateSelected(update),
                          })}
                        />
                      </ListItemIcon>
                      <ListItemText primary={update.key} />
                    </ListItemButton>
                  ))}
                </List>
              </>
            }
            okLabel={t("buttons.update")}
            cancelLabel={t("buttons.close")}
            isOkDisabled={!selectedUpdate}
          />
        </>
      }
    />
  );
}

export default GeneralInformationGrid;
