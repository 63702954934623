import { Tooltip, Typography } from "@mui/material";
import ParametersData from "models/Parameters";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHeaterEvent } from "store/heaterCalendar";
import ItemCell from "./ItemCell";
import TooltipContent from "./TooltipContent";

function WeekEvent({ event }: any) {
  const { id, start, end } = event;
  const { t } = useTranslation();
  const startTime = moment(start).format("HH:mm");
  const endTime = moment(end).format("HH:mm");
  const getEvent = useMemo(() => getHeaterEvent(id), [id]);
  const eventData = useSelector(getEvent);
  const hourDiff = moment(end).endOf("hour").diff(moment(start), "hour");
  const showTooltip = hourDiff <= 2;

  return (
    <Tooltip title={showTooltip ? <TooltipContent id={id} /> : ""}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0 16px",
          backgroundColor: "#c1d1dc",
          height: "100%",
          width: "100%",
        }}
      >
        <Typography color="textPrimary">
          {startTime} - {endTime}
        </Typography>

        {!showTooltip && (
          <>
            <br />
            <ItemCell
              label={t("modules.dashboard.calendar.targetRoomTemperature")}
              value={`${eventData.targetRoomTemperature} ${ParametersData.TargetRoomTemperature.unit}`}
              width="90%"
            />

            <ItemCell
              label={t("modules.dashboard.calendar.targetHotAirTemperature")}
              value={`${eventData.targetHotAirTemperature} ${ParametersData.TargetHotAirTemperature.unit}`}
              width="90%"
            />

            <ItemCell
              label={t("modules.dashboard.calendar.targetPowerHotAirFan")}
              value={`${eventData.targetPowerHotAirFan} ${ParametersData.HotAirFanPower.unit}`}
              width="90%"
            />

            <ItemCell
              label={t("modules.dashboard.calendar.hotAirFan")}
              value={eventData.modeHotAirFan ? "true" : "false"}
              width="90%"
            />

            <ItemCell
              label={t("modules.dashboard.calendar.additionalCooling")}
              value={eventData.additionalCooling ? "true" : "false"}
              width="90%"
            />
          </>
        )}
      </div>
    </Tooltip>
  );
}

export default WeekEvent;
