import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import getComparator, { Order } from "components/common/tableHelpers";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { defaultRowsPerPage, rowsPerPageOptions } from "helpers";
import { HeaterUser } from "models/HeaterUser";
import { UserRole, userRoleLabels } from "models/User";
import RoleIcon from "components/icons/Role";
import ManageHeaterUserMenu from "components/adminDevices/heaterUsersTab/ManageHeaterUserMenu";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes = "email" | "role";

type Props = {
  deviceId: number;
  users: HeaterUser[];
};

function HeaterUsersTable({ deviceId, users }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("email");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "email" as OrderByTypes,
        label: t("modules.users.table.email").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "role" as OrderByTypes,
        label: t("modules.users.table.role").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const mappedValues = useMemo(
    () =>
      users.map((user) => ({
        role: userRoleLabels[user.level],
        accessLevel: user.level,
        email: user.email,
      })),
    [users]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),
    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                className={headCell.className}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user) => (
              <TableRow key={`row-${user.email}-${user.accessLevel}`}>
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">{user.email}</Typography>
                </TableCell>
                <TableCell align="left">
                  <RoleIcon
                    role={user.role}
                    hasAccess={user.accessLevel !== UserRole.NoAccess}
                  />
                </TableCell>
                <TableCell align="right">
                  <ManageHeaterUserMenu
                    deviceId={deviceId}
                    heaterUserEmail={user.email}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={mappedValues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default HeaterUsersTable;
