import { Box, Alert, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, useCallback, useEffect, useState, useMemo } from "react";
import { trimString } from "helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import groupSlice, {
  groupNamesSelector,
  groupsSelector,
  GROUP_ACTIONS,
} from "store/group";
import heaterSlice from "store/heater";
import GroupCard from "components/groups/GroupCard";
import ModalWrapper from "components/common/ModalWrapper";
import LargeGroupIcon from "components/icons/LargeGroupIcon";
import EmptyList from "components/common/EmptyList";
import PageHeader from "components/common/PageHeader";

const useStyles = makeStyles((theme: any) => ({
  content: {
    width: "80%",
    paddingTop: "48px",
    margin: "0 auto",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
  alert: {
    margin: "10px 0",
  },
}));

function Groups() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const groups = useSelector(groupsSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const groupNames = useSelector(groupNamesSelector);

  useEffect(() => {
    dispatch(groupSlice.actions.init());
    dispatch(heaterSlice.actions.init());
  }, [dispatch]);

  const isGroupNameInvalid = useMemo(
    () => groupName.trim().length === 0,
    [groupName]
  );

  const groupWithNameAlreadyExists = useMemo(
    () => groupNames.includes(trimString(groupName)),
    [groupName, groupNames]
  );

  const onOpen = useCallback(() => setIsModalOpen(true), []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setGroupName("");
  }, []);

  const onAddGroup = useCallback(() => {
    dispatch({
      type: GROUP_ACTIONS.ADD_GROUP,
      payload: {
        label: trimString(groupName),
      },
    });
    onClose();
  }, [dispatch, groupName, onClose]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGroupName(event.target.value);
    },
    []
  );

  return (
    <>
      <Box className={styles.content}>
        <PageHeader
          title={t("modules.groups.title")}
          addLabel={t("modules.groups.addNew")}
          onAdd={onOpen}
        />
        {groups.length > 0 ? (
          groups.map((group) => (
            <GroupCard key={`${group.id} - ${group.label}`} group={group} />
          ))
        ) : (
          <EmptyList
            icon={<LargeGroupIcon />}
            emptyListText={t("modules.groups.empty")}
            onOpen={onOpen}
          />
        )}
      </Box>
      <ModalWrapper
        isOpen={isModalOpen}
        onCancelAction={onClose}
        onOkAction={onAddGroup}
        title={t("modals.groups.addNewTitle")}
        contentLabel={t("modals.groups.addNewSubtitle")}
        content={
          <>
            <Typography variant="subtitle2">
              {t("modals.groups.groupName")}
            </Typography>
            <TextField
              name="label"
              placeholder={t("modals.groups.groupNamePlaceholder")}
              variant="outlined"
              value={groupName}
              onChange={handleInputChange}
              hiddenLabel
              required
              fullWidth
            />
            <br />
            {groupWithNameAlreadyExists && (
              <Alert
                className={styles.alert}
                variant="outlined"
                severity="info"
                color="warning"
              >
                <Typography> {t("modals.groups.groupNameExists")}</Typography>
              </Alert>
            )}
          </>
        }
        okLabel={t("buttons.add")}
        isOkDisabled={
          !groupName || isGroupNameInvalid || groupWithNameAlreadyExists
        }
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default Groups;
