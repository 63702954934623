import { makeStyles } from "@mui/styles";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import CustomHidden from "components/common/CustomHidden";
import getComparator, { Order } from "components/common/tableHelpers";
import MenageOrganizationMenu from "components/organizations/MenageOrganizationMenu";
import { defaultRowsPerPage, rowsPerPageOptions } from "helpers";
import { Organization } from "models/Organization";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { getLanguageFlag } from "components/troubleshooting/helpers";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes = "label";

type Props = {
  organizations: Organization[];
};

function OrganizationsTable({ organizations }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("label");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "label" as OrderByTypes,
        label: t("modules.organizations.table.name").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
      {
        id: "label" as OrderByTypes,
        label: t("modules.organizations.table.language").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedValues = useMemo(
    () => organizations.sort(getComparator(order, orderBy)),

    [organizations, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) =>
              headCell.smallHidden ? (
                <CustomHidden size="xs" hideType="only">
                  <TableCell
                    className={headCell.className}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </CustomHidden>
              ) : (
                <TableCell
                  className={headCell.className}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            )}
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((org) => (
              <TableRow key={`row-${org.id}`}>
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">{org.label}</Typography>
                </TableCell>
                <TableCell>{getLanguageFlag(org.defaultLanguage)}</TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {t(`organizationKind.${org.kind}`)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <MenageOrganizationMenu orgId={org.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={organizations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default OrganizationsTable;
