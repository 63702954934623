import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import ModalWrapper from "components/common/ModalWrapper";
import useDeleteModal from "hooks/useDeleteModal";
import { useCallback, type MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TROUBLESHOOTING_ADMIN_ACTIONS } from "store/troubleshootingAdmin";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
}));

type Props = {
  guideId: number;
};

function ManageGuideMenu({ guideId }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    [setAnchorElement]
  );

  const handleClose = useCallback(
    () => setAnchorElement(null),
    [setAnchorElement]
  );

  const onDelete = useCallback(() => {
    dispatch({
      type: TROUBLESHOOTING_ADMIN_ACTIONS.DELETE_GUIDE,
      payload: {
        guideId,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, guideId, onCloseDeleteModal]);

  const onEditClick = useCallback(
    () => navigate(`${guideId}`),
    [navigate, guideId]
  );

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onEditClick}>
          <Typography>{t("modals.troubleshooting.edit")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenDeleteModal();
            handleClose();
          }}
        >
          <Typography color="error">
            {t("modals.troubleshooting.delete")}
          </Typography>
        </MenuItem>
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDelete}
        title={t("modals.troubleshooting.delete")}
        contentLabel={t("modals.troubleshooting.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default ManageGuideMenu;
