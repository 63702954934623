import { makeStyles } from "@mui/styles";
import { Button, Typography, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import CustomHidden from "./CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
  },
  pageHeaderButton: {
    textTransform: "none",
    backgroundColor: `${theme.palette.primary.main} !important`,
    padding: "6px 16px !important",
    borderRadius: "4px !important",
    color: "#fff !important",
  },
}));

type Props = {
  title: string;
  addLabel: string;
  onAdd: () => void;
  isAddEnabled?: boolean;
  enableSearch?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
};

function PageHeader({
  title,
  addLabel,
  onAdd,
  isAddEnabled = true,
  enableSearch = false,
  searchPlaceholder,
  searchValue,
  onSearch,
}: Props) {
  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h4">{title}</Typography>
        <div>
          <CustomHidden size="sm" hideType="down">
            {enableSearch && (
              <TextField
                style={{ marginRight: "20px" }}
                size="small"
                variant="outlined"
                placeholder={searchPlaceholder}
                value={searchValue}
                onChange={onSearch}
              />
            )}
          </CustomHidden>
          {isAddEnabled && (
            <Button
              key={title}
              className={styles.pageHeaderButton}
              color="primary"
              variant="contained"
              onClick={onAdd}
            >
              {addLabel}
            </Button>
          )}
        </div>
      </div>

      <CustomHidden size="sm" hideType="up">
        {enableSearch && (
          <TextField
            style={{ margin: "10px 0" }}
            size="small"
            fullWidth
            variant="outlined"
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={onSearch}
          />
        )}
      </CustomHidden>
    </>
  );
}

export default PageHeader;
