import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  children?: ReactElement;
  index: number;
  value: number;
  hasBackground?: boolean;
  hasFooter?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "400px",
    height: "calc(100%)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
    },
  },
  footerPaper: {
    minHeight: "400px",
    height: "calc(100%)",
    paddingTop: "60px",
  },
  content: {
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
    },
  },
}));

function TabPanel({
  children,
  value,
  index,
  // hasBackgroun and hasFooter can't be used at same time
  hasBackground = false,
  hasFooter = false,
}: Props) {
  const styles = useStyles();

  if (value === index) {
    if (hasBackground) {
      return <Paper className={styles.paper}>{children}</Paper>;
    }

    if (hasFooter) {
      return <Paper className={styles.footerPaper}>{children}</Paper>;
    }

    return <div className={styles.content}>{children}</div>;
  }

  return null;
}

export default TabPanel;
