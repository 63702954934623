import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { UserRole, userRoleLabels } from "models/User";
import { useTranslation } from "react-i18next";

type Prop = {
  handleChange: (e: SelectChangeEvent) => void;
  value: string;
};

export const userRolesAsSelectOptions = Object.keys(userRoleLabels)
  .map((role) => role as unknown as UserRole)
  .map((role) => ({
    value: role,
    label: userRoleLabels[role],
  }));

function RoleGrid({ handleChange, value }: Prop) {
  const { t } = useTranslation();

  return (
    <DashboardGrid
      title={t("modules.users.grid.role.header")}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              {t("modules.users.grid.role.label")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <Select
                name="role"
                variant="outlined"
                value={value}
                onChange={handleChange}
              >
                {userRolesAsSelectOptions.map((userRole) => (
                  <MenuItem
                    key={userRole.value}
                    value={userRole.value.toString()}
                  >
                    {userRole.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} />

          <Grid item xs={12} md={6}>
            <Typography color="textSecondary">
              {t(`modules.users.grid.role.helperText.${value}`)}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}

export default RoleGrid;
