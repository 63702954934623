import {
  GuideFormValues,
  TroubleshootingGuide,
  TroubleshootingGuideType,
} from "models/TroubleshootingGuide";
import api from "./api";

const getGuides = async () => {
  const response = await api.get<TroubleshootingGuide[]>("/troubleshooting");

  return response.data;
};

const deleteGuide = async (guideId: number) => {
  const response = await api.delete(`/troubleshooting/${guideId}`);

  return response.data;
};

const addGuide = async (formValues: GuideFormValues) => {
  const { type, language, deviceModel, update, fileName, code } = formValues;
  const formData = new FormData();
  formData.append("type", type);
  formData.append("language", language);
  formData.append("deviceModel", deviceModel.toString());
  formData.append("update", update);
  formData.append("fileName", fileName);

  if (
    type === TroubleshootingGuideType.ErrorGuideline ||
    type === TroubleshootingGuideType.WarningGuideline
  ) {
    formData.append("code", code.toString());
  }

  const response = await api.post("/troubleshooting", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

const editGuideRemoteDevices = async (
  guideId: number,
  remoteDevices: number[]
) => {
  const response = await api.put(`/troubleshooting/${guideId}/remote_devices`, {
    remoteDevices,
  });

  return response.data;
};

const getGuideRemoteDevices = async (guideId: number) => {
  const response = await api.get<number[]>(
    `/troubleshooting/${guideId}/remote_devices`
  );

  return response.data;
};

export default {
  getGuides,
  deleteGuide,
  addGuide,
  getGuideRemoteDevices,
  editGuideRemoteDevices,
};
