export type ParametersType =
  | "TargetRoomTemperature"
  | "OperationHoursHeating"
  | "EnergyMeter"
  | "TargetHotAirTemperature"
  | "HotAirFanPower"
  | "ProcessState"
  | "AshLevel"
  | "HoursTillMaintenance"
  | "SoftwareVersion"
  | "MachineName";

type ParameterDetails = {
  id: number;
  unit: string;
  min: number;
  max: number;
};

const ParametersData: Record<ParametersType, ParameterDetails> = {
  TargetRoomTemperature: {
    id: 1004,
    unit: "°C",
    min: 0,
    max: 60,
  },
  TargetHotAirTemperature: {
    id: 6001,
    unit: "°C",
    min: 0,
    max: 100,
  },
  HotAirFanPower: {
    id: 6002,
    unit: "%",
    min: 20,
    max: 80,
  },
  EnergyMeter: {
    id: 1001,
    unit: "kWh",
    min: 0,
    max: 1000,
  },
  OperationHoursHeating: {
    id: 1001,
    unit: "hrs",
    min: 0,
    max: 0,
  },
  ProcessState: {
    id: 30002,
    unit: "",
    min: 0,
    max: 200,
  },
  AshLevel: {
    id: 2014,
    unit: "",
    min: 0,
    max: 100,
  },
  HoursTillMaintenance: {
    id: 10124,
    unit: "",
    min: 0,
    max: 0,
  },
  SoftwareVersion: {
    id: 40010,
    unit: "",
    min: 0,
    max: 0,
  },
  MachineName: {
    id: 40001,
    unit: "",
    min: 0,
    max: 0,
  },
};

export type HeaterParametersProperties =
  | "targetRoomTemperature"
  | "processState";

export type ParametersProperties =
  | HeaterParametersProperties
  | "targetHotAirTemperature"
  | "hotAirFanPower"
  | "ashLevel"
  | "timePredictionTillNextMaintenance"
  | "softwareVersion";

export const parameterPropertiesById: Record<number, ParametersProperties> = {
  [ParametersData.TargetRoomTemperature.id]: "targetRoomTemperature",
  [ParametersData.TargetHotAirTemperature.id]: "targetHotAirTemperature",
  [ParametersData.HotAirFanPower.id]: "hotAirFanPower",
  [ParametersData.ProcessState.id]: "processState",
  [ParametersData.AshLevel.id]: "ashLevel",
  [ParametersData.HoursTillMaintenance.id]: "timePredictionTillNextMaintenance",
  [ParametersData.SoftwareVersion.id]: "softwareVersion",
};

export default ParametersData;
