export const getEnumFromStringValue = <T>(
  enm: { [s: string]: T },
  value: string
): T | undefined =>
  (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;

export const enumStringValues = (enm: { [s: string]: string }): string[] =>
  Object.values(enm).filter((value) => typeof value === "string") as string[];
