import { User, UserCredentials, UserInfo } from "models/User";
import api from "./api";

export type LoginResponse = {
  user: User;
  credentials: UserCredentials;
};

const login = async (data: UserInfo) => {
  const response = await api.post<UserInfo, { data: LoginResponse }>(
    "login",
    data
  );
  return response.data;
};

const resetPassword = async (password: string) => {
  const response = await api.put("reset_password", {
    password,
  });

  return response;
};

const refreshToken = async () => {
  const response = await api.post<UserCredentials>("refresh_credentials");
  return response.data;
};

const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("mqttUrl");
  localStorage.removeItem("orgIds");
  localStorage.removeItem("username");
  localStorage.removeItem("tmstp");
  localStorage.removeItem("accessLevel");
};

export default { refreshToken, login, logout, resetPassword };
