import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert";
import classNames from "classnames";
import { getFormatedDate } from "helpers";
import CustomHidden from "components/common/CustomHidden";

const useStyles = makeStyles(
  (theme: {
    palette: {
      error: { main: any };
      warning: { main: any };
      text: { disabled: any };
    };
  }) => ({
    code: {
      marginRight: "24px",
    },
    alert: {
      marginBottom: "12px",
    },
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    date: {
      color: theme.palette.text.disabled,
    },
  })
);

type Props = {
  type: "error" | "warning" | "default";
  severity: AlertProps["severity"];
  color: AlertProps["color"];
  code: number;
  message: string;
  date: string;
};

function InfoAlert({ type, severity, color, code, message, date }: Props) {
  const styles = useStyles();

  return (
    <Alert
      className={styles.alert}
      severity={severity}
      variant="outlined"
      color={color}
      action={
        <CustomHidden size="md" hideType="down">
          <Typography className={styles.date} variant="caption">
            {getFormatedDate(date)}
          </Typography>
        </CustomHidden>
      }
    >
      <Typography variant="body1" color="textPrimary">
        <span
          className={classNames(styles.code, {
            [styles.error]: type === "error",
            [styles.warning]: type === "warning",
          })}
        >
          {type.toUpperCase()} #{code}
        </span>
        <CustomHidden size="md" hideType="down">
          {message}
        </CustomHidden>
      </Typography>
      <CustomHidden size="md" hideType="up">
        <Typography className={styles.date} variant="caption">
          {getFormatedDate(date)}
        </Typography>
      </CustomHidden>
      <CustomHidden size="md" hideType="up">
        <Typography variant="body1" color="textPrimary">
          {message}
        </Typography>
      </CustomHidden>
    </Alert>
  );
}

export default InfoAlert;
