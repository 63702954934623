import { ReactElement, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AUTH_ACTIONS,
  isAuthenticatedSelector,
  navigateToSelector,
  userRequiresPasswordReset,
} from "store/auth";
import snackbarSlice from "store/snackbar";
import axios from "axios";
import { ErrorData } from "models/Error";
import { UI_ACTIONS } from "store/ui";
import paths from "config/paths";
import storageKey from "config/storageKey";

type Props = {
  children: ReactElement;
};

function WithAxios({ children }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const requiresPasswordReset = useSelector(userRequiresPasswordReset);
  const navigateTo = useSelector(navigateToSelector);
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && location.pathname === `/${paths.troubleshooting}`) {
      localStorage.setItem(
        storageKey.troubleshootingPath,
        JSON.stringify(location)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(paths.login);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (requiresPasswordReset) {
      navigate(paths.resetPassword);
    } else if (!requiresPasswordReset && navigateTo) {
      navigate(navigateTo);
      localStorage.removeItem(storageKey.troubleshootingPath);
    }
  }, [navigateTo, isAuthenticated, navigate, requiresPasswordReset]);

  useMemo(() => {
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 401) {
            dispatch({ type: AUTH_ACTIONS.LOGOUT });
            dispatch(
              snackbarSlice.actions.showInfo({
                type: "info",
                message: "You were logged out due to inactivity.",
              })
            );
          } else if (
            error.request?.responseURL &&
            (error.request.responseURL as string).includes("/remote/language")
          ) {
            /* eslint-disable no-console */
            console.log(`Ignoring potential errors for panel viewer: ${error}`);
            return Promise.resolve();
          } else {
            const { code } = error.response?.data as ErrorData;
            dispatch({ type: UI_ACTIONS.HANDLE_ERROR, payload: code });
          }
        }

        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  return children;
}

export default WithAxios;
