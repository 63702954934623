import { Box } from "@mui/material";
import AdminDevicesTable from "components/adminDevices/AdminDevicesTable";
import EmptyList from "components/common/EmptyList";
import PageHeader from "components/common/PageHeader";
import AdminDevicesLargeIcon from "components/icons/AdminDevicesLargeIcon";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import adminDevicesSlice, { adminDeviceListSelector } from "store/adminDevices";
import uiSlice, { AdminDevicesTabs } from "store/ui";
import useContentStyles from "./useContentStyles";

function AdminDevices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useContentStyles();
  const { t } = useTranslation();
  const adminDevices = useSelector(adminDeviceListSelector);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(adminDevicesSlice.actions.init());
    dispatch(
      uiSlice.actions.setAdminDevicesSelectedTab(AdminDevicesTabs.General)
    );
  }, [dispatch]);

  const onAdd = useCallback(() => navigate("new"), [navigate]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredDevices = useMemo(
    () =>
      adminDevices.filter((item) => {
        const { machineName = "" } = item.parameters;
        const { serialNumber = "" } = item.device;
        return (
          machineName.toLowerCase().includes(searchValue.toLowerCase()) ||
          serialNumber.toLowerCase().includes(searchValue.toLowerCase())
        );
      }),
    [searchValue, adminDevices]
  );

  return (
    <Box className={styles.content}>
      <PageHeader
        title={t("modules.devices.title")}
        addLabel={t("modules.devices.addNew")}
        onAdd={onAdd}
        enableSearch
        searchPlaceholder={t("modules.devices.placeholder")}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {adminDevices.length === 0 && (
        <EmptyList
          icon={<AdminDevicesLargeIcon />}
          emptyListText={t("modules.devices.empty")}
          onOpen={onAdd}
        />
      )}
      {adminDevices.length > 0 && (
        <AdminDevicesTable devices={filteredDevices} />
      )}
    </Box>
  );
}

export default AdminDevices;
