import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function GroupIcon({ className = "" }: Props) {
  return (
    <SvgIcon className={className}>
      <path d="M12.071 0H7.929L5 2.929V3H7.9L12 7.101V10H12.071L15 7.071V2.929L12.071 0Z" />
      <path d="M7.071 5H2.929L0 7.929V12.071L2.929 15H7.071L10 12.071V7.929L7.071 5Z" />
    </SvgIcon>
  );
}

export default GroupIcon;
