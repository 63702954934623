import {
  Radio,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "components/DashboardGrid";
import { getFirstLetterToUpperCase, trimString } from "helpers";
import { DeviceModel } from "models/Heater";
import { useCallback, useMemo, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createDeviceGeneralInfoData } from "store/adminDevices";

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    fontSize: "14px",
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
}));

type Prop = {
  deviceId: number;
  handleChange: (e: ChangeEvent<any>) => void;
  currentModel: string;
  currentSerialNumber: string;
  setFieldValue: any;
};

export const serialNumberRegex = /^[\w+.@-]+$/;

function GeneralInformationGrid({
  deviceId,
  currentModel,
  currentSerialNumber,
  handleChange,
  setFieldValue,
}: Prop) {
  const { t } = useTranslation();
  const styles = useStyles();
  const getData = useMemo(
    () => createDeviceGeneralInfoData(deviceId),
    [deviceId]
  );
  const { machineName, id } = useSelector(getData);

  const onBlur = useCallback(
    (property: string, value: string) => {
      setFieldValue(property, trimString(value));
    },
    [setFieldValue]
  );

  const isSerialNumberValid = useMemo(() => {
    if (!currentSerialNumber) {
      return true;
    }
    return serialNumberRegex.test(currentSerialNumber);
  }, [currentSerialNumber]);

  const serialNumberHelperText = useMemo(
    () => (isSerialNumberValid ? "" : t("textFields.invalidSN")),
    [isSerialNumberValid, t]
  );

  return (
    <DashboardGrid
      title={t("modules.devices.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.devices.grid.generalInformation.serialNumber")
              )}
            </Typography>
            <TextField
              size="small"
              name="serialNumber"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={currentSerialNumber}
              onBlur={() => onBlur("serialNumber", currentSerialNumber)}
              required
              error={!isSerialNumberValid}
              helperText={serialNumberHelperText}
            />
          </Grid>

          <Grid item xs={12} md={8} />

          {deviceId !== 0 && (
            <>
              <Grid item xs={6} md={2}>
                <Typography className={styles.gridLabel}>
                  {t(
                    "modules.devices.grid.generalInformation.id"
                  ).toUpperCase()}
                </Typography>
                <Typography>{id}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography className={styles.gridLabel}>
                  {getFirstLetterToUpperCase(
                    t("modules.devices.grid.generalInformation.deviceName")
                  )}
                </Typography>
                <Typography>{machineName}</Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={12}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.devices.grid.generalInformation.model")
              )}
            </Typography>
            <FormControlLabel
              name="model"
              control={<Radio checked color="primary" />}
              label={DeviceModel[1]}
              labelPlacement="end"
              onChange={handleChange}
              value={currentModel}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default GeneralInformationGrid;
