import { Grid, TextField, Typography } from "@mui/material";
import CustomSlider from "components/common/SliderItem";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

type Props = {
  label: string;
  min: number;
  max: number;
  defaultValue: number;
  onValueChange: (newValue: number) => void;
};

function ModalSliderItem({
  label,
  min,
  max,
  defaultValue,
  onValueChange,
}: Props) {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSliderValue(+event.target.value);
  }, []);

  const onChange = useCallback(
    (_: any, newValue: any) => {
      setSliderValue(newValue);
      onValueChange(newValue);
    },
    [onValueChange]
  );

  return (
    <>
      <Grid item xs={10}>
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={sliderValue}
          size="small"
          variant="outlined"
          type="number"
          onChange={onInputChange}
          InputProps={{
            inputProps: {
              min,
              max,
            },
          }}
        />
      </Grid>
      <Grid item xs={11}>
        <CustomSlider
          value={sliderValue}
          onChange={onChange}
          disabled={false}
          min={min}
          max={max}
        />
      </Grid>
    </>
  );
}

export default ModalSliderItem;
