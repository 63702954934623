import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: any) => ({
  role: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "16px",
    padding: "6px 12px",
    fontFamily: "Ropa Sans",
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      clear: "both",
      display: "inline-block",
      overflow: "hidden",
      whiteSpace: "nowrap !important",
    },
  },
  noAccess: {
    backgroundColor: "#FFE7DB",
  },
}));

type Props = {
  role: string;
  hasAccess: boolean;
};

function RoleIcon({ role, hasAccess }: Props) {
  const styles = useStyles();

  return (
    <span
      className={classNames(styles.role, { [styles.noAccess]: !hasAccess })}
    >
      {role}
    </span>
  );
}

export default RoleIcon;
