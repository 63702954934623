import { push } from "@lagunovsky/redux-react-router";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import paths from "config/paths";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import { Organization } from "models/Organization";

import { User, UserRole } from "models/User";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import usersService from "services/users";
import { organizationsByIdSelector } from "store/organizations";

export const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const USERS_ACTIONS = {
  ADD_USER: "ADD_USER",
  GET_USER: "GET_USER",
  DELETE_USER: "DELETE_USER",
  EDIT_USER: "EDIT_USER",
  EDIT_USER_ORG: "EDIT_USER_ORG",
};

type UserState = {
  initializing: boolean;
  users: Record<number, User>;
  actionInProgress: boolean;
  error?: string;
};

const initialState: UserState = {
  initializing: false,
  users: {},
  actionInProgress: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.users = {
        ...state.users,
        ...mapKeys(payload, (user) => user.id),
      };
      state.initializing = false;
      state.actionInProgress = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    setActionInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.actionInProgress = payload;
    },
    deleteUserSucces: (state, { payload }: PayloadAction<number>) => {
      state.users = omit(state.users, payload);
      state.actionInProgress = false;
    },
    getUserSucces: (state, { payload }: PayloadAction<User>) => {
      state.users[payload.id] = payload;
    },
    editUserSucces: (
      state,
      { payload }: PayloadAction<{ userId: number; user: Partial<User> }>
    ) => {
      const { userId, user } = payload;
      state.actionInProgress = false;
      state.users[userId] = {
        ...state.users[userId],
        ...user,
      };
    },
    editOrganizationsSucces: (
      state,
      {
        payload,
      }: PayloadAction<{
        userId: number;
        organizations: Organization[];
      }>
    ) => {
      const { userId, organizations } = payload;
      state.users[userId].organizations = organizations;
    },
    addUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.actionInProgress = false;
      state.users[payload.id] = {
        ...payload,
      };
    },
  },
});

function usersSelector(state: { [usersSlice.name]: UserState }) {
  return state[usersSlice.name];
}

export const usersByIdSelector = createSelector(
  usersSelector,
  (state: UserState) => state.users
);

export const usersActionInProgress = createSelector(
  usersSelector,
  (state: UserState) => state.actionInProgress
);

export const createUserDetailsByIdSelector = (userId: number) =>
  createSelector(usersByIdSelector, (usersById) => {
    const {
      firstName = "",
      lastName = "",
      username = "",
    } = usersById[userId] || {};

    return {
      fullName: `${firstName} ${lastName}`,
      email: username,
    };
  });

export type UserFormData = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  requiresPasswordReset: boolean;
  role: string;
  organizations: number[] | undefined;
};

export const createUserInitialFormValues = (userId: number) =>
  createSelector(usersByIdSelector, (usersById) => {
    const user = usersById[userId] || {};

    return {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      username: user.username || "",
      password: "",
      requiresPasswordReset: user.requiresPasswordReset ?? false,
      role: (user.accessLevel || UserRole.NoAccess).toString(),
      organizations: user.organizations?.map((org) => org.id),
    };
  });

export const userListSelector = createSelector(usersByIdSelector, (usersById) =>
  Object.values(usersById)
);

function* handleDeleteUser(
  action: PayloadAction<{ userId: number }>
): Generator {
  try {
    yield put(usersSlice.actions.setActionInProgress(true));

    const { userId } = action.payload;
    yield call(usersService.deleteUser, userId);

    yield put(usersSlice.actions.deleteUserSucces(userId));
    yield put(push(paths.users));
  } catch (error) {
    yield put(usersSlice.actions.setActionInProgress(false));
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteUser() {
  yield takeLatest(USERS_ACTIONS.DELETE_USER, handleDeleteUser);
}

function* handleGetUser(action: PayloadAction<{ userId: number }>): Generator {
  try {
    const { userId } = action.payload;
    const user = (yield call(usersService.getUser, userId)) as User;

    yield put(usersSlice.actions.getUserSucces(user));
  } catch (err) {
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetUser() {
  yield takeLatest(USERS_ACTIONS.GET_USER, handleGetUser);
}

function* handleEditOrganizations(
  action: PayloadAction<{
    userId: number;
    organizations: number[];
  }>
): Generator {
  try {
    const { userId, organizations } = action.payload;

    yield call(usersService.editUserOrganizations, userId, organizations);

    const orgsById = (yield select(organizationsByIdSelector)) as Record<
      string,
      Organization
    >;

    const list = organizations.map((orgId) => ({
      ...orgsById[orgId],
    }));

    yield put(
      usersSlice.actions.editOrganizationsSucces({
        userId,
        organizations: list,
      })
    );
  } catch (error) {
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditOrganizations() {
  yield takeLatest(USERS_ACTIONS.EDIT_USER_ORG, handleEditOrganizations);
}

function* handleEditUser(
  action: PayloadAction<{
    userId: number;
    username: string;
    password?: string;
    accessLevel: UserRole;
    firstName: string;
    lastName: string;
    requiresPasswordReset: boolean;
    organizations: number[];
  }>
): Generator {
  yield put(usersSlice.actions.setActionInProgress(true));

  try {
    const {
      userId,
      username,
      password,
      accessLevel,
      firstName,
      lastName,
      requiresPasswordReset,
      organizations,
    } = action.payload;

    const editUser = {
      username,
      password,
      accessLevel,
      firstName,
      lastName,
      requiresPasswordReset,
    };

    yield call(usersService.editUser, userId, editUser);

    yield put(usersSlice.actions.editUserSucces({ userId, user: editUser }));
    yield put(push(paths.users));

    yield put({
      type: USERS_ACTIONS.EDIT_USER_ORG,
      payload: {
        userId,
        organizations,
      },
    });
  } catch (error) {
    yield put(usersSlice.actions.setActionInProgress(false));
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditUser() {
  yield takeLatest(USERS_ACTIONS.EDIT_USER, handleEditUser);
}

function* handleAddUser(
  action: PayloadAction<{
    username: string;
    password?: string;
    accessLevel: UserRole;
    firstName: string;
    lastName: string;
    requiresPasswordReset: boolean;
    organizations: number[];
  }>
): Generator {
  try {
    yield put(usersSlice.actions.setActionInProgress(true));

    const {
      username,
      password,
      accessLevel,
      firstName,
      lastName,
      requiresPasswordReset,
      organizations,
    } = action.payload;

    const newUser = {
      username,
      password,
      accessLevel,
      firstName,
      lastName,
      requiresPasswordReset,
    };

    const user = (yield call(usersService.createUser, newUser)) as User;

    yield put(usersSlice.actions.addUserSuccess(user));
    yield put(push(paths.users));

    if (user && organizations.length > 0) {
      yield put({
        type: USERS_ACTIONS.EDIT_USER_ORG,
        payload: {
          userId: user.id,
          organizations,
        },
      });
    }
  } catch (error) {
    yield put(usersSlice.actions.setActionInProgress(false));
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddUser() {
  yield takeLatest(USERS_ACTIONS.ADD_USER, handleAddUser);
}

function* handleInit(): Generator {
  try {
    const users = (yield call(usersService.getUsers)) as User[];
    yield put(usersSlice.actions.initSuccess(users));
  } catch (err) {
    yield put(usersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(usersSlice.actions.init, handleInit);
}

export function* usersWatcher() {
  yield all([
    fork(watchInit),
    fork(watchDeleteUser),
    fork(watchGetUser),
    fork(watchEditUser),
    fork(watchEditOrganizations),
    fork(watchAddUser),
  ]);
}

export default usersSlice;
