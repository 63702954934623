import omit from "lodash/omit";
import { User, UserRole } from "models/User";
import api from "./api";

const getUsers = async () => {
  const response = await api.get<User[]>("/users");

  return response.data;
};

const deleteUser = async (userId: number) => {
  const response = await api.delete(`/users/${userId}`);

  return response.data;
};

const getUser = async (userId: number) => {
  const response = await api.get<User>(`/users/${userId}`);

  return response.data;
};

type UpdateUser = {
  username: string;
  password?: string;
  accessLevel: UserRole;
  firstName: string;
  lastName: string;
  requiresPasswordReset: boolean;
};

const editUser = async (id: number, user: UpdateUser) => {
  const newUser = user.password ? user : omit(user, "password");
  const response = await api.put<UpdateUser>(`/users/${id}`, {
    ...newUser,
  });

  return response.data;
};

const editUserOrganizations = async (id: number, organizations: number[]) => {
  const response = await api.put(`/users/${id}/organizations`, {
    organizations,
  });
  return response.data;
};

type CreateUser = Pick<
  User,
  | "username"
  | "firstName"
  | "lastName"
  | ("accessLevel" & {
      password: string;
    })
>;

const createUser = async (user: CreateUser) => {
  const response = await api.post<CreateUser>("/signup", {
    ...user,
  });

  return response.data;
};

export default {
  getUsers,
  deleteUser,
  getUser,
  editUser,
  editUserOrganizations,
  createUser,
};
