import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import pick from "lodash/pick";
import {
  AlarmRule,
  AlarmRulePayload,
  AlarmRuleType,
  OrganizationUnit,
  OrganizationUnitWithDetails,
} from "models/Organization";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import organizationsServices from "services/organizations";

export const UNIT_ACTIONS = {
  GET_UNITS: "GET_UNITS",
  ADD_UNIT: "ADD_UNIT",
  EDIT_UNIT: "EDIT_UNIT",
  DELETE_UNIT: "DELETE_UNIT",
  SET_UNIT_USERS: "SET_UNIT_USERS",
  SAVE_ALARM_RULES: "SAVE_ALARM_RULES",
  GET_ALARM_RULES: "GET_ALARM_RULES",
  ADD_ALARM_RULE: "ADD_ALARM_RULE",
  EDIT_ALARM_RULE: "EDIT_ALARM_RULE",
  DELETE_ALARM_RULE: "DELETE_ALARM_RULE",
};

type UnitsState = {
  initializing: boolean;
  organizationUnits: Record<
    number,
    Record<number, OrganizationUnitWithDetails>
  >;
  organizationUnitUsers: Record<number, number[]>;
  alarmRules: Record<number, Record<number, AlarmRule>>;
  error?: string;
};

const initialState: UnitsState = {
  initializing: false,
  organizationUnits: {},
  organizationUnitUsers: {},
  alarmRules: {},
};

type GetUnits = PayloadAction<{
  orgId: number;
  units: OrganizationUnitWithDetails[];
}>;

type ManageUnit = PayloadAction<{
  orgId: number;
  unit: OrganizationUnit;
}>;

type DeleteUnit = PayloadAction<{ orgId: number; orgUnitId: number }>;

type SetUnitUsers = PayloadAction<{
  unitId: number;
  unitUserIds: number[];
}>;

type SetUnitUsersCount = PayloadAction<{
  orgId: number;
  unitId: number;
  usersCount: number;
}>;

type GetAlarmRules = PayloadAction<{
  unitId: number;
  rules: AlarmRule[];
}>;

type AddAlarmRule = PayloadAction<{
  orgId: number;
  unitId: number;
  rule: AlarmRule;
}>;

type EditAlarmRule = PayloadAction<{
  unitId: number;
  rule: AlarmRule;
}>;

type DeleteAlarmRule = PayloadAction<{
  orgId: number;
  unitId: number;
  alarmRuleId: number;
}>;

type SaveAlarmRules = PayloadAction<{
  orgId: number;
  unitId: number;
  initialRules: AlarmRule[];
  rules: AlarmRule[];
}>;

const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    getUnitsSuccess: (state, { payload }: GetUnits) => {
      const { orgId, units } = payload;
      state.organizationUnits[orgId] = mapKeys(units, (unit) => unit.id);
    },
    addUnitsSuccess: (state, { payload }: ManageUnit) => {
      const { orgId, unit } = payload;
      state.organizationUnits[orgId][unit.id] = {
        ...unit,
        alarmRuleCount: 0,
        userCount: 0,
      };
    },
    deleteOrgUnitSuccess: (state, { payload }: DeleteUnit) => {
      const { orgId, orgUnitId } = payload;
      state.organizationUnits[orgId] = omit(
        state.organizationUnits[orgId],
        orgUnitId
      );
    },
    editOrgUnitSuccess: (state, { payload }: ManageUnit) => {
      const { orgId, unit } = payload;
      state.organizationUnits[orgId][unit.id] = {
        ...state.organizationUnits[orgId][unit.id],
        ...unit,
      };
    },
    setUnitUserIds: (state, { payload }: SetUnitUsers) => {
      const { unitId, unitUserIds } = payload;
      state.organizationUnitUsers[unitId] = unitUserIds;
    },
    setOrgUnitUsersSuccess: (state, { payload }: SetUnitUsersCount) => {
      const { orgId, unitId, usersCount } = payload;
      state.organizationUnits[orgId][unitId].userCount = usersCount;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    getRulesSuccess: (state, { payload }: GetAlarmRules) => {
      const { unitId, rules } = payload;
      state.alarmRules[unitId] = mapKeys(rules, (rule) => rule.id);
    },
    addRuleSuccess: (state, { payload }: AddAlarmRule) => {
      const { orgId, unitId, rule } = payload;
      state.alarmRules[unitId][rule.id] = {
        ...rule,
      };
      state.organizationUnits[orgId][unitId] = {
        ...state.organizationUnits[orgId][unitId],
        alarmRuleCount:
          Number(state.organizationUnits[orgId][unitId].alarmRuleCount) + 1,
      };
    },
    deleteRuleSuccess: (state, { payload }: DeleteAlarmRule) => {
      const { orgId, unitId, alarmRuleId } = payload;
      state.alarmRules[unitId] = omit(state.alarmRules[unitId], alarmRuleId);
      state.organizationUnits[orgId][unitId] = {
        ...state.organizationUnits[orgId][unitId],
        alarmRuleCount:
          Number(state.organizationUnits[orgId][unitId].alarmRuleCount) - 1,
      };
    },
    editRuleSuccess: (state, { payload }: EditAlarmRule) => {
      const { unitId, rule } = payload;
      state.alarmRules[unitId][rule.id] = {
        ...rule,
      };
    },
  },
});

function unitsSelector(state: { [unitsSlice.name]: UnitsState }) {
  return state[unitsSlice.name];
}

export const organizationUnitsByIdSelector = createSelector(
  unitsSelector,
  (state: UnitsState) => state.organizationUnits
);

export const alarmRulesByUnitIdSelector = createSelector(
  unitsSelector,
  (state: UnitsState) => state.alarmRules
);

export const usersForOpenUnit = createSelector(
  unitsSelector,
  (state: UnitsState) => state.organizationUnitUsers
);

export const alarmRulesTypesAsSelectOption = [
  {
    id: AlarmRuleType.HeaterErrorOrWarning,
  },
  {
    id: AlarmRuleType.HeaterMaintenance,
  },
  {
    id: AlarmRuleType.HeaterService,
  },
  {
    id: AlarmRuleType.HeaterAshLevel,
  },
];

type AddOrgUnitActionPayload = {
  label: string;
  orgId: number;
};

function* handleAddOrgUnit({
  payload,
}: PayloadAction<AddOrgUnitActionPayload>): Generator {
  try {
    const { label, orgId } = payload;
    const orgUnit = (yield call(
      organizationsServices.addOrganizationUnit,
      orgId,
      label
    )) as OrganizationUnit;
    yield put(unitsSlice.actions.addUnitsSuccess({ orgId, unit: orgUnit }));
  } catch (error) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

export function* addOrgUnitAction(payload: AddOrgUnitActionPayload) {
  yield put({ type: UNIT_ACTIONS.ADD_UNIT, payload });
}

type EditOrgUnitActionPayload = AddOrgUnitActionPayload & {
  orgUnitId: number;
};

export function* editOrgUnitAction(payload: EditOrgUnitActionPayload) {
  yield put({ type: UNIT_ACTIONS.EDIT_UNIT, payload });
}

type DeleteOrgUnitActionPayload = {
  orgId: number;
  orgUnitId: number;
};

export function* deleteOrgUnitAction(payload: DeleteOrgUnitActionPayload) {
  yield put({ type: UNIT_ACTIONS.DELETE_UNIT, payload });
}

function* watchAddOrgUnit() {
  yield takeEvery(UNIT_ACTIONS.ADD_UNIT, handleAddOrgUnit);
}

function* handleEditOrgUnit({
  payload,
}: PayloadAction<EditOrgUnitActionPayload>): Generator {
  try {
    const { label, orgId, orgUnitId } = payload;
    const unit: OrganizationUnit = {
      id: orgUnitId,
      organizationId: orgId,
      label,
    };
    yield call(
      organizationsServices.editOrganizationUnit,
      orgId,
      orgUnitId,
      label
    );
    yield put(unitsSlice.actions.editOrgUnitSuccess({ orgId, unit }));
  } catch (error) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditOrgUnit() {
  yield takeEvery(UNIT_ACTIONS.EDIT_UNIT, handleEditOrgUnit);
}

function* handleDeleteOrgUnit({
  payload,
}: PayloadAction<DeleteOrgUnitActionPayload>): Generator {
  try {
    const { orgId, orgUnitId } = payload;
    yield call(organizationsServices.deleteOrganizationUnit, orgId, orgUnitId);
    yield put(unitsSlice.actions.deleteOrgUnitSuccess({ orgId, orgUnitId }));
  } catch (error) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteOrgUnit() {
  yield takeEvery(UNIT_ACTIONS.DELETE_UNIT, handleDeleteOrgUnit);
}

function* handleGetOrgUnits(
  action: PayloadAction<{ orgId: number }>
): Generator {
  try {
    const { orgId } = action.payload;
    const units = (yield call(
      organizationsServices.getOrganizationUnits,
      orgId
    )) as OrganizationUnitWithDetails[];
    yield put(unitsSlice.actions.getUnitsSuccess({ orgId, units }));
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetOrgUnits() {
  yield takeLatest(UNIT_ACTIONS.GET_UNITS, handleGetOrgUnits);
}

function* handleSetOrgUnitUsers(
  action: PayloadAction<{ orgId: number; unitId: number; users: number[] }>
): Generator {
  try {
    const { orgId, unitId, users } = action.payload;

    yield call(
      organizationsServices.setOrganizationUnitUsers,
      orgId,
      unitId,
      users
    );

    yield put(
      unitsSlice.actions.setOrgUnitUsersSuccess({
        orgId,
        unitId,
        usersCount: users.length,
      })
    );
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetOrgUnitUsers() {
  yield takeLatest(UNIT_ACTIONS.SET_UNIT_USERS, handleSetOrgUnitUsers);
}

function* handleGetAlarmRules(
  action: PayloadAction<{ orgId: number; unitId: number }>
): Generator {
  try {
    const { orgId, unitId } = action.payload;
    const response = (yield call(
      organizationsServices.getOrganizationUnitAlarmRules,
      orgId,
      unitId
    )) as { data: AlarmRule[] };
    yield put(
      unitsSlice.actions.getRulesSuccess({ unitId, rules: response.data })
    );
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetAlarmRules() {
  yield takeLatest(UNIT_ACTIONS.GET_ALARM_RULES, handleGetAlarmRules);
}

type AddAlarmRuleActionPayload = {
  orgId: number;
  unitId: number;
  rule: AlarmRulePayload;
};

function* handleAddAlarmRules(
  action: PayloadAction<AddAlarmRuleActionPayload>
): Generator {
  try {
    const { orgId, unitId, rule } = action.payload;
    const newRule = (yield call(
      organizationsServices.addOrganizationUnitAlarmRule,
      orgId,
      unitId,
      rule
    )) as AlarmRule;
    yield put(
      unitsSlice.actions.addRuleSuccess({ orgId, unitId, rule: newRule })
    );
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddAlarmRules() {
  yield takeEvery(UNIT_ACTIONS.ADD_ALARM_RULE, handleAddAlarmRules);
}

type DeleteAlarmRuleActionPayload = {
  orgId: number;
  unitId: number;
  alarmRuleId: number;
};

function* handleDeleteAlarmRules(
  action: PayloadAction<DeleteAlarmRuleActionPayload>
): Generator {
  try {
    const { orgId, unitId, alarmRuleId } = action.payload;
    yield call(
      organizationsServices.removeOrganizationUnitAlarmRule,
      orgId,
      unitId,
      alarmRuleId
    );
    yield put(
      unitsSlice.actions.deleteRuleSuccess({ orgId, unitId, alarmRuleId })
    );
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteAlarmRule() {
  yield takeEvery(UNIT_ACTIONS.DELETE_ALARM_RULE, handleDeleteAlarmRules);
}

type EditAlarmRuleActionPayload = {
  orgId: number;
  unitId: number;
  alarmRuleId: number;
  rule: AlarmRulePayload;
};

function* handleEditAlarmRules(
  action: PayloadAction<EditAlarmRuleActionPayload>
): Generator {
  try {
    const { orgId, unitId, alarmRuleId, rule } = action.payload;

    const editedRule = (yield call(
      organizationsServices.editOrganizationUnitAlarmRule,
      orgId,
      unitId,
      alarmRuleId,
      rule
    )) as AlarmRule;
    yield put(unitsSlice.actions.editRuleSuccess({ unitId, rule: editedRule }));
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditAlarmRule() {
  yield takeEvery(UNIT_ACTIONS.EDIT_ALARM_RULE, handleEditAlarmRules);
}

function* deleteAlarmRuleAction(payload: DeleteAlarmRuleActionPayload) {
  yield put({ type: UNIT_ACTIONS.DELETE_ALARM_RULE, payload });
}

function* editAlarmRuleAction(payload: EditAlarmRuleActionPayload) {
  yield put({ type: UNIT_ACTIONS.EDIT_ALARM_RULE, payload });
}

function* addAlarmRuleAction(payload: AddAlarmRuleActionPayload) {
  yield put({ type: UNIT_ACTIONS.ADD_ALARM_RULE, payload });
}

const mapRule = (rule: AlarmRule) =>
  rule.type === AlarmRuleType.HeaterErrorOrWarning
    ? pick(rule, "type")
    : pick(rule, "type", "params");

function* handleSaveRulesChanges(action: SaveAlarmRules): Generator {
  try {
    const { orgId, unitId, rules, initialRules } = action.payload;

    const addedRules = rules.filter(
      (currentRule: AlarmRule) =>
        !initialRules.some((rule) => rule.id === currentRule.id)
    );

    const removedRules = initialRules.filter(
      (rule) => !rules.some((currentRule) => rule.id === currentRule.id)
    );

    const editedRules = rules.filter((currentRule) =>
      initialRules.some(
        (rule) =>
          rule.id === currentRule.id &&
          (rule.type !== currentRule.type ||
            rule?.params?.value !== currentRule?.params?.value)
      )
    );

    const addActions = addedRules.map((rule) =>
      addAlarmRuleAction({
        orgId,
        unitId,
        rule: mapRule(rule),
      })
    );

    const editActions = editedRules.map((rule) =>
      editAlarmRuleAction({
        orgId,
        unitId,
        alarmRuleId: rule.id,
        rule: mapRule(rule),
      })
    );

    const removeActions = removedRules.map((rule) =>
      deleteAlarmRuleAction({
        orgId,
        unitId,
        alarmRuleId: rule.id,
      })
    );

    yield all([...removeActions]);
    yield all([...editActions, ...addActions]);
  } catch (err) {
    yield put(unitsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSaveRulesChanges() {
  yield takeLatest(UNIT_ACTIONS.SAVE_ALARM_RULES, handleSaveRulesChanges);
}

export function* unitsWatcher() {
  yield all([
    fork(watchGetOrgUnits),
    fork(watchAddOrgUnit),
    fork(watchEditOrgUnit),
    fork(watchDeleteOrgUnit),
    fork(watchSetOrgUnitUsers),
    fork(watchGetAlarmRules),
    fork(watchAddAlarmRules),
    fork(watchSaveRulesChanges),
    fork(watchDeleteAlarmRule),
    fork(watchEditAlarmRule),
  ]);
}

export default unitsSlice;
