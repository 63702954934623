import { Typography, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Language, allLanguages } from "models/Language";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getLanguage } from "store/ui";

const useStyles = makeStyles((theme: any) => ({
  copyRightText: {
    color: "#9D9DAF",
    [theme.breakpoints.only("xs")]: {
      paddingTop: "12px",
      width: "240px",
    },
  },
  languageLabel: {
    color: "#9D9DAF",
    marginRight: "8px",
  },
}));

function Footer() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = useSelector(getLanguage);

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  const onSelect = useCallback(
    (event: SelectChangeEvent) => {
      i18n.changeLanguage(event.target.value);
      dispatch(uiSlice.actions.setLanguage(event.target.value as Language));
    },
    [dispatch, i18n]
  );

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={styles.languageLabel}>
          {t("language.label")}
        </Typography>
        <Select value={language} onChange={onSelect}>
          {allLanguages.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`language.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Typography className={styles.copyRightText}>{t("copyRight")}</Typography>
    </>
  );
}

export default Footer;
