import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "components/DashboardGrid";

type Prop = {
  deleteLabel: string;
  deleteHelperText: string;
  isNew: boolean;
  onDelete: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  deleteButton: {
    border: `1px solid ${theme.palette.error.main} !important`,
    color: `${theme.palette.error.main} !important`,
    padding: "5px 15px !important",
  },
}));

function DeleteItemGrid({
  deleteLabel,
  deleteHelperText,
  isNew,
  onDelete,
}: Prop) {
  const styles = useStyles();

  if (isNew) {
    return null;
  }

  return (
    <DashboardGrid
      title={deleteLabel}
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Button
              className={styles.deleteButton}
              variant="outlined"
              onClick={onDelete}
            >
              {deleteLabel}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">{deleteHelperText}</Typography>
          </Grid>
        </Grid>
      }
    />
  );
}

export default DeleteItemGrid;
