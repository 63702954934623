import { SvgIcon } from "@mui/material";

function ItFlag() {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ fill: "none" }}>
      <g clipPath="url(#clip0)">
        <rect x="0" y="0" width="8" height="18" fill="#009345" />
        <rect x="8" y="0" width="8" height="18" fill="#FFFFFF" />
        <rect x="16" y="0" width="8" height="18" fill="#CE2B37" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ItFlag;
