import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function UsersIcon({ className = "" }: Props) {
  return (
    <SvgIcon className={className}>
      <g clipPath="url(#clip0_1666_5384)">
        <path d="M15.275 10.293L13.087 9.66802C12.9102 9.61746 12.7507 9.51911 12.6262 9.38379C12.5016 9.24848 12.4168 9.08143 12.381 8.90102L12.247 8.22602C12.7696 7.98721 13.2125 7.60336 13.5233 7.12012C13.8341 6.63687 13.9995 6.07456 14 5.50002V4.12602C14.0121 3.33047 13.7163 2.56101 13.1744 1.97846C12.6325 1.3959 11.8864 1.04534 11.092 1.00002C10.499 0.98213 9.91406 1.14045 9.41105 1.45497C8.90803 1.76948 8.50956 2.22608 8.26601 2.76702C8.74306 3.4613 8.99893 4.28365 9.00001 5.12602V6.50002C8.99822 6.86379 8.94605 7.22556 8.84501 7.57502C9.10424 7.84696 9.41225 8.06779 9.75301 8.22602L9.61901 8.90002C9.58324 9.08043 9.49842 9.24748 9.37386 9.3828C9.2493 9.51811 9.08984 9.61646 8.91301 9.66702L8.07001 9.90802L9.55001 10.331C9.96721 10.4515 10.3341 10.7041 10.5955 11.0509C10.857 11.3976 10.9989 11.8198 11 12.254V14.5C10.9984 14.6707 10.9672 14.8399 10.908 15H15.5C15.6326 15 15.7598 14.9473 15.8536 14.8536C15.9473 14.7598 16 14.6326 16 14.5V11.254C15.9999 11.0368 15.9291 10.8256 15.7983 10.6522C15.6675 10.4788 15.4838 10.3527 15.275 10.293Z" />
        <path d="M9.275 11.293L7.087 10.668C6.91004 10.6174 6.75049 10.5189 6.62592 10.3834C6.50135 10.2479 6.4166 10.0806 6.381 9.90001L6.247 9.22501C6.7694 8.98626 7.21228 8.60257 7.52303 8.11952C7.83377 7.63648 7.99932 7.07438 8 6.50001V5.12601C8.01213 4.33045 7.71632 3.56099 7.17439 2.97844C6.63246 2.39589 5.88636 2.04532 5.092 2.00001C4.69036 1.98768 4.29034 2.05617 3.91568 2.20141C3.54101 2.34665 3.19935 2.56567 2.91095 2.84549C2.62256 3.1253 2.39332 3.4602 2.23683 3.8303C2.08035 4.20041 1.99981 4.59818 2 5.00001V6.50001C2.00049 7.07454 2.16595 7.63686 2.4767 8.1201C2.78746 8.60335 3.23045 8.98719 3.753 9.22601L3.619 9.90001C3.58323 10.0804 3.49841 10.2475 3.37385 10.3828C3.24929 10.5181 3.08983 10.6164 2.913 10.667L0.725 11.292C0.516025 11.3518 0.332214 11.478 0.201397 11.6516C0.0705796 11.8252 -0.000120636 12.0367 1.54521e-07 12.254V14.5C1.54521e-07 14.6326 0.0526786 14.7598 0.146447 14.8536C0.240215 14.9473 0.367392 15 0.5 15H9.5C9.63261 15 9.75979 14.9473 9.85355 14.8536C9.94732 14.7598 10 14.6326 10 14.5V12.254C9.9999 12.0368 9.9291 11.8256 9.7983 11.6522C9.6675 11.4788 9.48381 11.3527 9.275 11.293Z" />
      </g>
      <defs>
        <clipPath id="clip0_1666_5384">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default UsersIcon;
