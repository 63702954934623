import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { Provider } from "react-redux";
import Snackbar from "components/common/Snackbar";

import store, { history } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import "./index.css";
import WithAxios from "./WithAxios";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline />
          <ReduxRouter history={history}>
            <WithAxios>
              <>
                <Snackbar />
                <App />
              </>
            </WithAxios>
          </ReduxRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
