import { makeStyles } from "@mui/styles";

const useContentStyles = makeStyles((theme: any) => ({
  content: {
    width: "80%",
    margin: "48px auto 48px auto",
    paddingBottom: "16px",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
}));

export default useContentStyles;
