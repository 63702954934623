import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { DeviceKind } from "models/Heater";
import { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";

type Prop = {
  handleChange: (e: ChangeEvent<any>) => void;
  currentValue: string;
};

function KindGrid({ handleChange, currentValue }: Prop) {
  const { t } = useTranslation();

  return (
    <DashboardGrid
      title={t("modules.devices.grid.kind.header")}
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup name="kind" onChange={handleChange}>
              <FormControlLabel
                value={DeviceKind.Test.toString()}
                control={
                  <Radio
                    color="primary"
                    checked={currentValue === DeviceKind.Test.toString()}
                  />
                }
                label={DeviceKind[DeviceKind.Test]}
              />
              <FormControlLabel
                value={DeviceKind.Production.toString()}
                control={
                  <Radio
                    color="primary"
                    checked={currentValue === DeviceKind.Production.toString()}
                  />
                }
                label={DeviceKind[DeviceKind.Production]}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      }
    />
  );
}

export default KindGrid;
