import Layout from "components/Layout";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticatedSelector } from "store/auth";

type RouteProps = {
  redirectPath: string;
  hasNavigation?: boolean;
};

export function ProtectedRoute({
  redirectPath,
  hasNavigation = true,
}: RouteProps) {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!hasNavigation) {
    return <Outlet />;
  }

  return <Layout />;
}

type PublicRouteProps = Pick<RouteProps, "redirectPath">;

export function PublicRoute({ redirectPath }: PublicRouteProps) {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}
