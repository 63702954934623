import { t } from "i18next";
import ParametersData from "models/Parameters";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getHeaterEvent } from "store/heaterCalendar";
import ItemCell from "./ItemCell";

type Props = {
  id: number;
};

function TooltipContent({ id }: Props) {
  const getEvent = useMemo(() => getHeaterEvent(id), [id]);
  const eventData = useSelector(getEvent);

  return (
    <div style={{ width: "150px" }}>
      <ItemCell
        label={t("modules.dashboard.calendar.targetRoomTemperature")}
        value={`${eventData.targetRoomTemperature} ${ParametersData.TargetRoomTemperature.unit}`}
        width="150px"
      />

      <ItemCell
        label={t("modules.dashboard.calendar.targetHotAirTemperature")}
        value={`${eventData.targetHotAirTemperature} ${ParametersData.TargetHotAirTemperature.unit}`}
        width="150px"
      />

      <ItemCell
        label={t("modules.dashboard.calendar.targetPowerHotAirFan")}
        value={`${eventData.targetPowerHotAirFan} ${ParametersData.HotAirFanPower.unit}`}
        width="150px"
      />

      <ItemCell
        label={t("modules.dashboard.calendar.hotAirFan")}
        value={eventData.modeHotAirFan ? "true" : "false"}
        width="150px"
      />

      <ItemCell
        label={t("modules.dashboard.calendar.additionalCooling")}
        value={eventData.additionalCooling ? "true" : "false"}
        width="150px"
      />
    </div>
  );
}

export default TooltipContent;
