import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import heaterErrorHistorySlice, {
  heaterErrorHistoryListSelector,
} from "../../../store/heaterErrorHistory";
import HeaterErrorsTable from "./HeaterErrorsTable";

type Props = {
  deviceId: number;
};

function ErrorHistoryTab({ deviceId }: Props) {
  const dispatch = useDispatch();
  const errors = useSelector(heaterErrorHistoryListSelector);

  useEffectOnce(() => {
    dispatch(heaterErrorHistorySlice.actions.init(deviceId));
  });

  return (
    <div>
      <HeaterErrorsTable errors={errors} />
    </div>
  );
}

export default ErrorHistoryTab;
