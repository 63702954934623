import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  deviceModel: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "16px",
    padding: "4px 12px",
    fontFamily: "Ropa Sans",
    fontSize: "11px",
    letterSpacing: "2px",
  },
}));

type Props = {
  model: string;
};

function DeviceModelIcon({ model }: Props) {
  const styles = useStyles();

  return <span className={styles.deviceModel}>{model}</span>;
}

export default DeviceModelIcon;
