import { FormControlLabel, Grid, Switch } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { getFirstLetterToUpperCase } from "helpers";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Prop = {
  handleChange: (e: ChangeEvent<any>) => void;
  currentHasMqtt: boolean;
  currentHasOvpn: boolean;
};

function AddCertificateGrid({
  handleChange,
  currentHasMqtt,
  currentHasOvpn,
}: Prop) {
  const { t } = useTranslation();

  return (
    <DashboardGrid
      title={t("modules.devices.grid.certificate.header")}
      includeBottomDivider
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormControlLabel
              name="hasMqtt"
              control={
                <Switch
                  color="primary"
                  checked={currentHasMqtt}
                  onChange={handleChange}
                />
              }
              label={getFirstLetterToUpperCase(
                t("modules.devices.grid.certificate.buttons.createMqtt")
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControlLabel
              name="hasOvpn"
              control={
                <Switch
                  color="primary"
                  checked={currentHasOvpn}
                  onChange={handleChange}
                />
              }
              label={getFirstLetterToUpperCase(
                t("modules.devices.grid.certificate.buttons.createOvpn")
              )}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default AddCertificateGrid;
