import { SvgIcon } from "@mui/material";

function DownArrowIcon() {
  return (
    <SvgIcon
      viewBox="0 0 12 7"
      sx={{ fill: "none", width: "12px", height: "7px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.162141 0.131426C0.3657 -0.0551703 0.681984 -0.0414189 0.86858 0.162141L6 5.76006L11.1314 0.162141C11.318 -0.0414189 11.6343 -0.0551703 11.8379 0.131426C12.0414 0.318022 12.0552 0.634306 11.8686 0.837865L6.36858 6.83787C6.27387 6.94118 6.14016 7 6 7C5.85985 7 5.72613 6.94118 5.63143 6.83787L0.131426 0.837865C-0.0551703 0.634306 -0.0414189 0.318022 0.162141 0.131426Z"
        fill="#FF5200"
      />
    </SvgIcon>
  );
}

export default DownArrowIcon;
