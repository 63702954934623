import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function AdminDevicesLargeIcon({ className = "" }: Props) {
  return (
    <SvgIcon
      className={className}
      viewBox="0 0 156 156"
      sx={{ fill: "none", width: "156px", height: "156px" }}
    >
      <path
        d="M153 0.5H3C1.61929 0.5 0.5 1.61929 0.5 3V13C0.5 14.3807 1.61929 15.5 3 15.5H153C154.381 15.5 155.5 14.3807 155.5 13V3C155.5 1.61929 154.381 0.5 153 0.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M76.6175 48.2627C76.2363 48.0722 75.8126 47.9823 75.3869 48.0016C74.9611 48.0209 74.5474 48.1487 74.1849 48.3729C73.8225 48.597 73.5233 48.9102 73.3159 49.2825C73.1086 49.6548 72.9998 50.074 73 50.5002C73 56.9652 69.285 60.6802 64.9825 64.9827C60.5375 69.4252 55.5 74.4652 55.5 83.0002C55.5041 86.8341 56.4887 90.6033 58.3602 93.9494C60.2317 97.2955 62.9279 100.107 66.1925 102.118C68.4575 93.4527 75.6925 93.5277 76.75 83.0002C80.3517 84.824 83.4577 87.4936 85.8019 90.7804C88.1461 94.0673 89.6587 97.8734 90.21 101.873C93.3636 99.8353 95.9572 97.0411 97.7545 93.7447C99.5518 90.4484 100.496 86.7547 100.5 83.0002C100.5 71.6552 93.115 56.5127 76.6175 48.2627Z"
        fill="#C7C7D1"
      />
      <path
        d="M125.5 100.5V108C125.5 108.663 125.237 109.299 124.768 109.768C124.299 110.237 123.663 110.5 123 110.5H33C32.337 110.5 31.7011 110.237 31.2322 109.768C30.7634 109.299 30.5 108.663 30.5 108V100.5H10.5V123C10.5 123.663 10.7634 124.299 11.2322 124.768C11.7011 125.237 12.337 125.5 13 125.5H143C143.663 125.5 144.299 125.237 144.768 124.768C145.237 124.299 145.5 123.663 145.5 123V100.5H125.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M10.5 55.5H36.1825C39.3503 49.6667 43.6995 44.558 48.9525 40.5H10.5V55.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M10.5 60.5V75.5H30.6275C30.8831 70.3559 31.981 65.2886 33.8775 60.5H10.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M145.5 60.5H122.118C124.014 65.2886 125.112 70.3559 125.367 75.5H145.5V60.5Z"
        fill="#C7C7D1"
      />
      <path d="M145.5 80.5H125.5V95.5H145.5V80.5Z" fill="#C7C7D1" />
      <path
        d="M107.048 40.5C112.3 44.558 116.65 49.6667 119.818 55.5H145.5V40.5H107.048Z"
        fill="#C7C7D1"
      />
      <path d="M30.5 80.5H10.5V95.5H30.5V80.5Z" fill="#C7C7D1" />
      <path d="M33 20.5H10.5V35.5H33V20.5Z" fill="#C7C7D1" />
      <path
        d="M38 20.5V35.5H56.915C62.7091 32.6153 69.0359 30.9565 75.5 30.6275V20.5H38Z"
        fill="#C7C7D1"
      />
      <path
        d="M80.5 20.5V30.6275C86.9641 30.9565 93.2909 32.6153 99.085 35.5H118V20.5H80.5Z"
        fill="#C7C7D1"
      />
      <path d="M145.5 20.5H123V35.5H145.5V20.5Z" fill="#C7C7D1" />
    </SvgIcon>
  );
}

export default AdminDevicesLargeIcon;
