import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function OrganizationIcon({ className = "" }: Props) {
  return (
    <SvgIcon className={className}>
      <g clipPath="url(#clip0_1666_5466)">
        <path d="M16 10V5C16 4.4 15.6 4 15 4H12V1C12 0.4 11.6 0 11 0H5C4.4 0 4 0.4 4 1V4H1C0.4 4 0 4.4 0 5V10C0 10.6 0.4 11 1 11H15C15.6 11 16 10.6 16 10ZM6 2H10V4H6V2Z" />
        <path d="M1 13V15C1 15.6 1.4 16 2 16H14C14.6 16 15 15.6 15 15V13H1Z" />
      </g>
      <defs>
        <clipPath id="clip0_1666_5466">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default OrganizationIcon;
