import { makeStyles } from "@mui/styles";
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  type MouseEvent,
} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import {
  Divider,
  Alert,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalWrapper from "components/common/ModalWrapper";
import { groupNamesSelector, GROUP_ACTIONS } from "store/group";
import { useDispatch, useSelector } from "react-redux";
import { trimString } from "helpers";
import useDeleteModal from "hooks/useDeleteModal";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
  alert: {
    margin: "10px 0",
  },
}));

type Props = {
  groupId: number;
};

function ManageGroupMenu({ groupId }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState("");
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const groupNames = useSelector(groupNamesSelector);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onOpenEditModal = useCallback(() => {
    setIsEditModalOpen(true);
    handleClose();
  }, [handleClose]);

  const onCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false);
    setGroupName("");
  }, []);

  const onDeleteGroup = useCallback(() => {
    dispatch({
      type: GROUP_ACTIONS.DELETE_GROUP,
      payload: {
        groupId,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, groupId, onCloseDeleteModal]);

  const onEditGroup = useCallback(() => {
    dispatch({
      type: GROUP_ACTIONS.EDIT_GROUP,
      payload: {
        groupId,
        label: trimString(groupName),
      },
    });
    onCloseEditModal();
  }, [dispatch, groupId, groupName, onCloseEditModal]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGroupName(event.target.value);
    },
    []
  );

  const isGroupNameInvalid = useMemo(
    () => groupName.trim().length === 0,
    [groupName]
  );

  const groupWithNameAlreadyExists = useMemo(
    () => groupNames.includes(trimString(groupName)),
    [groupName, groupNames]
  );

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onOpenEditModal}>
          <Typography>{t("modals.groups.edit")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenDeleteModal();
            handleClose();
          }}
        >
          <Typography color="error">{t("modals.groups.delete")}</Typography>
        </MenuItem>
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDeleteGroup}
        title={t("modals.groups.delete")}
        contentLabel={t("modals.groups.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
      <ModalWrapper
        isOpen={isEditModalOpen}
        onCancelAction={onCloseEditModal}
        onOkAction={onEditGroup}
        title={t("modals.groups.edit")}
        contentLabel={t("modals.groups.editSubtitle")}
        okLabel={t("buttons.add")}
        cancelLabel={t("buttons.close")}
        isOkDisabled={
          !groupName || isGroupNameInvalid || groupWithNameAlreadyExists
        }
        content={
          <>
            <Typography variant="subtitle2">
              {t("modals.groups.groupName")}
            </Typography>
            <TextField
              name="label"
              placeholder={t("modals.groups.groupNamePlaceholder")}
              variant="outlined"
              value={groupName}
              onChange={handleInputChange}
              hiddenLabel
              required
              fullWidth
            />
            {groupWithNameAlreadyExists && (
              <Alert
                className={styles.alert}
                variant="outlined"
                severity="info"
                color="warning"
              >
                <Typography>{t("modals.groups.groupNameExists")}</Typography>
              </Alert>
            )}
          </>
        }
      />
    </>
  );
}

export default ManageGroupMenu;
