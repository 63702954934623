import { Box } from "@mui/material";
import EmptyList from "components/common/EmptyList";
import PageHeader from "components/common/PageHeader";
import LargeUserIcon from "components/icons/LargeUserIcon";
import UsersTable from "components/users/UsersTable";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usersSlice, { userListSelector } from "store/users";
import useContentStyles from "./useContentStyles";

function Users() {
  const styles = useContentStyles();
  const dispatch = useDispatch();
  const users = useSelector(userListSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(usersSlice.actions.init());
  }, [dispatch]);

  const onAdd = useCallback(() => navigate("new"), [navigate]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        const { username, lastName, firstName } = user;
        const fullName = `${firstName} ${lastName}`;
        const lowerCaseSearchValue = searchValue.toLowerCase();

        const matchFullName = fullName
          .toLowerCase()
          .includes(lowerCaseSearchValue);

        const matchUsername = username
          .toLowerCase()
          .includes(lowerCaseSearchValue);

        return matchFullName || matchUsername;
      }),
    [searchValue, users]
  );

  return (
    <Box className={styles.content}>
      <PageHeader
        title={t("modules.users.title")}
        addLabel={t("modules.users.addNew")}
        onAdd={onAdd}
        enableSearch
        searchPlaceholder={t("modules.users.placeholder")}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {users.length === 0 && (
        <EmptyList
          icon={<LargeUserIcon />}
          emptyListText={t("modules.users.empty")}
          onOpen={onAdd}
        />
      )}
      {users.length > 0 && <UsersTable users={filteredUsers} />}
    </Box>
  );
}

export default Users;
