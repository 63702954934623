import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomHidden from "components/common/CustomHidden";
import getComparator, { Order } from "components/common/tableHelpers";
import {
  defaultRowsPerPage,
  getFormatedDate,
  rowsPerPageOptions,
} from "helpers";
import { SoftwareUpdate } from "models/Update";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import ManageUpdateMenu from "./ManageUpdateMenu";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes = "key" | "type" | "lastModified";

type Props = {
  updates: SoftwareUpdate[];
};

function SoftwareUpdatesTable({ updates }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("key");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "key" as OrderByTypes,
        label: t(`modules.softwareUpdates.table.name`).toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
      {
        id: "lastModified" as OrderByTypes,
        label: t(`modules.softwareUpdates.table.lastModified`).toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedValues = useMemo(
    () => updates.sort(getComparator(order, orderBy)),

    [updates, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) =>
              headCell.smallHidden ? (
                <CustomHidden size="xs" hideType="only">
                  <TableCell
                    key={headCell.id}
                    className={headCell.className}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </CustomHidden>
              ) : (
                <TableCell
                  key={headCell.id}
                  className={headCell.className}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((update) => (
              <TableRow key={`row-${update.key}-${update.lastModified}`}>
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">{update.key}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {getFormatedDate(update.lastModified)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <ManageUpdateMenu softwareKey={update.key} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={updates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default SoftwareUpdatesTable;
