import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormFooter from "components/common/FormFooter";
import ModalWrapper from "components/common/ModalWrapper";
import paths from "config/paths";
import { Formik } from "formik";
import useDeleteModal from "hooks/useDeleteModal";
import { isEqual } from "lodash";
import { GuideFormValues } from "models/TroubleshootingGuide";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  TROUBLESHOOTING_ADMIN_ACTIONS,
  createGuideInitialFormValues,
} from "store/troubleshootingAdmin";
import DeleteGrid from "./DeleteGrid";
import EditGuideHeader from "./EditGuideHeader";
import GeneralInformationGrid from "./GeneralInformationGrid";
import RemoteDevicesGrid from "./RemoteDevicesGrid";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "400px",
    height: "calc(100%)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
    },
  },
}));

function EditGuide() {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { guideId } = useParams();
  const id = guideId && Number(guideId) ? +guideId : 0;
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const getInitialValues = useMemo(
    () => createGuideInitialFormValues(id),
    [id]
  );
  const initialValues = useSelector(getInitialValues);
  const isNew = id === 0;

  useEffect(() => {
    dispatch({
      type: TROUBLESHOOTING_ADMIN_ACTIONS.INIT,
      payload: {
        guideId: id,
      },
    });
  }, [dispatch, id]);

  const onSubmit = useCallback(
    (data: GuideFormValues) => {
      if (isNew) {
        dispatch({
          type: TROUBLESHOOTING_ADMIN_ACTIONS.ADD_GUIDE,
          payload: {
            formValues: data,
          },
        });
      } else {
        dispatch({
          type: TROUBLESHOOTING_ADMIN_ACTIONS.EDIT_GUIDE,
          payload: {
            guideId: id,
            remoteDevices: data.remoteDevices,
          },
        });
      }
    },
    [dispatch, id, isNew]
  );

  const onBack = useCallback(
    () => navigate(paths.troubleshootingAdmin),
    [navigate]
  );

  const onDelete = useCallback(() => {
    dispatch({
      type: TROUBLESHOOTING_ADMIN_ACTIONS.DELETE_GUIDE,
      payload: {
        guideId: id,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, id, onCloseDeleteModal]);

  const isValid = useCallback(
    (formValues: GuideFormValues): boolean => {
      if (isNew) {
        return Boolean(formValues.update && formValues.fileName);
      }
      return !isEqual(formValues.remoteDevices, initialValues.remoteDevices);
    },
    [initialValues.remoteDevices, isNew]
  );

  return (
    <div>
      <EditGuideHeader guideId={id} onBack={onBack} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={styles.paper}>
              <GeneralInformationGrid isEdit={!isNew} />
              <br />
              <RemoteDevicesGrid />
              <DeleteGrid isNew={isNew} onDelete={onOpenDeleteModal} />
            </Paper>
            <FormFooter
              id={id}
              onCancel={onBack}
              saveDisabled={!dirty || !isValid(values)}
            />
          </form>
        )}
      </Formik>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDelete}
        title={t("modals.troubleshooting.delete")}
        contentLabel={t("modals.troubleshooting.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </div>
  );
}

export default EditGuide;
