import { AppBar, Menu, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import Logo from "components/Logo";
import UserPreferenceMenu from "components/UserPreferenceMenu";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import MenuIcon from "components/icons/MenuIcon";
import noop from "lodash/noop";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAvailableNavItems } from "store/auth";
import uiSlice from "store/ui";
import paths from "../../config/paths";
import CustomHidden from "./CustomHidden";
import {
  getNavItemIcon,
  navItemForUrlPrefix,
  navItemKeyForUrl,
  navItemsMap,
} from "./navItems";

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 80,
    backgroundColor: theme.palette.grey["50"],
    width: "100%",
  },
  logo: {
    margin: "0 76px",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  tab: {
    fontSize: "14px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "none",
    opacity: "0.7",
    marginRight: "20px",
    "& .MuiTab-iconWrapper": {
      marginTop: "8px",
      marginBottom: 0,
      marginRight: "6px",
    },
  },
  activeTab: {
    opacity: "unset",
  },
  active: {
    color: theme.palette.primary.dark,
  },
}));

function Header() {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navItems = useSelector(getAvailableNavItems);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState(paths.dashboard);
  const [currentNavMenu, setCurrentNavMenu] = useState("updates");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const base = useMemo(
    () => navItemForUrlPrefix(location.pathname)?.url ?? location.pathname,
    [location.pathname]
  );

  useEffect(() => {
    setActivePath(base);
  }, [base]);

  const onMenuIconClick = useCallback(
    () => dispatch(uiSlice.actions.setIsDrawerOpen(true)),
    [dispatch]
  );

  const onChange = useCallback(
    (e: any, newPath: string) => {
      if (newPath.startsWith(paths.updates)) {
        setCurrentNavMenu("updates");
        e.preventDefault();
      } else {
        setActivePath(navItemForUrlPrefix(newPath)?.url ?? newPath);
        navigate(newPath);
      }
    },
    [navigate]
  );

  const handleOpenMenu = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnNavMenuClick = useCallback(
    (path: string) => {
      setActivePath(navItemKeyForUrl(path) ?? navItemsMap.updates.url);
      navigate(`${currentNavMenu}/${path}`);
      handleCloseMenu();
    },
    [currentNavMenu, handleCloseMenu, navigate]
  );

  return (
    <AppBar className={styles.appBar} position="static">
      <CustomHidden size="md" hideType="down">
        <div className={styles.container}>
          <Logo className={styles.logo} />
          <Tabs indicatorColor="primary" value={activePath} onChange={onChange}>
            {navItems.map((item) => (
              <Tab
                className={classNames(styles.tab, {
                  [styles.activeTab]: item.url === activePath,
                })}
                key={item.titleKey}
                icon={getNavItemIcon(
                  item.url,
                  classNames({
                    [styles.active]: item.url === activePath,
                  })
                )}
                label={t(`modules.${item.titleKey}.title`)}
                value={item.url}
                onClick={item.children ? handleOpenMenu : noop}
              />
            ))}
          </Tabs>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {navItemsMap[currentNavMenu].children?.map((item) => (
              <MenuItem
                key={item.url}
                onClick={() => handleOnNavMenuClick(item.url)}
              >
                <Typography>{t(`modules.${item.titleKey}.title`)}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </CustomHidden>
      <CustomHidden size="md" hideType="up">
        <IconButtonWrapper icon={<MenuIcon />} onClick={onMenuIconClick} />
      </CustomHidden>
      <UserPreferenceMenu />
    </AppBar>
  );
}

export default Header;
