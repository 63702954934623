import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {
  hideType: "up" | "down" | "only";
  size: "sm" | "md" | "xs";
  children: any;
};

function CustomHidden({ hideType, size, children }: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints[hideType](size));

  if (matches) {
    return null;
  }

  return children;
}

export default CustomHidden;
