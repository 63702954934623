import { Calendar, momentLocalizer } from "react-big-calendar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/de";
import { useDispatch, useSelector } from "react-redux";
import { CalendarEventFormValues } from "models/Calendar";
import heaterCalendarSlice, {
  CALENDAR_ACTIONS,
  heaterCalendarCreateInProgress,
  heaterCalendarsById,
  heaterCalendarsData,
} from "store/heaterCalendar";
import { dashboardMachineName } from "store/dashboardParameters";
import {
  Alert,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import uiSlice, { getCulture, isCalendarModalOpenSelector } from "store/ui";
import DrawerWrapper from "components/common/DrawerWrapper";
import ModalWrapper from "components/common/ModalWrapper";
import EditorTemplate from "./calendar/EditorTemplate";
import MonthEvent from "./calendar/events/MonthEvent";
import {
  getCurrentStartAndEndDateFormated,
  getStartAndEndDateFormated,
  initialRepetitionValue,
  initialValues,
} from "./calendar/helpers";
import WeekEvent from "./calendar/events/WeekEvent";
import DayEvent from "./calendar/events/DayEvent";

const localizer = momentLocalizer(moment);

type Props = {
  deviceId: number;
};

function CalendarTab({ deviceId }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevId = useRef<number>(0);
  const isModalOpen = useSelector(isCalendarModalOpenSelector);
  const formRef = useRef<HTMLFormElement>(null);
  const dataById = useSelector(heaterCalendarsById);
  const data = useSelector(heaterCalendarsData);
  const machineName = useSelector(dashboardMachineName);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const actionInProgress = useSelector(heaterCalendarCreateInProgress);
  const [formInitialValues, setFormInitialValues] =
    useState<CalendarEventFormValues>(initialValues);
  const [removeAll, setRemoveAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const customCulture = useSelector(getCulture);

  useEffect(() => {
    if (deviceId && deviceId !== prevId.current) {
      prevId.current = deviceId;
      dispatch(heaterCalendarSlice.actions.init(deviceId));
    }
  }, [dispatch, deviceId, prevId]);

  const title = useMemo(
    () =>
      formInitialValues.id
        ? `${t("modules.dashboard.calendar.editBase")} ${machineName}`
        : `${t("modules.dashboard.calendar.addBase")} ${machineName}`,
    [formInitialValues.id, machineName, t]
  );

  const handleSelectSlot = useCallback(
    ({ start, end }: any) => {
      const startDate = moment(start);
      const endDate = moment(end);
      let { startFormated, endFormated } = getStartAndEndDateFormated(
        startDate,
        endDate
      );

      const duration = endDate.diff(startDate, "days");

      if (duration === 1) {
        const dates = getCurrentStartAndEndDateFormated(startDate, endDate);
        startFormated = dates.startFormated;
        endFormated = dates.endFormated;
      }

      const newInitial = {
        ...initialValues,
        ...initialRepetitionValue,
        id: 0,
        from: startFormated,
        to: endFormated,
        endsOn: endFormated,
      };

      setFormInitialValues(newInitial);
      dispatch(uiSlice.actions.setCalendarModelOpen(true));
    },
    [dispatch]
  );

  const handleSelectEvent = useCallback(
    (props: { id: number }) => {
      const editEventData = dataById[props.id];

      let dataToFormValues: CalendarEventFormValues = {
        ...initialValues,
        ...initialRepetitionValue,
        from: moment(editEventData.from).format("YYYY-MM-DD HH:mm"),
        to: moment(editEventData.to).format("YYYY-MM-DD HH:mm"),
        endsOn: moment(editEventData.from).format("YYYY-MM-DD HH:mm"),
        repeatOption: "none",
        endType: "endsAfter",
      };

      if (editEventData) {
        const {
          id,
          targetRoomTemperature,
          targetHotAirTemperature,
          targetPowerHotAirFan,
          modeHotAirFan,
          additionalCooling,
        } = editEventData;

        dataToFormValues = {
          ...dataToFormValues,
          id,
          targetRoomTemperature,
          targetHotAirTemperature,
          targetPowerHotAirFan,
          modeHotAirFan,
          additionalCooling,
        };
      }
      setFormInitialValues(dataToFormValues);
      dispatch(uiSlice.actions.setCalendarModelOpen(true));
    },
    [dataById, dispatch]
  );

  const onCreateClick = useCallback(() => {
    const startDate = moment(new Date());
    const endDate = moment(new Date());

    startDate.add("1", "hour").startOf("hour");
    endDate.add("2", "hour").startOf("hour");

    const { startFormated, endFormated } = getStartAndEndDateFormated(
      startDate,
      endDate
    );

    const newInitial = {
      ...initialValues,
      ...initialRepetitionValue,
      id: 0,
      from: startFormated,
      to: endFormated,
      endsOn: endFormated,
    };

    setFormInitialValues(newInitial);

    dispatch(uiSlice.actions.setCalendarModelOpen(true));
  }, [dispatch]);

  const onClose = useCallback(() => {
    setFormInitialValues(initialValues);
    dispatch(uiSlice.actions.setCalendarModelOpen(false));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    setDeleteModalOpen(true);
    dispatch(uiSlice.actions.setCalendarModelOpen(false));
  }, [dispatch]);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalOpen(false);
    setRemoveAll(false);
  }, []);

  const onDeleteEventConfirm = useCallback(
    (entryId: number) => {
      dispatch({
        type: CALENDAR_ACTIONS.DELETE_EVENT,
        payload: {
          deviceId,
          entryId,
          removeAll,
        },
      });
      onCloseDeleteModal();
    },
    [deviceId, dispatch, onCloseDeleteModal, removeAll]
  );

  const onSave = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  }, []);

  const onSubmit = useCallback(
    (formValues: CalendarEventFormValues) => {
      if (formValues.id) {
        dispatch({
          type: CALENDAR_ACTIONS.EDIT_EVENT,
          payload: {
            deviceId,
            formValues,
          },
        });
      } else {
        dispatch({
          type: CALENDAR_ACTIONS.ADD_EVENT,
          payload: {
            deviceId,
            formValues,
          },
        });
      }
    },
    [dispatch, deviceId]
  );

  const mappedData = useMemo(
    () =>
      data.map((item) => ({
        id: item.id,
        title: `${machineName}`,
        start: new Date(item.from),
        end: new Date(item.to),
      })),
    [data, machineName]
  );

  const isOkDisabled = useCallback(
    (formValues: CalendarEventFormValues) => {
      const currentErrors: string[] = [];
      const {
        from,
        to,
        frequency,
        frequencyType,
        endsOn,
        endType,
        endsAfter,
        repeatOption,
        weekdays,
      } = formValues;

      const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
      const validEndDate = moment(to).isAfter(currentDateTime);
      const validStartDate = moment(from).isBefore(to);

      if (!validStartDate) {
        currentErrors.push(
          t("modules.dashboard.calendar.validations.invalidStartTime")
        );
      }

      if (!validEndDate) {
        currentErrors.push(
          t("modules.dashboard.calendar.validations.invalidEndTime")
        );
      }

      const invalidDates = !validStartDate || !validEndDate;

      if (repeatOption !== "custom") {
        const newMessage = currentErrors.join(", ");
        setErrorMessage(newMessage);
        return invalidDates;
      }

      const validFrequency = frequency >= 1;
      const validEndsOn = moment(endsOn).isAfter(from);
      const validEndsAfter = endsAfter > 1 && endsAfter <= 100;
      const validEndsType = endType === "endsOn" ? validEndsOn : validEndsAfter;
      const validWeekDays =
        frequencyType === "weeks"
          ? weekdays.length === 7 && weekdays.some((i) => i === true)
          : true;

      if (!validFrequency) {
        currentErrors.push(
          t("modules.dashboard.calendar.validations.invalidFrequency")
        );
      }
      if (!validWeekDays) {
        currentErrors.push(
          t("modules.dashboard.calendar.validations.invalidWeekdays")
        );
      }
      if (endType === "endsOn") {
        if (!validEndsOn) {
          currentErrors.push(
            t("modules.dashboard.calendar.validations.invalidEndsOn")
          );
        }
      }

      if (!validEndsAfter) {
        currentErrors.push(
          t("modules.dashboard.calendar.validations.invalidEndsAfter")
        );
      }

      const newMessage = currentErrors.join(", ");
      setErrorMessage(newMessage);

      return (
        invalidDates || !validFrequency || !validEndsType || !validWeekDays
      );
    },
    [t]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={formInitialValues}
      enableReinitialize
    >
      {({ handleChange, handleSubmit, setFieldValue, values }) => (
        <form ref={formRef} onSubmit={handleSubmit}>
          <div style={{ marginBottom: "15px" }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={onCreateClick}
              style={{
                textTransform: "none",
              }}
            >
              {t("modules.dashboard.calendar.addNew")}
            </Button>
          </div>
          <Calendar
            localizer={localizer}
            events={mappedData}
            startAccessor="start"
            endAccessor="end"
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            style={{ height: 550 }}
            selectable
            views={["month", "week", "day"]}
            defaultDate={moment().toDate()}
            tooltipAccessor={() => null as any}
            culture={customCulture}
            formats={{
              dayRangeHeaderFormat: ({ start, end }, culture) => {
                const s = localizer.format(start, "MMMM DD", culture);
                const e = localizer.format(end, "DD, YYYY", culture);
                return `${s} - ${e}`;
              },
              dayHeaderFormat: (date, culture) =>
                localizer.format(date, "MMMM DD, YYYY", culture),
              monthHeaderFormat: (date, culture) =>
                localizer.format(date, "MMMM YYYY", culture),
            }}
            components={{
              event: WeekEvent,
              month: {
                event: MonthEvent,
              },
              week: {
                event: WeekEvent,
              },
              day: {
                event: DayEvent,
              },
            }}
          />
          <DrawerWrapper
            title={title}
            isOpen={isModalOpen}
            onCancelAction={onClose}
            onOkAction={onSave}
            onDeleteAction={values.id !== 0 ? onDelete : undefined}
            isOkDisabled={isOkDisabled(values) || actionInProgress}
            loading={actionInProgress}
            okLabel={t("buttons.save")}
            cancelLabel={t("buttons.close")}
            content={
              <>
                <EditorTemplate
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                {errorMessage && (
                  <Alert variant="outlined" severity="info" color="error">
                    <Typography> {errorMessage}</Typography>
                  </Alert>
                )}
              </>
            }
          />
          <ModalWrapper
            isOpen={isDeleteModalOpen}
            onCancelAction={onCloseDeleteModal}
            onOkAction={() => onDeleteEventConfirm(values.id)}
            title={t("modals.calendar.delete")}
            contentLabel={t("modals.calendar.deleteSubtitle")}
            okLabel={t("buttons.delete")}
            cancelLabel={t("buttons.close")}
            content={
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={removeAll}
                    onChange={(e) => setRemoveAll(e.target.checked)}
                  />
                }
                label={t("modules.dashboard.calendar.removeAll")}
                labelPlacement="end"
              />
            }
          />
        </form>
      )}
    </Formik>
  );
}

export default CalendarTab;
