import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement } from "react";
import IconButtonWrapper from "./IconButtonWrapper";

const StyledDrawerContainer = styled("div")(({ theme }) => ({
  width: "620px",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  background: theme.palette.grey[100],

  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    maxWidth: "100vw",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "16px",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "0 16px",
  justifyContent: "flex-end",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const DrawerContent = styled("div")(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  margin: `${theme.spacing(2)} 0`,
  padding: "16px",
}));

const ResponsiveButton = styled(Button)(({ theme }) => ({
  marginLeft: "8px",

  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    marginBottom: "8px",
    width: "100%",
  },
}));

type Props = {
  title: string;
  content?: ReactElement;
  isOpen: boolean;
  okLabel?: string;
  cancelLabel?: string;
  isOkDisabled?: boolean;
  onOkAction?: () => void;
  onCancelAction?: () => void;
  onDeleteAction?: () => void;
  loading?: boolean;
};

function DrawerWrapper({
  title,
  content,
  isOpen,
  onOkAction,
  onCancelAction,
  onDeleteAction,
  okLabel,
  cancelLabel,
  isOkDisabled,
  loading,
}: Props) {
  return (
    <Drawer open={isOpen} anchor="right" onClose={onCancelAction}>
      <StyledDrawerContainer>
        <DrawerHeader>
          <Typography variant="h5">{title}</Typography>
          {onDeleteAction && (
            <IconButtonWrapper
              onClick={onDeleteAction}
              icon={<DeleteOutlineIcon />}
            />
          )}
        </DrawerHeader>
        <Divider />
        <DrawerContent>{content}</DrawerContent>

        <DrawerFooter>
          <ResponsiveButton
            onClick={onCancelAction}
            variant="outlined"
            color="primary"
          >
            {cancelLabel}
          </ResponsiveButton>
          {okLabel && !loading && (
            <ResponsiveButton
              variant="contained"
              onClick={onOkAction}
              disabled={isOkDisabled}
              color="primary"
            >
              {okLabel}
            </ResponsiveButton>
          )}
          {okLabel && loading && <CircularProgress />}
        </DrawerFooter>
      </StyledDrawerContainer>
    </Drawer>
  );
}

export default DrawerWrapper;
