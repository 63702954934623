/* eslint-disable no-console */
import mqtt, { IConnectPacket, IDisconnectPacket, IPublishPacket } from "mqtt";
import { eventChannel } from "redux-saga";
import { AnyAction } from "@reduxjs/toolkit";
import { MQTT_ACTIONS } from "store/mqtt";

const subscriptionTopic = `cloud/${process.env.REACT_APP_MQTT_TOPIC_ENV}/mph/#`;
const RECONNECT_ATTEMPTS = 5;
let reconnectCount = 0;

const mqttChannel = (mqttUrl: string) =>
  eventChannel<AnyAction | IPublishPacket>((emit) => {
    const clientId = `rhmFe_${Math.floor(Math.random() * 100000)}`;
    const client = mqtt.connect(mqttUrl, {
      clientId,
    });

    client.on("connect", (packet: IConnectPacket) => {
      console.log(`MQTT Connect: ${JSON.stringify(packet)}`);
      client.subscribe(subscriptionTopic, (error) => {
        if (error) {
          console.log(`MQTT Subscribe error: ${error}`);
        } else {
          reconnectCount = 0;
          console.log("MQTT subscribed successfully.");
        }
      });
    });

    client.on("disconnect", (packet: IDisconnectPacket) => {
      console.log(`MQTT Disconnect: ${JSON.stringify(packet)}`);
    });

    client.on("reconnect", () => {
      console.log("MQTT Reconnect...");
      reconnectCount += 1;

      if (reconnectCount < RECONNECT_ATTEMPTS) {
        emit({ type: MQTT_ACTIONS.RECONNECT });
        client.end();
      } else {
        reconnectCount = 0;
        emit({ type: MQTT_ACTIONS.STOP_CONNECTION });
        client.end();
      }
    });

    client.on("error", (error: unknown) => {
      console.log(`MQTT Error... ${error}`);
    });

    client.on(
      "message",
      (_topic: string, _payload: string, packet: IPublishPacket) => {
        emit(packet);
      }
    );

    return () => {
      if (client) {
        console.log("MQTT starting disconnect channel...");
        client.end();
      }
    };
  });

export default {
  mqttChannel,
};
