import { Checkbox, List, ListItem, ListItemText } from "@mui/material";
import DrawerWrapper from "components/common/DrawerWrapper";
import UnsavedChangesPrompt from "components/common/UnsavedChangesPrompt";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  UNIT_ACTIONS,
  organizationUnitsByIdSelector,
  usersForOpenUnit,
} from "store/organizationUnits";
import {
  ORGANIZATIONS_ACTIONS,
  organizationUsersByIdSelector,
} from "store/organizations";
import uiSlice, { isUsersUnitDrawerOpen } from "store/ui";

function sortArrayAscending(arr: number[]): number[] {
  return [...arr].sort((a, b) => a - b);
}

type Props = {
  onClose: (orgId: number) => void;
};

function UnitUsersDrawer({ onClose }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const orgId = Number(params.orgId) || 0;
  const unitId = Number(params.unitId) || 0;
  const initUsers = useSelector(usersForOpenUnit);
  const [usersForUnit, setUsersForUnit] = useState<number[]>([]);
  const isOpen = useSelector(isUsersUnitDrawerOpen);
  const usersById = useSelector(organizationUsersByIdSelector);
  const unitsById = useSelector(organizationUnitsByIdSelector);
  const users = Object.values(usersById[orgId] || {});
  const unitName = unitsById[orgId]?.[unitId].label || "-";
  const [showPrompt, setShowPropt] = useState(false);
  const isPristine = useMemo(
    () =>
      isEqual(
        sortArrayAscending(usersForUnit || []),
        sortArrayAscending(initUsers[unitId] || [])
      ),
    [initUsers, unitId, usersForUnit]
  );

  useEffect(() => {
    if (orgId && unitId) {
      dispatch({
        type: ORGANIZATIONS_ACTIONS.GET_ORG_USERS,
        payload: {
          orgId,
          unitId,
        },
      });
      dispatch(uiSlice.actions.setUsersUnitDrawerOpen(true));
    }
  }, [dispatch, unitId, orgId]);

  useEffect(() => {
    if (initUsers[unitId]) {
      setUsersForUnit(initUsers[unitId]);
    }
  }, [initUsers, unitId]);

  const handleClose = useCallback(() => {
    if (!isPristine) {
      setShowPropt(true);
    } else {
      onClose(orgId);
    }
  }, [isPristine, onClose, orgId]);

  const onConfirmClose = useCallback(() => {
    onClose(orgId);
    setShowPropt(false);
  }, [onClose, orgId]);

  const onCancelClose = useCallback(() => setShowPropt(false), []);

  const onSave = useCallback(() => {
    dispatch({
      type: UNIT_ACTIONS.SET_UNIT_USERS,
      payload: {
        orgId,
        unitId,
        users: usersForUnit,
      },
    });
    onClose(orgId);
  }, [dispatch, orgId, unitId, usersForUnit, onClose]);

  const onToggleUser = useCallback(
    (userId: number) => {
      const isUserAssigned = usersForUnit.some((id) => id === userId);

      if (isUserAssigned) {
        const updatedUsers = usersForUnit.filter((id) => id !== userId);
        setUsersForUnit(updatedUsers);
      } else {
        const updatedUsers = [...usersForUnit, userId];
        setUsersForUnit(updatedUsers);
      }
    },
    [usersForUnit]
  );

  return (
    <DrawerWrapper
      title={t("modules.organizations.grid.orgUnit.userTitle", {
        unitName,
      })}
      isOpen={isOpen}
      onCancelAction={handleClose}
      onOkAction={onSave}
      isOkDisabled={isPristine}
      loading={false}
      okLabel={t("buttons.save")}
      cancelLabel={t("buttons.close")}
      content={
        <List>
          <UnsavedChangesPrompt
            showPrompt={showPrompt}
            isDirty={!isPristine}
            onConfirm={onConfirmClose}
            onCancel={onCancelClose}
          />
          {users.map((user) => (
            <ListItem key={user.id} onClick={() => onToggleUser(user.id)}>
              <Checkbox
                edge="start"
                checked={usersForUnit.includes(user.id)}
                disableRipple
              />
              <ListItemText primary={user.username} />
            </ListItem>
          ))}
        </List>
      }
    />
  );
}

export default UnitUsersDrawer;
