import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import Badge from "components/icons/Badge";

type Props = {
  label: string;
  isActive: boolean;
  type: "error" | "warning";
  count: number;
  onClick: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  content: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    padding: "8px 16px",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.lighter,
    "&:hover": {
      backgroundColor: theme.palette.primary.lightest,
    },
  },
  inactive: {
    backgroundColor: theme.palette.primary.lightest,
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
}));

function InfoLabel({ label, type, isActive, count, onClick }: Props) {
  const styles = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classNames(styles.content, {
        [styles.inactive]: !isActive,
      })}
    >
      <Typography variant="body1">{label.toUpperCase()}</Typography>
      <Badge type={type} count={count} />
    </Button>
  );
}

export default InfoLabel;
