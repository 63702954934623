import {
  createRouterMiddleware,
  createRouterReducer,
} from "@lagunovsky/redux-react-router";
import { BrowserHistory, createBrowserHistory } from "history";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import adminDevicesSlice, {
  ADMIN_DEVICES_ACTIONS,
  adminDevicesWatcher,
} from "store/adminDevices";
import authSlice, { authWatcher } from "store/auth";
import dashboardSlice, { dashboardWatcher } from "store/dashboard";
import dashboardErrorSlice, {
  dashboardErrorWatcher,
} from "store/dashboardErrors";
import dashboardParametersSlice, {
  dashboardParametersWatcher,
} from "store/dashboardParameters";
import dashboardWarningSlice, {
  dashboarWarningsWatcher,
} from "store/dashboardWarnings";
import groupSlice, { groupWatcher } from "store/group";
import heaterSlice, { heaterWatcher } from "store/heater";
import heaterUsersSlice, { heaterUsersWatcher } from "store/heaterUsers";
import mqttSlice, { mqttWatcher } from "store/mqtt";
import organizationsSlice, { organizationsWatcher } from "store/organizations";
import snackbarSlice from "store/snackbar";
import uiSlice, { uiWatcher } from "store/ui";
import usersSlice, { usersWatcher } from "store/users";
import heaterCalendarSlice, { heaterCalendarWatcher } from "./heaterCalendar";
import unitsSlice, { unitsWatcher } from "./organizationUnits";
import troubleshootingAdminSlice, {
  TROUBLESHOOTING_ADMIN_ACTIONS,
  troubleshootingAdminWatcher,
} from "./troubleshootingAdmin";
import updatesSlice, {
  UPDATE_ACTIONS,
  softwareUpdatesWatcher,
} from "./updates";
import troubleshootingSlice, {
  troubleshootingWatcher,
} from "./troubleshooting";
import heaterErrorHistorySlice, {
  heaterErrorHistoryWatcher,
} from "./heaterErrorHistory";
import heaterScheduledUpdatesSlice, {
  heaterScheduledUpdatesWatcher,
} from "./heaterScheduledUpdates";

const createStore = (history: BrowserHistory) => {
  const rootReducer = combineReducers({
    router: createRouterReducer(history),
    [authSlice.name]: authSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [dashboardParametersSlice.name]: dashboardParametersSlice.reducer,
    [dashboardErrorSlice.name]: dashboardErrorSlice.reducer,
    [dashboardWarningSlice.name]: dashboardWarningSlice.reducer,
    [groupSlice.name]: groupSlice.reducer,
    [heaterSlice.name]: heaterSlice.reducer,
    [mqttSlice.name]: mqttSlice.reducer,
    [uiSlice.name]: uiSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [organizationsSlice.name]: organizationsSlice.reducer,
    [unitsSlice.name]: unitsSlice.reducer,
    [adminDevicesSlice.name]: adminDevicesSlice.reducer,
    [heaterUsersSlice.name]: heaterUsersSlice.reducer,
    [snackbarSlice.name]: snackbarSlice.reducer,
    [heaterCalendarSlice.name]: heaterCalendarSlice.reducer,
    [updatesSlice.name]: updatesSlice.reducer,
    [troubleshootingAdminSlice.name]: troubleshootingAdminSlice.reducer,
    [troubleshootingSlice.name]: troubleshootingSlice.reducer,
    [heaterErrorHistorySlice.name]: heaterErrorHistorySlice.reducer,
    [heaterScheduledUpdatesSlice.name]: heaterScheduledUpdatesSlice.reducer,
  });

  const watchers = [
    authWatcher,
    dashboardWatcher,
    dashboardParametersWatcher,
    dashboardErrorWatcher,
    dashboarWarningsWatcher,
    groupWatcher,
    heaterWatcher,
    mqttWatcher,
    usersWatcher,
    organizationsWatcher,
    adminDevicesWatcher,
    heaterUsersWatcher,
    heaterCalendarWatcher,
    uiWatcher,
    softwareUpdatesWatcher,
    unitsWatcher,
    troubleshootingAdminWatcher,
    troubleshootingWatcher,
    heaterErrorHistoryWatcher,
    heaterScheduledUpdatesWatcher,
  ];

  function* rootSaga() {
    yield all(watchers.map((watcher) => fork(watcher)));
  }

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            ADMIN_DEVICES_ACTIONS.IMPORT_CERTIFICATE,
            UPDATE_ACTIONS.ADD_FACTORY_SETTING,
            TROUBLESHOOTING_ADMIN_ACTIONS.ADD_GUIDE,
          ],
        },
      })
        .prepend(sagaMiddleware)
        .prepend(createRouterMiddleware(history)),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const history = createBrowserHistory();
const store = createStore(history);
export default store;
