import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert, Typography } from "@mui/material";
import snackbarSlice, { snackbarDataSelector } from "store/snackbar";
import { useCallback } from "react";

export default function SuccessSnackbar() {
  const dispatch = useDispatch();
  const { isOpen, errorMessage, type } = useSelector(snackbarDataSelector);

  const handleClose = useCallback(() => {
    dispatch(snackbarSlice.actions.close());
  }, [dispatch]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        severity={type}
        onClose={handleClose}
        sx={{ width: "300px", color: "white" }}
      >
        <Typography>{errorMessage}</Typography>
      </Alert>
    </Snackbar>
  );
}
