import { Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "components/DashboardGrid";
import PasswordField, { passwordRegex } from "components/fields/PasswordField";
import { getFirstLetterToUpperCase, trimString } from "helpers";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { emailRegex } from "store/users";

type Prop = {
  handleChange: (e: ChangeEvent<any>) => void;
  currentUsername: string;
  currentPassword: string;
  setFieldValue: any;
};

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    fontSize: "14px",
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
}));

function GeneralInformationGrid({
  handleChange,
  currentUsername,
  currentPassword,
  setFieldValue,
}: Prop) {
  const { t } = useTranslation();
  const styles = useStyles();

  const isMailValid = useMemo(() => {
    if (!currentUsername) {
      return true;
    }
    return emailRegex.test(currentUsername);
  }, [currentUsername]);

  const mailHelperText = useMemo(
    () => (isMailValid ? "" : t("textFields.invalidUsername")),
    [isMailValid, t]
  );

  const isPasswordValid = useMemo(() => {
    if (!currentPassword) {
      return true;
    }
    return passwordRegex.test(currentPassword);
  }, [currentPassword]);

  const passwordHelperText = useMemo(
    () => (isPasswordValid ? "" : t("textFields.invalidPassword")),
    [isPasswordValid, t]
  );

  const onBlur = useCallback(
    (property: string, value: string) => {
      setFieldValue(property, trimString(value));
    },
    [setFieldValue]
  );

  return (
    <DashboardGrid
      title={t("modules.users.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.users.grid.generalInformation.email")
              )}
            </Typography>
            <TextField
              size="small"
              name="username"
              variant="outlined"
              fullWidth
              error={!isMailValid}
              helperText={mailHelperText}
              value={currentUsername}
              onChange={handleChange}
              onBlur={() => onBlur("username", currentUsername)}
              required
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.users.grid.generalInformation.passwordInitial")
              )}
            </Typography>

            <PasswordField
              name="password"
              size="small"
              placeholder=""
              value={currentPassword}
              onChange={handleChange}
              required
              error={!isPasswordValid}
              helperText={passwordHelperText}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default GeneralInformationGrid;
