import { Typography } from "@mui/material";

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import DetailsHeader from "components/common/DetailsHeader";
import paths from "config/paths";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createDeviceHeaderData } from "store/adminDevices";

type Props = {
  deviceId: number;
};

function DeviceDetailsHeader({ deviceId }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getInfo = useMemo(() => createDeviceHeaderData(deviceId), [deviceId]);
  const info = useSelector(getInfo);
  const title = deviceId ? info.title : t("modules.devices.addNew");

  const onBackClick = useCallback(() => navigate(paths.devices), [navigate]);

  return (
    <DetailsHeader
      mainContent={
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Typography style={{ marginRight: "16px" }} variant="h4">
            {title}
          </Typography>
        </div>
      }
      onBack={onBackClick}
      path={t("modules.devices.title")}
      item={info.serialNumber}
      itemLabel={deviceId ? "" : t("modules.devices.details")}
    />
  );
}

export default DeviceDetailsHeader;
