import { Menu, MenuItem, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useState, type MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "components/common/ModalWrapper";
import { ADMIN_DEVICES_ACTIONS } from "store/adminDevices";
import { useDispatch } from "react-redux";
import useDeleteModal from "hooks/useDeleteModal";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
}));

type Props = {
  deviceId: number;
};

function ManageAdminDevicesMenu({ deviceId }: Props) {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onEditClick = useCallback(
    () => navigate(`${deviceId}`),
    [navigate, deviceId]
  );

  const onDeleteDevice = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.DELETE_DEVICE,
      payload: {
        deviceId,
      },
    });
    onCloseDeleteModal();
  }, [deviceId, dispatch, onCloseDeleteModal]);

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onEditClick}>
          <Typography>{t("modals.devices.edit")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenDeleteModal();
            handleClose();
          }}
        >
          <Typography color="error">{t("modals.devices.delete")}</Typography>
        </MenuItem>
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDeleteDevice}
        title={t("modals.devices.delete")}
        contentLabel={t("modals.devices.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default ManageAdminDevicesMenu;
