import { ChangeEvent, FormEvent, useCallback, useState } from "react";

import { makeStyles } from "@mui/styles";

import {
  Button,
  Grid,
  TextField,
  Paper,
  Typography,
  Alert,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Logo from "components/Logo";

import authSlice, { authErrorSelector } from "store/auth";
import { UserInfo } from "models/User";
import Footer from "components/common/Footer";
import PasswordField from "components/fields/PasswordField";
import CustomHidden from "components/common/CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
    [`${theme.breakpoints.only("sm")} and (orientation : landscape)`]: {
      paddingTop: "20px",
    },
  },
  paper: {
    [theme.breakpoints.up("xs")]: {
      width: "416px",
      padding: "48px 32px",
      margin: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      height: "100vh",
      width: "100vw",
      overflow: "auto",
    },
  },
  logo: {
    [theme.breakpoints.only("sm")]: {
      paddingBottom: "40px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "80px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 64px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 44px",
    },
  },
  footerXs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "calc(100vh - 640px)",
  },
}));

const defaultValues: UserInfo = {
  username: "",
  password: "",
};

function LoginForm() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState(defaultValues);
  const errorCode = useSelector(authErrorSelector);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setLoginData({
        ...loginData,
        [name]: value,
      });
    },
    [loginData]
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(authSlice.actions.login(loginData));
    },
    [dispatch, loginData]
  );

  return (
    <div className={styles.container}>
      <Paper className={styles.paper}>
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item className={styles.logo}>
              <Logo />
            </Grid>
            <Grid item>
              <Typography variant="h4">{t("login")}</Typography>
            </Grid>
            <Grid item>
              <TextField
                name="username"
                placeholder={t("textFields.username")}
                variant="outlined"
                value={loginData.username}
                onChange={handleInputChange}
                hiddenLabel
                required
                fullWidth
              />
            </Grid>
            <Grid item>
              <PasswordField
                name="password"
                placeholder={t("textFields.password")}
                value={loginData.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
              >
                {t("buttons.login")}
              </Button>
            </Grid>
            <Grid item>
              {errorCode && (
                <Alert
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.error.main,
                  }}
                  icon={false}
                  variant="outlined"
                  severity="error"
                >
                  <Typography>{t(`apiErrors.login.${errorCode}`)}</Typography>
                </Alert>
              )}
            </Grid>
          </Grid>
        </form>
        <CustomHidden size="sm" hideType="up">
          <div className={styles.footerXs}>
            <Footer />
          </div>
        </CustomHidden>
      </Paper>
      <CustomHidden size="sm" hideType="down">
        <div className={styles.footer}>
          <Footer />
        </div>
      </CustomHidden>
    </div>
  );
}

export default LoginForm;
