import { Divider } from "@mui/material";
import DrawerWrapper from "components/common/DrawerWrapper";
import UpdateList from "components/common/UpdateList";
import { UpdateType } from "models/Update";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UpdateDetails } from "services/heater";
import {
  DASHBOARD_ACTIONS,
  createGetUpdatesSelectorByType,
} from "store/dashboard";
import uiSlice, { isUpdatesDrawerOpen } from "store/ui";

type Props = {
  onSave: (softwareKey: string, factorySettingsKey: string) => void;
};

function UpdatesDrawer({ onSave }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(isUpdatesDrawerOpen);
  const getSoftwareUpdates = useMemo(
    () => createGetUpdatesSelectorByType("softwareUpdates"),
    []
  );
  const getFactorySettings = useMemo(
    () => createGetUpdatesSelectorByType("factorySettings"),
    []
  );
  const softwareUpdates = useSelector(getSoftwareUpdates);
  const factorySettings = useSelector(getFactorySettings);
  const [softwareUpdateKey, setSoftwareUpdateKey] = useState<string | null>(
    null
  );
  const [factorySettingsKey, setFactorySettingsKey] = useState<string | null>(
    null
  );

  const getIsSoftwareUpdateSelected = useCallback(
    (update: UpdateDetails) => softwareUpdateKey === update.key,
    [softwareUpdateKey]
  );

  const getIsFactorySettingsSelected = useCallback(
    (update: UpdateDetails) => factorySettingsKey === update.key,
    [factorySettingsKey]
  );

  const fetchData = useCallback(
    (type: UpdateType) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_UPDATES_BY_TYPE,
        payload: {
          type,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData("factorySettings");
    fetchData("softwareUpdates");
  }, [dispatch, fetchData]);

  const onClose = useCallback(() => {
    dispatch(uiSlice.actions.setVisibleUpdatesDrawer(false));
    setSoftwareUpdateKey(null);
    setFactorySettingsKey(null);
  }, [dispatch]);

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onOk = useCallback(() => {
    if (softwareUpdateKey && factorySettingsKey) {
      onSave(softwareUpdateKey, factorySettingsKey);
      dispatch(uiSlice.actions.setBatchUpdateMode("init"));
    }
  }, [dispatch, factorySettingsKey, onSave, softwareUpdateKey]);

  return (
    <DrawerWrapper
      title={t(`modules.dashboard.batchUpdateTitle`)}
      isOpen={isOpen}
      onCancelAction={onCancel}
      onOkAction={onOk}
      isOkDisabled={!softwareUpdateKey || !factorySettingsKey}
      loading={false}
      okLabel={t("buttons.save")}
      cancelLabel={t("buttons.close")}
      content={
        <div>
          <UpdateList
            title={t(`modules.softwareUpdates.title`)}
            searchPlaceholder={t("modules.devices.placeholder")}
            updates={softwareUpdates}
            onSelectUpdate={(update) => setSoftwareUpdateKey(update.key)}
            getIsUpdateSelected={getIsSoftwareUpdateSelected}
          />
          <Divider />

          <br />

          <UpdateList
            title={t(`modules.factorySettings.title`)}
            searchPlaceholder={t("modules.devices.placeholder")}
            updates={factorySettings}
            onSelectUpdate={(update) => setFactorySettingsKey(update.key)}
            getIsUpdateSelected={getIsFactorySettingsSelected}
          />
          <Divider />
        </div>
      }
    />
  );
}

export default UpdatesDrawer;
