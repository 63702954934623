import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import troubleshootingService from "services/troubleshooting";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { Device } from "models/Heater";
import {
  TroubleshootingGuide,
  TroubleshootingGuideType,
} from "models/TroubleshootingGuide";
import { find, isUndefined, omitBy, reduce, sortBy } from "lodash";
import {
  TroubleshootingGuideTypeFile,
  TroubleshootingParameters,
} from "models/Troubleshooting";

type TroubleshootingState = {
  initializing: boolean;
  deviceId?: number;
  urls?: TroubleshootingGuideTypeFile;
  guides?: TroubleshootingGuide[];
  error?: string;
};

const initialState: TroubleshootingState = {
  initializing: false,
};

const troubleshootingSlice = createSlice({
  name: "troubleshooting",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<any>) => {
      state.initializing = true;
    },
    setDeviceId: (state, { payload }: PayloadAction<number>) => {
      state.deviceId = payload;
    },
    setGuides: (state, action: PayloadAction<TroubleshootingGuide[]>) => {
      state.guides = sortBy(action.payload, ["createdAt"]);
      state.urls = omitBy(
        reduce(
          Object.values(TroubleshootingGuideType),
          (object, key) => ({
            ...object,
            [key]: find(action.payload, (guide) => guide.type === key)?.s3Key,
          }),
          {}
        ) as TroubleshootingGuideTypeFile,
        isUndefined
      );

      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
  },
});

function troubleshootingSelector(state: {
  [troubleshootingSlice.name]: TroubleshootingState;
}) {
  return state[troubleshootingSlice.name];
}

export const troubleshootingDeviceIdSelector = createSelector(
  troubleshootingSelector,
  (state: TroubleshootingState) => state.deviceId
);

export const troubleshootingUrlsSelector = createSelector(
  troubleshootingSelector,
  (state: TroubleshootingState) => state.urls
);

export const guidesSelector = createSelector(
  troubleshootingSelector,
  (state: TroubleshootingState) => state.guides
);

function* handleInit(
  action: PayloadAction<TroubleshootingParameters>
): Generator {
  try {
    const { payload } = action;
    const { serialNumber, codeType, code, language } = payload;
    let { deviceId } = payload;

    deviceId =
      deviceId ??
      ((yield call(troubleshootingService.getDeviceId, serialNumber)) as Device)
        .id;

    if (!serialNumber && !deviceId) {
      yield put(
        troubleshootingSlice.actions.initError("Missing guide target...")
      );
      return;
    }

    yield put(troubleshootingSlice.actions.setDeviceId(deviceId));

    const guides = (yield call(troubleshootingService.getGuidesForDevice, {
      deviceId,
      codeType,
      code,
      language,
    })) as TroubleshootingGuide[];

    yield put(troubleshootingSlice.actions.setGuides(guides));
  } catch (err) {
    yield put(
      troubleshootingSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchInit() {
  yield takeLatest(troubleshootingSlice.actions.init, handleInit);
}

export function* troubleshootingWatcher() {
  yield all([fork(watchInit)]);
}

export default troubleshootingSlice;
