import { makeStyles } from "@mui/styles";
import {
  Button,
  Grid,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { ChangeEvent, useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isRemoteActionEnabledSelector } from "store/auth";
import CustomSlider from "components/common/SliderItem";
import { ActivityRecord } from "models/Heater";
import { getLastModifiedMessage } from "helpers";

const useStyles = makeStyles((theme: any) => ({
  inputLabel: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  title: string;
  inputLabel: string;
  value: number;
  min: number;
  max: number;
  actionDisabled: boolean;
  activity: ActivityRecord;
  actionInProgress: boolean;
  actionFailed: boolean;
  onValueChange: (value: number) => void;
  onClick: (value: number) => void;
  fadeOut?: boolean;
};

function ParameterGridItem({
  title,
  inputLabel,
  value,
  min,
  max,
  actionDisabled,
  activity,
  onClick,
  onValueChange,
  actionInProgress,
  actionFailed,
  fadeOut = false,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState<number>(value);
  const isRemoteActionEnabled = useSelector(isRemoteActionEnabledSelector);
  const [isEditMode, setEditMode] = useState(false);
  const disabled = actionDisabled || !isEditMode;

  const lastModifiedMessage = getLastModifiedMessage(
    activity?.firstName,
    activity?.lastName,
    activity?.email,
    activity?.timestamp
  );

  const validValue = useMemo(
    () => sliderValue >= min && sliderValue <= max && value !== sliderValue,
    [max, min, sliderValue, value]
  );

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const onChange = useCallback(
    (_: any, newValue: number | number[]) => {
      if (isRemoteActionEnabled) {
        let changedValue: number;
        if (Array.isArray(newValue)) {
          // eslint-disable-next-line prefer-destructuring
          changedValue = newValue[0];
        } else {
          changedValue = newValue;
        }
        setSliderValue(changedValue);
        onValueChange(changedValue);
      }
    },
    [isRemoteActionEnabled, onValueChange]
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isRemoteActionEnabled) {
        setSliderValue(+event.target.value);
      }
    },
    [isRemoteActionEnabled]
  );

  const onEditClick = useCallback(() => {
    setEditMode(true);
  }, []);

  const onSetClick = useCallback(() => {
    onClick(sliderValue);
    setEditMode(false);
  }, [onClick, sliderValue]);

  return (
    <>
      <Grid item xs={9.3} sm={10} md={6} lg={6} xl={6.3}>
        <Typography color="textSecondary" variant="subtitle2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={2} sm={1} md={1} lg={1} xl={0.7}>
        <TextField
          value={sliderValue}
          size="small"
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min, max } }}
          onChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={0.5} sm={0.3} md={0.9} className={styles.inputLabel}>
        <Typography variant="subtitle2">{inputLabel}</Typography>
      </Grid>
      <Grid item xs={11} md={7}>
        <CustomSlider
          value={sliderValue}
          onChange={onChange}
          min={min}
          max={max}
          disabled={disabled}
          fadeOut={fadeOut}
        />
      </Grid>
      {activity && (
        <Grid item xs={9.5} sm={10.5} md={6} lg={6.2}>
          <Typography color="textSecondary" style={{ fontSize: "12px" }}>
            {t("modules.dashboard.grid.parameters.lastModifiedBy")}
            &nbsp;
            {lastModifiedMessage}
          </Typography>
          <Typography color="textSecondary" style={{ fontSize: "12px" }}>
            {t("modules.dashboard.grid.parameters.previousValue")}:&nbsp;
            {activity.previousValue}
            {inputLabel}
          </Typography>
        </Grid>
      )}
      {isRemoteActionEnabled && (
        <Grid item xs={2} sm={1} md={0.5}>
          {actionInProgress && <CircularProgress />}
          {!actionInProgress && isEditMode && (
            <Button
              color="primary"
              variant="outlined"
              disabled={!validValue}
              onClick={onSetClick}
            >
              {t("buttons.set")}
            </Button>
          )}
          {!actionInProgress && !isEditMode && (
            <Button
              color="primary"
              variant="outlined"
              disabled={actionDisabled}
              onClick={onEditClick}
              startIcon={actionFailed ? <SyncProblemIcon color="error" /> : ""}
            >
              {t("buttons.edit")}
            </Button>
          )}
        </Grid>
      )}
    </>
  );
}

export default ParameterGridItem;
