import { push } from "@lagunovsky/redux-react-router";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import paths from "config/paths";
import { mapKeys, omit } from "lodash";
import { AdminDevice, DeviceModel } from "models/Heater";
import {
  GuideFormValues,
  TroubleshootingGuide,
  TroubleshootingGuideType,
} from "models/TroubleshootingGuide";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import troubleshootingAdminServices from "services/troubleshootingAdmin";

export const TROUBLESHOOTING_ADMIN_ACTIONS = {
  INIT: "INIT",
  DELETE_GUIDE: "DELETE_GUIDE",
  ADD_GUIDE: "ADD_GUIDE",
  EDIT_GUIDE: "EDIT_GUIDE",
};

type TroubleshootingAdminState = {
  initializing: boolean;
  guides: Record<string, TroubleshootingGuide>;
  remoteDevices: Record<string, AdminDevice>;
  error?: string;
};

const initialState: TroubleshootingAdminState = {
  initializing: false,
  guides: {},
  remoteDevices: {},
};

const troubleshootingAdminSlice = createSlice({
  name: "troubleshootingState",
  initialState,
  reducers: {
    initSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        guides: TroubleshootingGuide[];
        adminDevices: AdminDevice[];
      }>
    ) => {
      state.guides = mapKeys(payload.guides, (guide) => guide.id);
      state.remoteDevices = mapKeys(
        payload.adminDevices,
        (admin) => admin.device.id
      );

      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    initRemoteDevices: (
      state,
      { payload }: PayloadAction<{ guideId: number; remoteDevices: number[] }>
    ) => {
      state.guides[payload.guideId].remoteDevices = payload.remoteDevices || [];
    },
    editRemoteDevicesSuccess: (
      state,
      { payload }: PayloadAction<{ guideId: number; remoteDevices: number[] }>
    ) => {
      state.guides[payload.guideId].remoteDevices = payload.remoteDevices || [];
    },
    addGuideSuccess: (
      state,
      { payload }: PayloadAction<TroubleshootingGuide>
    ) => {
      state.guides[payload.id] = {
        ...payload,
      };
    },
    deleteGuideSucces: (state, { payload }: PayloadAction<number>) => {
      state.guides = omit(state.guides, payload);
    },
  },
});

function troubleshootingStateSelector(state: {
  [troubleshootingAdminSlice.name]: TroubleshootingAdminState;
}) {
  return state[troubleshootingAdminSlice.name];
}

export const troubleshootingDataSelector = createSelector(
  troubleshootingStateSelector,
  (state: TroubleshootingAdminState) => state
);

export const guidesByIdSelector = createSelector(
  troubleshootingDataSelector,
  (state: TroubleshootingAdminState) => state.guides
);

export const devicesByIdSelector = createSelector(
  troubleshootingDataSelector,
  (state: TroubleshootingAdminState) => state.remoteDevices
);

export const remoteDevicesListSelector = createSelector(
  devicesByIdSelector,
  (devicesById) => Object.values(devicesById) || []
);

export const createGuideDetailsByIdSelector = (guideId: number) =>
  createSelector(guidesByIdSelector, (byId) => {
    const { s3Key = "" } = byId[guideId] || {};

    return {
      label: s3Key,
    };
  });

export const createGuideInitialFormValues = (id: number) =>
  createSelector(guidesByIdSelector, (byId) => {
    const guide = byId[id] || {
      type: TroubleshootingGuideType.ErrorGuideline,
      language: "en",
      code: 0,
      deviceModel: DeviceModel.G200,
      remoteDevices: [],
    };

    return {
      ...guide,
      update: "",
      fileName: "",
    } as GuideFormValues;
  });

export const guidesListSelector = createSelector(
  guidesByIdSelector,
  (byId) => Object.values(byId) || []
);

function* handleDeleteGuide(
  action: PayloadAction<{ guideId: number }>
): Generator {
  try {
    const { guideId } = action.payload;
    yield call(troubleshootingAdminServices.deleteGuide, guideId);
    yield put(troubleshootingAdminSlice.actions.deleteGuideSucces(guideId));
    yield put(push(paths.troubleshootingAdmin));
  } catch (error) {
    yield put(
      troubleshootingAdminSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchDeleteGuide() {
  yield takeLatest(
    TROUBLESHOOTING_ADMIN_ACTIONS.DELETE_GUIDE,
    handleDeleteGuide
  );
}

function* handleAddGuide(
  action: PayloadAction<{ formValues: GuideFormValues }>
): Generator {
  try {
    const { formValues } = action.payload;
    const guide = (yield call(
      troubleshootingAdminServices.addGuide,
      formValues
    )) as TroubleshootingGuide;

    yield put({
      type: TROUBLESHOOTING_ADMIN_ACTIONS.EDIT_GUIDE,
      payload: {
        guideId: guide.id,
        remoteDevices: formValues.remoteDevices,
      },
    });
    yield put(troubleshootingAdminSlice.actions.addGuideSuccess(guide));
    yield put(push(paths.troubleshootingAdmin));
  } catch (error) {
    yield put(
      troubleshootingAdminSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchAddGuide() {
  yield takeLatest(TROUBLESHOOTING_ADMIN_ACTIONS.ADD_GUIDE, handleAddGuide);
}

function* handleEditGuide(
  action: PayloadAction<{
    guideId: number;
    remoteDevices: number[];
  }>
): Generator {
  try {
    const { guideId, remoteDevices } = action.payload;
    yield call(
      troubleshootingAdminServices.editGuideRemoteDevices,
      guideId,
      remoteDevices
    );
    yield put(
      troubleshootingAdminSlice.actions.editRemoteDevicesSuccess({
        guideId,
        remoteDevices,
      })
    );
  } catch (error) {
    yield put(
      troubleshootingAdminSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchEditGuide() {
  yield takeLatest(TROUBLESHOOTING_ADMIN_ACTIONS.EDIT_GUIDE, handleEditGuide);
}

function* handleInit(
  action: PayloadAction<{ guideId: number } | null>
): Generator {
  try {
    const { guideId } = action.payload || { guideId: 0 };
    const guides = (yield call(troubleshootingAdminServices.getGuides)) as {
      data: TroubleshootingGuide[];
    };
    const adminDevices = (yield call(
      heaterService.getAdminDeviceList
    )) as AdminDevice[];

    yield put(
      troubleshootingAdminSlice.actions.initSuccess({
        guides: guides.data,
        adminDevices,
      })
    );

    if (guideId) {
      const remoteDevices = (yield call(
        troubleshootingAdminServices.getGuideRemoteDevices,
        guideId
      )) as number[];

      yield put(
        troubleshootingAdminSlice.actions.initRemoteDevices({
          guideId,
          remoteDevices,
        })
      );
    }
  } catch (err) {
    yield put(
      troubleshootingAdminSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchInit() {
  yield takeLatest(TROUBLESHOOTING_ADMIN_ACTIONS.INIT, handleInit);
}

export function* troubleshootingAdminWatcher() {
  yield all([
    fork(watchInit),
    fork(watchDeleteGuide),
    fork(watchAddGuide),
    fork(watchEditGuide),
  ]);
}

export default troubleshootingAdminSlice;
