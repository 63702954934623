import { Grid, Tooltip, Typography } from "@mui/material";
import ParametersData from "models/Parameters";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHeaterEvent } from "store/heaterCalendar";
import ItemCell from "./ItemCell";
import TooltipContent from "./TooltipContent";

function DayEvent({ event }: any) {
  const { id, start, end } = event;
  const { t } = useTranslation();
  const startTime = moment(start).format("HH:mm");
  const endTime = moment(end).format("HH:mm");
  const getEvent = useMemo(() => getHeaterEvent(id), [id]);
  const eventData = useSelector(getEvent);
  const hourDiff = moment(end).endOf("hour").diff(moment(start), "hour");
  const showTooltip = hourDiff <= 2;
  const columnView = hourDiff <= 3;

  const TRT = (
    <ItemCell
      label={t("modules.dashboard.calendar.targetRoomTemperature")}
      value={`${eventData.targetRoomTemperature} ${ParametersData.TargetRoomTemperature.unit}`}
      width="150px"
      variant="subtitle2"
    />
  );

  const THAR = (
    <ItemCell
      label={t("modules.dashboard.calendar.targetHotAirTemperature")}
      value={`${eventData.targetHotAirTemperature} ${ParametersData.TargetHotAirTemperature.unit}`}
      width="150px"
      variant="subtitle2"
    />
  );

  const HAFR = (
    <ItemCell
      label={t("modules.dashboard.calendar.targetPowerHotAirFan")}
      value={`${eventData.targetPowerHotAirFan} ${ParametersData.HotAirFanPower.unit}`}
      width="150px"
      variant="subtitle2"
    />
  );

  const HAF = (
    <ItemCell
      label={t("modules.dashboard.calendar.hotAirFan")}
      value={eventData.modeHotAirFan ? "true" : "false"}
      width="150px"
      variant="subtitle2"
    />
  );

  const AC = (
    <ItemCell
      label={t("modules.dashboard.calendar.additionalCooling")}
      value={eventData.additionalCooling ? "true" : "false"}
      width="150px"
      variant="subtitle2"
    />
  );

  return (
    <Tooltip title={showTooltip ? <TooltipContent id={id} /> : ""}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          backgroundColor: "#c1d1dc",
          padding: "0 16px",
          height: "100%",
        }}
      >
        <Typography color="textPrimary">
          {startTime} - {endTime}
        </Typography>

        <br />
        {columnView ? (
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={2}>
              {TRT}
            </Grid>
            <Grid item xs={2}>
              {THAR}
            </Grid>
            <Grid item xs={2}>
              {HAFR}
            </Grid>
            <Grid item xs={2}>
              {HAF}
              {AC}
            </Grid>
          </Grid>
        ) : (
          <>
            {TRT}
            {THAR}
            {HAFR}
            {HAF}
            {AC}
          </>
        )}
      </div>
    </Tooltip>
  );
}

export default DayEvent;
