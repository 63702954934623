import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  status: "pending" | "softwareUpdated" | "completed";
};

const useStyles = makeStyles((theme: any) => ({
  content: {
    color: theme.palette.grey[50],
    borderRadius: 20,
    padding: 5,
    fontFamily: "Ropa Sans",
    fontSize: 11,
    letterSpacing: 2,
    textTransform: "uppercase",
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
  },
  softwareUpdated: {
    backgroundColor: theme.palette.info.main,
  },
  completed: {
    backgroundColor: theme.palette.success.main,
  },
}));

function UpdateStatusIcon({ status }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <span
        className={classNames(styles.content, {
          [styles.pending]: status === "pending",
          [styles.softwareUpdated]: status === "softwareUpdated",
          [styles.completed]: status === "completed",
        })}
      >
        {t(`modules.dashboard.scheduledUpdates.status.${status}`)}
      </span>
    </Box>
  );
}

export default UpdateStatusIcon;
