import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactElement } from "react";

type Props = {
  title: string;
  contentLabel?: string;
  content?: ReactElement;
  isOpen: boolean;
  okLabel?: string;
  cancelLabel?: string;
  isOkDisabled?: boolean;
  onOkAction?: () => void;
  onCancelAction?: () => void;
  loading?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  modal: {
    padding: "20px 40px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  divider: {
    margin: "20px -40px 40px -40px",
  },
  content: {
    padding: "30px 0",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function ModalWrapper({
  title,
  contentLabel,
  content,
  isOpen,
  onOkAction,
  onCancelAction,
  okLabel,
  cancelLabel,
  isOkDisabled,
  loading,
}: Props) {
  const styles = useStyles();

  return (
    <Modal open={isOpen} onClose={onCancelAction}>
      <Paper classes={{ root: styles.modal }}>
        <Typography variant="h6">{title}</Typography>
        <Divider className={styles.divider} />
        {contentLabel && (
          <Typography color="textSecondary" variant="subtitle1">
            {contentLabel}
          </Typography>
        )}
        <div className={styles.content}>{content}</div>
        <div className={styles.footer}>
          {cancelLabel && (
            <Button onClick={onCancelAction} variant="outlined" color="primary">
              {cancelLabel}
            </Button>
          )}
          {okLabel && !loading && (
            <Button
              variant="contained"
              onClick={onOkAction}
              disabled={isOkDisabled}
              color="primary"
            >
              {okLabel}
            </Button>
          )}
          {okLabel && loading && <CircularProgress />}
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalWrapper;
