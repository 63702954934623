import { SvgIcon, useTheme } from "@mui/material";
import { useMemo } from "react";

type Props = {
  type: "error" | "success" | "warning";
  label?: string;
};

function ConnectionIcon({ type, label }: Props) {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (type) {
      case "error":
        return theme.palette.error.main;
      case "success":
        return theme.palette.success.main;
      case "warning":
        return theme.palette.warning.main;
      default:
        return theme.palette.error.main;
    }
  }, [
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    type,
  ]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <SvgIcon viewBox="0 0 16 16" sx={{ fill: "none", marginRight: "8px" }}>
        <circle cx="8" cy="8" r="6" fill={color} />
        <circle opacity="0.3" cx="8" cy="8" r="7.5" stroke={color} />
      </SvgIcon>
      <span style={{ color, fontSize: "16px" }}>{label}</span>
    </div>
  );
}

export default ConnectionIcon;
