import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  showPrompt: boolean;
  isDirty: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

function UnsavedChangesPrompt({
  showPrompt,
  isDirty,

  onConfirm,
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(isDirty);

  useEffect(() => setHasUnsavedChanges(isDirty), [isDirty]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <Dialog open={showPrompt} onClose={onCancel}>
      <DialogTitle>{t("modals.unsavedChangesTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("modals.unsavedChanges")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onCancel} color="primary">
          {t("buttons.cancel")}
        </Button>
        <Button
          size="small"
          onClick={onConfirm}
          variant="contained"
          color="primary"
        >
          {t("buttons.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnsavedChangesPrompt;
