import { Box } from "@mui/material";
import EmptyList from "components/common/EmptyList";
import PageHeader from "components/common/PageHeader";
import LargeOrganizationIcon from "components/icons/LargeOrganizationIcon";
import OrganizationsTable from "components/organizations/OrganizationsTable";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isOrganizationActionEnabled } from "store/auth";
import organizationsSlice, {
  organizationsListSelector,
} from "store/organizations";
import useContentStyles from "./useContentStyles";

function Organizations() {
  const dispatch = useDispatch();
  const contentStyles = useContentStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organizations = useSelector(organizationsListSelector);
  const isActionEnabled = useSelector(isOrganizationActionEnabled);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(organizationsSlice.actions.init());
  }, [dispatch]);

  const onAdd = useCallback(() => {
    navigate("/organizations/new");
  }, [navigate]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredOrgs = useMemo(
    () =>
      organizations.filter((org) =>
        org.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, organizations]
  );

  return (
    <Box className={contentStyles.content}>
      <PageHeader
        title={t("modules.organizations.title")}
        addLabel={t("modules.organizations.addNew")}
        onAdd={onAdd}
        isAddEnabled={isActionEnabled}
        enableSearch
        searchPlaceholder={t("modules.organizations.placeholder")}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {organizations.length > 0 && (
        <OrganizationsTable organizations={filteredOrgs} />
      )}
      {organizations.length === 0 && (
        <EmptyList
          icon={<LargeOrganizationIcon />}
          emptyListText={t("modules.organizations.empty")}
          onOpen={onAdd}
          isAddEnabled={isActionEnabled}
        />
      )}
    </Box>
  );
}

export default Organizations;
