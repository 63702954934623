import EditDevice from "components/adminDevices/EditDevice";
import AddUser from "components/adminDevices/heaterUsersTab/AddUser";
import EditOrganization from "components/organizations/EditOrganization";
import EditUser from "components/users/EditUser";
import moment from "moment";
import AdminDevices from "pages/AdminDevices";
import Dashboard from "pages/Dashboard";
import FactorySettingTypes from "pages/FactorySettingTypes";
import FactorySettings from "pages/FactorySettings";
import Groups from "pages/Groups";
import HeaterDetails from "pages/HeaterDetails";
import Login from "pages/Login";
import Organizations from "pages/Organizations";
import ResetPassword from "pages/ResetPassword";
import SoftwareUpdates from "pages/SoftwareUpdates";
import Users from "pages/Users";
import TroubleshootingAdmin from "pages/TroubleshootingAdmin";
import Troubleshooting from "pages/Troubleshooting";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  AUTH_ACTIONS,
  isAuthenticatedSelector,
  userRequiresPasswordReset,
} from "store/auth";
import mqttSlice from "store/mqtt";

import UnitRulesDrawer from "components/organizations/UnitRulesDrawer";
import UnitUsersDrawer from "components/organizations/UnitUsersDrawer";
import EditGuide from "components/troubleshooting/EditGuide";
import paths from "config/paths";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import uiSlice from "store/ui";
import "./App.css";
import { ProtectedRoute, PublicRoute } from "./Routes";
import "./i18n";

const SESSION_IN_MINUTES = 60;
const REFRESH_LIMIT = 2;

const checkTimeDif = (loginTimeString: string) => {
  const loginTime = moment(loginTimeString);
  const currentTime = moment();
  const minutesDiff = loginTime.diff(currentTime, "minutes");
  return minutesDiff;
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const requiresPasswordReset = useSelector(userRequiresPasswordReset);

  const publicRouteRedirectPath = useMemo(
    () => (requiresPasswordReset ? paths.resetPassword : paths.dashboard),
    [requiresPasswordReset]
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(mqttSlice.actions.init());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const interval = setInterval(() => {
      const loginTimeString = localStorage.getItem("tmstp");

      if (loginTimeString) {
        const minutesDiff = checkTimeDif(loginTimeString);

        if (Math.abs(minutesDiff) > SESSION_IN_MINUTES) {
          dispatch({ type: AUTH_ACTIONS.LOGOUT });
          clearInterval(interval);
        } else if (SESSION_IN_MINUTES - Math.abs(minutesDiff) < REFRESH_LIMIT) {
          dispatch({ type: AUTH_ACTIONS.REFRESH });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const onClose = useCallback(
    (orgId: number) => {
      dispatch(uiSlice.actions.clearUnitDrawer());
      navigate(`${paths.organizations}/${orgId}`);
    },
    [dispatch, navigate]
  );

  return (
    <Routes>
      <Route element={<PublicRoute redirectPath={publicRouteRedirectPath} />}>
        <Route index element={<Login />} />
        <Route path={paths.login} element={<Login />} />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath={paths.login} hasNavigation={false} />
        }
      >
        <Route path={paths.resetPassword} element={<ResetPassword />} />
      </Route>
      <Route element={<ProtectedRoute redirectPath={paths.login} />}>
        <Route path={paths.dashboard}>
          <Route index element={<Dashboard />} />
          <Route path=":heaterId" element={<HeaterDetails />} />
        </Route>
        <Route path={paths.groups} element={<Groups />} />
        <Route path={paths.users}>
          <Route index element={<Users />} />
          <Route path=":userId" element={<EditUser />} />
          <Route path="new" element={<EditUser />} />
        </Route>
        <Route path={paths.organizations}>
          <Route index element={<Organizations />} />
          <Route path=":orgId" element={<EditOrganization />}>
            <Route
              path="units/:unitId/users"
              element={<UnitUsersDrawer onClose={onClose} />}
            />
            <Route
              path="units/:unitId/rules"
              element={<UnitRulesDrawer onClose={onClose} />}
            />
          </Route>
        </Route>
        <Route path={paths.devices}>
          <Route index element={<AdminDevices />} />
          <Route path=":deviceId" element={<EditDevice isNew={false} />} />
          <Route path="new" element={<EditDevice isNew />} />
          <Route path=":deviceId/newUser" element={<AddUser />} />
        </Route>
        <Route path={paths.updates}>
          <Route index element={<SoftwareUpdates />} />
          <Route path={paths.softwareUpdate} element={<SoftwareUpdates />} />
          <Route path={paths.factoryUpdate} element={<FactorySettings />} />
          <Route
            path={paths.factoryTypeUpdate}
            element={<FactorySettingTypes />}
          />
        </Route>
        <Route path={paths.troubleshootingAdmin}>
          <Route index element={<TroubleshootingAdmin />} />
          <Route path="new" element={<EditGuide />} />
          <Route path=":guideId" element={<EditGuide />} />
        </Route>
        <Route path={paths.troubleshooting}>
          <Route index element={<Troubleshooting />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <div>
            <p>There is nothing here!</p>
          </div>
        }
      />
    </Routes>
  );
}

export default App;
