import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getFirstLetterToUpperCase } from "helpers";
import { UpdateType } from "models/Update";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isUpdateEnabledSelector } from "store/auth";
import {
  DASHBOARD_ACTIONS,
  enableDashboardActionsSelector,
  getDashboardSoftwareUpdateDetails,
} from "store/dashboard";
import { dashboardSoftwareVersion } from "store/dashboardParameters";
import UpdateDetails from "./UpdateDetails";

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
}));

type Props = {
  onOpenModal: (type: UpdateType) => void;
};

function SoftwareUpdatesItem({ onOpenModal }: Props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const softwareVersion = useSelector(dashboardSoftwareVersion);
  const isUpdateEnabled = useSelector(isUpdateEnabledSelector);
  const enableDashboardActions = useSelector(enableDashboardActionsSelector);
  const { confirm, updateInProgress } = useSelector(
    getDashboardSoftwareUpdateDetails
  );

  const onGetUpdatesClick = useCallback(() => {
    dispatch({
      type: DASHBOARD_ACTIONS.GET_UPDATES_BY_TYPE,
      payload: {
        type: "softwareUpdates",
      },
    });
    onOpenModal("softwareUpdates");
  }, [dispatch, onOpenModal]);

  return (
    <>
      <Typography className={styles.gridLabel} variant="body2">
        {getFirstLetterToUpperCase(
          t("modules.dashboard.grid.generalInformation.software")
        )}
      </Typography>
      <UpdateDetails
        title={softwareVersion}
        type="softwareUpdates"
        isUpdateEnabled={isUpdateEnabled}
        enableDashboardActions={enableDashboardActions || false}
        updateInProgress={updateInProgress}
        confirm={confirm}
        onGetUpdatesClick={onGetUpdatesClick}
      />
    </>
  );
}

export default SoftwareUpdatesItem;
