import { AnyAction, createSlice } from "@reduxjs/toolkit";
import pick from "lodash/pick";
import { HeaterCalendar } from "models/Calendar";
import {
  HeaterDataAllAttributes,
  HeaterDataAttributes,
  HeaterErrorOrWarningInfo,
} from "models/Heater";
import {
  ActivityCode,
  maintenanceActivityCodes,
  MqttConfirm,
  MqttUserActivityMessage,
  workModeActivityCodes,
} from "models/Mqtt";
import ParametersData from "models/Parameters";
import { IPublishPacket } from "mqtt";

import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import {
  AUTH_ACTIONS,
  isAuthenticatedSelector,
  mqttUrlSelector,
} from "store/auth";
import { acknowledgeErrorId } from "store/dashboardParameters";
import channels from "./channel";

type MqttState = {
  initializing: boolean;
  connected: boolean;
  error?: string;
};

const initialState: MqttState = {
  initializing: false,
  connected: false,
};

const mqttSlice = createSlice({
  name: "mqtt",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
  },
});

const MQTT_PUBLISHER = {
  AshLevel: "ashLevel",
  Confirm: "confirm",
  Connected: "connected",
  Calendar: "calendar",
  Data: "data",
  Errors: "errors",
  HoursTillMaintenance: "hoursTillMaintenance",
  Mode: "mode",
  Parameter: "parameter",
  Update: "update",
  UserActivity: "userActivity",
  Users: "users",
  Warnings: "warinngs",
};

export const MQTT_ACTIONS = {
  SET_DEVICE_CONNECTION: "SET_DEVICE_CONNECTION",
  PARAM_SET_CONFIRMED: "PARAM_SET_CONFIRMED",
  PARAM_NEW_VALUE: "PARAM_NEW_VALUE",
  HEATER_BULK_NEW_VALUES: "HEATER_BULK_NEW_VALUES",
  SET_USER_ACTIVITY: "SET_USER_ACTIVITY",
  SET_WORK_MODE_USER_ACTIVITY: "SET_WORK_MODE_USER_ACTIVITY",
  SET_MAINTENANCE_USER_ACTIVITY: "SET_MAINTENANCE_USER_ACTIVITY",
  STOP_CONNECTION: "STOP_CONNECTION",
  UPDATE_SOFTWARE_CONFIRM: "UPDATE_SOFTWARE_CONFIRM",
  UPDATE_FACTORY_CONFIRM: "UPDATE_FACTORY_CONFIRM",
  UPDATE_ERRORS: "UPDATE_ERRORS",
  UPDATE_WARNINGS: "UPDATE_WARNINGS",
  RECONNECT: "RECONNECT",
  ADD_HEATER_USER_CONFIRMED: "ADD_HEATER_USER_CONFIRMED",
  ADD_EVENT_CONFIRMED: "ADD_EVENT_CONFIRMED",
  NEW_CALENDAR_DATA: "NEW_CALENDAR_DATA",
};

const heaterDataPickedAttributes = [
  "id",
  "source",
  "energyMeter",
  "targetRoomTemperature",
  "ashLevel",
  "targetHotAirTemperature",
  "hotAirFanPower",
  "processState",
  "operationHoursHeating",
  "softwareVersion",
  "machineName",
  "serialNumber",
  "remoteAccess",
  "maintenanceInterval",
  "timePredictionTillNextMaintenance",
];

const getMessagePayload = (message: IPublishPacket) => {
  let messagePayload: string;
  if (typeof message.payload === "string") {
    messagePayload = message.payload;
  } else {
    messagePayload = message.payload.toString("utf8");
  }
  return messagePayload;
};

type AcitivitesByActivityCodeType = Record<number, MqttUserActivityMessage[]>;
const getActivitiesByActivityCode = (data: MqttUserActivityMessage[]) =>
  data.reduce((prev, current) => {
    if (prev[current.activityCode]) {
      prev[current.activityCode].push(current);
    } else {
      prev[current.activityCode] = [current];
    }
    return prev;
  }, {} as AcitivitesByActivityCodeType);

const getUserActivityAction = (
  mqttUserActivityMessage: MqttUserActivityMessage[],
  serialNumber: string
): AnyAction | null => {
  let userActivityAction: AnyAction | null = null;
  const activitiesByActivityCode = getActivitiesByActivityCode(
    mqttUserActivityMessage as MqttUserActivityMessage[]
  );

  Object.keys(activitiesByActivityCode).forEach((key) => {
    const activityCode = Number(key);

    if (activityCode === ActivityCode.extendingParameterData) {
      userActivityAction = {
        type: MQTT_ACTIONS.SET_USER_ACTIVITY,
        payload: {
          serialNumber,
          activities: activitiesByActivityCode[activityCode],
        },
      };
    }

    if (workModeActivityCodes.includes(activityCode)) {
      userActivityAction = {
        type: MQTT_ACTIONS.SET_WORK_MODE_USER_ACTIVITY,
        payload: {
          serialNumber,
          property: ActivityCode[activityCode],
          activities: activitiesByActivityCode[activityCode],
        },
      };
    }

    if (maintenanceActivityCodes.includes(activityCode)) {
      userActivityAction = {
        type: MQTT_ACTIONS.SET_MAINTENANCE_USER_ACTIVITY,
        payload: {
          serialNumber,
          property: ActivityCode[activityCode],
          activities: activitiesByActivityCode[activityCode],
        },
      };
    }
  });

  return userActivityAction;
};

function* handleMqttSaga(a: AnyAction) {
  if (a.type === MQTT_ACTIONS.STOP_CONNECTION) {
    return;
  }

  const mqttUrl = (yield select(mqttUrlSelector)) as string;

  const mqttChannel: ReturnType<typeof channels.mqttChannel> = yield call(
    channels.mqttChannel,
    mqttUrl
  );

  try {
    while (true) {
      const message = (yield take(mqttChannel)) as IPublishPacket | AnyAction;

      if ((message as AnyAction).type === MQTT_ACTIONS.RECONNECT) {
        const isAuthenticated = (yield select(
          isAuthenticatedSelector
        )) as boolean;

        if (isAuthenticated) {
          yield put({ type: AUTH_ACTIONS.REFRESH });
        }

        return;
      }

      if ((message as AnyAction).type === MQTT_ACTIONS.STOP_CONNECTION) {
        yield put({ type: AUTH_ACTIONS.LOGOUT });
        return;
      }

      const pathParts = message.topic.split("/").slice(3);
      const pathPartsLength = pathParts.length;
      const serialNumber = pathParts[0];
      const mqttPublisher = pathParts[1];
      const mqttSubPublisher = pathParts[pathPartsLength - 1];
      const messagePayload = getMessagePayload(message as IPublishPacket);

      // eslint-disable-next-line no-console
      console.log(`MQTT message received: ${message.topic}, ${messagePayload}`);

      if (mqttPublisher === MQTT_PUBLISHER.Connected) {
        yield put({
          type: MQTT_ACTIONS.SET_DEVICE_CONNECTION,
          payload: {
            serialNumber,
            isConnected: messagePayload === "true",
          },
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.Data) {
        const heaterData = JSON.parse(
          messagePayload
        ) as HeaterDataAllAttributes;

        const pickedData = pick(
          heaterData,
          heaterDataPickedAttributes
        ) as HeaterDataAttributes;

        yield put({
          type: MQTT_ACTIONS.HEATER_BULK_NEW_VALUES,
          payload: {
            serialNumber,
            data: pickedData,
          },
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.Parameter) {
        const paramId = pathParts[2];
        if (
          pathPartsLength === 5 &&
          mqttSubPublisher === MQTT_PUBLISHER.Confirm
        ) {
          yield put({
            type: MQTT_ACTIONS.PARAM_SET_CONFIRMED,
            payload: {
              serialNumber,
              paramId: Number(paramId),
              confirm: messagePayload as MqttConfirm,
            },
          });
        }

        if (pathPartsLength === 3) {
          yield put({
            type: MQTT_ACTIONS.PARAM_NEW_VALUE,
            payload: {
              paramId: Number(paramId),
              serialNumber,
              newValue: Number(messagePayload)
                ? Number(messagePayload)
                : messagePayload,
            },
          });
        }
      }

      if (mqttPublisher === MQTT_PUBLISHER.Mode) {
        if (
          pathPartsLength === 4 &&
          mqttSubPublisher === MQTT_PUBLISHER.Confirm
        ) {
          yield put({
            type: MQTT_ACTIONS.PARAM_SET_CONFIRMED,
            payload: {
              serialNumber,
              paramId: ParametersData.ProcessState.id,
              value: messagePayload as MqttConfirm,
            },
          });
        }
      }

      if (mqttPublisher === MQTT_PUBLISHER.AshLevel) {
        yield put({
          type: MQTT_ACTIONS.PARAM_SET_CONFIRMED,
          payload: {
            serialNumber,
            paramId: ParametersData.AshLevel.id,
            confirm: messagePayload as MqttConfirm,
          },
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.HoursTillMaintenance) {
        yield put({
          type: MQTT_ACTIONS.PARAM_SET_CONFIRMED,
          payload: {
            serialNumber,
            paramId: ParametersData.HoursTillMaintenance.id,
            confirm: messagePayload as MqttConfirm,
          },
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.Errors) {
        const payloadStringToObject = JSON.parse(messagePayload);
        const errors = payloadStringToObject.data as HeaterErrorOrWarningInfo[];

        if (pathPartsLength === 4) {
          yield put({
            type: MQTT_ACTIONS.UPDATE_ERRORS,
            payload: { serialNumber, errors },
          });
        }

        if (
          pathPartsLength === 4 &&
          mqttSubPublisher === MQTT_PUBLISHER.Confirm
        ) {
          yield put({
            type: MQTT_ACTIONS.PARAM_SET_CONFIRMED,
            payload: {
              serialNumber,
              paramId: acknowledgeErrorId,
              confirm: messagePayload as MqttConfirm,
            },
          });
        }
      }

      if (mqttPublisher === MQTT_PUBLISHER.Warnings) {
        const payloadStringToObject = JSON.parse(messagePayload);
        const warnings =
          payloadStringToObject.data as HeaterErrorOrWarningInfo[];

        yield put({
          type: MQTT_ACTIONS.UPDATE_WARNINGS,
          payload: { serialNumber, warnings },
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.UserActivity) {
        const payloadStringToObject = JSON.parse(messagePayload);
        const userActivityAction = getUserActivityAction(
          payloadStringToObject.data as MqttUserActivityMessage[],
          serialNumber
        );

        if (userActivityAction) {
          yield put(userActivityAction);
        }
      }

      if (mqttPublisher === MQTT_PUBLISHER.Update) {
        const updateType = pathParts[2];

        if (updateType === "software") {
          yield put({
            type: MQTT_ACTIONS.UPDATE_SOFTWARE_CONFIRM,
            payload: {
              serialNumber,
              confirm: messagePayload,
              type: "software",
            },
          });
        } else if (updateType === "factorySettings") {
          yield put({
            type: MQTT_ACTIONS.UPDATE_FACTORY_CONFIRM,
            payload: {
              serialNumber,
              confirm: messagePayload,
              type: "factorySettings",
            },
          });
        }
      }

      if (mqttPublisher === MQTT_PUBLISHER.Users) {
        yield put({
          type: MQTT_ACTIONS.ADD_HEATER_USER_CONFIRMED,
          payload: Boolean(+messagePayload),
        });
      }

      if (mqttPublisher === MQTT_PUBLISHER.Calendar) {
        if (
          pathPartsLength === 4 &&
          mqttSubPublisher === MQTT_PUBLISHER.Confirm
        ) {
          yield put({
            type: MQTT_ACTIONS.ADD_EVENT_CONFIRMED,
            payload: messagePayload as MqttConfirm,
          });
        } else {
          const payloadStringToObject = JSON.parse(messagePayload);
          const heaterCalendar = payloadStringToObject as {
            data: HeaterCalendar[];
          };

          yield put({
            type: MQTT_ACTIONS.NEW_CALENDAR_DATA,
            payload: {
              serialNumber,
              heaterCalendar,
            },
          });
        }
      }
    }
  } finally {
    const done: boolean = yield cancelled();

    if (done) {
      mqttChannel.close();
    }
  }
}

function* watchMqttSaga() {
  yield takeLatest(
    [mqttSlice.actions.init, MQTT_ACTIONS.STOP_CONNECTION],
    handleMqttSaga
  );
}

export function* mqttWatcher() {
  yield all([fork(watchMqttSaga)]);
}

export default mqttSlice;
