import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "i18n";
import { Language } from "models/Language";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import snackbarSlice from "./snackbar";

export enum AdminDevicesTabs {
  General,
  HeaterUsers,
}

export const UI_ACTIONS = {
  HANDLE_ERROR: "HANDLE_ERROR",
};

type BatchUpdateMode = "init" | "count";

type UiState = {
  isDrawerOpen: boolean;
  language: Language;
  scrollToGroupId: number | null;
  adminDevices: {
    selectedTab: AdminDevicesTabs;
  };
  calendar: {
    isModalOpen: boolean;
  };
  units: {
    usersDrawerOpen: boolean;
    rulesDrawerOpen: boolean;
  };
  dashboard: {
    dashboardView: "table" | "grid";
    batchUpdateMode: BatchUpdateMode;
    updatesDrawerOpen: boolean;
  };
};

const initialState: UiState = {
  isDrawerOpen: false,
  language: (localStorage.getItem("language") as Language) || "en",
  scrollToGroupId: null,
  adminDevices: {
    selectedTab: AdminDevicesTabs.General,
  },
  calendar: {
    isModalOpen: false,
  },
  units: {
    usersDrawerOpen: false,
    rulesDrawerOpen: false,
  },
  dashboard: {
    batchUpdateMode: "init",
    dashboardView: "table",
    updatesDrawerOpen: false,
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawerOpen = payload;
    },
    setDashboardView: (state, { payload }: PayloadAction<"table" | "grid">) => {
      state.dashboard.dashboardView = payload;
    },
    setBatchUpdateMode: (
      state,
      { payload }: PayloadAction<BatchUpdateMode>
    ) => {
      state.dashboard.batchUpdateMode = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<Language>) => {
      state.language = payload;

      localStorage.setItem("language", payload);
    },
    setScrollToGroupId: (state, { payload }: PayloadAction<number | null>) => {
      state.scrollToGroupId = payload;
    },
    setAdminDevicesSelectedTab: (
      state,
      { payload }: PayloadAction<AdminDevicesTabs>
    ) => {
      state.adminDevices.selectedTab = payload;
    },
    setCalendarModelOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.calendar.isModalOpen = payload;
    },
    setUsersUnitDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.units.usersDrawerOpen = payload;
    },
    setRulesUnitDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.units.rulesDrawerOpen = payload;
    },
    clearUnitDrawer: (state) => {
      state.units.rulesDrawerOpen = false;
      state.units.usersDrawerOpen = false;
    },
    setVisibleUpdatesDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.dashboard.updatesDrawerOpen = payload;
    },
  },
});

function uiSelector(state: { [uiSlice.name]: UiState }) {
  return state[uiSlice.name];
}

export const isDrawerOpenSelector = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.isDrawerOpen
);

export const isCalendarModalOpenSelector = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.calendar.isModalOpen
);

export const getDashboardView = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.dashboard.dashboardView
);

export const getDashboardBatchUpdateMode = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.dashboard.batchUpdateMode
);

export const getLanguage = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.language
);

export const isUsersUnitDrawerOpen = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.units.usersDrawerOpen
);

export const isRulesUnitDrawerOpen = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.units.rulesDrawerOpen
);

export const isUpdatesDrawerOpen = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.dashboard.updatesDrawerOpen
);

export const getCulture = createSelector(getLanguage, (language) =>
  language === "en" ? "en-gb" : "de"
);

export const getScrollToGroupId = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.scrollToGroupId
);

export const isGridDashboardView = createSelector(
  getDashboardView,
  (dashboardView) => dashboardView === "grid"
);

export const getAdminDevicesSelectedTab = createSelector(
  uiSelector,
  (uiState: UiState) => uiState.adminDevices.selectedTab
);

function* handleError(action: PayloadAction<string>): Generator {
  const code = action.payload;
  const baseMessage = `${i18n.t("apiErrors.base")}`;

  let message = baseMessage;

  if (code) {
    message = `${i18n.t(`apiErrors.codes.${code}`)} ${baseMessage}`;
  }

  yield put(snackbarSlice.actions.showError(message));
}

function* watchHandleError() {
  yield takeLatest(UI_ACTIONS.HANDLE_ERROR, handleError);
}

export function* uiWatcher() {
  yield all([fork(watchHandleError)]);
}

export default uiSlice;
