import { push } from "@lagunovsky/redux-react-router";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import paths from "config/paths";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import { AdminDevice, DeviceKind, DeviceModel } from "models/Heater";
import { Organization } from "models/Organization";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { organizationsByIdSelector } from "store/organizations";

export const ADMIN_DEVICES_ACTIONS = {
  ADD_DEVICE: "ADD_DEVICE",
  GET_DEVICE: "GET_DEVICE",
  EDIT_DEVICE: "EDIT_DEVICE",
  EDIT_DEVICE_ORG: "EDIT_DEVICE_ORG",
  NAVIGATE_TO_EDIT: "NAVIGATE_TO_EDIT",
  DELETE_DEVICE: "DELETE_DEVICE",
  CREATE_CERTIFICATE: "CREATE_CERTIFICATE",
  DELETE_CERTIFICATE: "DELETE_CERTIFICATE",
  GET_CERTIFICATE: "GET_CERTIFICATE",
  IMPORT_CERTIFICATE: "IMPORT_CERTIFICATE",
};

type AdminDeviceState = {
  initializing: boolean;
  devices: Record<number, AdminDevice>;
  actionInProgress: boolean;
  error?: string;
};

const initialState: AdminDeviceState = {
  initializing: false,
  devices: {},
  actionInProgress: false,
};

const adminDevicesSlice = createSlice({
  name: "adminDevices",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<AdminDevice[]>) => {
      state.devices = {
        ...state.devices,
        ...mapKeys(payload, (item) => item.device.id),
      };
      state.initializing = false;
      state.actionInProgress = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    setActionInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.actionInProgress = payload;
    },
    getDeviceSuccess: (state, { payload }: PayloadAction<AdminDevice>) => {
      state.devices[payload.device.id] = payload;
    },
    deleteDeviceSucces: (state, { payload }: PayloadAction<number>) => {
      state.devices = omit(state.devices, payload);
      state.actionInProgress = false;
    },
    editDeviceSucces: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        serialNumber: string;
        model: DeviceModel;
        kind: DeviceKind;
      }>
    ) => {
      const { id, serialNumber, model, kind } = payload;
      state.actionInProgress = false;
      state.devices[id].device = {
        ...state.devices[id].device,
        serialNumber,
        model,
        kind,
      };
    },
    editOrganizationsSucces: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        organizations: Organization[];
      }>
    ) => {
      const { id, organizations } = payload;

      if (state.devices[id]) {
        state.devices[id].device.organizations = organizations;
      }
    },
    addDeviceSucces: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        serialNumber: string;
        model: DeviceModel;
        kind: DeviceKind;
      }>
    ) => {
      const { id, serialNumber, model, kind } = payload;
      state.actionInProgress = false;
      state.devices[id].device = {
        ...state.devices[id].device,
        serialNumber,
        model,
        kind,
      };
    },
    certActionSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        certType: "iot_core" | "ovpn";
        flag: boolean;
      }>
    ) => {
      const { id, certType, flag } = payload;
      const isOvpn = certType === "ovpn";
      const isMqtt = certType === "iot_core";

      if (state.devices[id].keysAndCerts) {
        if (isOvpn) {
          state.devices[id].keysAndCerts.hasOvpn = flag;
        }

        if (isMqtt) {
          state.devices[id].keysAndCerts.hasMqtt = flag;
        }
      } else {
        state.devices[id].keysAndCerts = {
          hasOvpn: isOvpn ? flag : false,
          hasMqtt: isMqtt ? flag : false,
        };
      }
    },
  },
});

function devicesSelector(state: {
  [adminDevicesSlice.name]: AdminDeviceState;
}) {
  return state[adminDevicesSlice.name];
}

export const devicesByIdSelector = createSelector(
  devicesSelector,
  (state: AdminDeviceState) => state.devices
);

export const deviceActionInProgress = createSelector(
  devicesSelector,
  (state: AdminDeviceState) => state.actionInProgress
);

export const adminDeviceListSelector = createSelector(
  devicesByIdSelector,
  (devicesById) => Object.values(devicesById)
);

export const createDeviceHeaderData = (deviceId: number) =>
  createSelector(devicesByIdSelector, (devicesById) => {
    const { device, parameters } = devicesById[deviceId] || {};

    return {
      title: parameters?.machineName || device?.serialNumber,
      serialNumber: device?.serialNumber,
    };
  });

export const createHasDeviceCertData = (
  deviceId: number,
  certType: "ovpn" | "iot_core"
) =>
  createSelector(devicesByIdSelector, (devicesById) => {
    const { keysAndCerts = { hasMqtt: false, hasOvpn: false } } =
      devicesById[deviceId] || {};

    return certType === "ovpn" ? keysAndCerts.hasOvpn : keysAndCerts.hasMqtt;
  });

export const createDeviceGeneralInfoData = (deviceId: number) =>
  createSelector(devicesByIdSelector, (devicesById) => {
    const {
      device,
      parameters,
      keysAndCerts = { hasMqtt: false, hasOvpn: false },
    } = devicesById[deviceId] || {};

    return {
      id: device?.id,
      machineName: parameters?.machineName || "-",
      model: device?.model,
      keysAndCerts,
    };
  });

export const createDeviceInitialFormValues = (deviceId: number) =>
  createSelector(devicesByIdSelector, (devicesById) => {
    const { device, keysAndCerts = { hasMqtt: false, hasOvpn: false } } =
      devicesById[deviceId] || {};

    return {
      serialNumber: device?.serialNumber || "",
      model: device?.model.toString() || DeviceModel.G200.toString(),
      kind: device?.kind.toString() || DeviceKind.Test.toString(),
      organizations: device?.organizations.map((org) => org.id) || [],
      hasMqtt: keysAndCerts.hasMqtt,
      hasOvpn: keysAndCerts.hasOvpn,
    };
  });

function* handleGetDevice(
  action: PayloadAction<{ deviceId: number }>
): Generator {
  try {
    const { deviceId } = action.payload;
    const device = (yield call(
      heaterService.getAdminDevice,
      deviceId
    )) as AdminDevice;
    yield put(adminDevicesSlice.actions.getDeviceSuccess(device));
  } catch (err) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetDevice() {
  yield takeLatest(ADMIN_DEVICES_ACTIONS.GET_DEVICE, handleGetDevice);
}

function* handleAddDevice(
  action: PayloadAction<{
    serialNumber: string;
    model: number;
    kind: number;
    organizations: number[];
    hasMqtt: boolean;
    hasOvpn: boolean;
  }>
): Generator {
  try {
    yield put(adminDevicesSlice.actions.setActionInProgress(true));
    const { serialNumber, model, kind, organizations, hasMqtt, hasOvpn } =
      action.payload;

    const newDevice = {
      serialNumber,
      model,
      kind,
    };

    const device = (yield call(heaterService.createDevice, newDevice)) as any;

    if (device) {
      adminDevicesSlice.actions.addDeviceSucces({
        id: device.id,
        ...newDevice,
      });

      yield put(push(paths.devices));

      if (organizations.length > 0) {
        yield put({
          type: ADMIN_DEVICES_ACTIONS.EDIT_DEVICE_ORG,
          payload: {
            id: device.id,
            organizations,
          },
        });
      }

      if (hasMqtt) {
        yield put({
          type: ADMIN_DEVICES_ACTIONS.CREATE_CERTIFICATE,
          payload: {
            id: device.id,
            certType: "iot_core",
          },
        });
      }

      if (hasOvpn) {
        yield put({
          type: ADMIN_DEVICES_ACTIONS.CREATE_CERTIFICATE,
          payload: {
            id: device.id,
            certType: "ovpn",
          },
        });
      }
    }
  } catch (error) {
    yield put(adminDevicesSlice.actions.setActionInProgress(false));
  }
}

function* watchAddDevice() {
  yield takeLatest(ADMIN_DEVICES_ACTIONS.ADD_DEVICE, handleAddDevice);
}

function* handleDeleteDevice(
  action: PayloadAction<{ deviceId: number }>
): Generator {
  try {
    yield put(adminDevicesSlice.actions.setActionInProgress(true));

    const { deviceId } = action.payload;

    yield call(heaterService.deleteDevice, deviceId);

    yield put(adminDevicesSlice.actions.deleteDeviceSucces(deviceId));
    yield put(push(paths.devices));
  } catch (error) {
    yield put(adminDevicesSlice.actions.setActionInProgress(false));
  }
}

function* watchDeleteDevice() {
  yield takeLatest(ADMIN_DEVICES_ACTIONS.DELETE_DEVICE, handleDeleteDevice);
}

function* handleEditOrganizations(
  action: PayloadAction<{
    id: number;
    organizations: number[];
  }>
): Generator {
  try {
    const { id, organizations } = action.payload;

    yield call(heaterService.editDeviceOrganizations, id, organizations);

    const orgsById = (yield select(organizationsByIdSelector)) as Record<
      string,
      Organization
    >;

    const list = organizations.map((orgId) => ({
      id: orgsById[orgId].id,
      label: orgsById[orgId].label,
      defaultLanguage: orgsById[orgId].defaultLanguage,
      kind: orgsById[orgId].kind,
    }));

    yield put(
      adminDevicesSlice.actions.editOrganizationsSucces({
        id,
        organizations: list,
      })
    );
  } catch (error) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditOrganizations() {
  yield takeLatest(
    ADMIN_DEVICES_ACTIONS.EDIT_DEVICE_ORG,
    handleEditOrganizations
  );
}

function* handleEditDevice(
  action: PayloadAction<{
    id: number;
    serialNumber: string;
    model: number;
    kind: number;
    organizations: number[];
  }>
): Generator {
  yield put(adminDevicesSlice.actions.setActionInProgress(true));

  try {
    const { id, serialNumber, model, kind, organizations } = action.payload;
    const newDevice = {
      serialNumber,
      model,
      kind,
    };

    yield call(heaterService.editDevice, id, newDevice);

    yield put(adminDevicesSlice.actions.editDeviceSucces({ id, ...newDevice }));
    yield put(push(paths.devices));

    yield put({
      type: ADMIN_DEVICES_ACTIONS.EDIT_DEVICE_ORG,
      payload: {
        id,
        organizations,
      },
    });
  } catch (error) {
    yield put(adminDevicesSlice.actions.setActionInProgress(false));
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditDevice() {
  yield takeLatest(ADMIN_DEVICES_ACTIONS.EDIT_DEVICE, handleEditDevice);
}

function* handleDeleteCertificate(
  action: PayloadAction<{
    id: number;
    certType: "ovpn" | "iot_core";
  }>
): Generator {
  const { id, certType } = action.payload;

  try {
    yield call(heaterService.deleteCoreKeysAndCert, id, certType);

    yield put(
      adminDevicesSlice.actions.certActionSuccess({
        id,
        certType,
        flag: false,
      })
    );
  } catch (error) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteCertificate() {
  yield takeLatest(
    ADMIN_DEVICES_ACTIONS.DELETE_CERTIFICATE,
    handleDeleteCertificate
  );
}

function* handleImportCertificate(
  action: PayloadAction<{
    id: number;
    certType: "ovpn" | "iot_core";
    file: File;
  }>
): Generator {
  const { id, certType, file } = action.payload;
  try {
    yield call(heaterService.importCoreKeysAndCert, id, certType, file);
    yield put(
      adminDevicesSlice.actions.certActionSuccess({
        id,
        certType,
        flag: true,
      })
    );
  } catch (error) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchImportCertificate() {
  yield takeLatest(
    ADMIN_DEVICES_ACTIONS.IMPORT_CERTIFICATE,
    handleImportCertificate
  );
}

const saveFile = (data: string, fileName: string, type: string) => {
  const blob = new Blob([data], {
    type,
  });
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });
};

function* handleGetCertificate(
  action: PayloadAction<{
    id: number;
    certType: "ovpn" | "iot_core";
  }>
): Generator {
  const { id, certType } = action.payload;
  const devicesById = (yield select(devicesByIdSelector)) as Record<
    number,
    AdminDevice
  >;
  const serialNumber = devicesById[id]?.device?.serialNumber;

  try {
    const data = (yield call(
      heaterService.getKeysAndCert,
      id,
      certType
    )) as string;

    const isMqtt = certType === "iot_core";
    const prefix = isMqtt ? "mqtt" : certType;
    const fileType = isMqtt ? "application/zip" : "application/octet-stream";
    const postfix = isMqtt ? "" : ".ovpn";
    saveFile(
      data,
      `SN-${serialNumber}-${prefix}-certificate${postfix}`,
      fileType
    );
  } catch (error) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetCertificate() {
  yield takeLatest(ADMIN_DEVICES_ACTIONS.GET_CERTIFICATE, handleGetCertificate);
}

function* handleCreateCertificate(
  action: PayloadAction<{
    id: number;
    certType: "ovpn" | "iot_core";
  }>
): Generator {
  const { id, certType } = action.payload;

  try {
    yield call(heaterService.createCoreKeysAndCert, id, certType);

    yield put(
      adminDevicesSlice.actions.certActionSuccess({
        id,
        certType,
        flag: true,
      })
    );
  } catch (error) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchCreateCertificate() {
  yield takeLatest(
    ADMIN_DEVICES_ACTIONS.CREATE_CERTIFICATE,
    handleCreateCertificate
  );
}

function* handleInit(): Generator {
  try {
    const adminDevices = (yield call(
      heaterService.getAdminDeviceList
    )) as AdminDevice[];
    yield put(adminDevicesSlice.actions.initSuccess(adminDevices));
  } catch (err) {
    yield put(adminDevicesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(adminDevicesSlice.actions.init, handleInit);
}

export function* adminDevicesWatcher() {
  yield all([
    fork(watchInit),
    fork(watchGetDevice),
    fork(watchAddDevice),
    fork(watchDeleteDevice),
    fork(watchEditDevice),
    fork(watchEditOrganizations),
    fork(watchCreateCertificate),
    fork(watchDeleteCertificate),
    fork(watchGetCertificate),
    fork(watchImportCertificate),
  ]);
}

export default adminDevicesSlice;
