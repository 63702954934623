import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapKeys from "lodash/mapKeys";
import { CreateHeaterUser, HeaterUser } from "models/HeaterUser";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { MQTT_ACTIONS } from "store/mqtt";

export const HEATER_USERS_ACTIONS = {
  GET_HEATER_USERS: "GET_HEATER_USERS",
  ADD_HEATER_USERS: "ADD_HEATER_USERS",
  DELETE_HEATER_USERS: "DELETE_HEATER_USERS",
};

type HeaterUserState = {
  initializing: boolean;
  heaterUsers: Record<string, HeaterUser>;
  addInProgress: {
    loading: boolean;
    created: boolean;
    user: CreateHeaterUser | null;
  };
  error?: string;
};

const initialState: HeaterUserState = {
  initializing: false,
  heaterUsers: {},
  addInProgress: {
    loading: false,
    created: false,
    user: null,
  },
};

const heaterUsersSlice = createSlice({
  name: "heaterUsers",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<number>) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<HeaterUser[]>) => {
      state.heaterUsers = {
        ...state.heaterUsers,
        ...mapKeys(payload, (user) => user.email),
      };
      state.initializing = false;
      state.addInProgress.loading = false;
      state.addInProgress.created = false;
      state.addInProgress.user = null;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    setAddInProgress: (state, { payload }: PayloadAction<CreateHeaterUser>) => {
      state.addInProgress.loading = true;
      state.addInProgress.user = payload;
    },
    addDone: (state, { payload }: PayloadAction<boolean>) => {
      state.addInProgress.loading = false;
      state.addInProgress.created = payload;
      if (state.addInProgress.user) {
        const mail = state.addInProgress.user.email;
        state.heaterUsers = {
          ...state.heaterUsers,
          [mail]: {
            email: state.addInProgress.user.email,
            level: state.addInProgress.user.accessLevel,
          },
        };
      }
    },
    deleteSuccess: (state, { payload }: PayloadAction<HeaterUser[]>) => {
      state.heaterUsers = mapKeys(payload, (user) => user.email);
    },
  },
});

function heaterUsersSelector(state: {
  [heaterUsersSlice.name]: HeaterUserState;
}) {
  return state[heaterUsersSlice.name];
}

export const heaterUsersByMailSelector = createSelector(
  heaterUsersSelector,
  (state: HeaterUserState) => state.heaterUsers
);

export const createHeaterUserInProgress = createSelector(
  heaterUsersSelector,
  (state: HeaterUserState) => state.addInProgress.loading
);

export const heaterUserCreatead = createSelector(
  heaterUsersSelector,
  (state: HeaterUserState) => state.addInProgress.created
);

export const heaterUserListSelector = createSelector(
  heaterUsersByMailSelector,
  (usersByMail) => Object.values(usersByMail)
);

function* handleDelete(
  action: PayloadAction<{ deviceId: number; email: string }>
): Generator {
  try {
    const { deviceId, email } = action.payload;
    const newUsers = (yield call(
      heaterService.deleteRemoteHeaterUser,
      deviceId,
      email
    )) as HeaterUser[];

    yield put(heaterUsersSlice.actions.deleteSuccess(newUsers));
  } catch (error) {
    yield put(heaterUsersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDelete() {
  yield takeLatest(HEATER_USERS_ACTIONS.DELETE_HEATER_USERS, handleDelete);
}

function* handleConfirmAdd(action: PayloadAction<boolean>): Generator {
  try {
    yield put(heaterUsersSlice.actions.addDone(action.payload));
  } catch (err) {
    yield put(heaterUsersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchConfirmAddHeaterUser() {
  yield takeLatest(MQTT_ACTIONS.ADD_HEATER_USER_CONFIRMED, handleConfirmAdd);
}

function* handleAddHeaterUser(
  action: PayloadAction<{ deviceId: number; user: CreateHeaterUser }>
): Generator {
  try {
    const { deviceId, user } = action.payload;
    yield call(heaterService.addRemoteHeaterUsers, deviceId, user);
    yield put(heaterUsersSlice.actions.setAddInProgress(user));
  } catch (error) {
    yield put(heaterUsersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAdd() {
  yield takeLatest(HEATER_USERS_ACTIONS.ADD_HEATER_USERS, handleAddHeaterUser);
}

function* handleInit(action: PayloadAction<number>): Generator {
  try {
    const deviceId = action.payload;
    const users = (yield call(
      heaterService.getRemoteHeaterUsers,
      deviceId
    )) as HeaterUser[];

    yield put(heaterUsersSlice.actions.initSuccess(users));
  } catch (err) {
    yield put(heaterUsersSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(heaterUsersSlice.actions.init, handleInit);
}

export function* heaterUsersWatcher() {
  yield all([
    fork(watchInit),
    fork(watchAdd),
    fork(watchDelete),
    fork(watchConfirmAddHeaterUser),
  ]);
}

export default heaterUsersSlice;
