import { Outlet } from "react-router-dom";
import Header from "components/common/Header";
import Sidebar from "components/Sidebar";

function Layout() {
  return (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  );
}

export default Layout;
