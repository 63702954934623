import { Backdrop, CircularProgress } from "@mui/material";

type Props = {
  open: boolean;
};

function Loader({ open }: Props) {
  return (
    <Backdrop
      style={{
        zIndex: 60000,
      }}
      open={open}
    >
      <CircularProgress size={130} color="primary" />
    </Backdrop>
  );
}

export default Loader;
