import { Button } from "@mui/material";
import HeaterUsersTable from "components/adminDevices/heaterUsersTab/HeaterUsersTable";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import heaterUsersSlice, { heaterUserListSelector } from "store/heaterUsers";

type Props = {
  deviceId: number;
};
function HeaterUsers({ deviceId }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = useSelector(heaterUserListSelector);

  useEffectOnce(() => {
    dispatch(heaterUsersSlice.actions.init(deviceId));
  });

  const onAdd = useCallback(() => {
    navigate(`/devices/${deviceId}/newUser`);
  }, [deviceId, navigate]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "36px",
        }}
      >
        <Button
          style={{ textTransform: "none" }}
          color="primary"
          variant="contained"
          onClick={onAdd}
        >
          {t("modules.users.addNew")}
        </Button>
      </div>
      <HeaterUsersTable deviceId={deviceId} users={users} />
    </div>
  );
}

export default HeaterUsers;
