import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { ChangeEvent, useMemo, useState } from "react";
import { UpdateDetails } from "services/heater";

type Props = {
  title: string;
  searchPlaceholder: string;
  updates: UpdateDetails[];
  onSelectUpdate: (update: UpdateDetails) => void;
  getIsUpdateSelected: (update: UpdateDetails) => boolean;
};

const useStyles = makeStyles(() => ({
  fileIcon: {
    color: "#9D9DAF !important",
  },
  fileIconSelected: {
    color: "#FCFCFD !important",
  },
  selected: {
    backgroundColor: "#FF5200 !important",
    color: "#FCFCFD !important",
  },
}));

function UpdateList({
  title,
  updates,
  searchPlaceholder,
  onSelectUpdate,
  getIsUpdateSelected,
}: Props) {
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const filteredUpdates = useMemo(
    () =>
      updates.filter((update) =>
        update.key.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, updates]
  );

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          value={searchValue}
          placeholder={searchPlaceholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearchValue(event.target.value)
          }
        />
      </Box>

      <List>
        {filteredUpdates.map((update) => (
          <ListItemButton
            classes={{ selected: styles.selected }}
            key={update.lastModified}
            selected={getIsUpdateSelected(update)}
            onClick={() => onSelectUpdate(update)}
          >
            <ListItemIcon>
              <InsertDriveFileIcon
                className={classNames(styles.fileIcon, {
                  [styles.fileIconSelected]: getIsUpdateSelected(update),
                })}
              />
            </ListItemIcon>
            <ListItemText primary={update.key} />
          </ListItemButton>
        ))}
      </List>

      {filteredUpdates.length === 0 && (
        <Box
          py={2}
          sx={{
            textAlign: "center",
          }}
        >
          <ContentPasteOffIcon
            className={styles.fileIcon}
            sx={{ width: 40, height: 40 }}
          />
        </Box>
      )}
    </>
  );
}

export default UpdateList;
