import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "i18n";
import omit from "lodash/omit";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";
import {
  ActivityRecord,
  Heater,
  HeaterConnectivityStatus,
  HeaterDashboard,
  HeaterDataAttributes,
  HeaterMaintenanceActivities,
  HeaterWorkModeActivities,
} from "models/Heater";
import { Language } from "models/Language";
import { MqttConfirm, MqttUserActivityMessage } from "models/Mqtt";
import ParametersData from "models/Parameters";
import { UpdateType } from "models/Update";
import moment from "moment";
import { Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import heaterService, {
  ScheduleUpdatePayload,
  UpdateDetails,
} from "services/heater";
import dashboardErrorSlice from "store/dashboardErrors";
import dashboardParametersSlice from "store/dashboardParameters";
import dashboardWarningSlice from "store/dashboardWarnings";
import heaterSlice from "store/heater";
import { MQTT_ACTIONS } from "store/mqtt";
import snackbarSlice from "./snackbar";
import uiSlice from "./ui";
import { isHeaterDataOld } from "../helpers";

type ParamUpdate = {
  loading: boolean;
  confirm: MqttConfirm | null;
};

type DashboardState = {
  initializing: boolean;
  dashboard: Omit<HeaterDashboard, "warnings" | "errors" | "parameters"> | null;
  badgeInfo: Pick<Heater, "errorCount" | "warningCount">;
  updates: {
    softwareUpdates: UpdateDetails[];
    factorySettings: UpdateDetails[];
  };
  paramActivityRecord: Record<string, ActivityRecord>;
  updatesInProgress: {
    softwareUpdates: Record<string, ParamUpdate>;
    factorySettings: Record<string, ParamUpdate>;
  };
  guacamole: {
    url: string;
    loading: boolean;
  };
  error?: string;
};

const initialState: DashboardState = {
  initializing: true,
  badgeInfo: {
    errorCount: 0,
    warningCount: 0,
  },
  updates: {
    softwareUpdates: [],
    factorySettings: [],
  },
  dashboard: null,
  paramActivityRecord: {},
  updatesInProgress: {
    softwareUpdates: {},
    factorySettings: {},
  },
  guacamole: {
    url: "",
    loading: false,
  },
};

type SetUserActivitiesPayload = {
  serialNumber: string;
  activities: MqttUserActivityMessage[];
};

type SetUserWorkModeActivityPayload = SetUserActivitiesPayload & {
  property: keyof HeaterWorkModeActivities;
};

type SetUserMaintenanceActivityPayload = SetUserActivitiesPayload & {
  property: keyof HeaterMaintenanceActivities;
};

export const DASHBOARD_ACTIONS = {
  GET_UPDATES_BY_TYPE: "GET_UPDATES_BY_TYPE",
  GET_UPDATES_BY_TYPE_URL: "GET_UPDATES_BY_TYPE_URL",
  RESET: "RESET",
  GET_GUACAMOLE: "GET_GUACAMOLE",
  SCHEDULE_UPDATES: "SCHEDULE_UPDATES",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<number>) => {
      state.initializing = true;
      state.guacamole.url = "";
      state.guacamole.loading = true;
    },
    initSuccess: (state, { payload }: PayloadAction<HeaterDashboard>) => {
      state.dashboard = omit(payload, "errors", "warnings", "parameters");
      state.badgeInfo = {
        errorCount: payload.errors.length,
        warningCount: payload.warnings.length,
      };
      state.paramActivityRecord = payload.activities.parameters?.reduce(
        (prev, current) => ({
          ...prev,
          [current.code]: current,
        }),
        {}
      );
      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    setGuacamoleLoading: (state) => {
      state.guacamole.loading = true;
    },
    setGuacamoleUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.guacamole.url = payload || "";
      state.guacamole.loading = false;
    },
    setSoftwareUpdates: (
      state,
      {
        payload,
      }: PayloadAction<{
        updates: UpdateDetails[];
        type: UpdateType;
      }>
    ) => {
      const { type, updates } = payload;
      state.updates[type] = updates;
    },
    setUserActivityRecord: (
      state,
      { payload }: PayloadAction<SetUserActivitiesPayload>
    ) => {
      const { activities } = payload;

      state.paramActivityRecord = activities.reduce(
        (prev, current) => ({
          ...prev,
          [current.extendedCode]: {
            firstName: current.firstName,
            lastName: current.lastName,
            email: current.email,
            timestamp: current.timestamp,
            code: current.extendedCode,
            previousValue: current.valueFrom,
            currentValue: current.valueTo,
          },
        }),
        state.paramActivityRecord
      );
    },
    setUserWorkModeActivityRecord: (
      state,
      { payload }: PayloadAction<SetUserWorkModeActivityPayload>
    ) => {
      const { activities, property } = payload;

      if (state.dashboard && state.dashboard.activities) {
        state.dashboard.activities = activities.reduce(
          (prev, current) => ({
            ...prev,
            [property]: {
              firstName: current.firstName,
              lastName: current.lastName,
              email: current.email,
              timestamp: current.timestamp,
            },
          }),
          state.dashboard.activities
        );
      }
    },
    setUserMaintenanceActivityRecord: (
      state,
      { payload }: PayloadAction<SetUserMaintenanceActivityPayload>
    ) => {
      const { activities, property } = payload;

      if (state.dashboard && state.dashboard.activities) {
        state.dashboard.activities = activities.reduce(
          (prev, current) => ({
            ...prev,
            [property]: {
              firstName: current.firstName,
              lastName: current.lastName,
              email: current.email,
              timestamp: current.timestamp,
            },
          }),
          state.dashboard.activities
        );
      }
    },
    initSoftwareUpdate: (
      state,
      { payload }: PayloadAction<{ type: UpdateType; key: string }>
    ) => {
      const { type, key } = payload;

      state.updatesInProgress[type][key] = {
        loading: true,
        confirm: null,
      };
    },
    clearSoftwareUpdate: (
      state,
      { payload }: PayloadAction<{ type: UpdateType; key: string }>
    ) => {
      const { type, key } = payload;
      state.updatesInProgress[type][key] = {
        loading: false,
        confirm: null,
      };
    },
    setUpdateConfirm: (
      state,
      {
        payload,
      }: PayloadAction<{
        serialNumber: string;
        confirm: "0" | "10" | "20";
        type: UpdateType;
      }>
    ) => {
      const { type, confirm, serialNumber } = payload;
      state.updatesInProgress[type][serialNumber] = {
        loading: false,
        confirm,
      };
    },
    setDeviceConnection: (state, { payload }: PayloadAction<boolean>) => {
      if (state.dashboard) {
        state.dashboard.device.connected = payload;
      }
    },
    bulkUpdateDevice: (
      state,
      {
        payload,
      }: PayloadAction<{ remoteAccess: boolean; serialNumber: string }>
    ) => {
      const { remoteAccess, serialNumber } = payload;
      if (state.dashboard) {
        state.dashboard.device.remoteAccess = remoteAccess;
        state.dashboard.device.serialNumber = serialNumber;
      }
    },
    reset: (state) => {
      state.dashboard = null;
      state.updatesInProgress.softwareUpdates = {};
      state.updatesInProgress.factorySettings = {};
    },
  },
});

function dashboardSelector(state: { [dashboardSlice.name]: DashboardState }) {
  return state[dashboardSlice.name];
}

export const dashboardItemSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.dashboard
);

export const dashboardActivitiesSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.dashboard?.activities
);

export const softwareUpdatesSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.updates.softwareUpdates
);

export const factorySettingsSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.updates.factorySettings
);

export const dashboardBadgeInfo = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.badgeInfo
);

export const createGetUpdatesSelectorByType = (type: UpdateType) =>
  createSelector(
    softwareUpdatesSelector,
    factorySettingsSelector,
    (softwareUpdates, factoryUpdates) =>
      type === "softwareUpdates" ? softwareUpdates : factoryUpdates
  );

export const parametersActivitySelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.paramActivityRecord
);

export const guacamoleUrlSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.guacamole.url
);

export const guacamoleLoadingSelector = createSelector(
  dashboardSelector,
  (state: DashboardState) => state.guacamole.loading
);

export const createParamActivitySelector = (paramId: number) =>
  createSelector(parametersActivitySelector, (state) => state[paramId]);

export const getEarlierSetParamId = createSelector(
  createParamActivitySelector(ParametersData.TargetHotAirTemperature.id),
  createParamActivitySelector(ParametersData.HotAirFanPower.id),
  (targetHotAirTemperatureActivity, hotAirFanPowerActivity) => {
    const targetHotAirTimestamp = targetHotAirTemperatureActivity?.timestamp;
    const hotAirFanPowerTimestamp = hotAirFanPowerActivity?.timestamp;

    return moment(targetHotAirTimestamp).isBefore(hotAirFanPowerTimestamp)
      ? ParametersData.TargetHotAirTemperature.id
      : ParametersData.HotAirFanPower.id;
  }
);

export const dashboardDevice = createSelector(
  dashboardItemSelector,
  (dashboardItem) => dashboardItem?.device
);

export const dashboardItemSerialNumber = createSelector(
  dashboardDevice,
  (device) => device?.serialNumber || ""
);

export const dashboardItemConnectivityStatus = createSelector(
  dashboardItemSelector,
  (dashboardItem): HeaterConnectivityStatus => ({
    isConnected: dashboardItem?.device.connected || false,
    isPotentiallyOffline: isHeaterDataOld(dashboardItem?.lastDataUpdate),
  })
);

export const dashboardDeviceModel = createSelector(
  dashboardDevice,
  (device) => device?.model || 0
);

export const dashboardDeviceRemoteAccess = createSelector(
  dashboardDevice,
  (device) => device?.remoteAccess
);

export const enableDashboardActionsSelector = createSelector(
  dashboardItemConnectivityStatus,
  dashboardDeviceRemoteAccess,
  (connectivityStatus, remoteAccess) =>
    connectivityStatus.isConnected && remoteAccess
);

export const dashboardDeviceGroup = createSelector(
  dashboardDevice,
  (device) => device?.group
);

export const getDashboardSoftwareUpdateBySerialNumber = createSelector(
  dashboardSelector,
  dashboardItemSerialNumber,
  (dashboard, serialNumber) =>
    dashboard?.updatesInProgress.softwareUpdates[serialNumber]
);

export const getDashboardFactoryUpdateBySerialNumber = createSelector(
  dashboardSelector,
  dashboardItemSerialNumber,
  (dashboard, serialNumber) =>
    dashboard?.updatesInProgress.factorySettings[serialNumber]
);

export const getDashboardSoftwareUpdateDetails = createSelector(
  getDashboardSoftwareUpdateBySerialNumber,
  (softwareUpdate) => ({
    updateInProgress: softwareUpdate?.loading || false,
    confirm: softwareUpdate?.confirm,
  })
);

export const getDashboardFactoryUpdateDetails = createSelector(
  getDashboardFactoryUpdateBySerialNumber,
  (factory) => ({
    updateInProgress: factory?.loading || false,
    confirm: factory?.confirm,
  })
);
export const getLastWorkModeActivitySelector = createSelector(
  dashboardActivitiesSelector,
  (activities) => {
    const workModeActivities = omit(
      activities,
      "parameters",
      "resetAshLevel",
      "resetHoursTillMaintenance"
    );
    const latestModifiedValue = sortBy(
      Object.values(workModeActivities),
      "timestamp"
    ).reverse()[0];
    const lastModifiedKey = Object.keys(workModeActivities)
      .map((key) => key as keyof HeaterWorkModeActivities)
      .filter((key) => workModeActivities[key] === latestModifiedValue)[0];

    return { lastModifiedKey, ...latestModifiedValue };
  }
);

export const getLastAshLevelActivitySelector = createSelector(
  dashboardActivitiesSelector,
  (activities) => activities?.resetAshLevel || null
);

export const getLastHoursTillMaintenanceActivitySelector = createSelector(
  dashboardActivitiesSelector,
  (activities) => activities?.resetHoursTillMaintenance || null
);

function* handleGetUpdatesByType(
  action: PayloadAction<{ type: UpdateType }>
): Generator {
  try {
    const { type } = action.payload;
    const updates = (yield call(
      heaterService.getUpdatesByType,
      type
    )) as UpdateDetails[];

    yield put(
      dashboardSlice.actions.setSoftwareUpdates({
        updates,
        type,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetUpdates() {
  yield takeEvery(
    DASHBOARD_ACTIONS.GET_UPDATES_BY_TYPE,
    handleGetUpdatesByType
  );
}

function* handleGetUpdatesByTypeUrl(
  action: PayloadAction<{
    key: string;
    deviceId: number;
    type: UpdateType;
  }>
): Generator {
  try {
    const { key, deviceId, type } = action.payload;
    const url = (yield call(
      heaterService.updatesUrlByType,
      key,
      type
    )) as string;
    const serialNumber = (yield select(dashboardItemSerialNumber)) as string;

    yield all([
      put(
        dashboardSlice.actions.initSoftwareUpdate({ type, key: serialNumber })
      ),
      yield call(heaterService.startUpdateByType, deviceId, url, type),
    ]);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetUpdatesUrl() {
  yield takeLatest(
    DASHBOARD_ACTIONS.GET_UPDATES_BY_TYPE_URL,
    handleGetUpdatesByTypeUrl
  );
}

function* handleSetUserActivity(
  action: PayloadAction<SetUserActivitiesPayload>
): Generator {
  try {
    yield put(dashboardSlice.actions.setUserActivityRecord(action.payload));
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetUserActivity() {
  yield takeLatest(MQTT_ACTIONS.SET_USER_ACTIVITY, handleSetUserActivity);
}

function* handleUpdateConfirm(
  action: PayloadAction<{
    serialNumber: string;
    confirm: MqttConfirm;
    type: UpdateType;
  }>
): Generator {
  try {
    yield put(
      dashboardSlice.actions.setUpdateConfirm({
        serialNumber: action.payload.serialNumber,
        confirm: action.payload.confirm,
        type: action.payload.type,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchUpdateConfirm() {
  yield takeLatest(
    [MQTT_ACTIONS.UPDATE_SOFTWARE_CONFIRM, MQTT_ACTIONS.UPDATE_FACTORY_CONFIRM],
    handleUpdateConfirm
  );
}

function* handleSetWorkModeUserActivity(
  action: PayloadAction<SetUserWorkModeActivityPayload>
): Generator {
  try {
    yield put(
      dashboardSlice.actions.setUserWorkModeActivityRecord(action.payload)
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetWorkModeUserActivity() {
  yield takeLatest(
    MQTT_ACTIONS.SET_WORK_MODE_USER_ACTIVITY,
    handleSetWorkModeUserActivity
  );
}

function* handleSetMaintenanceUserActivity(
  action: PayloadAction<SetUserMaintenanceActivityPayload>
): Generator {
  try {
    yield put(
      dashboardSlice.actions.setUserMaintenanceActivityRecord(action.payload)
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetMaintenanceUserActivity() {
  yield takeLatest(
    MQTT_ACTIONS.SET_MAINTENANCE_USER_ACTIVITY,
    handleSetMaintenanceUserActivity
  );
}

function* handleSetDeviceConnection(
  action: PayloadAction<{ serialNumber: string; isConnected: boolean }>
): Generator {
  try {
    yield put(
      dashboardSlice.actions.setDeviceConnection(action.payload.isConnected)
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetDeviceConnection() {
  yield takeLatest(
    MQTT_ACTIONS.SET_DEVICE_CONNECTION,
    handleSetDeviceConnection
  );
}

function* handleBulkNewValues(
  action: PayloadAction<{ serialNumber: string; data: HeaterDataAttributes }>
): Generator {
  try {
    const { serialNumber, data } = action.payload;

    const dashboardSerialNumber = yield select(dashboardItemSerialNumber);

    if (serialNumber === dashboardSerialNumber) {
      const parameters = omit(
        data,
        "id",
        "source",
        "serialNumber",
        "remoteAccess"
      );

      yield put(dashboardParametersSlice.actions.bulkUpdateParams(parameters));
      yield put(
        dashboardSlice.actions.bulkUpdateDevice(
          pick(data, ["remoteAccess", "serialNumber"])
        )
      );
    }

    yield put(
      heaterSlice.actions.bulkUpdateHeater({
        serialNumber,
        heater: pick(data, [
          "processState",
          "machineName",
          "targetRoomTemperature",
          "softwareVersion",
        ]),
        device: pick(data, ["remoteAccess", "serialNumber"]),
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchBulkNewValues() {
  yield takeLatest(MQTT_ACTIONS.HEATER_BULK_NEW_VALUES, handleBulkNewValues);
}

function* handleReset(): Generator {
  yield put(dashboardSlice.actions.reset());
  yield put(dashboardParametersSlice.actions.reset());
}

function* watchReset() {
  yield takeLatest(DASHBOARD_ACTIONS.RESET, handleReset);
}

function* handleGetGuacamole(action: PayloadAction<number>): Generator {
  let setLanguageTask: Task | null = null;

  try {
    yield put(dashboardSlice.actions.setGuacamoleLoading());

    setLanguageTask = (yield fork(
      heaterService.setLanguage,
      action.payload,
      (localStorage.getItem("language") as Language) || "en"
    )) as Task;

    const guacamoleUrl = (yield call(
      heaterService.getGuacamoleUrl,
      action.payload
    )) as string | null;

    yield put(dashboardSlice.actions.setGuacamoleUrl(guacamoleUrl));
  } catch (e) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  } finally {
    if (setLanguageTask) {
      yield cancel(setLanguageTask);
    }
  }
}

function* watchGetGuacamole() {
  yield takeLatest(DASHBOARD_ACTIONS.GET_GUACAMOLE, handleGetGuacamole);
}

function* handleScheduleUpdates(
  action: PayloadAction<ScheduleUpdatePayload>
): Generator {
  try {
    const { payload } = action;

    yield call(heaterService.scheduleUpdates, payload);
    yield put(uiSlice.actions.setVisibleUpdatesDrawer(false));
    yield put(
      snackbarSlice.actions.showInfo({
        type: "success",
        message: `${i18n.t("modules.dashboard.batchUpdateSuccess")}`,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchScheduleUpdates() {
  yield takeLatest(DASHBOARD_ACTIONS.SCHEDULE_UPDATES, handleScheduleUpdates);
}

function* handleInit(action: PayloadAction<number>): Generator {
  try {
    const { payload } = action;
    const heaterDashboard = (yield call(
      heaterService.getHeaterDashboard,
      payload
    )) as HeaterDashboard;

    yield put(dashboardSlice.actions.initSuccess(heaterDashboard));
    yield put(dashboardErrorSlice.actions.loadErrors(heaterDashboard.errors));
    yield put(
      dashboardWarningSlice.actions.loadWarnings(heaterDashboard.warnings)
    );
    yield put(
      dashboardParametersSlice.actions.loadParameters(
        heaterDashboard.parameters
      )
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(dashboardSlice.actions.init, handleInit);
}

export function* dashboardWatcher() {
  yield all([
    fork(watchInit),
    fork(watchGetUpdates),
    fork(watchGetUpdatesUrl),
    fork(watchSetUserActivity),
    fork(watchUpdateConfirm),
    fork(watchSetWorkModeUserActivity),
    fork(watchSetMaintenanceUserActivity),
    fork(watchSetDeviceConnection),
    fork(watchBulkNewValues),
    fork(watchReset),
    fork(watchGetGuacamole),
    fork(watchScheduleUpdates),
  ]);
}

export default dashboardSlice;
