import { Typography, Grid } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import InfoAlert from "components/heaterDetails/InfoAlert";
import InfoLabel from "components/heaterDetails/InfoLabel";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dashboardSortedErors } from "store/dashboardErrors";
import { dashboardSortedWarnings } from "store/dashboardWarnings";

function WarningsAndErrors() {
  const { t } = useTranslation();
  const errors = useSelector(dashboardSortedErors);
  const warnings = useSelector(dashboardSortedWarnings);
  const [showError, setShowError] = useState(true);
  const [showWarning, setShowWarning] = useState(true);

  const toggleDisplayFilter = useCallback(
    (value: "error" | "warning") => {
      if (value === "error") {
        setShowError(!showError);
      }
      if (value === "warning") {
        setShowWarning(!showWarning);
      }
    },
    [showError, showWarning]
  );

  return (
    <DashboardGrid
      title={t("warningEndErrors.header")}
      content={
        <>
          <Grid item xs={4.7} sm={2.5} lg={1.8} xl={1.5}>
            <InfoLabel
              type="error"
              label={t("warningEndErrors.error")}
              count={errors.length || 0}
              onClick={() => toggleDisplayFilter("error")}
              isActive={showError}
            />
          </Grid>

          <Grid item xs={4}>
            <InfoLabel
              type="warning"
              label={t("warningEndErrors.warning")}
              count={warnings.length || 0}
              onClick={() => toggleDisplayFilter("warning")}
              isActive={showWarning}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} md={10}>
            {showError &&
              errors.map((error) => (
                <InfoAlert
                  key={`error-${error.code}-${error.timeOn}`}
                  type="error"
                  severity="warning"
                  color="error"
                  code={error.code}
                  message={t(`error.${error.code}`)}
                  date={error.timeOn}
                />
              ))}
            {showWarning &&
              warnings.map((warn) => (
                <InfoAlert
                  key={`warning-${warn.code}-${warn.timeOn}`}
                  type="warning"
                  severity="info"
                  color="warning"
                  code={warn.code}
                  message={t(`warning.${warn.code}`)}
                  date={warn.timeOn}
                />
              ))}
            {errors.length === 0 && warnings.length === 0 && (
              <Typography color="textSecondary">
                {t("warningEndErrors.noActive")}
              </Typography>
            )}
          </Grid>
        </>
      }
    />
  );
}

export default WarningsAndErrors;
