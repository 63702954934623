import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent } from "react";

type Props = {
  name: string;
  value: string;
  label?: string;
  options: { value: string; label: string }[];
  onChange: any;
};

function RHMSelect({ name, label, value, options, onChange }: Props) {
  return (
    <TextField
      name={name}
      label={label}
      variant="standard"
      select
      InputLabelProps={{ shrink: true }}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default RHMSelect;
