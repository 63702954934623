import { SvgIcon } from "@mui/material";

function LargeUserIcon() {
  return (
    <SvgIcon
      viewBox="0 0 156 156"
      sx={{ fill: "none", width: "156px", height: "156px" }}
    >
      <path
        d="M115.5 60.5C129.347 60.5 140.5 44.0875 140.5 30.5C140.5 16.715 129.285 5.5 115.5 5.5C101.715 5.5 90.5 16.715 90.5 30.5C90.5 44.0875 101.653 60.5 115.5 60.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M50.5 65.5C67.395 65.5 80.5 46.685 80.5 30.5C80.5 13.9575 67.0425 0.5 50.5 0.5C33.9575 0.5 20.5 13.9575 20.5 30.5C20.5 46.685 33.605 65.5 50.5 65.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M144.57 80.96C138.317 78.47 128.198 75.5 115.5 75.5C102.803 75.5 92.6825 78.47 86.43 80.96C85.805 81.21 85.21 81.515 84.6225 81.8325C86.6375 82.6325 88.51 83.4425 90.12 84.2275C99.4625 88.78 105.5 98.4725 105.5 108.925V110.5H153C154.382 110.5 155.5 109.38 155.5 108V97.19C155.5 89.9775 151.21 83.605 144.57 80.96Z"
        fill="#C7C7D1"
      />
      <path
        d="M87.93 88.7225C80.2325 84.9725 67.4875 80.5 50.5 80.5C33.5125 80.5 20.7675 84.9725 13.07 88.7225C5.435 92.4425 0.5 100.372 0.5 108.925V123C0.5 124.38 1.6175 125.5 3 125.5H98C99.3825 125.5 100.5 124.38 100.5 123V108.925C100.5 100.372 95.565 92.4425 87.93 88.7225Z"
        fill="#C7C7D1"
      />
    </SvgIcon>
  );
}

export default LargeUserIcon;
