import PlusIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DrawerWrapper from "components/common/DrawerWrapper";
import UnsavedChangesPrompt from "components/common/UnsavedChangesPrompt";
import { FieldArray, Formik } from "formik";
import { groupBy, isEqual } from "lodash";
import { AlarmRule, AlarmRuleType } from "models/Organization";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  UNIT_ACTIONS,
  alarmRulesByUnitIdSelector,
  alarmRulesTypesAsSelectOption,
  organizationUnitsByIdSelector,
} from "store/organizationUnits";
import uiSlice, { isRulesUnitDrawerOpen } from "store/ui";
import UnitRuleValueField from "./UnitRuleValueField";

type Props = {
  onClose: (orgId: number) => void;
};

const emptyRule: AlarmRule = {
  id: 0,
  organizationUnitId: 0,
  type: AlarmRuleType.HeaterErrorOrWarning,
  params: {
    value: 1,
  },
  createdAt: new Date(),
  updatedAt: new Date(),
};

type UniqueTypeErrors = {
  [key in AlarmRuleType]: string;
};

function UnitRulesDrawer({ onClose }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const orgId = Number(params.orgId) || 0;
  const unitId = Number(params.unitId) || 0;
  const isOpen = useSelector(isRulesUnitDrawerOpen);
  const unitsById = useSelector(organizationUnitsByIdSelector);
  const unitName = unitsById[orgId]?.[unitId].label || "-";
  const alarmRulesByUnitId = useSelector(alarmRulesByUnitIdSelector);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [showPrompt, setShowPropt] = useState(false);
  const [uniqueTypeErrors, setUniqueTypeErrors] = useState<UniqueTypeErrors>({
    [AlarmRuleType.HeaterErrorOrWarning]: "",
    [AlarmRuleType.HeaterMaintenance]: "",
    [AlarmRuleType.HeaterService]: "",
    [AlarmRuleType.HeaterAshLevel]: "",
  });

  const initialValues = useMemo(
    () => ({
      rules: Object.values(alarmRulesByUnitId[unitId] || {}),
    }),
    [alarmRulesByUnitId, unitId]
  );

  const getIsOkDisabled = useCallback(
    (rules: AlarmRule[]) => {
      const hasTypeErrors = Object.values(uniqueTypeErrors).some(
        (item) => item !== ""
      );
      const pristine = isEqual(initialValues.rules, rules);

      return hasTypeErrors || pristine;
    },
    [initialValues.rules, uniqueTypeErrors]
  );

  const handleClose = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        setShowPropt(true);
      } else {
        onClose(orgId);
      }
    },
    [orgId, onClose]
  );

  const onConfirmClose = useCallback(() => {
    onClose(orgId);
    setShowPropt(false);
  }, [onClose, orgId]);

  const onCancelClose = useCallback(() => setShowPropt(false), []);

  useEffect(() => {
    if (orgId && unitId) {
      dispatch({
        type: UNIT_ACTIONS.GET_ALARM_RULES,
        payload: {
          orgId,
          unitId,
        },
      });
      dispatch(uiSlice.actions.setRulesUnitDrawerOpen(true));
    }
  }, [dispatch, orgId, unitId]);

  const onSubmit = useCallback(
    ({ rules }: { rules: AlarmRule[] }) => {
      const newRules = rules.map((rule) => {
        const newParams = { value: 0 };
        if (rule.params !== undefined && rule.params !== null) {
          newParams.value = +rule.params.value;
        }
        return {
          ...rule,
          params: newParams,
        };
      });

      dispatch({
        type: UNIT_ACTIONS.SAVE_ALARM_RULES,
        payload: {
          orgId,
          unitId,
          rules: newRules,
          initialRules: initialValues.rules,
        },
      });
      onClose(orgId);
    },
    [dispatch, initialValues.rules, onClose, orgId, unitId]
  );

  const validate = useCallback(
    (values: { rules: AlarmRule[] }) => {
      const groupedRules = groupBy(values.rules, "type") as Record<
        AlarmRuleType,
        AlarmRule[]
      >;
      const copyErrors = { ...uniqueTypeErrors };

      Object.keys(groupedRules).forEach((type) => {
        const typeRules = groupedRules[type as AlarmRuleType] || [];

        const someHasOverlaps = typeRules.length > 1;

        copyErrors[type as AlarmRuleType] = someHasOverlaps
          ? t("modules.organizations.grid.orgUnit.alarmRuleError")
          : "";
      });

      setUniqueTypeErrors(copyErrors);
    },
    [t, uniqueTypeErrors]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleChange, handleSubmit, values, dirty }) => (
        <DrawerWrapper
          title={t("modules.organizations.grid.orgUnit.alarmRulesTitle", {
            unitName,
          })}
          isOpen={isOpen}
          onCancelAction={() => handleClose(dirty)}
          onOkAction={handleSubmit}
          isOkDisabled={getIsOkDisabled(values.rules) || editIndex !== null}
          loading={false}
          okLabel={t("buttons.save")}
          cancelLabel={t("buttons.close")}
          content={
            <form onSubmit={handleSubmit}>
              <UnsavedChangesPrompt
                showPrompt={showPrompt}
                isDirty={dirty}
                onConfirm={onConfirmClose}
                onCancel={onCancelClose}
              />
              <List>
                <FieldArray name="rules">
                  {({ remove, push }) => (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="h6" color="textSecondary">
                          {t(
                            "modules.organizations.grid.orgUnit.alarmRulesSubtitle"
                          )}
                        </Typography>
                        <IconButton
                          size="large"
                          color="primary"
                          onClick={() => {
                            push({
                              ...emptyRule,
                              organizationUnitId: unitId,
                            });
                            setEditIndex(values.rules.length);
                          }}
                          disabled={
                            editIndex !== null && values.rules.length > 0
                          }
                        >
                          <PlusIcon />
                        </IconButton>
                      </Grid>
                      {values.rules.length === 0 && (
                        <Typography variant="h6" textAlign="center">
                          {t("modules.organizations.grid.orgUnit.noRulesYet")}
                        </Typography>
                      )}
                      {values.rules.map((rule: AlarmRule, index: number) => (
                        <>
                          <ListItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${rule.id}-${rule.type}-${index}`}
                            disableGutters
                          >
                            <Grid container spacing={1}>
                              {editIndex === index ? (
                                <>
                                  <Grid item xs={6} sm={7}>
                                    <FormControl fullWidth>
                                      <Select
                                        size="small"
                                        variant="outlined"
                                        name={`rules.${index}.type`}
                                        value={rule.type}
                                        onChange={handleChange}
                                      >
                                        {alarmRulesTypesAsSelectOption.map(
                                          (item) => (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {t(
                                                `modules.organizations.grid.orgUnit.alarmRulesTypes.${item.id}`
                                              )}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  {rule.type !==
                                    AlarmRuleType.HeaterErrorOrWarning && (
                                    <Grid item xs={2} sm={3}>
                                      <UnitRuleValueField
                                        name={`rules.${index}.params.value`}
                                        value={`${rule?.params?.value}`}
                                        hasError={!!uniqueTypeErrors[rule.type]}
                                      />
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <Grid item xs={10}>
                                  <ListItemText
                                    primary={`${t(
                                      `modules.organizations.grid.orgUnit.alarmRulesTypes.${rule.type}`
                                    )}${
                                      rule.type !==
                                      AlarmRuleType.HeaterErrorOrWarning
                                        ? ` - ${rule?.params?.value}%`
                                        : ""
                                    }`}
                                    secondary={
                                      uniqueTypeErrors[rule.type]
                                        ? uniqueTypeErrors[rule.type]
                                        : ""
                                    }
                                    secondaryTypographyProps={{
                                      color: "error",
                                    }}
                                  />
                                </Grid>
                              )}

                              <Grid item xs={2}>
                                <ListItemSecondaryAction>
                                  {editIndex === index ? (
                                    <IconButton
                                      size="small"
                                      onClick={() => setEditIndex(null)}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      onClick={() => setEditIndex(index)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                    </>
                  )}
                </FieldArray>
              </List>
            </form>
          }
        />
      )}
    </Formik>
  );
}

export default UnitRulesDrawer;
