import { makeStyles } from "@mui/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import GridViewIcon from "components/icons/GridViewIcon";
import TableViewIcon from "components/icons/TableViewIcon";
import { type MouseEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { getDashboardView } from "store/ui";

// TODO: Export colors;
const useStyles = makeStyles(() => ({
  right: {
    paddingLeft: "20px !important",
    borderTopLeftRadius: "10px !important",
    borderBottomLeftRadius: "10px !important",
  },
  left: {
    paddingRight: "20px !important",
    borderTopRightRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
  },
  button: {
    backgroundColor: "#ffffff80 !important",
  },
  selected: {
    "&&": {
      backgroundColor: "#FFFFFF !important",
    },
  },
}));

function DashboardViewToggle() {
  const styles = useStyles();
  const viewType = useSelector(getDashboardView);
  const dispatch = useDispatch();

  const onButtonGroupClick = useCallback(
    (_event: MouseEvent<HTMLElement>, type: "table" | "grid" | null) => {
      if (type) {
        dispatch(uiSlice.actions.setDashboardView(type));
      }
    },
    [dispatch]
  );

  return (
    <ToggleButtonGroup value={viewType} exclusive onChange={onButtonGroupClick}>
      <ToggleButton
        className={styles.right}
        value="table"
        classes={{ root: styles.button, selected: styles.selected }}
      >
        <TableViewIcon type={viewType === "table" ? "active" : "inactive"} />
      </ToggleButton>
      <ToggleButton
        className={styles.left}
        value="grid"
        classes={{ root: styles.button, selected: styles.selected }}
      >
        <GridViewIcon type={viewType === "grid" ? "active" : "inactive"} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default DashboardViewToggle;
