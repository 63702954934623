import { SvgIcon } from "@mui/material";

function LargeOrganizationIcon() {
  return (
    <SvgIcon
      viewBox="0 0 156 156"
      sx={{ fill: "none", width: "156px", height: "156px" }}
    >
      <path
        d="M105.5 18H100.5V5.5H55.5V18H50.5V3C50.5 1.62 51.62 0.5 53 0.5H103C104.38 0.5 105.5 1.62 105.5 3V18Z"
        fill="#C7C7D1"
      />
      <path
        d="M95.5 108V115.5C95.5 116.88 94.38 118 93 118H63C61.62 118 60.5 116.88 60.5 115.5V108H10.5V135.5C10.5 141.022 14.9775 145.5 20.5 145.5H135.5C141.023 145.5 145.5 141.022 145.5 135.5V108H95.5Z"
        fill="#C7C7D1"
      />
      <path
        d="M145.5 23H10.5C4.9775 23 0.5 27.4775 0.5 33V100.5C0.5 101.88 1.62 103 3 103H60.5V95.5C60.5 94.12 61.62 93 63 93H93C94.38 93 95.5 94.12 95.5 95.5V103H153C154.38 103 155.5 101.88 155.5 100.5V33C155.5 27.4775 151.023 23 145.5 23Z"
        fill="#C7C7D1"
      />
    </SvgIcon>
  );
}

export default LargeOrganizationIcon;
