import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeviceModel } from "models/Heater";
import Badge from "components/icons/Badge";
import { useTranslation } from "react-i18next";
import DeviceModelIcon from "components/icons/DeviceModel";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ManageGroupDeviceMenu from "components/groups/ManageGroupDeviceMenu";
import CustomHidden from "components/common/CustomHidden";
import ConnectivityStatusIcon from "../icons/ConnectivityStatusIcon";

const useStyles = makeStyles((theme: any) => ({
  card: {
    margin: "16px 0 ",
  },
  cardWithoutBorder: {
    position: "relative",
    boxShadow: "none !important",
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "160px",
    padding: "40px 80px 40px 40px !important",
    [theme.breakpoints.only("sm")]: {
      padding: "40px !important",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "28px 24px !important",
      flexDirection: "column",
      alignItems: "initial",
      height: "244px",
    },
  },
  label: {
    letterSpacing: "2px",
  },
  cardContentWithIcons: {
    display: "flex",
    alignItems: "flex-start",
    height: "45px",
    padding: "0 !important",
  },
  softwareContent: {
    display: "flex",
    flexDirection: "column",
    padding: "8px !important",
    [theme.breakpoints.only("sm")]: {
      padding: "0 !important",
    },
    [theme.breakpoints.only("xs")]: {
      "& > .MuiTypography-h6": {
        fontSize: "16px",
      },
    },
  },
  temperatureContent: {
    display: "flex",
    flex: 0.5,
    padding: "8px !important",
    flexDirection: "column",
    [theme.breakpoints.only("sm")]: {
      padding: "0 !important",
    },
    [theme.breakpoints.only("xs")]: {
      "& > .MuiTypography-h6": {
        fontSize: "16px",
      },
    },
  },
  workModeContent: {
    display: "flex",
    flex: 0.7,
    flexDirection: "column",
    padding: "8px !important",
    [theme.breakpoints.only("sm")]: {
      padding: "0 !important",
    },
    [theme.breakpoints.only("xs")]: {
      "& > .MuiTypography-h6": {
        fontSize: "16px",
      },
    },
  },
  temperatureSign: {
    fontSize: "16px",
    [theme.breakpoints.only("xs")]: {
      fontSize: "12px",
    },
  },
  optionalMenu: {
    position: "absolute",
    top: "36px",
    right: "0",
  },
}));

type Props = {
  deviceId: number;
  isConnected: boolean;
  isPotentiallyOffline: boolean;
  softwareVersion: string;
  targetRoomTemperature: number;
  processState: number;
  machineName: string;
  model: DeviceModel;
  errorCount: number;
  warningCount: number;
  groupId?: number;
};

function HeaterCard({
  deviceId,
  isConnected,
  isPotentiallyOffline,
  softwareVersion,
  targetRoomTemperature,
  processState,
  machineName,
  model,
  errorCount,
  warningCount,
  groupId,
}: Props) {
  const navigate = useNavigate();
  const styles = useStyles();
  const { t } = useTranslation();

  const onCardClick = useCallback(
    (id: number) => navigate(`${id}`),
    [navigate]
  );

  const sofwareContent = (
    <CardContent classes={{ root: styles.softwareContent }}>
      <Typography variant="h6">{softwareVersion}</Typography>
      <Typography className={styles.label} variant="body2">
        {t("modules.dashboard.table.software").toUpperCase()}
      </Typography>
    </CardContent>
  );

  const temperatureContent = (
    <CardContent classes={{ root: styles.temperatureContent }}>
      <Typography variant="h6">
        {targetRoomTemperature}
        <span className={styles.temperatureSign}>&#x2103;</span>
      </Typography>
      <Typography className={styles.label} variant="body2">
        {t("modules.dashboard.table.temperature").toUpperCase()}
      </Typography>
    </CardContent>
  );

  const workModeCotent = (
    <CardContent classes={{ root: styles.workModeContent }}>
      <Typography variant="h6">
        {t(`processState.${processState || 0}`)}
      </Typography>
      <Typography className={styles.label} variant="body2">
        {t("modules.dashboard.table.workMode").toUpperCase()}
      </Typography>
    </CardContent>
  );

  return (
    <Card classes={{ root: groupId ? styles.cardWithoutBorder : styles.card }}>
      <CustomHidden size="sm" hideType="up">
        {groupId && (
          <div className={styles.optionalMenu}>
            <ManageGroupDeviceMenu groupId={groupId} deviceId={deviceId} />
          </div>
        )}
      </CustomHidden>

      <CardActionArea
        classes={{ root: styles.root }}
        disableRipple
        disabled={!!groupId}
        onClick={() => onCardClick(deviceId)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <CardContent classes={{ root: styles.cardContentWithIcons }}>
            <ConnectivityStatusIcon
              status={{
                isConnected,
                isPotentiallyOffline,
              }}
            />
          </CardContent>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {machineName}
            </Typography>
            <DeviceModelIcon model={DeviceModel[model]} />
          </CardContent>
          <CardContent classes={{ root: styles.cardContentWithIcons }}>
            {errorCount > 0 && <Badge type="error" count={errorCount} />}
            {warningCount > 0 && <Badge type="warning" count={warningCount} />}
          </CardContent>
        </Box>

        <CustomHidden size="sm" hideType="down">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 0.5,
            }}
          >
            {workModeCotent}
            {temperatureContent}
            {sofwareContent}
          </Box>
        </CustomHidden>
        <CustomHidden size="sm" hideType="up">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sofwareContent}
              {workModeCotent}
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {temperatureContent}
            </Box>
          </Box>
        </CustomHidden>
      </CardActionArea>
    </Card>
  );
}

export default HeaterCard;
