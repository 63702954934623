import { IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GridViewIcon from "components/icons/GridViewIcon";
import TableViewIcon from "components/icons/TableViewIcon";
import { useCallback, useState, type MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { isGridDashboardView } from "store/ui";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "30px !important",
    padding: "10px 20px !important",
    backgroundColor: "white !important",
  },
}));

function DashobardViewMenu() {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isGridView = useSelector(isGridDashboardView);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onMenuItemClick = useCallback(() => {
    dispatch(uiSlice.actions.setDashboardView(isGridView ? "table" : "grid"));
  }, [dispatch, isGridView]);

  return (
    <>
      <IconButton classes={{ root: styles.root }} onClick={handleClick}>
        {isGridView ? <GridViewIcon /> : <TableViewIcon />}{" "}
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onMenuItemClick}>
          {isGridView ? <TableViewIcon /> : <GridViewIcon />}
        </MenuItem>
      </Menu>
    </>
  );
}

export default DashobardViewMenu;
