import { makeStyles } from "@mui/styles";
import { CircularProgress, Paper, Typography } from "@mui/material";
import GeneralInformationGrid from "components/adminDevices/heaterUsersTab/GeneralInformation";
import DetailsHeader from "components/common/DetailsHeader";
import FormFooter from "components/common/FormFooter";
import ModalWrapper from "components/common/ModalWrapper";
import { passwordRegex } from "components/fields/PasswordField";
import RoleGrid from "components/users/RoleGrid";
import { Formik } from "formik";
import { isEqual, noop } from "lodash";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createHeaterUserInProgress,
  heaterUserCreatead,
  HEATER_USERS_ACTIONS,
} from "store/heaterUsers";
import { emailRegex } from "store/users";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    height: "auto",
    minHeight: "calc(100vh - 385px)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      minHeight: "calc(100vh - 300px)",
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      padding: "32px 20px",
    },
  },
}));

export type HeaterUserFormData = {
  username: string;
  password: string;
  role: string;
};

const initialValues: HeaterUserFormData = {
  username: "",
  password: "",
  role: "0",
};

function AddUser() {
  const { deviceId } = useParams();
  const dispatch = useDispatch();
  const id = deviceId && Number(deviceId) ? +deviceId : 0;
  const isCreateInProgress = useSelector(createHeaterUserInProgress);
  const isCreated = useSelector(heaterUserCreatead);

  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();

  const onBackClick = useCallback(
    () => navigate(`/devices/${id}`),
    [id, navigate]
  );

  useEffect(() => {
    if (isCreateInProgress) {
      setIsLoaderOpen(true);
    }
  }, [isCreateInProgress]);

  useEffect(() => {
    if (isCreated) {
      setIsLoaderOpen(false);
      onBackClick();
    }
  }, [isCreateInProgress, isCreated, onBackClick]);

  const onSubmit = useCallback(
    (data: HeaterUserFormData) => {
      dispatch({
        type: HEATER_USERS_ACTIONS.ADD_HEATER_USERS,
        payload: {
          deviceId: id,
          user: {
            email: data.username,
            password: data.password,
            accessLevel: Number(data.role),
          },
        },
      });
    },
    [dispatch, id]
  );

  const isValid = (user: HeaterUserFormData): boolean =>
    user.username !== "" &&
    emailRegex.test(user.username) &&
    passwordRegex.test(user.password);

  return (
    <div>
      <DetailsHeader
        mainContent={
          <Typography style={{ marginRight: "16px" }} variant="h4">
            {t("modules.users.addNewHeaterUser")}
          </Typography>
        }
        path=""
        onBack={onBackClick}
        item=""
        itemLabel={t("modules.users.heaterUserDetails")}
      />
      <Formik
        initialValues={{
          username: "",
          password: "",
          role: "0",
        }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={styles.paper}>
              <GeneralInformationGrid
                currentPassword={values.password}
                currentUsername={values.username}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
              />
              <br />
              <RoleGrid value={values.role} handleChange={handleChange} />
            </Paper>
            <FormFooter
              id={0}
              onCancel={onBackClick}
              saveDisabled={isEqual(initialValues, values) || !isValid(values)}
            />
          </form>
        )}
      </Formik>
      <ModalWrapper
        isOpen={isLoaderOpen}
        onCancelAction={noop}
        onOkAction={noop}
        title={t("modals.users.pleaseWait")}
        contentLabel=""
        okLabel=""
        cancelLabel=""
        content={
          <div
            style={{ display: "flex", alignItems: "center", margin: "-40px 0" }}
          >
            <CircularProgress style={{ marginRight: "28px" }} size={60} />
            <Typography color="textSecondary">
              {t("modals.users.creatingInfo")}
            </Typography>
          </div>
        }
      />
    </div>
  );
}

export default AddUser;
