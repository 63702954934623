import { Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "components/DashboardGrid";
import LanguageSelectField from "components/fields/LanguageSelectField";
import { useFormikContext } from "formik";
import { getFirstLetterToUpperCase, trimString } from "helpers";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import OrganizationKindSelectField from "../fields/OrganizationKindSelectField";

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    fontSize: "14px",
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
}));

type Prop = {
  isNameInvalid: boolean;
};

function GeneralInformationGrid({ isNameInvalid }: Prop) {
  const { t } = useTranslation();
  const { handleChange, values, setFieldValue } = useFormikContext<any>();
  const styles = useStyles();
  const emptyName = values.label === "";

  const labelHelperText = useMemo(() => {
    if (emptyName) {
      return t("modules.organizations.grid.generalInformation.invalidName");
    }

    return isNameInvalid
      ? t("modules.organizations.grid.generalInformation.orgNameExists")
      : "";
  }, [emptyName, isNameInvalid, t]);

  const onBlur = useCallback(
    (property: string, value: string) => {
      setFieldValue(property, trimString(value));
    },
    [setFieldValue]
  );

  return (
    <DashboardGrid
      title={t("modules.organizations.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.organizations.grid.generalInformation.orgName")
              )}
            </Typography>
            <TextField
              size="small"
              name="label"
              variant="outlined"
              placeholder={t(
                "modules.organizations.grid.generalInformation.orgNamePlaceholder"
              )}
              fullWidth
              error={isNameInvalid || emptyName}
              helperText={labelHelperText}
              value={values.label}
              onChange={handleChange}
              onBlur={() => onBlur("label", values.label)}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.organizations.grid.generalInformation.language")
              )}
            </Typography>
            <LanguageSelectField
              name="defaultLanguage"
              currentValue={values.defaultLanguage}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.organizations.grid.generalInformation.kind")
              )}
            </Typography>
            <OrganizationKindSelectField
              name="kind"
              currentValue={values.kind}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default GeneralInformationGrid;
