import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GeneralInformationGrid from "components/adminDevices/generalTab/GeneralInformationGrid";
import KindGrid from "components/adminDevices/generalTab/KindGrid";
import FormFooter from "components/common/FormFooter";
import ModalWrapper from "components/common/ModalWrapper";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_DEVICES_ACTIONS,
  createDeviceInitialFormValues,
  deviceActionInProgress,
} from "store/adminDevices";

import Loader from "components/common/Loader";
import paths from "config/paths";
import useDeleteModal from "hooks/useDeleteModal";
import DeleteGrid from "./DeleteGrid";
import OrganizationsGrid from "./OrganizationsGrid";
import AddCertificateGrid from "./certificate/AddCertificateGrid";
import MqttCertificateGrid from "./certificate/MqttCertficateGrid";
import OpenVPNCertificateGrid from "./certificate/OpenVPNCertificateGrid";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "400px",
    height: "calc(100%)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
    },
  },
}));

type Props = {
  id: number;
};

function GeneralTab({ id }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useStyles();
  const actionInProgress = useSelector(deviceActionInProgress);
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const { t } = useTranslation();
  const isNew = id === 0;

  const getInitialValues = useMemo(
    () => createDeviceInitialFormValues(id),
    [id]
  );

  const initialValues = useSelector(getInitialValues);

  const onBack = useCallback(() => navigate(paths.devices), [navigate]);

  const onSubmit = useCallback(
    (data: any) => {
      const { serialNumber, model, kind, organizations, hasMqtt, hasOvpn } =
        data;
      if (isNew) {
        dispatch({
          type: ADMIN_DEVICES_ACTIONS.ADD_DEVICE,
          payload: {
            serialNumber,
            model: Number(model),
            kind: Number(kind),
            organizations,
            hasMqtt,
            hasOvpn,
          },
        });
      } else {
        dispatch({
          type: ADMIN_DEVICES_ACTIONS.EDIT_DEVICE,
          payload: {
            id,
            serialNumber,
            model: Number(model),
            kind: Number(kind),
            organizations,
          },
        });
      }
    },
    [dispatch, id, isNew]
  );
  const onDelete = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.DELETE_DEVICE,
      payload: {
        deviceId: id,
      },
    });
  }, [dispatch, id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={styles.paper}>
              <Loader open={actionInProgress} />
              <GeneralInformationGrid
                deviceId={id}
                handleChange={handleChange}
                currentModel={values.model}
                currentSerialNumber={values.serialNumber}
                setFieldValue={setFieldValue}
              />
              <br />
              <KindGrid
                handleChange={handleChange}
                currentValue={values.kind.toString()}
              />
              <br />

              {isNew ? (
                <AddCertificateGrid
                  handleChange={handleChange}
                  currentHasMqtt={values.hasMqtt}
                  currentHasOvpn={values.hasOvpn}
                />
              ) : (
                <>
                  <MqttCertificateGrid deviceId={id} />
                  <br />
                  <OpenVPNCertificateGrid deviceId={id} />
                </>
              )}

              <br />
              <OrganizationsGrid
                setFieldValue={setFieldValue}
                currentValue={values.organizations || []}
              />
              <br />
              <DeleteGrid deviceId={id} onDelete={onOpenDeleteModal} />
            </Paper>
            <FormFooter
              id={id}
              saveDisabled={isEqual(initialValues, values)}
              onCancel={onBack}
            />
          </form>
        )}
      </Formik>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDelete}
        title={t("modals.devices.delete")}
        contentLabel={t("modals.devices.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default GeneralTab;
