import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import {
  CalendarEventFormValues,
  EditEntryData,
  HeaterCalendar,
  NewCalendarData,
} from "models/Calendar";
import { MqttConfirm } from "models/Mqtt";
import moment from "moment";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { dashboardItemSerialNumber } from "./dashboard";
import { MQTT_ACTIONS } from "./mqtt";
import uiSlice from "./ui";

export const CALENDAR_ACTIONS = {
  ADD_EVENT: "ADD_EVENT",
  EDIT_EVENT: "EDIT_EVENT",
  DELETE_EVENT: "DELETE_EVENT",
};

type HeaterCalendarState = {
  initializing: boolean;
  events: Record<string, Record<number, HeaterCalendar>>;
  add: {
    loading: boolean;
    confirm: MqttConfirm | null;
  };
  error?: string;
};

const initialState: HeaterCalendarState = {
  initializing: false,
  add: {
    loading: false,
    confirm: null,
  },
  events: {},
};

const heaterCalendarSlice = createSlice({
  name: "heaterCalendar",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<number>) => {
      state.initializing = true;
    },
    initSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ data: HeaterCalendar[]; serialNumber: string }>
    ) => {
      const { serialNumber, data } = payload;

      state.initializing = true;
      state.events[serialNumber] = mapKeys(data, (event) => event.id);
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    setCreateActionInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.add.loading = payload;
    },
    setConfirmation: (state, { payload }: PayloadAction<MqttConfirm>) => {
      state.add.loading = false;
      state.add.confirm = payload;
    },
    deleteEntrySucces: (
      state,
      { payload }: PayloadAction<{ serialNumber: string; entryId: number }>
    ) => {
      const { serialNumber, entryId } = payload;
      state.events[serialNumber] = omit(state.events[serialNumber], entryId);
    },
  },
});

function heaterCalendarSelector(state: { [heaterCalendarSlice.name]: any }) {
  return state[heaterCalendarSlice.name];
}

export const heaterCalendarsById = createSelector(
  heaterCalendarSelector,
  dashboardItemSerialNumber,
  (state: HeaterCalendarState, serialNumber: string) =>
    state.events[serialNumber] || {}
);

export const heaterCalendarCreateInProgress = createSelector(
  heaterCalendarSelector,
  (state: HeaterCalendarState) => state.add.loading
);

export const heaterCalendarsData = createSelector(heaterCalendarsById, (byId) =>
  Object.values(byId)
);

const mapToDataForPost = (data: CalendarEventFormValues): NewCalendarData => {
  const isWeekDays = data.frequencyType === "weeks";
  const hasRepetition = data.repeatOption !== "none";

  let repetition: NewCalendarData["repetition"] | undefined;

  if (hasRepetition) {
    repetition = {
      frequency: data.frequency,
      frequencyType: data.frequencyType,
    };

    if (data.repeatOption === "custom") {
      repetition.frequencyType = data.frequencyType;
    }

    if (isWeekDays) {
      repetition.weekdays = data.weekdays;
    }

    if (data.endType === "endsAfter") {
      repetition.endsAfter = data.endsAfter;
    }

    if (data.endType === "endsOn") {
      repetition.endsOn = data.endsOn;
    }
  }

  return {
    event: {
      from: moment(data.from).utc().format(),
      to: moment(data.to).utc().format(),
      targetRoomTemperature: data.targetRoomTemperature,
      targetHotAirTemperature: data.targetHotAirTemperature,
      targetPowerHotAirFan: data.targetPowerHotAirFan,
      modeHotAirFan: data.modeHotAirFan,
      additionalCooling: data.additionalCooling,
    },
    repetition,
  };
};

const mapToDataForEdit = (data: CalendarEventFormValues): EditEntryData => ({
  event: {
    from: moment(data.from).utc().format(),
    to: moment(data.to).utc().format(),
    targetRoomTemperature: data.targetRoomTemperature,
    targetHotAirTemperature: data.targetHotAirTemperature,
    targetPowerHotAirFan: data.targetPowerHotAirFan,
    modeHotAirFan: data.modeHotAirFan,
    additionalCooling: data.additionalCooling,
  },
  updateAll: data.updateAll,
});

export const getHeaterEvent = (id: number) =>
  createSelector(heaterCalendarsById, (byId) => byId[id]);

function* handleConfirmAddEvent(action: PayloadAction<MqttConfirm>): Generator {
  try {
    const confirmed = action.payload;
    yield put(heaterCalendarSlice.actions.setConfirmation(confirmed));

    if (confirmed === "0") {
      yield put(uiSlice.actions.setCalendarModelOpen(false));
    }
  } catch (err) {
    yield put(heaterCalendarSlice.actions.initError("Something went wrong..."));
  }
}

function* watchConfirmAddEvent() {
  yield takeLatest(MQTT_ACTIONS.ADD_EVENT_CONFIRMED, handleConfirmAddEvent);
}

function* handleNewCalendarData(
  action: PayloadAction<{
    heaterCalendar: { data: HeaterCalendar[] };
    serialNumber: string;
  }>
): Generator {
  try {
    const { heaterCalendar, serialNumber } = action.payload;
    yield put(
      heaterCalendarSlice.actions.initSuccess({
        data: heaterCalendar.data,
        serialNumber,
      })
    );
  } catch (err) {
    yield put(heaterCalendarSlice.actions.initError("Something went wrong..."));
  }
}

function* watchNewCalendarData() {
  yield takeLatest(MQTT_ACTIONS.NEW_CALENDAR_DATA, handleNewCalendarData);
}

function* handleAddEvent(
  action: PayloadAction<{
    deviceId: number;
    formValues: CalendarEventFormValues;
  }>
): Generator {
  try {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(true));
    const { deviceId, formValues } = action.payload;

    const newCalendarData = mapToDataForPost(formValues);

    yield call(
      heaterService.createRemoteDeviceCalendar,
      deviceId,
      newCalendarData
    );
  } catch (error) {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(false));
  }
}

function* watchAddEvent() {
  yield takeLatest(CALENDAR_ACTIONS.ADD_EVENT, handleAddEvent);
}

function* handleDeleteEvent(
  action: PayloadAction<{
    deviceId: number;
    entryId: number;
    removeAll: boolean;
  }>
): Generator {
  try {
    const { deviceId, entryId, removeAll } = action.payload;
    yield call(
      heaterService.deleteRemoteDeviceCalendarEntry,
      deviceId,
      entryId,
      removeAll
    );
    const serialNumber = (yield select(dashboardItemSerialNumber)) as string;
    yield put(
      heaterCalendarSlice.actions.deleteEntrySucces({ serialNumber, entryId })
    );
  } catch (error) {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(false));
  }
}

function* watchDeleteEvent() {
  yield takeLatest(CALENDAR_ACTIONS.DELETE_EVENT, handleDeleteEvent);
}

function* handleEditEvent(
  action: PayloadAction<{
    deviceId: number;
    formValues: CalendarEventFormValues;
  }>
): Generator {
  try {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(true));
    const { deviceId, formValues } = action.payload;

    yield call(
      heaterService.putRemoteDeviceCalendarEntry,
      deviceId,
      formValues.id,
      mapToDataForEdit(formValues)
    );
  } catch (error) {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(false));
  }
}

function* watchEditEvent() {
  yield takeLatest(CALENDAR_ACTIONS.EDIT_EVENT, handleEditEvent);
}

function* handleInit(action: PayloadAction<number>): Generator {
  try {
    const id = action.payload;

    const remoteDevice = (yield call(
      heaterService.getRemoteDeviceCalendar,
      id
    )) as {
      data: HeaterCalendar[];
    };
    const serialNumber = (yield select(dashboardItemSerialNumber)) as string;

    yield put(
      heaterCalendarSlice.actions.initSuccess({
        data: remoteDevice.data,
        serialNumber,
      })
    );
  } catch (error) {
    yield put(heaterCalendarSlice.actions.setCreateActionInProgress(false));

    yield put(heaterCalendarSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(heaterCalendarSlice.actions.init, handleInit);
}

export function* heaterCalendarWatcher() {
  yield all([
    fork(watchInit),
    fork(watchAddEvent),
    fork(watchEditEvent),
    fork(watchConfirmAddEvent),
    fork(watchNewCalendarData),
    fork(watchDeleteEvent),
  ]);
}

export default heaterCalendarSlice;
