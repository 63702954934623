import { Device } from "models/Heater";
import { TroubleshootingGuide } from "models/TroubleshootingGuide";
import { TroubleshootingParameters } from "models/Troubleshooting";
import api from "./api";

const getDeviceId = async (serialNumber: string) => {
  const response = await api.get<Device>(
    `/remote_devices/serial_number/${serialNumber}`
  );

  return response.data;
};

const getGuidesForDevice = async ({
  deviceId,
  language,
  code = null,
  codeType = null,
}: Omit<TroubleshootingParameters, "serialNumber">) => {
  const params = { code, codeType, language };
  const response = await api.get<{ data: TroubleshootingGuide[] }>(
    `/remote_devices/${deviceId}/troubleshooting`,
    { params }
  );

  return response.data.data;
};

export default {
  getDeviceId,
  getGuidesForDevice,
};
