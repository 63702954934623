import { createTheme, adaptV4Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    darker?: string;
  }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    darker?: string;
  }
}

// WIP: A custom theme for this app
const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "light",
      primary: {
        lightest: "#FFE7DB",
        lighter: "#FFBA99",
        light: "#FF9766",
        main: "#FF5200",
        dark: "#CC4200",
        darker: "#993100",
      },
      secondary: {
        lightest: "#C1D1DC",
        lighter: "#ABC0CF",
        light: "#7D9EB5",
        main: "#52768F",
        dark: "#2D404E",
        darker: "#1A252D",
      },
      error: {
        main: "#D2341E",
      },
      warning: {
        main: "#FDAE37",
      },
      info: {
        main: "#4DA2F0",
      },
      grey: {
        50: "#FCFCFD",
        300: "#C7C7D1",
        900: "#2E2E38",
      },
      background: {
        default: "#E9ECF1",
      },
      text: {
        primary: "#2E2E38",
        secondary: "#505062",
        disabled: "#73738C",
      },
    },
    typography: {
      fontFamily: "Ropa Sans",

      h4: {
        fontSize: "2rem",
        lineHeight: "2.5rem",
      },
      body2: {
        fontSize: "0.6875rem",
        lineHeight: "1rem",
      },
    },
  })
);

export default theme;
