import { SvgIcon } from "@mui/material";

function UpArrowIcon() {
  return (
    <SvgIcon
      viewBox="0 0 12 7"
      sx={{ fill: "none", width: "12px", height: "7px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8379 6.86857C11.6343 7.05517 11.318 7.04142 11.1314 6.83786L6 1.23994L0.868574 6.83786C0.681978 7.04142 0.365694 7.05517 0.162135 6.86857C-0.0414248 6.68198 -0.0551758 6.36569 0.13142 6.16213L5.63142 0.162135C5.72613 0.0588202 5.85984 -2.86102e-06 6 -2.86102e-06C6.14015 -2.86102e-06 6.27387 0.0588202 6.36857 0.162135L11.8686 6.16213C12.0552 6.36569 12.0414 6.68198 11.8379 6.86857Z"
        fill="#FF5200"
      />
    </SvgIcon>
  );
}

export default UpArrowIcon;
