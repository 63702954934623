import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};
function SoftwareUpdateIcon({ className }: Props) {
  return (
    <SvgIcon className={className} viewBox="0 0 28 28">
      <rect width="28" height="28" rx="14" fill="#4DA2F0" />
      <g clipPath="url(#clip0_625_6667)">
        <path
          d="M19.854 8.03601C19.8085 8.01704 19.7584 8.01195 19.71 8.02138C19.6616 8.03081 19.6171 8.05434 19.582 8.08901L18.061 9.59401C16.9587 8.56656 15.5069 7.99672 14 8.00001C12.9773 8.00001 11.9715 8.26145 11.0782 8.75948C10.1849 9.25752 9.43378 9.97563 8.8961 10.8456C8.35841 11.7156 8.05204 12.7086 8.00606 13.7303C7.96009 14.752 8.17605 15.7685 8.63344 16.6833C9.09082 17.5981 9.77444 18.3807 10.6194 18.957C11.4643 19.5332 12.4426 19.8839 13.4612 19.9758C14.4798 20.0676 15.505 19.8976 16.4394 19.4818C17.3738 19.066 18.1864 18.4182 18.8 17.6C18.9591 17.3878 19.0275 17.1211 18.99 16.8586C18.9524 16.596 18.8122 16.3591 18.6 16.2C18.4949 16.1212 18.3754 16.0639 18.2482 16.0313C18.121 15.9987 17.9886 15.9915 17.8586 16.0101C17.596 16.0476 17.3591 16.1878 17.2 16.4C16.7909 16.9455 16.2492 17.3773 15.6262 17.6545C15.0033 17.9317 14.3198 18.0451 13.6408 17.9839C12.9617 17.9226 12.3096 17.6888 11.7463 17.3047C11.183 16.9205 10.7272 16.3987 10.4223 15.7889C10.1174 15.179 9.9734 14.5014 10.004 13.8202C10.0347 13.1391 10.2389 12.4771 10.5974 11.8971C10.9559 11.3171 11.4566 10.8384 12.0521 10.5063C12.6477 10.1743 13.3182 10 14 10C14.9717 9.99813 15.9101 10.3541 16.636 11L14.941 12.681C14.9078 12.7142 14.8845 12.7561 14.8739 12.8019C14.8633 12.8477 14.8657 12.8956 14.881 12.94C14.8964 12.9847 14.9242 13.0241 14.9613 13.0535C14.9983 13.0829 15.043 13.1012 15.09 13.106L19.73 13.62H19.758C19.7909 13.6203 19.8235 13.614 19.854 13.6015C19.8844 13.5891 19.9121 13.5707 19.9354 13.5474C19.9586 13.5241 19.977 13.4965 19.9895 13.466C20.002 13.4355 20.0083 13.4029 20.008 13.37V8.26701C20.0079 8.21763 19.9932 8.16937 19.9658 8.12828C19.9385 8.08719 19.8995 8.0551 19.854 8.03601Z"
          fill="#FCFCFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_625_6667">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SoftwareUpdateIcon;
