import { TextField } from "@mui/material";
import moment from "moment";
import { ChangeEvent } from "react";

type Props = {
  name: string;
  label?: string;
  onChange: (field: string, value: string) => void;
  value: string;
  required?: boolean;
  disabled?: boolean;
};

function DateTime({
  name,
  label,
  onChange,
  value,
  required = false,
  disabled = false,
}: Props) {
  return (
    <TextField
      name={name}
      color="primary"
      variant="standard"
      required={required}
      disabled={disabled}
      label={label}
      type="datetime-local"
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(name, event.target.value)
      }
      InputLabelProps={{ shrink: true }}
      inputProps={{
        min: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      }}
    />
  );
}

export default DateTime;
