type Props = {
  className?: string;
  width?: number;
  height?: number;
};

function Logo({ className = "", width = 124, height = 28 }: Props) {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src="/logo.svg"
      alt="logo"
    />
  );
}

export default Logo;
