import BrowserUpdatedRoundedIcon from "@mui/icons-material/BrowserUpdatedRounded";
import DashboardIcon from "components/icons/DashboardIcon";
import AdminDevicesIcon from "components/icons/AdminDevicesIcon";
import GroupIcon from "components/icons/GroupIcon";
import UsersIcon from "components/icons/UsersIcon";
import OrganizationIcon from "components/icons/OrganizationIcon";
import { UserRole } from "models/User";
import { Troubleshoot } from "@mui/icons-material";
import paths from "../../config/paths";

export type NavItem = {
  url: string;
  titleKey: string;
  access: UserRole[];
  children?: any[];
};

export const navItemsMap: Record<string, NavItem> = {
  dashboard: {
    url: paths.dashboard,
    titleKey: "dashboard",
    access: [
      UserRole.NoAccess,
      UserRole.CloudAdmin,
      UserRole.Operator,
      UserRole.SuperUser,
      UserRole.Technician,
    ],
  },
  groups: {
    url: paths.groups,
    titleKey: "groups",
    access: [
      UserRole.NoAccess,
      UserRole.CloudAdmin,
      UserRole.Operator,
      UserRole.SuperUser,
      UserRole.Technician,
    ],
  },
  users: {
    url: paths.users,
    titleKey: "users",
    access: [UserRole.CloudAdmin],
  },
  organizations: {
    url: paths.organizations,
    titleKey: "organizations",
    access: [UserRole.CloudAdmin, UserRole.SuperUser],
  },
  devices: {
    url: paths.devices,
    titleKey: "devices",
    access: [UserRole.CloudAdmin],
  },
  updates: {
    url: paths.updates,
    titleKey: "updates",
    access: [UserRole.CloudAdmin],
    children: [
      {
        url: "software",
        titleKey: "softwareUpdates",
      },
      {
        url: "factory",
        titleKey: "factorySettings",
      },
      {
        url: "factory-type",
        titleKey: "factorySettingType",
      },
    ],
  },
  troubleshooting: {
    url: paths.troubleshootingAdmin,
    titleKey: "troubleshooting",
    access: [UserRole.CloudAdmin],
  },
};

const navItemMapKeys = Object.keys(navItemsMap);

export const navItems = Object.values(navItemsMap);

export const navItemKeyForUrl = (url: string): string | undefined =>
  navItemMapKeys.find((key) => navItemsMap[key].url === url);

export const navItemForUrlPrefix = (url: string): NavItem | undefined =>
  navItems.find((item) => url.startsWith(item.url));

export const getNavItemIcon = (url: string, className?: string) => {
  switch (url) {
    case paths.dashboard:
      return <DashboardIcon className={className} />;
    case paths.groups:
      return <GroupIcon className={className} />;
    case paths.users:
      return <UsersIcon className={className} />;
    case paths.organizations:
      return <OrganizationIcon className={className} />;
    case paths.devices:
      return <AdminDevicesIcon className={className} />;
    case paths.updates:
      return (
        <BrowserUpdatedRoundedIcon
          fontSize="small"
          sx={{ marginTop: "0 !important" }}
          className={className}
        />
      );
    case paths.troubleshootingAdmin:
      return (
        <Troubleshoot
          sx={{ marginTop: "0 !important" }}
          className={className}
        />
      );
    default:
      return "";
  }
};
