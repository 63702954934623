import { Breadcrumbs, Typography, Divider, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import BackIcon from "components/icons/BackIcon";
import { ReactElement } from "react";
import CustomHidden from "./CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 0",
  },
  item: {
    color: theme.palette.text.disabled,
  },
  icon: {
    color: theme.palette.grey[300],
  },
  divider: {
    margin: "14px 12px 14px 8px !important",
  },
}));

type Props = {
  mainContent: ReactElement;
  path: string;
  item: string;
  itemLabel?: string;
  onBack: () => void;
};

function DetailsHeader({ mainContent, path, item, itemLabel, onBack }: Props) {
  const styles = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        py: { xs: 3, md: 4 },
        px: { xs: 2, md: 6 },
        mb: 4,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", ml: -0.5 }}>
        <CustomHidden size="md" hideType="up">
          <IconButtonWrapper icon={<BackIcon />} onClick={onBack} />
        </CustomHidden>
        {mainContent}
      </Box>
      <CustomHidden size="md" hideType="down">
        <div className={styles.content}>
          {itemLabel ? (
            <Typography style={{ fontSize: "14px" }} color="textSecondary">
              {itemLabel}
            </Typography>
          ) : (
            <>
              <IconButtonWrapper icon={<BackIcon />} onClick={onBack} />
              <Divider
                className={styles.divider}
                orientation="vertical"
                variant="middle"
                flexItem
              />
              <Breadcrumbs
                separator={
                  <NavigateNextIcon className={styles.icon} fontSize="small" />
                }
              >
                <Typography color="textSecondary">{path}</Typography>
                <Typography className={styles.item}>{item}</Typography>
              </Breadcrumbs>
            </>
          )}
        </div>
      </CustomHidden>
    </Box>
  );
}

export default DetailsHeader;
