import { makeStyles } from "@mui/styles";

export const useTabStyles = makeStyles((theme: any) => ({
  tabsRoot: {
    margin: "0 64px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
    },
  },
  tabRoot: {
    textTransform: "none",
    minWidth: "73px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  selected: {
    backgroundColor: "white",
    padding: "0 16px",
  },
  indicator: {
    height: 0,
  },
}));

export default useTabStyles;
