import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Parameters } from "models/Heater";
import { MqttConfirm } from "models/Mqtt";
import ParametersData, {
  HeaterParametersProperties,
  parameterPropertiesById,
} from "models/Parameters";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import dashboardSlice, {
  dashboardDeviceGroup,
  enableDashboardActionsSelector,
  dashboardItemSerialNumber,
} from "store/dashboard";
import heaterService from "services/heater";
import groupService from "services/group";
import { MQTT_ACTIONS } from "store/mqtt";
import heaterSlice from "store/heater";

export const acknowledgeErrorId = 9999999;

export const PARAMETERS_ACTIONS = {
  SET_PARAMS: "SET_PARAMS",
  SET_WORK_MODE: "SET_WORK_MODE",
  APPLY_CHANGES_TO_GROUP: "APPLY_CHANGES_TO_GROUP",
  ACKNOWLEDGE_ERROR: "ACKNOWLEDGE_ERROR",
  RESET_ASH_LEVEL: "RESET_ASH_LEVEL",
  RESET_TIME_UNTIL_MAINTENANCE: "RESET_TIME_UNTIL_MAINTENANCE",
};

type ParamUpdate = {
  loading: boolean;
  confirm: MqttConfirm | null;
};

type ParametersState = {
  parameters: Parameters | null;
  currentUpdates: Record<string, Record<number, ParamUpdate>>;
  unsavedValues: Pick<
    Parameters,
    "targetRoomTemperature" | "targetHotAirTemperature" | "hotAirFanPower"
  >;
};

const initialState: ParametersState = {
  parameters: null,
  currentUpdates: {},
  unsavedValues: {
    targetRoomTemperature: 0,
    targetHotAirTemperature: 0,
    hotAirFanPower: 0,
  },
};

type SetConfirmPayload = {
  serialNumber: string;
  paramId: number;
  confirm: MqttConfirm;
};

type SetParamsUpdatesPayload = Omit<SetConfirmPayload, "confirm">;

type ParamUpdatedValuePayload = Omit<SetConfirmPayload, "confirm"> & {
  newValue: number;
};

type SetWorkModePayload = {
  deviceId: number;
  serialNumber: string;
  value: string;
};

type SetParamsPayload = {
  serialNumber: string;
  deviceId: number;
  paramId: number;
  value: string;
};

const dashboardParametersSlice = createSlice({
  name: "dashboardParameters",
  initialState,
  reducers: {
    loadParameters: (state, { payload }: PayloadAction<Parameters>) => {
      state.parameters = payload;
      state.unsavedValues = {
        targetRoomTemperature: payload.targetRoomTemperature,
        targetHotAirTemperature: payload.targetHotAirTemperature,
        hotAirFanPower: payload.hotAirFanPower,
      };
    },
    setTargetRoomTemperature: (state, { payload }: PayloadAction<number>) => {
      state.unsavedValues.targetRoomTemperature = payload;
    },
    setTargeHotAirTemperature: (state, { payload }: PayloadAction<number>) => {
      state.unsavedValues.targetHotAirTemperature = payload;
    },
    setHotAirFanPower: (state, { payload }: PayloadAction<number>) => {
      state.unsavedValues.hotAirFanPower = payload;
    },
    setParamsUpdates: (
      state,
      { payload }: PayloadAction<SetParamsUpdatesPayload>
    ) => {
      const { serialNumber, paramId } = payload;

      state.currentUpdates[serialNumber] = {
        ...state.currentUpdates[serialNumber],
        [paramId]: {
          loading: true,
          confirm: null,
        },
      };
    },
    setUpdatedProperty: (
      state,
      { payload }: PayloadAction<ParamUpdatedValuePayload>
    ) => {
      const { paramId, newValue } = payload;
      const updatedProperty = parameterPropertiesById[paramId];

      if (state.parameters) {
        state.parameters = {
          ...state.parameters,
          [updatedProperty]: newValue,
        };
      }
    },
    setParamsUpdateConfirm: (
      state,
      { payload }: PayloadAction<SetConfirmPayload>
    ) => {
      const { serialNumber, paramId, confirm } = payload;

      state.currentUpdates[serialNumber] = {
        ...state.currentUpdates[serialNumber],
        [paramId]: {
          loading: false,
          confirm,
        },
      };
    },
    bulkUpdateParams: (state, { payload }: PayloadAction<Parameters>) => {
      state.parameters = payload;
    },
    reset: (state) => {
      state.parameters = null;
      state.currentUpdates = {};
      state.unsavedValues = {
        targetRoomTemperature: 0,
        targetHotAirTemperature: 0,
        hotAirFanPower: 0,
      };
    },
  },
});

function dashboardParametersSelector(state: {
  [dashboardParametersSlice.name]: ParametersState;
}) {
  return state[dashboardParametersSlice.name];
}

export const dashboardParameters = createSelector(
  dashboardParametersSelector,
  (state) => state?.parameters
);

export const dashboardEnergyMeter = createSelector(
  dashboardParameters,
  (parameters) => parameters?.energyMeter
);

export const dashboardAshLevel = createSelector(
  dashboardParameters,
  (parameters) => parameters?.ashLevel || 0
);

export const dashboardTimePredictionTillNextService = createSelector(
  dashboardParameters,
  (parameters) => parameters?.timePredictionTillNextMaintenance || 0
);

export const dashboardMaintenanceInterval = createSelector(
  dashboardParameters,
  (parameters) => parameters?.maintenanceInterval || 0
);

export const dashboardTargetRoomTemperature = createSelector(
  dashboardParameters,
  (parameters) => parameters?.targetRoomTemperature || 0
);

export const dashboardCurrentTargetRoomTemperature = createSelector(
  dashboardParametersSelector,
  (state) => state.unsavedValues.targetRoomTemperature
);

export const dashboardHotAirFanPower = createSelector(
  dashboardParameters,
  (parameters) => parameters?.hotAirFanPower || 0
);

export const dashboardCurrentHotAirFanPower = createSelector(
  dashboardParametersSelector,
  (state) => state.unsavedValues.hotAirFanPower
);

export const dashboardTargetHotAirTemperature = createSelector(
  dashboardParameters,
  (parameters) => parameters?.targetHotAirTemperature || 0
);

export const dashboardCurrentTargetHotAirTemperature = createSelector(
  dashboardParametersSelector,
  (state) => state.unsavedValues.targetHotAirTemperature
);

export const dashboardProcessState = createSelector(
  dashboardParameters,
  (parameters) => parameters?.processState
);

export const dashboardMachineName = createSelector(
  dashboardParameters,
  (parameters) => parameters?.machineName || "-"
);

export const dashboardOperationHoursHeating = createSelector(
  dashboardParameters,
  (parameters) => parameters?.operationHoursHeating
);

export const dashboardSoftwareVersion = createSelector(
  dashboardParameters,
  (parameters) => parameters?.softwareVersion || "-"
);

export const parametersUpdatesSelector = createSelector(
  dashboardParametersSelector,
  (state: ParametersState) => state.currentUpdates
);

const relevantGroupParameterIds = [
  ParametersData.TargetHotAirTemperature.id,
  ParametersData.HotAirFanPower.id,
  ParametersData.TargetRoomTemperature.id,
];

export const isApplyChangesToGroupEnabled = createSelector(
  dashboardItemSerialNumber,
  enableDashboardActionsSelector,
  parametersUpdatesSelector,
  (serialNumber, isConnected, currentUpdates) => {
    const paramIds = Object.keys(currentUpdates[serialNumber] || {});
    const validParamIds = paramIds.filter((id) =>
      relevantGroupParameterIds.includes(+id)
    );

    const isNoneLoading = validParamIds
      .map((id) => +id)
      .every((id) => currentUpdates[serialNumber][id].loading === false);

    return isNoneLoading && isConnected;
  }
);

export const createIsParameterUpdatingSelector = (
  serialNumber: string,
  paramId: number
) =>
  createSelector(
    parametersUpdatesSelector,
    (state) => state?.[serialNumber]?.[paramId]?.loading || false
  );

export const createParameterCurrentUpdateConfirmSelector = (
  serialNumber: string,
  paramId: number
) =>
  createSelector(
    parametersUpdatesSelector,
    (state) => state?.[serialNumber]?.[paramId]?.confirm
  );

export const createParameterCurrentUpdateHasErrorSelector = (
  serialNumber: string,
  paramId: number
) =>
  createSelector(
    createParameterCurrentUpdateConfirmSelector(serialNumber, paramId),
    (confirm) => confirm === "10" || confirm === "20"
  );

function* handleSetParams(action: PayloadAction<SetParamsPayload>): Generator {
  try {
    const { serialNumber, deviceId, paramId, value } = action.payload;

    yield all([
      put(
        dashboardParametersSlice.actions.setParamsUpdates({
          serialNumber,
          paramId,
        })
      ),
      call(
        heaterService.putRemoteDeviceParametersData,
        deviceId,
        paramId,
        value
      ),
    ]);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetParams() {
  yield takeLatest(PARAMETERS_ACTIONS.SET_PARAMS, handleSetParams);
}

function* handleApplyChangesToGroup(): Generator {
  try {
    const serialNumber = (yield select(dashboardItemSerialNumber)) as string;
    const groupId = (yield select(dashboardDeviceGroup)) as number;
    const targetRoomTemperature = (yield select(
      dashboardCurrentTargetRoomTemperature
    )) as number;
    const targetHotAirTemperature = (yield select(
      dashboardCurrentTargetHotAirTemperature
    )) as number;
    const hotAirFanPower = (yield select(
      dashboardCurrentHotAirFanPower
    )) as number;

    const targetRoomTemperatureId = ParametersData.TargetRoomTemperature.id;
    const targetHotAirId = ParametersData.TargetHotAirTemperature.id;
    const hotAirFanPowerId = ParametersData.HotAirFanPower.id;

    yield all([
      put(
        dashboardParametersSlice.actions.setParamsUpdates({
          serialNumber,
          paramId: targetRoomTemperatureId,
        })
      ),
      call(
        groupService.applyParameterChangesToGroup,
        groupId,
        targetRoomTemperatureId,
        `${targetRoomTemperature}`
      ),
      put(
        dashboardParametersSlice.actions.setParamsUpdates({
          serialNumber,
          paramId: targetHotAirId,
        })
      ),
      call(
        groupService.applyParameterChangesToGroup,
        groupId,
        targetHotAirId,
        `${targetHotAirTemperature}`
      ),
      put(
        dashboardParametersSlice.actions.setParamsUpdates({
          serialNumber,
          paramId: hotAirFanPowerId,
        })
      ),
      call(
        groupService.applyParameterChangesToGroup,
        groupId,
        hotAirFanPowerId,
        `${hotAirFanPower}`
      ),
    ]);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchApplyChangesToGroup() {
  yield takeLatest(
    PARAMETERS_ACTIONS.APPLY_CHANGES_TO_GROUP,
    handleApplyChangesToGroup
  );
}

function* handleParamNewValue(
  action: PayloadAction<ParamUpdatedValuePayload>
): Generator {
  try {
    const { serialNumber, paramId, newValue } = action.payload;

    yield put(
      dashboardParametersSlice.actions.setUpdatedProperty({
        serialNumber,
        paramId,
        newValue,
      })
    );

    if (paramId === ParametersData.SoftwareVersion.id) {
      yield put(
        dashboardSlice.actions.clearSoftwareUpdate({
          type: "softwareUpdates",
          key: serialNumber,
        })
      );
    }

    if (
      paramId === ParametersData.ProcessState.id ||
      paramId === ParametersData.TargetRoomTemperature.id
    ) {
      yield put(
        heaterSlice.actions.updateParameter({
          serialNumber,
          property: parameterPropertiesById[
            paramId
          ] as HeaterParametersProperties,
          value: newValue,
        })
      );
    }
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchParamNewValue() {
  yield takeLatest(MQTT_ACTIONS.PARAM_NEW_VALUE, handleParamNewValue);
}

function* handleParamSetConfirm(
  action: PayloadAction<SetConfirmPayload>
): Generator {
  try {
    const { serialNumber, paramId, confirm } = action.payload;

    yield put(
      dashboardParametersSlice.actions.setParamsUpdateConfirm({
        serialNumber,
        paramId,
        confirm,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchParamSetConfirm() {
  yield takeLatest(MQTT_ACTIONS.PARAM_SET_CONFIRMED, handleParamSetConfirm);
}

function* handleSetWorkMode(
  action: PayloadAction<SetWorkModePayload>
): Generator {
  try {
    const { deviceId, serialNumber, value } = action.payload;

    yield all([
      put(
        dashboardParametersSlice.actions.setParamsUpdates({
          serialNumber,
          paramId: ParametersData.ProcessState.id,
        })
      ),
      call(heaterService.putRemoteHeatersMachineMode, deviceId, value),
    ]);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetWorkMode() {
  yield takeLatest(PARAMETERS_ACTIONS.SET_WORK_MODE, handleSetWorkMode);
}

function* handleAcknowledgeError(
  action: PayloadAction<{ serialNumber: string; deviceId: number }>
): Generator {
  try {
    const { serialNumber, deviceId } = action.payload;
    yield put(
      dashboardParametersSlice.actions.setParamsUpdates({
        serialNumber,
        paramId: acknowledgeErrorId,
      })
    );
    yield call(heaterService.acknowledgeError, deviceId);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* handleResetAshLevel(
  action: PayloadAction<{ serialNumber: string; deviceId: number }>
): Generator {
  try {
    const { serialNumber, deviceId } = action.payload;

    yield put(
      dashboardParametersSlice.actions.setParamsUpdates({
        serialNumber,
        paramId: ParametersData.AshLevel.id,
      })
    );
    yield call(heaterService.resetAshLevel, deviceId);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchResetAshLevel() {
  yield takeLatest(PARAMETERS_ACTIONS.RESET_ASH_LEVEL, handleResetAshLevel);
}

function* handleResetTimeUntilMaintenance(
  action: PayloadAction<{ serialNumber: string; deviceId: number }>
): Generator {
  try {
    const { serialNumber, deviceId } = action.payload;

    yield put(
      dashboardParametersSlice.actions.setParamsUpdates({
        serialNumber,
        paramId: ParametersData.HoursTillMaintenance.id,
      })
    );

    yield call(heaterService.resetTimeUntilMaintenance, deviceId);
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchResetTimeUntilMaintenance() {
  yield takeLatest(
    PARAMETERS_ACTIONS.RESET_TIME_UNTIL_MAINTENANCE,
    handleResetTimeUntilMaintenance
  );
}

function* watchAcknowledgeError() {
  yield takeLatest(
    PARAMETERS_ACTIONS.ACKNOWLEDGE_ERROR,
    handleAcknowledgeError
  );
}

export function* dashboardParametersWatcher() {
  yield all([
    fork(watchSetParams),
    fork(watchParamNewValue),
    fork(watchParamSetConfirm),
    fork(watchSetWorkMode),
    fork(watchParamSetConfirm),
    fork(watchAcknowledgeError),
    fork(watchApplyChangesToGroup),
    fork(watchResetTimeUntilMaintenance),
    fork(watchResetAshLevel),
  ]);
}

export default dashboardParametersSlice;
