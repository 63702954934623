import { SvgIcon } from "@mui/material";

function DeFlag() {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ fill: "none" }}>
      <g clipPath="url(#clip0_1109_5710)">
        <path
          d="M0 18C0 18.7073 0.280952 19.3856 0.781049 19.8857C1.28115 20.3858 1.95942 20.6667 2.66667 20.6667H21.3333C22.0406 20.6667 22.7189 20.3858 23.219 19.8857C23.719 19.3856 24 18.7073 24 18V15.3334H0V18Z"
          fill="#FFCD05"
        />
        <path d="M0 9.33337H24V15.3334H0V9.33337Z" fill="#ED1F24" />
        <path
          d="M21.3333 3.33337H2.66667C1.95942 3.33337 1.28115 3.61433 0.781049 4.11442C0.280952 4.61452 0 5.2928 0 6.00004L0 9.33337H24V6.00004C24 5.2928 23.719 4.61452 23.219 4.11442C22.7189 3.61433 22.0406 3.33337 21.3333 3.33337Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_1109_5710">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default DeFlag;
