import { Troubleshoot } from "@mui/icons-material";
import { Box } from "@mui/material";
import EmptyList from "components/common/EmptyList";
import PageHeader from "components/common/PageHeader";
import GuidesTable from "components/troubleshooting/GuidesTable";
import paths from "config/paths";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TROUBLESHOOTING_ADMIN_ACTIONS,
  guidesListSelector,
} from "store/troubleshootingAdmin";
import useContentStyles from "./useContentStyles";

function TroubleshootingAdmin() {
  const dispatch = useDispatch();
  const styles = useContentStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = useSelector(guidesListSelector);

  useEffect(() => {
    dispatch({ type: TROUBLESHOOTING_ADMIN_ACTIONS.INIT });
  }, [dispatch]);

  const onAdd = useCallback(() => {
    navigate(`${paths.troubleshootingAdmin}/new`);
  }, [navigate]);

  return (
    <Box className={styles.content}>
      <PageHeader
        title={t("modules.troubleshooting.title")}
        addLabel={t("modules.troubleshooting.addNew")}
        onAdd={onAdd}
      />
      {items.length > 0 && <GuidesTable guides={items} />}
      {items.length === 0 && (
        <EmptyList
          icon={<Troubleshoot color="disabled" style={{ fontSize: "150px" }} />}
          emptyListText={t(`modules.troubleshooting.empty`)}
          onOpen={onAdd}
        />
      )}
    </Box>
  );
}

export default TroubleshootingAdmin;
