import ParametersData, { ParametersType } from "models/Parameters";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { PARAMETERS_ACTIONS } from "store/dashboardParameters";

const useParameterDispatch = (id: number, serialNumber: string) => {
  const dispatch = useDispatch();

  const updateParameter = useCallback(
    (value: string, type: ParametersType) => {
      dispatch({
        type: PARAMETERS_ACTIONS.SET_PARAMS,
        payload: {
          serialNumber,
          deviceId: id,
          paramId: ParametersData[type].id,
          value,
        },
      });
    },
    [dispatch, id, serialNumber]
  );

  return { updateParameter };
};

export default useParameterDispatch;
