import PlusIcon from "@mui/icons-material/Add";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SaveIcon from "@mui/icons-material/Save";

import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { OrganizationUnitWithDetails } from "models/Organization";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import uiSlice from "store/ui";

const emptyUnit = { label: "", userCount: 0, alarmRuleCount: 0 };

function UnitList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext<any>();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editUnit, setEditUnit] =
    useState<Partial<OrganizationUnitWithDetails>>(emptyUnit);
  const actionsDisabled = !editUnit.label && editIndex !== null;

  const namesSet = useMemo(
    () =>
      new Set(
        values.units.map((item: OrganizationUnitWithDetails) => item.label)
      ),
    [values.units]
  );

  const isNewName = !namesSet.has(editUnit.label);

  const handleAddUnit = useCallback(() => {
    setEditUnit(emptyUnit);
    const newIndex = values.units.length;
    const updatedUnits = [...values.units];
    updatedUnits[newIndex] = emptyUnit;
    setFieldValue("units", updatedUnits);
    setEditIndex(newIndex);
  }, [setFieldValue, values.units]);

  const handleEditUnit = useCallback(
    (index: number) => {
      setEditUnit(values.units[index]);
      setEditIndex(index);
    },
    [values.units]
  );

  const handleManageUsers = useCallback(
    (unitId: number) => {
      dispatch(uiSlice.actions.setUsersUnitDrawerOpen(true));
      navigate(`units/${unitId}/users`);
    },
    [dispatch, navigate]
  );

  const handleManageRules = useCallback(
    (unitId: number) => {
      dispatch(uiSlice.actions.setRulesUnitDrawerOpen(true));
      navigate(`units/${unitId}/rules`);
    },
    [dispatch, navigate]
  );

  const handleSaveEditUnit = useCallback(() => {
    if (editIndex !== null) {
      const updatedUnits = [...values.units];
      updatedUnits[Number(editIndex)] = editUnit;
      setFieldValue("units", updatedUnits);
      setEditIndex(null);
    }
  }, [editIndex, editUnit, setFieldValue, values.units]);

  const handleEditChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;
      const currentUnit = {
        ...values.units[index],
      };
      const editedUnit = { ...currentUnit, [name]: value };
      setEditUnit(editedUnit);
    },
    [values.units]
  );

  const isSaveItemDisabled = useCallback(
    (index: number) => {
      const currentUnit = values.units[index];

      return (
        actionsDisabled || (!isNewName && editUnit.label !== currentUnit.label)
      );
    },
    [actionsDisabled, editUnit.label, isNewName, values.units]
  );

  const getNameHelperText = useCallback(
    (index: number) => {
      const currentUnit = values.units[index];

      if (editUnit.label === "") {
        return t("modules.organizations.grid.orgUnit.invalidName");
      }
      if (!isNewName && editUnit.label !== currentUnit.label) {
        return t("modules.organizations.grid.orgUnit.unitNameExists");
      }
      return "";
    },
    [editUnit.label, isNewName, t, values.units]
  );

  return (
    <Grid container xs={12} sm={5} lg={4}>
      <Grid container alignItems="center">
        <Typography color="textSecondary">
          {t("modules.organizations.grid.orgUnit.label")}
        </Typography>
        <IconButton
          size="small"
          color="primary"
          onClick={handleAddUnit}
          disabled={editIndex !== null && values.units.length > 0}
        >
          <PlusIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <List>
          <FieldArray name="units">
            {({ remove }) => (
              <>
                {values.units.map((unit: any, index: number) => (
                  <>
                    <ListItem
                      key={`${unit.id}-${unit.label}-${unit.createdAt}`}
                      sx={{ margin: "10px 0" }}
                      disableGutters
                    >
                      <Grid container spacing={1}>
                        {editIndex === index ? (
                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              label={t(
                                "modules.organizations.grid.orgUnit.name"
                              )}
                              name="label"
                              value={editUnit.label}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleEditChange(e, index)
                              }
                              variant="outlined"
                              error={isSaveItemDisabled(index)}
                              helperText={getNameHelperText(index)}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={8}>
                            <ListItemText
                              primary={unit.label || "-"}
                              secondary={
                                unit.id ? (
                                  <Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="caption">
                                        {t(
                                          "modules.organizations.grid.orgUnit.userCount",
                                          { count: unit.userCount }
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography variant="caption">
                                        {t(
                                          "modules.organizations.grid.orgUnit.alarmRulesCount",
                                          { count: unit.alarmRuleCount }
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    color="primary"
                                  >
                                    {t(
                                      "modules.organizations.grid.orgUnit.saveBeforeManage"
                                    )}
                                  </Typography>
                                )
                              }
                            />
                          </Grid>
                        )}

                        <Grid item xs={4}>
                          <ListItemSecondaryAction>
                            {editIndex === index ? (
                              <IconButton
                                size="small"
                                onClick={handleSaveEditUnit}
                                disabled={isSaveItemDisabled(index)}
                              >
                                <SaveIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                onClick={() => handleEditUnit(index)}
                                disabled={actionsDisabled}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                              disabled={actionsDisabled}
                            >
                              <DeleteIcon />
                            </IconButton>
                            {unit.id && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => handleManageUsers(unit.id)}
                                  disabled={actionsDisabled}
                                >
                                  <GroupAddIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleManageRules(unit.id)}
                                  disabled={actionsDisabled}
                                >
                                  <AddAlarmIcon />
                                </IconButton>
                              </>
                            )}
                          </ListItemSecondaryAction>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </>
            )}
          </FieldArray>
        </List>
      </Grid>
    </Grid>
  );
}

export default UnitList;
