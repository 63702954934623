import { Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 64px",
    height: "80px",
    background: "#F9F9FB",
    borderTop: "1px solid #C7C7D1",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
  cancelButton: {
    marginRight: "40px",
    textTransform: "none",
  },
  button: {
    textTransform: "none",
  },
}));

type Props = {
  id: number;
  saveDisabled?: boolean;
  onCancel: () => void;
};

function FormFooter({ id, saveDisabled = false, onCancel }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const isNew = id === 0;

  return (
    <Paper className={styles.paper} elevation={3}>
      <Button className={styles.cancelButton} onClick={onCancel}>
        {t("buttons.cancel")}
      </Button>
      <Button
        type="submit"
        className={styles.button}
        color="primary"
        variant="contained"
        disabled={saveDisabled}
      >
        {isNew ? t("buttons.create") : t("buttons.save")}
      </Button>
    </Paper>
  );
}

export default FormFooter;
