import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import getComparator, { Order } from "components/common/tableHelpers";
import { AdminDevice, DeviceModel, DeviceKind } from "models/Heater";
import DeviceModelIcon from "components/icons/DeviceModel";
import ManageAdminDevicesMenu from "components/adminDevices/ManageAdminDevicesMenu";
import { defaultRowsPerPage, rowsPerPageOptions } from "helpers";
import CustomHidden from "components/common/CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes =
  | "machineName"
  | "serialNumber"
  | "modelLabel"
  | "kindLabel"
  | "organizations";

type Props = {
  devices: AdminDevice[];
};

function AdminDevicesTable({ devices }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("machineName");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "machineName" as OrderByTypes,
        label: t("modules.devices.table.deviceName").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "serialNumber" as OrderByTypes,
        label: t("modules.devices.table.serialNumber").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
      {
        id: "modelLabel" as OrderByTypes,
        label: t("modules.devices.table.model").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "kindLabel" as OrderByTypes,
        label: t("modules.devices.table.kind").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "organizations" as OrderByTypes,
        label: t("modules.devices.table.organization").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
    ],
    [styles.headCell, t]
  );

  const mappedValues = useMemo(
    () =>
      devices.map((d) => ({
        id: d.device.id,
        serialNumber: d.device.serialNumber,
        machineName: d.parameters.machineName || "-",
        modelLabel: DeviceModel[d.device.model],
        kindLabel: DeviceKind[d.device.kind],
        organizations: d.device.organizations
          .map((org) => org.label)
          .join(", "),
      })),
    [devices]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),
    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) =>
              headCell.smallHidden ? (
                <CustomHidden size="xs" hideType="only">
                  <TableCell
                    className={headCell.className}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </CustomHidden>
              ) : (
                <TableCell
                  className={headCell.className}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((device) => (
              <TableRow key={`row-${device.id}`}>
                <TableCell padding="checkbox" />

                <CustomHidden size="sm" hideType="down">
                  <TableCell align="left">
                    <Typography variant="body1">
                      {device.machineName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="textSecondary">
                      {device.serialNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <DeviceModelIcon model={device.modelLabel} />
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="textSecondary">
                      {device.kindLabel}
                    </Typography>
                  </TableCell>
                </CustomHidden>
                <CustomHidden size="sm" hideType="up">
                  <TableCell align="left">
                    <Typography variant="body1" color="textSecondary">
                      {device.serialNumber}
                    </Typography>
                  </TableCell>
                </CustomHidden>
                <TableCell align="left">
                  <Typography variant="body1" color="textSecondary">
                    {device.organizations}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <ManageAdminDevicesMenu deviceId={device.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedValues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default AdminDevicesTable;
