import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormFooter from "components/common/FormFooter";
import Loader from "components/common/Loader";
import ModalWrapper from "components/common/ModalWrapper";
import { passwordRegex } from "components/fields/PasswordField";
import DeleteGrid from "components/users/DeleteGrid";
import GeneralInformationGrid from "components/users/GeneralInformationGrid";
import OrganizationsGrid from "components/users/OrganizationsGrid";
import RoleGrid from "components/users/RoleGrid";
import UserDetailsHeader from "components/users/UserDetailsHeader";
import paths from "config/paths";
import { Formik } from "formik";
import useDeleteModal from "hooks/useDeleteModal";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import organizationsSlice from "store/organizations";
import {
  USERS_ACTIONS,
  UserFormData,
  createUserInitialFormValues,
  emailRegex,
  usersActionInProgress,
} from "store/users";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "400px",
    height: "calc(100%)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
    },
  },
}));

function EditUser() {
  const styles = useStyles();
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const actionInProgress = useSelector(usersActionInProgress);
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const id = userId && Number(userId) ? +userId : 0;
  const getInitialValues = useMemo(() => createUserInitialFormValues(id), [id]);
  const initialValues = useSelector(getInitialValues);
  const isNew = id === 0;

  const onBack = useCallback(() => navigate(paths.users), [navigate]);

  useEffect(() => {
    dispatch(organizationsSlice.actions.init());
    if (id) {
      dispatch({
        type: USERS_ACTIONS.GET_USER,
        payload: {
          userId: id,
        },
      });
    }
  }, [dispatch, id]);

  const onSubmit = useCallback(
    (data: any) => {
      if (isNew) {
        dispatch({
          type: USERS_ACTIONS.ADD_USER,
          payload: {
            username: data.username,
            password: data.password,
            accessLevel: Number(data.role),
            firstName: data.firstName,
            lastName: data.lastName,
            organizations: data.organizations,
          },
        });
      } else {
        dispatch({
          type: USERS_ACTIONS.EDIT_USER,
          payload: {
            userId: id,
            username: data.username,
            password: data.password,
            accessLevel: Number(data.role),
            firstName: data.firstName,
            lastName: data.lastName,
            requiresPasswordReset: data.requiresPasswordReset,
            organizations: data.organizations,
          },
        });
      }
    },
    [dispatch, id, isNew]
  );

  const onDeleteUser = useCallback(() => {
    dispatch({
      type: USERS_ACTIONS.DELETE_USER,
      payload: {
        userId,
      },
    });
  }, [dispatch, userId]);

  const isValid = (user: UserFormData): boolean =>
    user.firstName !== "" &&
    user.lastName !== "" &&
    emailRegex.test(user.username) &&
    ((!isNew && user.password === "") || passwordRegex.test(user.password));

  return (
    <div>
      <UserDetailsHeader userId={id} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={styles.paper}>
              <Loader open={actionInProgress} />
              <GeneralInformationGrid
                isNew={isNew}
                currentFirstName={values.firstName}
                currentLastName={values.lastName}
                currentPassword={values.password}
                currentUsername={values.username}
                currentRequirePasswordReset={values.requiresPasswordReset}
                showRequirePasswordReset={!isNew}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <br />
              <RoleGrid value={values.role} handleChange={handleChange} />
              <br />
              <OrganizationsGrid
                currentValue={values.organizations || []}
                setFieldValue={setFieldValue}
              />
              <br />
              <DeleteGrid userId={id} onDelete={onOpenDeleteModal} />
            </Paper>
            <FormFooter
              id={id}
              onCancel={onBack}
              saveDisabled={isEqual(initialValues, values) || !isValid(values)}
            />
          </form>
        )}
      </Formik>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDeleteUser}
        title={t("modals.users.delete")}
        contentLabel={t("modals.users.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </div>
  );
}

export default EditUser;
