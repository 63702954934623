import UpArrowIcon from "components/icons/UpArrowIcon";
import DownArrowIcon from "components/icons/DownArrowIcon";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  InputAdornment,
  List,
  ListItemText,
  TextField,
  Typography,
  Divider,
  ListItemButton,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { useTranslation } from "react-i18next";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Group } from "models/Group";
import ManageGroupMenu from "components/groups/ManageGroupMenu";
import GroupDeviceTable from "components/groups/GroupDeviceTable";
import ModalWrapper from "components/common/ModalWrapper";
import {
  heaterCommonByDeviceIdSelector,
  heaterListDevicesSelector,
} from "store/heater";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { GROUP_ACTIONS } from "store/group";
import uiSlice, { getScrollToGroupId } from "store/ui";
import HeaterCard from "components/dashboard/HeaterCard";
import { useUnmount } from "react-use";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import CustomHidden from "components/common/CustomHidden";
import { currentUserOrgAsSetSelector } from "store/auth";

type Props = {
  group: Group;
};

const useStyles = makeStyles((theme: any) => ({
  card: {
    margin: "16px 0 ",
  },
  button: {
    textTransform: "none",
    margin: "12px 0",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "24px",
    },
  },
  contetContainer: {
    display: "flex",
    flexDirection: "row",
  },
  placeholderDiv: {
    flexGrow: 1,
  },
  showMorePlaceholder: {
    width: "85px",
  },
  deviceCount: {
    flex: "0.2",
  },
  expandable: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  mobileExpandableContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0 16px",
  },
  icon: {
    color: "#9D9DAF",
  },
  listIcon: {
    color: "#9D9DAF",
    "&:hover": {
      cursor: "pointer",
    },
  },
  primaryIcon: {
    color: theme.palette.primary.main,
  },
  arrowIcon: {
    marginRight: "8px",
  },
}));

function GroupCard({ group }: Props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [scrolledIntoView, setScrolledIntoView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedDevices, setSelectedDevices] = useState<number[]>([]);
  const devices = useSelector(heaterListDevicesSelector);
  const groupCardRef = useRef<HTMLDivElement>(null);
  const groupId = useSelector(getScrollToGroupId);
  const heaterCommonByDeviceId = useSelector(heaterCommonByDeviceIdSelector);
  const userOrgsSet = useSelector(currentUserOrgAsSetSelector);

  const initialIds = useMemo(
    () => group.devices.map((device) => device.id),
    [group.devices]
  );

  const groupDevicesForUser = useMemo(
    () =>
      group?.devices.filter((groupDevice) => {
        const deviceOrgs = groupDevice.organizations.map((org) => org.id);
        return deviceOrgs.some((org) => userOrgsSet.has(org));
      }),
    [group?.devices, userOrgsSet]
  );

  const deviceCount = useMemo(
    () => groupDevicesForUser.length,
    [groupDevicesForUser.length]
  );

  useEffect(() => setSelectedDevices(initialIds), [initialIds, isModalOpen]);

  useEffect(() => {
    if (groupCardRef && groupCardRef.current && groupId === group.id) {
      setExpanded(true);

      groupCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });

      setScrolledIntoView(true);
    }
  }, [group.id, groupId, group, dispatch]);

  useUnmount(() => {
    if (groupId && scrolledIntoView) {
      dispatch(uiSlice.actions.setScrollToGroupId(null));
    }
  });

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onAddDeviceToGroup = useCallback(() => {
    dispatch(uiSlice.actions.setScrollToGroupId(null));
    const removedIds = group.devices
      .map((device) => device.id)
      .filter((id) => !selectedDevices.includes(id));

    dispatch({
      type: GROUP_ACTIONS.UPDATE_GROUP_MEMBERS,
      payload: {
        groupId: group.id,
        add: selectedDevices,
        remove: removedIds,
      },
    });
    closeModal();
  }, [closeModal, dispatch, group.devices, group.id, selectedDevices]);

  const onDeviceClick = useCallback(
    (deviceId: number) => {
      if (selectedDevices.includes(deviceId)) {
        const newDevices = selectedDevices.filter((id) => id !== deviceId);
        setSelectedDevices(newDevices);
      } else {
        const newDevices = [...selectedDevices, deviceId];
        setSelectedDevices(newDevices);
      }
    },
    [selectedDevices]
  );

  const isDeviceSelected = useCallback(
    (deviceId: number) => selectedDevices.includes(deviceId),
    [selectedDevices]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    []
  );

  const filteredDevices = useMemo(
    () =>
      devices.filter((device) =>
        heaterCommonByDeviceId[device.id]?.machineName
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      ),
    [devices, heaterCommonByDeviceId, searchValue]
  );

  const addNewGroupButton = (
    <Button
      className={styles.button}
      size="small"
      variant="outlined"
      color="primary"
      onClick={openModal}
    >
      {t("modules.groups.editDevices")}
    </Button>
  );

  const expandableCardContent = useMemo(() => {
    if (deviceCount === 0) {
      return (
        <CardContent className={styles.expandable}>
          <div className={styles.showMorePlaceholder} />{" "}
        </CardContent>
      );
    }

    if (expanded) {
      return (
        <CardContent>
          <IconButtonWrapper
            className={styles.showMorePlaceholder}
            onClick={toggleExpanded}
            icon={
              <>
                <Typography
                  className={styles.arrowIcon}
                  color="primary"
                  variant="body2"
                >
                  {t("modules.groups.showLess")}
                </Typography>
                <UpArrowIcon />
              </>
            }
          />
        </CardContent>
      );
    }

    return (
      <CardContent className={styles.expandable}>
        <IconButtonWrapper
          className={styles.showMorePlaceholder}
          onClick={toggleExpanded}
          icon={
            <>
              <Typography
                className={styles.arrowIcon}
                color="primary"
                variant="body2"
              >
                {t("modules.groups.showMore")}
              </Typography>
              <DownArrowIcon />
            </>
          }
        />
      </CardContent>
    );
  }, [
    deviceCount,
    expanded,
    styles.arrowIcon,
    styles.expandable,
    styles.showMorePlaceholder,
    t,
    toggleExpanded,
  ]);

  const deviceCountContent = (
    <CardContent className={styles.deviceCount}>
      <Typography component="div" variant="h6">
        {deviceCount}
      </Typography>
      <Typography color="textSecondary" variant="body2" component="div">
        {deviceCount === 1
          ? t("modules.groups.deviceSingular")
          : t("modules.groups.devicePlural")}
      </Typography>
    </CardContent>
  );

  return (
    <>
      <Card className={styles.card} ref={groupCardRef}>
        <CardHeader
          action={
            <>
              <CustomHidden size="sm" hideType="down">
                {addNewGroupButton}
              </CustomHidden>
              <ManageGroupMenu groupId={group.id} />
            </>
          }
          title={group.label}
        />
        <CustomHidden size="sm" hideType="down">
          <Box className={styles.contetContainer}>
            <div className={styles.placeholderDiv} />
            {deviceCountContent}
            {expandableCardContent}
          </Box>
        </CustomHidden>

        <CustomHidden size="sm" hideType="up">
          {deviceCountContent}
          <Box className={styles.mobileExpandableContainer}>
            {addNewGroupButton}
            {expandableCardContent}
          </Box>
        </CustomHidden>

        <Collapse in={expanded} unmountOnExit>
          <CardContent>
            <Typography gutterBottom component="div">
              {t("modules.groups.deviceList")}
            </Typography>
            <CustomHidden size="sm" hideType="down">
              <GroupDeviceTable
                groupId={group.id}
                devices={groupDevicesForUser}
              />
            </CustomHidden>
            <CustomHidden size="sm" hideType="up">
              {groupDevicesForUser.map((device) => (
                <>
                  <HeaterCard
                    key={`card-${device.id}-${device.serialNumber}`}
                    deviceId={device.id}
                    isConnected={device.connected}
                    isPotentiallyOffline={false} // we can't know this for now
                    model={device.model}
                    machineName={heaterCommonByDeviceId[device.id]?.machineName}
                    softwareVersion={
                      heaterCommonByDeviceId[device.id]?.softwareVersion
                    }
                    processState={
                      heaterCommonByDeviceId[device.id]?.processState
                    }
                    targetRoomTemperature={
                      heaterCommonByDeviceId[device.id]?.targetRoomTemperature
                    }
                    errorCount={heaterCommonByDeviceId[device.id]?.errorCount}
                    warningCount={
                      heaterCommonByDeviceId[device.id]?.warningCount
                    }
                    groupId={group.id}
                  />
                  <Divider />
                </>
              ))}
            </CustomHidden>
          </CardContent>
        </Collapse>
      </Card>
      <ModalWrapper
        isOpen={isModalOpen}
        onCancelAction={closeModal}
        onOkAction={onAddDeviceToGroup}
        title={t("modals.groups.addDeviceToGroup")}
        contentLabel={t("modals.groups.addDeviceToGroupSubtitle")}
        okLabel={t("buttons.add")}
        isOkDisabled={isEqual(initialIds, selectedDevices)}
        cancelLabel={t("buttons.close")}
        content={
          <>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={styles.icon} />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              onChange={handleInputChange}
              placeholder={t("modules.groups.placeholder")}
              hiddenLabel
              fullWidth
              size="small"
              variant="outlined"
            />
            <List style={{ maxHeight: "300px", overflow: "auto" }}>
              {filteredDevices.map((device) => (
                <ListItem
                  key={`list-${device.id}-${device.serialNumber}`}
                  disablePadding
                  secondaryAction={
                    <div>
                      {isDeviceSelected(device.id) ? (
                        <CheckIcon
                          onClick={() => onDeviceClick(device.id)}
                          className={classNames(
                            styles.primaryIcon,
                            styles.listIcon
                          )}
                        />
                      ) : (
                        <AddIcon
                          onClick={() => onDeviceClick(device.id)}
                          className={styles.listIcon}
                        />
                      )}
                    </div>
                  }
                >
                  <ListItemButton onClick={() => onDeviceClick(device.id)}>
                    <ListItemText
                      primary={heaterCommonByDeviceId[device.id]?.machineName}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Typography variant="body2" color="textSecondary">
              {selectedDevices.length}{" "}
              {selectedDevices.length === 1
                ? t("modules.groups.deviceSingular")
                : t("modules.groups.devicePlural")}{" "}
              {t("modules.groups.selected")}
            </Typography>
          </>
        }
      />
    </>
  );
}

export default GroupCard;
