import BrowserUpdatedRoundedIcon from "@mui/icons-material/BrowserUpdatedRounded";
import { Box } from "@mui/material";
import EmptyList from "components/common/EmptyList";
import ModalWrapper from "components/common/ModalWrapper";
import PageHeader from "components/common/PageHeader";
import SoftwareUpdatesTable from "components/softwareUpdates/Table";
import { getFormatedDateForSearch } from "helpers";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import updatesSlice, {
  UPDATE_ACTIONS,
  getSoftwareUpdates,
} from "store/updates";
import useContentStyles from "./useContentStyles";

function SoftwareUpdates() {
  const dispatch = useDispatch();
  const styles = useContentStyles();
  const { t } = useTranslation();
  const updates = useSelector(getSoftwareUpdates);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    dispatch(updatesSlice.actions.init({ type: "softwareUpdates" }));
  }, [dispatch]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredUpdates = useMemo(
    () =>
      updates.filter(
        (update) =>
          update.key.toLowerCase().includes(searchValue.toLowerCase()) ||
          getFormatedDateForSearch(update.lastModified).includes(searchValue)
      ),
    [searchValue, updates]
  );

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setFile(null);
  }, []);

  const onAdd = useCallback(() => setIsModalOpen(true), []);

  const onAddUpdate = useCallback(() => {
    dispatch({
      type: UPDATE_ACTIONS.ADD_SOFTWARE_UPDATE,
      payload: {
        newFile: file,
        type: "softwareUpdates",
      },
    });
    onClose();
  }, [dispatch, file, onClose]);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  return (
    <Box className={styles.content}>
      <PageHeader
        title={t(`modules.softwareUpdates.title`)}
        addLabel={t(`modules.softwareUpdates.addNew`)}
        onAdd={onAdd}
        enableSearch
        searchPlaceholder={t(`modules.softwareUpdates.placeholder`)}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {updates.length > 0 && <SoftwareUpdatesTable updates={filteredUpdates} />}
      {updates.length === 0 && (
        <EmptyList
          icon={
            <BrowserUpdatedRoundedIcon
              color="disabled"
              style={{ fontSize: "150px" }}
            />
          }
          emptyListText={t(`modules.softwareUpdates.empty`)}
          onOpen={onAdd}
        />
      )}
      <ModalWrapper
        isOpen={isModalOpen}
        onCancelAction={onClose}
        onOkAction={onAddUpdate}
        title={t(`modals.softwareUpdates.addNewTitle`)}
        contentLabel=""
        content={<input type="file" onChange={onFileChange} />}
        okLabel={t("buttons.add")}
        isOkDisabled={!file}
        cancelLabel={t("buttons.close")}
      />
    </Box>
  );
}

export default SoftwareUpdates;
