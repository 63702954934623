import { push } from "@lagunovsky/redux-react-router";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import paths from "config/paths";
import { isEmpty, sortBy } from "lodash";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import { Language } from "models/Language";
import {
  Organization,
  OrganizationKind,
  OrganizationUnitWithDetails,
} from "models/Organization";
import { User } from "models/User";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import organizationsServices from "services/organizations";
import unitsSlice, {
  addOrgUnitAction,
  deleteOrgUnitAction,
  editOrgUnitAction,
  organizationUnitsByIdSelector,
  UNIT_ACTIONS,
} from "./organizationUnits";

export const ORGANIZATIONS_ACTIONS = {
  ADD_ORGANIZATION: "ADD_ORGANIZATION",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  EDIT_ORGANIZATION: "EDIT_ORGANIZATION",
  GET_ORG_DATA: "GET_ORG_DATA",
  GET_ORG_USERS: "GET_ORG_USERS",
};

type OrganizationState = {
  initializing: boolean;
  organizations: Record<number, Organization>;
  organizationUsers: Record<number, Record<number, User>>;
  error?: string;
};

const initialState: OrganizationState = {
  initializing: false,
  organizations: {},
  organizationUsers: {},
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<Organization[]>) => {
      state.organizations = mapKeys(payload, (org) => org.id);
      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    addOrgSuccess: (state, { payload }: PayloadAction<Organization>) => {
      state.organizations[payload.id] = {
        ...payload,
      };
    },
    deleteOrgSucces: (state, { payload }: PayloadAction<number>) => {
      state.organizations = omit(state.organizations, payload);
    },
    editOrganizationSucces: (
      state,
      {
        payload,
      }: PayloadAction<{
        orgId: number;
        label: string;
        defaultLanguage: Language;
        kind: OrganizationKind;
      }>
    ) => {
      const { orgId, label, defaultLanguage, kind } = payload;
      state.organizations[orgId] = {
        ...state.organizations[orgId],
        label,
        defaultLanguage,
        kind,
      };
    },
    setOrganizationsUsers: (
      state,
      {
        payload,
      }: PayloadAction<{
        orgId: number;
        organizationUsers: User[];
      }>
    ) => {
      const { orgId, organizationUsers } = payload;
      state.organizationUsers[orgId] = mapKeys(
        organizationUsers,
        (user) => user.id
      );
    },
  },
});

function organizationsSelector(state: {
  [organizationsSlice.name]: OrganizationState;
}) {
  return state[organizationsSlice.name];
}

export const organizationsByIdSelector = createSelector(
  organizationsSelector,
  (state: OrganizationState) => state.organizations
);

export const organizationUsersByIdSelector = createSelector(
  organizationsSelector,
  (state: OrganizationState) => state.organizationUsers
);

export const organizationsLoaded = createSelector(
  organizationsByIdSelector,
  (byId) => !isEmpty(byId)
);

export const organizationsListSelector = createSelector(
  organizationsByIdSelector,
  (orgsById) => Object.values(orgsById) || []
);

export const organizationNamesSelector = createSelector(
  organizationsListSelector,
  (orgs: Organization[]) => orgs.map((org) => org.label)
);

export const createOrgInitialFormValues = (id: number) =>
  createSelector(
    organizationsByIdSelector,
    organizationUnitsByIdSelector,
    (orgsById, unitsById) => {
      const org = orgsById[id] || {};
      const units = Object.values(unitsById[id] || {}) || [];

      return {
        label: org.label || "",
        defaultLanguage: org.defaultLanguage || "en",
        kind: org.kind || OrganizationKind.Unspecified,
        units: sortBy(units, "label")
          .filter((item) => item.id)
          .map((item) => ({
            ...item,
            alarmRuleCount: item.alarmRuleCount || 0,
            userCount: item.userCount || 0,
          })),
      };
    }
  );

export const createOrgDetailsByIdSelector = (orgId: number) =>
  createSelector(organizationsByIdSelector, (orgsById) => {
    const { label = "" } = orgsById[orgId] || {};

    return {
      label,
    };
  });

function* handleAddOrg(
  action: PayloadAction<{
    label: string;
    defaultLanguage: Language;
    kind: OrganizationKind;
    units: Partial<OrganizationUnitWithDetails>[];
  }>
): Generator {
  try {
    const { label, defaultLanguage, kind, units } = action.payload;
    const org = (yield call(organizationsServices.addOrganization, {
      label,
      defaultLanguage,
      kind,
    })) as Organization;

    const orgId = org.id;

    const addActions = units.map((unit) =>
      addOrgUnitAction({
        label: unit.label as string,
        orgId,
      })
    );

    yield all([...addActions]);
    yield put(organizationsSlice.actions.addOrgSuccess(org));
    yield put(push(paths.organizations));
  } catch (error) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddOrg() {
  yield takeLatest(ORGANIZATIONS_ACTIONS.ADD_ORGANIZATION, handleAddOrg);
}

function* handleDeleteOrg(action: PayloadAction<{ orgId: number }>): Generator {
  try {
    const { orgId } = action.payload;
    yield call(organizationsServices.deleteOrganization, orgId);

    yield put(organizationsSlice.actions.deleteOrgSucces(orgId));
    yield put(push(paths.organizations));
  } catch (error) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteOrg() {
  yield takeLatest(ORGANIZATIONS_ACTIONS.DELETE_ORGANIZATION, handleDeleteOrg);
}

function* handleEditOrganization(
  action: PayloadAction<{
    orgId: number;
    label: string;
    defaultLanguage: Language;
    kind: OrganizationKind;
    units: Partial<OrganizationUnitWithDetails>[];
  }>
): Generator {
  try {
    const { orgId, label, defaultLanguage, kind, units } = action.payload;
    const orgsById = (yield select(organizationsByIdSelector)) as Record<
      string,
      Organization
    >;

    const initialOrg = orgsById[orgId];
    const hasDiff =
      initialOrg.label !== label ||
      defaultLanguage !== initialOrg.defaultLanguage ||
      kind !== initialOrg.kind;

    if (hasDiff) {
      yield call(organizationsServices.editOrganization, orgId, {
        label,
        defaultLanguage,
        kind,
      });
      yield put(
        organizationsSlice.actions.editOrganizationSucces({
          orgId,
          label,
          defaultLanguage,
          kind,
        })
      );
    }

    const unitsById = (yield select(organizationUnitsByIdSelector)) as Record<
      string,
      Record<number, OrganizationUnitWithDetails>
    >;
    const orgInitialUnits = Object.values(unitsById[orgId] || []);

    const addedUnits = units.filter(
      (currentUnit: Partial<OrganizationUnitWithDetails>) =>
        !orgInitialUnits.some((unit) => unit.id === currentUnit.id)
    );

    const removedUnits = orgInitialUnits.filter(
      (unit) => !units.some((currentUnit) => unit.id === currentUnit.id)
    );

    const editedUnits = units.filter((currentUnit) =>
      orgInitialUnits.some(
        (unit) => unit.id === currentUnit.id && unit.label !== currentUnit.label
      )
    );

    const addActions = addedUnits.map((unit) =>
      addOrgUnitAction({
        label: unit.label as string,
        orgId,
      })
    );

    const deleteActins = removedUnits.map((unit) =>
      deleteOrgUnitAction({
        orgId,
        orgUnitId: unit.id,
      })
    );

    const editActions = editedUnits.map((unit) =>
      editOrgUnitAction({
        orgId,
        orgUnitId: unit.id as number,
        label: unit.label as string,
      })
    );

    yield all([...addActions, ...deleteActins, ...editActions]);
  } catch (error) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditOrganization() {
  yield takeLatest(
    ORGANIZATIONS_ACTIONS.EDIT_ORGANIZATION,
    handleEditOrganization
  );
}

function* handleGetOrgUsers(
  action: PayloadAction<{ orgId: number; unitId: number }>
): Generator {
  try {
    const { orgId, unitId } = action.payload;

    const { organizationUsers, unitUserIds } = (yield call(
      organizationsServices.getOrganizationUsers,
      orgId,
      unitId
    )) as {
      organizationUsers: User[];
      unitUserIds: number[];
    };
    yield put(
      organizationsSlice.actions.setOrganizationsUsers({
        orgId,
        organizationUsers,
      })
    );
    yield put(unitsSlice.actions.setUnitUserIds({ unitId, unitUserIds }));
  } catch (err) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetOrgUsers() {
  yield takeLatest(ORGANIZATIONS_ACTIONS.GET_ORG_USERS, handleGetOrgUsers);
}

function* handleGetOrgData(
  action: PayloadAction<{ orgId: number }>
): Generator {
  try {
    const { orgId } = action.payload;
    const isLoaded = (yield select(organizationsLoaded)) as boolean;

    if (!isLoaded) {
      yield put(organizationsSlice.actions.init());
    }

    yield put({
      type: UNIT_ACTIONS.GET_UNITS,
      payload: { orgId },
    });
  } catch (err) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchGetOrgData() {
  yield takeLatest(ORGANIZATIONS_ACTIONS.GET_ORG_DATA, handleGetOrgData);
}

function* handleInit(): Generator {
  try {
    const organizations = (yield call(
      organizationsServices.getOrganizations
    )) as Organization[];
    yield put(organizationsSlice.actions.initSuccess(organizations));
  } catch (err) {
    yield put(organizationsSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(organizationsSlice.actions.init, handleInit);
}

export function* organizationsWatcher() {
  yield all([
    fork(watchInit),
    fork(watchAddOrg),
    fork(watchDeleteOrg),
    fork(watchEditOrganization),
    fork(watchGetOrgUsers),
    fork(watchGetOrgData),
  ]);
}

export default organizationsSlice;
