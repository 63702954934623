import { Organization } from "models/Organization";

export type UserInfo = {
  username: string;
  password: string;
};

export enum UserRole {
  CloudAdmin = -1,
  NoAccess = 0,
  Operator = 10,
  Technician = 20,
  SuperUser = 30,
}

export const userRoleLabels: Record<UserRole, string> = {
  [UserRole.CloudAdmin]: "Cloud Admin",
  [UserRole.NoAccess]: "No Access",
  [UserRole.Operator]: "Operator",
  [UserRole.Technician]: "Technician",
  [UserRole.SuperUser]: "Super User",
};

export type User = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  accessLevel: UserRole;
  requiresPasswordReset: boolean;
  organizations?: Organization[];
};

export type UserCredentials = {
  accessToken: string | null;
  mqttUrl: string | null;
};
