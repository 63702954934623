import { Box, Button, TextField, Typography } from "@mui/material";
import CustomHidden from "components/common/CustomHidden";
import DashboardViewSelection from "components/dashboard/dashboardViewSelection/DashboardViewSelection";
import HeaterCard from "components/dashboard/HeaterCard";
import HeatersTable from "components/dashboard/HeatersTable";

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import authSlice from "store/auth";
import groupSlice from "store/group";
import heaterSlice, { heaterListSelector } from "store/heater";
import uiSlice, {
  getDashboardBatchUpdateMode,
  getDashboardView,
} from "store/ui";
import useContentStyles from "./useContentStyles";
import { isHeaterDataOld } from "../helpers";

function Dashboard() {
  const dispatch = useDispatch();
  const heaterList = useSelector(heaterListSelector);
  const viewType = useSelector(getDashboardView);
  const styles = useContentStyles();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const batchMode = useSelector(getDashboardBatchUpdateMode);

  const onCheckboxChange = useCallback(
    (deviceId: number) => {
      const isSelected = selectedRows.includes(deviceId);
      if (isSelected) {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((id) => id !== deviceId)
        );
      } else {
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, deviceId]);
      }
    },
    [selectedRows]
  );

  const onClearAll = useCallback(() => {
    setSelectedRows([]);
  }, []);

  const onSelectAll = useCallback(() => {
    if (selectedRows.length === heaterList.length) {
      onClearAll();
    } else {
      setSelectedRows(heaterList.map((heater) => heater.device.id));
    }
  }, [heaterList, onClearAll, selectedRows.length]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  useMount(() => {
    dispatch(authSlice.actions.setNavigateTo());
  });

  useEffect(() => {
    dispatch(heaterSlice.actions.init());
    dispatch(groupSlice.actions.init());
  }, [dispatch]);

  const filteredDevices = useMemo(
    () =>
      heaterList.filter((heater) =>
        heater.machineName.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [heaterList, searchValue]
  );

  const onOpen = useCallback(() => {
    if (batchMode === "init") {
      dispatch(uiSlice.actions.setBatchUpdateMode("count"));
    } else {
      dispatch(uiSlice.actions.setVisibleUpdatesDrawer(true));
    }
  }, [batchMode, dispatch]);

  return (
    <Box className={styles.content}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
        }}
      >
        <Typography variant="h4">{t("modules.dashboard.title")}</Typography>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginRight: "8px" }}
            variant="contained"
            color="primary"
            disabled={selectedRows.length === 0 && batchMode === "count"}
            onClick={onOpen}
          >
            {batchMode === "count"
              ? t("buttons.batchUpdateWithCount", {
                  count: selectedRows.length,
                })
              : t("buttons.batchUpdate")}
          </Button>
          <CustomHidden size="sm" hideType="down">
            <TextField
              style={{ marginRight: "20px" }}
              size="small"
              variant="outlined"
              placeholder={t("modules.devices.placeholder")}
              value={searchValue}
              onChange={onSearch}
            />
          </CustomHidden>

          <DashboardViewSelection />
        </div>
      </div>
      <CustomHidden size="sm" hideType="up">
        <TextField
          style={{ marginBottom: "10px" }}
          size="small"
          fullWidth
          variant="outlined"
          placeholder={t("modules.devices.placeholder")}
          value={searchValue}
          onChange={onSearch}
        />
      </CustomHidden>

      {viewType === "grid" ? (
        filteredDevices.map((heater) => (
          <HeaterCard
            key={heater.device.id}
            deviceId={heater.device.id}
            isConnected={heater.device.connected}
            isPotentiallyOffline={isHeaterDataOld(heater.lastDataUpdate)}
            model={heater.device.model}
            machineName={heater.machineName}
            softwareVersion={heater.softwareVersion}
            processState={heater.processState}
            targetRoomTemperature={heater.targetRoomTemperature}
            errorCount={heater.errorCount}
            warningCount={heater.warningCount}
          />
        ))
      ) : (
        <HeatersTable
          heaters={filteredDevices}
          remoteDeviceIds={selectedRows}
          onCheckboxChange={onCheckboxChange}
          onSelectAll={onSelectAll}
          onClearAll={onClearAll}
        />
      )}
    </Box>
  );
}

export default Dashboard;
