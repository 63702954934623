import {
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { getLanguageFlag } from "components/troubleshooting/helpers";
import { Language, allLanguages } from "models/Language";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  currentValue: Language;
  onChange: (event: SelectChangeEvent<string>) => void;
};

function LanguageSelectField({ name, currentValue, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <Select
      size="small"
      name={name}
      variant="outlined"
      fullWidth
      required
      value={currentValue}
      onChange={onChange}
      renderValue={(value: string) => t(`language.${value}`)}
    >
      {allLanguages.map((language) => (
        <MenuItem key={language} value={language}>
          <ListItemIcon>{getLanguageFlag(language)}</ListItemIcon>
          {t(`language.${language}`)}
        </MenuItem>
      ))}
    </Select>
  );
}

export default LanguageSelectField;
