import { SvgIcon, useTheme } from "@mui/material";
import { useMemo } from "react";

type Props = {
  type?: string;
};

function TableViewIcon({ type }: Props) {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (type) {
      case "active":
        return theme.palette.grey["900"];
      case "inactive":
        return theme.palette.grey["300"];
      default:
        return theme.palette.grey["900"];
    }
  }, [theme.palette.grey, type]);

  return (
    <SvgIcon
      viewBox="0 0 16 13"
      sx={{
        fill: "none",
      }}
    >
      <path
        d="M15.5 0.5H0.5V2.5H15.5V0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 5.5H0.5V7.5H15.5V5.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 10.5H0.5V12.5H15.5V10.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default TableViewIcon;
