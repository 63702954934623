import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import getComparator, { Order } from "components/common/tableHelpers";
import UpdateStatusIcon from "components/icons/UpdateStatusIcon";
import {
  defaultRowsPerPage,
  getFormatedDate,
  rowsPerPageOptions,
} from "helpers";
import { HeaterScheduledUpdatesInfo } from "models/Heater";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import ManageScheduledUpdatesMenu from "./ManageScheduledUpdatesMenu";

const useStyles = makeStyles(() => ({
  headCell: {
    color: "text.disabled",
    letterSpacing: "2px",
  },
}));

type OrderByTypes =
  | "softwareUpdateKey"
  | "factorySettingsKey"
  | "status"
  | "updatedAt";

type Props = {
  updates: HeaterScheduledUpdatesInfo[];
};

function ScheduledUpdatesTable({ updates }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("softwareUpdateKey");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "softwareUpdateKey" as OrderByTypes,
        label: t(
          "modules.dashboard.scheduledUpdates.softwareUpdate"
        ).toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "factorySettingsKey" as OrderByTypes,
        label: t(
          "modules.dashboard.scheduledUpdates.factorySettings"
        ).toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "status" as OrderByTypes,
        label: t(
          "modules.dashboard.scheduledUpdates.status.label"
        ).toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "updatedAt" as OrderByTypes,
        label: t("modules.dashboard.scheduledUpdates.updatedAt").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const mappedValues = useMemo(
    () =>
      updates.map((update) => ({
        ...update,
      })),
    [updates]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),
    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                className={headCell.className}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell width={20} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((update) => (
              <TableRow key={`row-${update.id}`}>
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">
                    {update.softwareUpdateKey}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {update.factorySettingsKey}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    <UpdateStatusIcon status={update.status} />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {getFormatedDate(update.updatedAt)}
                  </Typography>
                </TableCell>
                <TableCell align="right" width={20}>
                  {update.status === "pending" && (
                    <ManageScheduledUpdatesMenu updateId={update.id} />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={updates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default ScheduledUpdatesTable;
