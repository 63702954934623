import { Typography } from "@mui/material";
import DetailsHeader from "components/common/DetailsHeader";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createGuideDetailsByIdSelector } from "store/troubleshootingAdmin";

type Props = {
  guideId: number;
  onBack: () => void;
};

function EditGuideHeader({ guideId, onBack }: Props) {
  const { t } = useTranslation();
  const getDetails = useMemo(
    () => createGuideDetailsByIdSelector(guideId),
    [guideId]
  );
  const details = useSelector(getDetails);

  const title = guideId ? details.label : t("modules.troubleshooting.addNew");

  return (
    <DetailsHeader
      mainContent={
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Typography style={{ marginRight: "16px" }} variant="h4">
            {title}
          </Typography>
        </div>
      }
      onBack={onBack}
      path={t("modules.troubleshooting.title")}
      item={details.label}
      itemLabel={guideId ? "" : t("modules.troubleshooting.guideDetails")}
    />
  );
}

export default EditGuideHeader;
