import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";
import classNames from "classnames";
import { HeaterConnectivityStatus } from "../../models/Heater";

const useStyles = makeStyles((theme: any) => ({
  chip: {
    marginLeft: "5px",
    width: "83px",
  },
  chipConnected: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  chipPotentiallyDisconnected: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  chipDisconnected: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

type Props = {
  status: HeaterConnectivityStatus;
};

function ConnectivityStatusChip({
  status: { isConnected, isPotentiallyOffline },
}: Props) {
  const styles = useStyles();
  const label = !isConnected
    ? "OFFLINE"
    : isPotentiallyOffline
    ? "ONLINE?"
    : "ONLINE";

  return (
    <Chip
      label={label}
      color="primary"
      variant="outlined"
      className={classNames(styles.chip, {
        [styles.chipConnected]: isConnected && !isPotentiallyOffline,
        [styles.chipPotentiallyDisconnected]: isPotentiallyOffline,
        [styles.chipDisconnected]: !isConnected,
      })}
    />
  );
}

export default ConnectivityStatusChip;
