import { SvgIcon } from "@mui/material";

function BackIcon() {
  return (
    <SvgIcon viewBox="0 0 20 20" sx={{ fill: "none" }}>
      <g clipPath="url(#clip0_625_4682)">
        <path d="M12 6L8 10L12 14" stroke="#FF5200" />
      </g>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#FF5200" />
      <defs>
        <clipPath id="clip0_625_4682">
          <rect width="4" height="12" fill="white" transform="translate(8 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default BackIcon;
