import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControlLabel,
  TextField,
  Switch,
  Typography,
  Radio,
  Divider,
} from "@mui/material";
import {
  CalendarEventFormValues,
  RepeatOptions,
  RepetitionFrequencyType,
} from "models/Calendar";
import ParametersData from "models/Parameters";
import DayOption from "./DayOption";
import DateTime from "./DateTime";
import { dayOptions, frequencyTypeOptions, repeatOptions } from "./helpers";
import ModalSliderItem from "./ModalSliderItem";
import RHMSelect from "./RHMSelect";

type EditorTemplateProps = {
  values: CalendarEventFormValues;
  handleChange: (e: ChangeEvent<any>) => void;
  setFieldValue: any;
};

function EditorTemplate({
  values,
  handleChange,
  setFieldValue,
}: EditorTemplateProps) {
  const { t } = useTranslation();

  const onSliderValueChange = useCallback(
    (field: string, value: number) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const onRepeatOptionChange = useCallback(
    (value: RepeatOptions) => {
      setFieldValue("repeatOption", value);

      if (value === "daily") {
        setFieldValue("endsAfter", 99);
      }
      if (value === "workingDays") {
        setFieldValue("endsAfter", 99);
        setFieldValue("frequencyType", "weeks");
        setFieldValue("weekdays", [true, true, true, true, true, false, false]);
      }
    },
    [setFieldValue]
  );

  const onEndsTypeChange = useCallback(
    (value: string) => {
      setFieldValue("endType", value);
    },
    [setFieldValue]
  );

  const onDateValueChange = useCallback(
    (field: string, value: string) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const onFrequencyTypeChange = useCallback(
    (value: RepetitionFrequencyType) => {
      setFieldValue("frequencyType", value);
    },
    [setFieldValue]
  );

  const onInputFieldChange = useCallback(
    (field: string, value: string) => {
      setFieldValue(field, +value);
    },
    [setFieldValue]
  );

  const onDayOptionClick = useCallback(
    (index: number) => {
      const copy = values.weekdays ? [...values.weekdays] : [];

      if (copy[index]) {
        copy[index] = false;
      } else {
        copy[index] = true;
      }
      setFieldValue("weekdays", copy);
    },
    [setFieldValue, values.weekdays]
  );

  const isDayOptionSelected = useCallback(
    (index: number) => (values.weekdays || [])[index] === true,
    [values.weekdays]
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <DateTime
          name="from"
          label={t("modules.dashboard.calendar.startTime")}
          value={values.from}
          onChange={onDateValueChange}
          required
        />
      </Grid>

      <Grid item xs={4}>
        <DateTime
          name="to"
          label={t("modules.dashboard.calendar.endTime")}
          value={values.to}
          onChange={onDateValueChange}
          required
        />
      </Grid>

      {values.id === 0 && (
        <Grid item xs={4}>
          <RHMSelect
            name="repeatOption"
            label=" "
            value={values.repeatOption}
            onChange={onRepeatOptionChange}
            options={repeatOptions}
          />
        </Grid>
      )}

      <Grid item xs={12} />

      {values.repeatOption === "custom" && (
        <>
          <Grid item xs={2}>
            <Typography>
              {t("modules.dashboard.calendar.repetition.frequency")}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {t("modules.dashboard.calendar.repetition.repeatEvery")} &nbsp;
            </Typography>
            <TextField
              name="frequency"
              type="number"
              variant="standard"
              defaultValue={values.frequency}
              style={{ width: "20px" }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onInputFieldChange("frequency", event.target.value)
              }
              inputProps={{
                min: 1,
              }}
            />
            <Typography>
              {values.frequencyType === "weeks"
                ? t("modules.dashboard.calendar.repetition.weeks")
                : t("modules.dashboard.calendar.repetition.days")}
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={2}>
            <Typography>
              {t("modules.dashboard.calendar.repetition.repeatOn")}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <RHMSelect
              name="frequencyType"
              value={values.frequencyType}
              onChange={onFrequencyTypeChange}
              options={frequencyTypeOptions}
            />
          </Grid>

          {values.frequencyType === "weeks" && (
            <Grid item xs={6}>
              {dayOptions.map((day) => (
                <DayOption
                  key={day.value}
                  label={day.label}
                  value={day.value}
                  selected={isDayOptionSelected(day.value)}
                  onClick={onDayOptionClick}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={12} />

          <Grid item xs={2}>
            <Typography>
              {t("modules.dashboard.calendar.repetition.ends")}
            </Typography>
          </Grid>

          <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
            <Radio
              style={{ paddingLeft: 0 }}
              color="primary"
              checked={values.endType === "endsAfter"}
              onChange={() => onEndsTypeChange("endsAfter")}
            />
            <Typography>
              {t("modules.dashboard.calendar.repetition.after")}
              &nbsp;
            </Typography>
            <TextField
              name="endsAfter"
              type="number"
              variant="standard"
              defaultValue={values.endsAfter}
              style={{ width: "20px" }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onInputFieldChange("endsAfter", event.target.value)
              }
              disabled={values.endType !== "endsAfter"}
              inputProps={{
                min: 2,
                max: 99,
              }}
            />
            <Typography>
              &nbsp; {t("modules.dashboard.calendar.repetition.occurrences")}
            </Typography>
          </Grid>

          <Grid item xs={2} />
          <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
            <Radio
              style={{ paddingLeft: 0 }}
              color="primary"
              checked={values.endType === "endsOn"}
              onChange={() => onEndsTypeChange("endsOn")}
            />
            <Typography>
              {t("modules.dashboard.calendar.repetition.on")}
              &nbsp;
            </Typography>

            <DateTime
              name="endsOn"
              value={values.endsOn}
              onChange={onDateValueChange}
              disabled={values.endType !== "endsOn"}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} />
      <ModalSliderItem
        label={t("modules.dashboard.grid.parameters.targetRoomTemperature")}
        min={ParametersData.TargetRoomTemperature.min}
        max={ParametersData.TargetRoomTemperature.max}
        defaultValue={values.targetRoomTemperature}
        onValueChange={(value) =>
          onSliderValueChange("targetRoomTemperature", value)
        }
      />

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              name="modeHotAirFan"
              color="primary"
              checked={values.modeHotAirFan}
              onChange={handleChange}
            />
          }
          label={t("modules.dashboard.calendar.hotAirFan")}
          labelPlacement="end"
        />
      </Grid>

      <ModalSliderItem
        label={t("modules.dashboard.grid.parameters.targetHotAirTemperature")}
        min={ParametersData.TargetHotAirTemperature.min}
        max={ParametersData.TargetHotAirTemperature.max}
        defaultValue={values.targetHotAirTemperature}
        onValueChange={(value) =>
          onSliderValueChange("targetHotAirTemperature", value)
        }
      />

      <ModalSliderItem
        label={t("modules.dashboard.grid.parameters.targetHotAirFanPower")}
        min={ParametersData.HotAirFanPower.min}
        max={ParametersData.HotAirFanPower.max}
        defaultValue={values.targetPowerHotAirFan}
        onValueChange={(value) =>
          onSliderValueChange("targetPowerHotAirFan", value)
        }
      />
      <br />
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              name="additionalCooling"
              color="primary"
              checked={values.additionalCooling}
              onChange={handleChange}
            />
          }
          label={t("modules.dashboard.calendar.additionalCooling")}
          labelPlacement="end"
        />
      </Grid>
      <Grid item xs={12} />
      {values.id !== 0 && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="updateAll"
                  color="primary"
                  checked={values.updateAll}
                  onChange={handleChange}
                />
              }
              label={t("modules.dashboard.calendar.updateAll")}
              labelPlacement="end"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default EditorTemplate;
