import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import sortBy from "lodash/sortBy";
import { HeaterErrorOrWarningInfo } from "models/Heater";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import dashboardSlice from "store/dashboard";
import heaterSlice from "store/heater";
import { MQTT_ACTIONS } from "store/mqtt";

type ErrorState = {
  errors: HeaterErrorOrWarningInfo[];
};

const initialState: ErrorState = {
  errors: [],
};

const dashboardErrorSlice = createSlice({
  name: "dashboardError",
  initialState,
  reducers: {
    loadErrors: (
      state,
      { payload }: PayloadAction<HeaterErrorOrWarningInfo[]>
    ) => {
      state.errors = payload;
    },
  },
});

function dashboardErrorSelector(state: {
  [dashboardErrorSlice.name]: ErrorState;
}) {
  return state[dashboardErrorSlice.name];
}

export const dashboardSortedErors = createSelector(
  dashboardErrorSelector,
  (state) => sortBy(state?.errors, "timeOn").reverse()
);

function* handleUpdateErrors(
  action: PayloadAction<{
    serialNumber: string;
    errors: HeaterErrorOrWarningInfo[];
  }>
): Generator {
  try {
    const { serialNumber, errors } = action.payload;
    yield put(dashboardErrorSlice.actions.loadErrors(errors));
    yield put(
      heaterSlice.actions.updateErrorCount({
        serialNumber,
        errorCount: errors.length,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchUpdateErrors() {
  yield takeLatest(MQTT_ACTIONS.UPDATE_ERRORS, handleUpdateErrors);
}

export function* dashboardErrorWatcher() {
  yield all([fork(watchUpdateErrors)]);
}

export default dashboardErrorSlice;
