import { Tab, Tabs } from "@mui/material";
import TabPanel from "components/common/TabsPanel";
import DeviceDashboardHeader from "components/heaterDetails/DeviceDashboardHeader";
import CalendarTab from "components/heaterDetails/tabs/CalendarTab";
import DocumentationTab from "components/heaterDetails/tabs/DocumentationTab";
import GeneralTab from "components/heaterDetails/tabs/GeneralTab";
import PanelTab from "components/heaterDetails/tabs/PanelTab";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useUnmount } from "react-use";
import dashboardSlice, { DASHBOARD_ACTIONS } from "store/dashboard";
import ErrorHistoryTab from "../components/heaterDetails/tabs/ErrorHistoryTab";
import ScheduledUpdatesTab from "../components/heaterDetails/tabs/ScheduledUpdatesTab";
import useTabStyles from "./useTabStyles";

const HeaterDetailsTabs = {
  General: 0,
  Calendar: 1,
  Guacamole: 2,
  Documentation: 3,
  ErrorHistory: 4,
  ScheduledUpdates: 5,
};

function HeaterDetails() {
  const { heaterId } = useParams();
  const styles = useTabStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = heaterId ? +heaterId : 0;
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (heaterId) {
      dispatch(dashboardSlice.actions.init(+heaterId));
    }
  }, [dispatch, heaterId]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  }, [ref]);

  useUnmount(() => dispatch({ type: DASHBOARD_ACTIONS.RESET }));

  const initGuacamole = useCallback(() => {
    dispatch({
      type: DASHBOARD_ACTIONS.GET_GUACAMOLE,
      payload: id,
    });
  }, [dispatch, id]);

  const handleChange = useCallback(
    (_: unknown, newValue: any) => {
      setValue(newValue);
      if (newValue === HeaterDetailsTabs.Guacamole) {
        initGuacamole();
      }
    },
    [initGuacamole]
  );

  const tabsClasses = {
    root: styles.tabsRoot,
    indicator: styles.indicator,
  };

  const tabClasses = {
    root: styles.tabRoot,
    selected: styles.selected,
  };

  return (
    <div ref={ref}>
      <DeviceDashboardHeader deviceId={id} />
      <Tabs
        value={value}
        textColor="primary"
        classes={tabsClasses}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
      >
        <Tab classes={tabClasses} label={t("modules.dashboard.tabs.general")} />
        <Tab
          classes={tabClasses}
          label={t("modules.dashboard.tabs.calendar")}
        />
        <Tab classes={tabClasses} label={t("modules.dashboard.tabs.panel")} />
        <Tab
          classes={tabClasses}
          label={t("modules.dashboard.tabs.documentation")}
        />
        <Tab
          classes={tabClasses}
          label={t("modules.dashboard.tabs.errorHistory")}
        />
        <Tab
          classes={tabClasses}
          label={t("modules.dashboard.tabs.scheduledUpdates")}
        />
      </Tabs>
      <TabPanel value={value} index={HeaterDetailsTabs.General} hasBackground>
        <GeneralTab id={id} />
      </TabPanel>
      <TabPanel value={value} index={HeaterDetailsTabs.Calendar} hasBackground>
        <CalendarTab deviceId={id} />
      </TabPanel>
      <TabPanel value={value} index={HeaterDetailsTabs.Guacamole}>
        <PanelTab initGuacamole={initGuacamole} />
      </TabPanel>
      <TabPanel
        value={value}
        index={HeaterDetailsTabs.Documentation}
        hasBackground
      >
        <DocumentationTab {...{ id }} />
      </TabPanel>
      <TabPanel
        value={value}
        index={HeaterDetailsTabs.ErrorHistory}
        hasBackground
      >
        <ErrorHistoryTab deviceId={id} />
      </TabPanel>
      <TabPanel
        value={value}
        index={HeaterDetailsTabs.ScheduledUpdates}
        hasBackground
      >
        <ScheduledUpdatesTab deviceId={id} />
      </TabPanel>
    </div>
  );
}

export default HeaterDetails;
