import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function MenuIcon({ className = "" }: Props) {
  return (
    <SvgIcon className={className}>
      <line y1="7" x2="16" y2="7" stroke="#CC4200" strokeWidth="2" />
      <line y1="13" x2="8" y2="13" stroke="#CC4200" strokeWidth="2" />
      <line y1="1" x2="20" y2="1" stroke="#CC4200" strokeWidth="2" />
    </SvgIcon>
  );
}

export default MenuIcon;
