import {
  FormControlLabel,
  Grid,
  TextField,
  Switch,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "components/DashboardGrid";
import PasswordField, { passwordRegex } from "components/fields/PasswordField";
import { getFirstLetterToUpperCase, trimString } from "helpers";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { emailRegex } from "store/users";

const useStyles = makeStyles((theme: any) => ({
  gridLabel: {
    fontSize: "14px",
    color: theme.palette.text.disabled,
    marginBottom: "8px !important",
  },
}));

type Prop = {
  isNew: boolean;
  handleChange: (e: ChangeEvent<any>) => void;
  currentFirstName: string;
  currentLastName: string;
  currentUsername: string;
  currentPassword: string;
  currentRequirePasswordReset: boolean;
  showRequirePasswordReset: boolean;
  setFieldValue: any;
};

function GeneralInformationGrid({
  isNew,
  handleChange,
  currentFirstName,
  currentLastName,
  currentPassword,
  currentUsername,
  currentRequirePasswordReset,
  showRequirePasswordReset,
  setFieldValue,
}: Prop) {
  const { t } = useTranslation();
  const styles = useStyles();

  const isPasswordValid = useMemo(() => {
    if (!currentPassword) {
      return true;
    }
    return passwordRegex.test(currentPassword);
  }, [currentPassword]);

  const passwordHelperText = useMemo(
    () => (isPasswordValid ? "" : t("textFields.invalidPassword")),
    [isPasswordValid, t]
  );

  const isMailValid = useMemo(() => {
    if (!currentUsername) {
      return true;
    }
    return emailRegex.test(currentUsername);
  }, [currentUsername]);

  const mailHelperText = useMemo(
    () => (isMailValid ? "" : t("textFields.invalidUsername")),
    [isMailValid, t]
  );

  const onBlur = useCallback(
    (property: string, value: string) => {
      setFieldValue(property, trimString(value));
    },
    [setFieldValue]
  );

  return (
    <DashboardGrid
      title={t("modules.users.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.users.grid.generalInformation.firstName")
              )}
            </Typography>
            <TextField
              size="small"
              name="firstName"
              variant="outlined"
              fullWidth
              value={currentFirstName}
              onChange={handleChange}
              onBlur={() => onBlur("firstName", currentFirstName)}
              required
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.users.grid.generalInformation.lastName")
              )}
            </Typography>
            <TextField
              size="small"
              name="lastName"
              variant="outlined"
              fullWidth
              value={currentLastName}
              onBlur={() => onBlur("lastName", currentLastName)}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid md={4} />

          <Grid item xs={6} md={4}>
            <Typography className={styles.gridLabel}>
              {getFirstLetterToUpperCase(
                t("modules.users.grid.generalInformation.email")
              )}
            </Typography>
            <TextField
              size="small"
              name="username"
              variant="outlined"
              fullWidth
              error={!isMailValid}
              helperText={mailHelperText}
              value={currentUsername}
              onChange={handleChange}
              onBlur={() => onBlur("username", currentUsername)}
              required
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className={styles.gridLabel}>
              {isNew
                ? getFirstLetterToUpperCase(
                    t("modules.users.grid.generalInformation.passwordInitial")
                  )
                : getFirstLetterToUpperCase(
                    t("modules.users.grid.generalInformation.password")
                  )}
            </Typography>

            <PasswordField
              name="password"
              size="small"
              placeholder=""
              value={currentPassword}
              onChange={handleChange}
              required={isNew}
              error={!isPasswordValid}
              helperText={passwordHelperText}
            />
          </Grid>

          {showRequirePasswordReset && (
            <Grid item xs={6} md={6}>
              <FormControlLabel
                name="requiresPasswordReset"
                control={
                  <Switch
                    color="primary"
                    checked={currentRequirePasswordReset}
                    onChange={handleChange}
                  />
                }
                label={getFirstLetterToUpperCase(
                  t(
                    "modules.users.grid.generalInformation.requiresPasswordReset"
                  )
                )}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

export default GeneralInformationGrid;
