import { Alert, Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BrowserUpdatedRoundedIcon from "@mui/icons-material/BrowserUpdatedRounded";
import EmptyList from "components/common/EmptyList";
import ModalWrapper from "components/common/ModalWrapper";
import PageHeader from "components/common/PageHeader";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getFactorySettingsTypes,
  UPDATE_ACTIONS,
  factorySettingTypeLabelsSelector,
} from "store/updates";
import FactorySettingTypesTable from "components/factorySettingTypes/Table";
import { trimString } from "helpers";
import useContentStyles from "./useContentStyles";

const useStyles = makeStyles(() => ({
  alert: {
    margin: "10px 0",
  },
}));

function FactorySettingTypes() {
  const contentStyles = useContentStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = useSelector(getFactorySettingsTypes);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const labels = useSelector(factorySettingTypeLabelsSelector);
  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    dispatch({
      type: UPDATE_ACTIONS.INIT_FACTORY_SETTING_TYPE,
    });
  }, [dispatch]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredItems = useMemo(
    () =>
      items.filter((item) =>
        item.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [items, searchValue]
  );

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setLabel("");
  }, []);

  const onAdd = useCallback(() => setIsModalOpen(true), []);

  const onAddType = useCallback(() => {
    dispatch({
      type: UPDATE_ACTIONS.ADD_FACTORY_SETTING_TYPE,
      payload: {
        label,
      },
    });
    onClose();
  }, [dispatch, label, onClose]);

  const isLabelInvalid = useMemo(() => label.trim().length === 0, [label]);

  const labelAlreadyExists = useMemo(
    () => labels.includes(trimString(label).toLocaleLowerCase()),
    [label, labels]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLabel(event.target.value);
    },
    []
  );

  return (
    <Box className={contentStyles.content}>
      <PageHeader
        title={t(`modules.factorySettingType.title`)}
        addLabel={t(`modules.factorySettingType.addNew`)}
        onAdd={onAdd}
        enableSearch
        searchPlaceholder={t(`modules.factorySettingType.placeholder`)}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {items.length > 0 && <FactorySettingTypesTable items={filteredItems} />}
      {items.length === 0 && (
        <EmptyList
          icon={
            <BrowserUpdatedRoundedIcon
              color="disabled"
              style={{ fontSize: "150px" }}
            />
          }
          emptyListText={t(`modules.factorySettingType.empty`)}
          onOpen={onAdd}
        />
      )}
      <ModalWrapper
        isOpen={isModalOpen}
        onCancelAction={onClose}
        onOkAction={onAddType}
        title={t(`modals.factorySettingType.addNewTitle`)}
        contentLabel={t("modals.factorySettingType.addNewSubtitle")}
        content={
          <>
            <Typography variant="subtitle2">
              {t("modals.factorySettingType.label")}
            </Typography>
            <TextField
              name="label"
              placeholder={t("modals.factorySettingType.labelPlaceholder")}
              variant="outlined"
              value={label}
              onChange={handleInputChange}
              hiddenLabel
              required
              fullWidth
            />
            <br />
            {labelAlreadyExists && (
              <Alert
                className={styles.alert}
                variant="outlined"
                severity="info"
                color="warning"
              >
                <Typography>
                  {t("modals.factorySettingType.labelExists")}
                </Typography>
              </Alert>
            )}
          </>
        }
        okLabel={t("buttons.add")}
        isOkDisabled={!label || isLabelInvalid || labelAlreadyExists}
        cancelLabel={t("buttons.close")}
      />
    </Box>
  );
}

export default FactorySettingTypes;
