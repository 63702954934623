import { useTranslation } from "react-i18next";
import CertificateGrid from "./CertificateGrid";

type Prop = {
  deviceId: number;
};

function MqttCertificateGrid({ deviceId }: Prop) {
  const { t } = useTranslation();

  return (
    <CertificateGrid
      deviceId={deviceId}
      certType="iot_core"
      title={t("modules.devices.grid.certificate.mqtt")}
      editLabel={t("modules.devices.grid.certificate.editMqtt")}
      addLabel={t("modules.devices.grid.certificate.createMqtt")}
    />
  );
}

export default MqttCertificateGrid;
