import ParameterGridItem from "components/heaterDetails/parameters/ParameterGridItem";
import { t } from "i18next";
import ParametersData, { ParametersType } from "models/Parameters";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createParamActivitySelector,
  dashboardItemSerialNumber,
  enableDashboardActionsSelector,
} from "store/dashboard";
import dashboardParametersSlice, {
  createIsParameterUpdatingSelector,
  createParameterCurrentUpdateHasErrorSelector,
  dashboardTargetRoomTemperature,
} from "store/dashboardParameters";

type Props = {
  onParameterClick: (value: number, type: ParametersType) => void;
};

function TargetRoomTemperatureItem({ onParameterClick }: Props) {
  const dispatch = useDispatch();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const enableDashboardActions = useSelector(enableDashboardActionsSelector);
  const targetRoomTemperature = useSelector(dashboardTargetRoomTemperature);
  const { id } = ParametersData.TargetRoomTemperature;

  const getIsParamUpdating = useMemo(
    () => createIsParameterUpdatingSelector(serialNumber, id),
    [id, serialNumber]
  );
  const getActivity = useMemo(() => createParamActivitySelector(id), [id]);

  const getHasParameterError = useMemo(
    () => createParameterCurrentUpdateHasErrorSelector(serialNumber, id),
    [id, serialNumber]
  );

  const isParamUpdating = useSelector(getIsParamUpdating);
  const activity = useSelector(getActivity);
  const parameterUpdateError = useSelector(getHasParameterError);

  const onTargerRoomTemperatureSet = useCallback(
    (value: number) => onParameterClick(value, "TargetRoomTemperature"),
    [onParameterClick]
  );

  const onValueChange = useCallback(
    (value: number) => {
      dispatch(
        dashboardParametersSlice.actions.setTargetRoomTemperature(value)
      );
    },
    [dispatch]
  );

  return (
    <ParameterGridItem
      title={t("modules.dashboard.grid.parameters.targetRoomTemperature")}
      inputLabel={ParametersData.TargetRoomTemperature.unit}
      value={targetRoomTemperature}
      onValueChange={onValueChange}
      min={ParametersData.TargetRoomTemperature.min}
      max={ParametersData.TargetRoomTemperature.max}
      activity={activity}
      onClick={onTargerRoomTemperatureSet}
      actionDisabled={!enableDashboardActions}
      actionInProgress={isParamUpdating}
      actionFailed={parameterUpdateError}
    />
  );
}

export default TargetRoomTemperatureItem;
