import { SvgIcon } from "@mui/material";

function LargeGroupIcon() {
  return (
    <SvgIcon
      viewBox="0 0 156 156"
      sx={{ fill: "none", width: "156px", height: "156px" }}
    >
      <path
        d="M121.818 45.705C123.835 56.1361 123.264 66.903 120.153 77.0619C117.042 87.2208 111.487 96.4616 103.974 103.974C96.4617 111.487 87.2209 117.042 77.062 120.153C66.9031 123.264 56.1362 123.835 45.7051 121.818C49.5493 130.27 55.3844 137.666 62.711 143.371C70.0375 149.076 78.6373 152.921 87.7743 154.577C96.9114 156.233 106.314 155.65 115.176 152.879C124.039 150.108 132.098 145.23 138.664 138.664C145.231 132.098 150.108 124.039 152.879 115.176C155.651 106.314 156.233 96.9113 154.577 87.7743C152.921 78.6372 149.076 70.0374 143.371 62.7109C137.666 55.3844 130.27 49.5493 121.818 45.705Z"
        fill="#C7C7D1"
      />
      <path
        d="M58 115.5C89.7564 115.5 115.5 89.7564 115.5 58C115.5 26.2436 89.7564 0.5 58 0.5C26.2436 0.5 0.5 26.2436 0.5 58C0.5 89.7564 26.2436 115.5 58 115.5Z"
        fill="#C7C7D1"
      />
    </SvgIcon>
  );
}

export default LargeGroupIcon;
