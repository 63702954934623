import { Box, Button } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { useTranslation } from "react-i18next";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import troubleshootingSlice, { guidesSelector } from "store/troubleshooting";
import { getLanguage } from "store/ui";
import PdfViewer from "components/common/PdfViewer";
import paths from "config/paths";
import { isNull, isUndefined, last, omitBy } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";
import { TroubleshootingGuide } from "models/TroubleshootingGuide";

type Props = {
  id: number;
};

function DocumentationTab({ id }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const guides = useSelector(guidesSelector) ?? [];
  const [selectedGuide, setSelectedGuide] =
    useState<TroubleshootingGuide | null>(null);

  useEffect(() => {
    dispatch(
      troubleshootingSlice.actions.init({
        deviceId: id,
        language,
      })
    );
  }, [dispatch, language, id]);

  const generateLabel = (guide: TroubleshootingGuide): string => {
    let label = t(`modules.troubleshooting.tabs.${guide.type}`);
    label += t(`modules.dashboard.documentation.label.${guide.type}`);
    if (!isNull(guide.code)) {
      label += `${t("modules.dashboard.documentation.label.code")} ${
        guide.code
      }`;
    }
    return label;
  };

  const onLinkClick = useCallback(
    (event: any, href: string) => {
      if (!href.includes(paths.troubleshootingDeeplink)) {
        return;
      }
      event?.stopPropagation();
      event?.nativeEvent.stopImmediatePropagation();
      event?.preventDefault();
      const url = new URL(href);
      const searchPage = Number(url.searchParams.get("page"));
      const guideType = last(url.pathname.split("/"));

      const params: any = {
        tab: guideType,
        page: searchPage,
      };

      navigate({
        pathname: `/${paths.troubleshooting}`,
        search: `?${createSearchParams(omitBy(params, isUndefined))}`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <DashboardGrid
        title={t("modules.dashboard.tabs.documentation")}
        includeBottomDivider
        content={
          <Box sx={{ width: { xs: "100%", sm: "65%", md: "55%" } }}>
            <Box sx={{ typography: "body2", color: "text.disabled", mb: 2.5 }}>
              {t("modules.dashboard.documentation.info")}
            </Box>
            {guides.map((guide) => (
              <Button
                key={`documentation-tab-${guide.type}`}
                onClick={() => {
                  setSelectedGuide(null);
                  setSelectedGuide(guide);
                }}
                variant="outlined"
                fullWidth
                sx={{
                  border: 1,
                  borderColor: "primary.main",
                  textTransform: "capitalize",
                  fontSize: "body1.fontSize",
                  justifyContent: "flex-start",
                  color: "black",
                  mb: 1,
                }}
                startIcon={
                  <InsertDriveFileOutlinedIcon
                    sx={{ color: "primary.main", mr: 1, fontSize: "large" }}
                  />
                }
              >
                {generateLabel(guide)}
              </Button>
            ))}
          </Box>
        }
      />
      {selectedGuide?.s3Key && (
        <PdfViewer
          key={`documentation-tab-pdf-${selectedGuide.type}`}
          file={selectedGuide.s3Key}
          {...{ onLinkClick }}
        />
      )}
    </>
  );
}

export default DocumentationTab;
