import { Typography } from "@mui/material";

function ItemCell({ label, value, width = "60%", variant = "body2" }: any) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width,
        maxWidth: "200px",
      }}
    >
      <Typography variant={variant}>{label}</Typography>
      <Typography variant={variant}>{value}</Typography>
    </div>
  );
}

export default ItemCell;
