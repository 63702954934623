import { Group } from "models/Group";
import api from "./api";

const getGroups = async () => {
  const response = await api.get<Group[]>("/remote_device_groups/all_members");

  return response.data;
};

const addGroup = async (label: string) => {
  const response = await api.post<Group>("/remote_device_groups", {
    label,
  });

  return response.data;
};

const deleteGroup = async (groupId: number) => {
  const response = await api.delete(`/remote_device_groups/${groupId}`);

  return response.data;
};

const editGroup = async (groupId: number, label: string) => {
  const response = await api.put<Group>(`/remote_device_groups/${groupId}`, {
    label,
  });

  return response.data;
};

const updateGroupMembership = async (
  groupId: number,
  add: number[],
  remove: number[]
) => {
  const response = await api.put<Group>(
    `/remote_device_groups/${groupId}/members`,
    {
      add,
      remove,
    }
  );
  return response.data;
};

const applyParameterChangesToGroup = async (
  groupId: number,
  paramId: number,
  value: string
) => {
  const response = await api.put<Group>(
    `/remote_device_groups/${groupId}/data/${paramId}/remote`,
    {
      value,
    }
  );
  return response;
};

export default {
  getGroups,
  addGroup,
  deleteGroup,
  editGroup,
  updateGroupMembership,
  applyParameterChangesToGroup,
};
