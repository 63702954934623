import { makeStyles } from "@mui/styles";
import { useCallback, useState, type MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalWrapper from "components/common/ModalWrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GROUP_ACTIONS } from "store/group";
import useDeleteModal from "hooks/useDeleteModal";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
}));

type Props = {
  groupId: number;
  deviceId: number;
};

function ManageGroupDeviceMenu({ groupId, deviceId }: Props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onDeleteGroup = useCallback(() => {
    dispatch({
      type: GROUP_ACTIONS.UPDATE_GROUP_MEMBERS,
      payload: {
        groupId,
        add: [],
        remove: [deviceId],
      },
    });
    onCloseDeleteModal();
  }, [deviceId, dispatch, groupId, onCloseDeleteModal]);

  const onViewDetails = useCallback(() => {
    navigate(`/dashboard/${deviceId}`);
  }, [deviceId, navigate]);

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onViewDetails}>
          <Typography>{t("modals.groups.viewDeviceDetails")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenDeleteModal();
            handleClose();
          }}
        >
          <Typography color="error">{t("modals.groups.deleteItem")}</Typography>
        </MenuItem>
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDeleteGroup}
        title={t("modals.groups.deleteItem")}
        contentLabel={t("modals.groups.deleteItemSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default ManageGroupDeviceMenu;
