import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import Alert from "@mui/material/Alert";

import { Grid, Paper, Typography, Button, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  AUTH_ACTIONS,
  refreshPasswordErrorSelector,
  userRequiresPasswordReset,
} from "store/auth";
import { UserInfo } from "models/User";
import Footer from "components/common/Footer";
import Logo from "components/Logo";
import PasswordField, { passwordRegex } from "components/fields/PasswordField";
import { useUnmount } from "react-use";
import CustomHidden from "components/common/CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: "inherit",
    paddingTop: "2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
    [`${theme.breakpoints.only("sm")} and (orientation : landscape)`]: {
      paddingTop: "20px",
    },
  },
  paper: {
    [theme.breakpoints.up("xs")]: {
      width: "416px",
      padding: "48px 32px",
      margin: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      height: "100vh",
      width: "100vw",
    },
  },
  logo: {
    [theme.breakpoints.only("sm")]: {
      paddingBottom: "40px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "80px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 64px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 44px",
    },
  },
  footerXs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "calc(100vh - 517px)",
  },
}));

type ResetPasswordData = Pick<UserInfo, "password"> & {
  confirmPassword: string;
};

const defaultValues: ResetPasswordData = {
  password: "",
  confirmPassword: "",
};

function ResetPassword() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const requiresPasswordReset = useSelector(userRequiresPasswordReset);
  const [resetPasswordData, setResetPasswordData] = useState(defaultValues);
  const theme = useTheme();
  const errorCode = useSelector(refreshPasswordErrorSelector);

  const { t } = useTranslation();

  useEffect(() => {
    if (requiresPasswordReset) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  });

  useUnmount(() => {
    window.onbeforeunload = null;
  });

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setResetPasswordData({
        ...resetPasswordData,
        [name]: value,
      });
    },
    [resetPasswordData]
  );

  const isPasswordValid = useMemo(() => {
    if (!resetPasswordData.password) {
      return true;
    }
    return passwordRegex.test(resetPasswordData.password);
  }, [resetPasswordData.password]);

  const isValidInputs = useMemo(() => {
    const { password, confirmPassword } = resetPasswordData;
    if (confirmPassword === "") {
      return true;
    }

    return password === confirmPassword;
  }, [resetPasswordData]);

  const disableSubmit =
    !resetPasswordData.password ||
    !resetPasswordData.confirmPassword ||
    !isValidInputs;

  const passwordHelperText = useMemo(
    () => (isPasswordValid ? "" : t("textFields.invalidPassword")),
    [isPasswordValid, t]
  );

  const confrimPasswordHelperText = useMemo(
    () => (isValidInputs ? "" : t("textFields.passwordMisMatch")),
    [isValidInputs, t]
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch({
        type: AUTH_ACTIONS.REFRESH_PASSWORD,
        payload: resetPasswordData.password,
      });
    },
    [dispatch, resetPasswordData.password]
  );

  return (
    <div className={styles.container}>
      <Paper className={styles.paper}>
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item className={styles.logo}>
              <Logo />
            </Grid>
            <Grid item>
              <Typography variant="h4">{t("resetPassword")}</Typography>
            </Grid>
            <Grid item>
              <PasswordField
                name="password"
                placeholder={t("textFields.password")}
                error={!isPasswordValid}
                value={resetPasswordData.password}
                onChange={handleInputChange}
                helperText={passwordHelperText}
              />
            </Grid>
            <Grid item>
              <PasswordField
                name="confirmPassword"
                placeholder={t("textFields.confirmPassword")}
                value={resetPasswordData.confirmPassword}
                onChange={handleInputChange}
                error={!isValidInputs}
                helperText={confrimPasswordHelperText}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={disableSubmit}
              >
                {t("buttons.reset")}
              </Button>
            </Grid>
            <Grid item>
              {errorCode && (
                <Alert
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.error.main,
                  }}
                  icon={false}
                  variant="outlined"
                  severity="error"
                >
                  <Typography>
                    {t(`apiErrors.refreshPassword.${errorCode}`)}
                  </Typography>
                </Alert>
              )}
            </Grid>
          </Grid>
        </form>
        <CustomHidden size="sm" hideType="up">
          <div className={styles.footerXs}>
            <Footer />
          </div>
        </CustomHidden>
      </Paper>
      <CustomHidden size="sm" hideType="down">
        <div className={styles.footer}>
          <Footer />
        </div>
      </CustomHidden>
    </div>
  );
}

export default ResetPassword;
