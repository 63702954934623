import { AccountCircle, Check } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { Language, allLanguages } from "models/Language";
import { useCallback, useEffect, useState, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_ACTIONS, usernameSelector } from "store/auth";
import uiSlice, { getLanguage } from "store/ui";
import { getLanguageFlag } from "./troubleshooting/helpers";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    marginRight: "8px",
    color: theme.palette.secondary.lightest,
  },
  menuContainer: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  buttonWrapper: {
    marginRight: "36px !important",
    borderRadius: "10px !important",
    [theme.breakpoints.down("md")]: {
      marginRight: "12px !important",
    },
  },
}));

function UserPreferenceMenu() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userName = useSelector(usernameSelector);
  const language = useSelector(getLanguage);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  const handleLogout = useCallback(() => {
    handleClose();
    dispatch({ type: AUTH_ACTIONS.LOGOUT });
  }, [dispatch, handleClose]);

  const onSetLanguage = useCallback(
    (key: Language) => {
      handleClose();
      i18n.changeLanguage(key);
      dispatch(uiSlice.actions.setLanguage(key));
    },
    [dispatch, handleClose, i18n]
  );

  const setLanguage = useCallback(
    (lang: Language) => onSetLanguage(lang),
    [onSetLanguage]
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  return (
    <>
      <IconButtonWrapper
        className={styles.buttonWrapper}
        onClick={handleClick}
        icon={
          <div className={styles.menuContainer}>
            <AccountCircle className={styles.icon} />
            <Typography variant="subtitle2"> {userName}</Typography>
            <KeyboardArrowDownIcon />
          </div>
        }
      />
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <ListItemText>{t("language.label")}</ListItemText>
        </MenuItem>
        {allLanguages.map((item) => (
          <MenuItem key={item} value={item} onClick={() => setLanguage(item)}>
            <ListItemIcon>{getLanguageFlag(item)}</ListItemIcon>
            <ListItemText> {t(`language.${item}`)} </ListItemText>
            {item === language && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
      </Menu>
    </>
  );
}

export default UserPreferenceMenu;
