import MaintenanceGridItem from "components/heaterDetails/maintenance/MaintenanceGridItem";
import ParametersData from "models/Parameters";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardItemSerialNumber,
  getLastAshLevelActivitySelector,
} from "store/dashboard";
import {
  createIsParameterUpdatingSelector,
  dashboardAshLevel,
  PARAMETERS_ACTIONS,
} from "store/dashboardParameters";

type Props = {
  id: number;
};

const MAX_ASH_LEVEL = 100;

function AshLevel({ id }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activity = useSelector(getLastAshLevelActivitySelector);
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const ashLevel = useSelector(dashboardAshLevel);
  const ashLevelPercentage = (ashLevel / MAX_ASH_LEVEL) * 100;

  const getIsAshLevelUpdating = useMemo(
    () =>
      createIsParameterUpdatingSelector(
        serialNumber,
        ParametersData.AshLevel.id
      ),
    [serialNumber]
  );
  const isAshLevelUpdating = useSelector(getIsAshLevelUpdating);

  const onResetAshLevel = useCallback(
    () =>
      dispatch({
        type: PARAMETERS_ACTIONS.RESET_ASH_LEVEL,
        payload: { deviceId: id, serialNumber },
      }),
    [dispatch, id, serialNumber]
  );

  return (
    <MaintenanceGridItem
      title={t("modules.dashboard.grid.maintenance.ashLevel")}
      valueLabel={`${ashLevelPercentage}%`}
      value={ashLevelPercentage}
      actionInProgress={isAshLevelUpdating}
      onClick={onResetAshLevel}
      activity={activity}
    />
  );
}

export default AshLevel;
