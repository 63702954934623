import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";

type Prop = {
  name: string;
  value: string;
  hasError: boolean;
};

function UnitRuleValueField({ name, value, hasError }: Prop) {
  const { handleChange, setFieldValue } = useFormikContext();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;

      if (key === "ArrowUp" || key === "ArrowDown") {
        event.preventDefault();

        const floatValue = parseFloat(value);
        let newValue: any;

        if (!Number.isNaN(floatValue)) {
          if (key === "ArrowUp") {
            newValue = Math.min(floatValue + 1, 100);
          } else if (key === "ArrowDown") {
            newValue = Math.max(floatValue - 1, 1);
          }
          setFieldValue(name, newValue);
        }
      }
    },
    [name, setFieldValue, value]
  );

  const handleBlur = useCallback(() => {
    const floatValue = parseFloat(value);

    if (!Number.isNaN(floatValue)) {
      const roundedValue = Math.max(Math.min(floatValue, 100), 1).toFixed(2);
      setFieldValue(name, roundedValue);
    } else {
      setFieldValue(name, 1);
    }
  }, [name, setFieldValue, value]);

  return (
    <TextField
      size="small"
      type="text"
      name={name}
      value={value}
      onChange={(e) => {
        handleChange(e);
      }}
      InputProps={{
        endAdornment: <span>%</span>,
        onKeyDown: handleKeyDown,
      }}
      onBlur={handleBlur}
      required
      error={hasError}
    />
  );
}

export default UnitRuleValueField;
