import { IconButton } from "@mui/material";
import { ReactElement } from "react";

type Props = {
  icon: ReactElement;
  className?: string;
  disabled?: boolean;
  onClick?: (event?: any) => void;
};

function IconButtonWrapper({
  className,
  disabled = false,
  icon,
  onClick,
}: Props) {
  return (
    <IconButton
      className={className}
      disabled={disabled}
      disableRipple
      onClick={onClick}
    >
      {icon}
    </IconButton>
  );
}

export default IconButtonWrapper;
