import { useCallback, useState } from "react";

const useDeleteModal = (initialState = false) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(initialState);
  const onCloseDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const onOpenDeleteModal = () => setDeleteModalOpen(true);

  return {
    isDeleteModalOpen,
    onCloseDeleteModal,
    onOpenDeleteModal,
  };
};

export default useDeleteModal;
