import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "20px !important",
    "& .MuiSlider-rail": {
      height: "8px",
      borderRadius: "4px",
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiSlider-track": {
      height: "8px",
      borderRadius: "4px",
    },
    "& .MuiSlider-thumb": {
      width: "32px",
      height: "32px",
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      color: "#FFFFFF",
      border: "1px solid #9D9DAF",
    },
  },
  fadeOut: {
    opacity: "0.3",
  },
}));

type Props = {
  value: number;
  onChange: (event: Event, value: number | number[]) => void;
  min: number;
  max: number;
  disabled: boolean;
  fadeOut?: boolean;
};

function CustomSlider({
  value,
  onChange,
  min,
  max,
  disabled,
  fadeOut = false,
}: Props) {
  const styles = useStyles();
  return (
    <Slider
      classes={{ root: styles.root, track: fadeOut ? styles.fadeOut : "" }}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      disabled={disabled}
    />
  );
}

export default CustomSlider;
