import { Button, Grid } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import TargetHotAirTemperatureItem from "components/heaterDetails/parameters/TargetHotAirTemperatureItem";
import TargetHotAirFanPowerItem from "components/heaterDetails/parameters/TargetHotAirFanPowerItem";
import TargetRoomTemperatureItem from "components/heaterDetails/parameters/TargetRoomTemperatureItem";
import { ParametersType } from "models/Parameters";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isRemoteActionEnabledSelector } from "store/auth";
import {
  dashboardDeviceGroup,
  dashboardItemSerialNumber,
} from "store/dashboard";
import {
  isApplyChangesToGroupEnabled,
  PARAMETERS_ACTIONS,
} from "store/dashboardParameters";
import useParameterDispatch from "hooks/useParameterDispatch";

type Props = {
  id: number;
};

function ParametersGrid({ id }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const isParamActionsEnabled = useSelector(isRemoteActionEnabledSelector);
  const groupId = useSelector(dashboardDeviceGroup);
  const applyChangesToGroupEnabled = useSelector(isApplyChangesToGroupEnabled);
  const { updateParameter } = useParameterDispatch(id, serialNumber);

  const onParameterClick = (value: number, type: ParametersType) =>
    updateParameter(`${value}`, type);

  const onApplyChangesToGroup = useCallback(() => {
    dispatch({
      type: PARAMETERS_ACTIONS.APPLY_CHANGES_TO_GROUP,
    });
  }, [dispatch]);

  return (
    <DashboardGrid
      title={t("modules.dashboard.grid.parameters.header")}
      includeBottomDivider
      content={
        <>
          <TargetRoomTemperatureItem onParameterClick={onParameterClick} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <TargetHotAirTemperatureItem onParameterClick={onParameterClick} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid item xs={12} />
          <TargetHotAirFanPowerItem onParameterClick={onParameterClick} />
          {groupId && isParamActionsEnabled && (
            <Grid item xs={12}>
              <Button
                style={{
                  textTransform: "none",
                }}
                disabled={!applyChangesToGroupEnabled}
                onClick={onApplyChangesToGroup}
                color="primary"
                variant="outlined"
              >
                {t("modules.groups.applyChangesToGroup")}
              </Button>
            </Grid>
          )}
        </>
      }
    />
  );
}

export default ParametersGrid;
