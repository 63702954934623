import {
  EditEntryData,
  HeaterCalendar,
  NewCalendarData,
} from "models/Calendar";
import {
  AdminDevice,
  Heater,
  HeaterDashboard,
  HeaterErrors,
  HeaterScheduledUpdatesInfo,
} from "models/Heater";
import { CreateHeaterUser, HeaterUser } from "models/HeaterUser";
import { UpdateType } from "models/Update";
import api from "./api";
import { getUpdateUrlTypeFromType } from "./updates";
import { Language } from "../models/Language";

const getRemoteHeaterList = async () => {
  const response = await api.get<Heater[]>(`remote_heaters/list`);
  return response.data;
};

const getRemoteHeaterUsers = async (deviceId: number) => {
  const response = await api.get<{ data: HeaterUser[] }>(
    `remote_heaters/${deviceId}/users`
  );
  return response.data.data;
};

const addRemoteHeaterUsers = async (
  deviceId: number,
  newUser: CreateHeaterUser
) => {
  const response = await api.post(
    `remote_heaters/${deviceId}/users/remote/user`,
    {
      ...newUser,
    }
  );
  return response.data;
};

const deleteRemoteHeaterUser = async (deviceId: number, email: string) => {
  const response = await api.delete(
    `remote_heaters/${deviceId}/users/remote/user`,
    {
      data: { email },
    }
  );

  return response.data.data;
};

const getHeaterDashboard = async (id: number) => {
  const response = await api.get<HeaterDashboard>(
    `/remote_heaters/${id}/dashboard`
  );
  return response.data;
};

const putRemoteDeviceParametersData = async (
  deviceId: number,
  paramId: number,
  value: string
) => {
  const response = await api.put<HeaterDashboard>(
    `/remote_devices/${deviceId}/data/${paramId}/remote`,
    {
      value,
    }
  );

  return response.data;
};

const putRemoteHeatersMachineMode = async (deviceId: number, value: string) => {
  const response = await api.put<HeaterDashboard>(
    `/remote_heaters/${deviceId}/remote/machine_mode`,
    {
      value,
    }
  );

  return response.data;
};

export type UpdateDetails = {
  key: string;
  lastModified: string;
  id?: string;
  typeId?: number;
};

type FactorySettingResponse = {
  id: string;
  s3Key: string;
  typeId: string;
};

type Updates = {
  software?: UpdateDetails[];
  data?: FactorySettingResponse[];
};

const getUpdatesByType = async (type: UpdateType) => {
  const response = await api.get<Updates>(`/${getUpdateUrlTypeFromType(type)}`);

  if (type === "softwareUpdates") {
    return response.data.software;
  }

  return response.data.data?.map((item) => ({
    id: item.id,
    key: item.s3Key,
    typeId: item.typeId,
  }));
};

const updatesUrlByType = async (key: string, type: UpdateType) => {
  let response;

  if (type === "softwareUpdates") {
    response = await api.post<{ url: string }>(
      `/${getUpdateUrlTypeFromType(type)}/url`,
      {
        key,
      }
    );
  } else {
    response = await api.post<{ url: string }>(
      `/${getUpdateUrlTypeFromType(type)}/${key}/url`
    );
  }

  return response.data.url;
};

const startUpdateByType = async (
  deviceId: number,
  url: string,
  type: UpdateType
) => {
  const startType =
    type === "factorySettings" ? "factory_settings" : "software";
  const response = await api.post<{ url: string }>(
    `/remote_devices/${deviceId}/updates/remote/${startType}`,
    {
      url,
    }
  );

  return response.data;
};

const acknowledgeError = async (deviceId: number) => {
  await api.put(`/remote_devices/${deviceId}/errors/remote/acknowledge`);
};

const resetAshLevel = async (deviceId: number) => {
  await api.put(`/remote_heaters/${deviceId}/remote/reset_ash_level`);
};

const resetTimeUntilMaintenance = async (deviceId: number) => {
  await api.put(
    `/remote_heaters/${deviceId}/remote/reset_hours_till_maintenance`
  );
};

const getGuacamoleUrl = async (deviceId: number) => {
  const response = await api.get<{ url: string }>(
    `/remote_heaters/${deviceId}/guacamole`
  );

  if (response.status === 200) {
    const fullUrl = `${process.env.REACT_APP_API_URL}${response.data.url}`;
    return fullUrl;
  }

  return null;
};

const setLanguage = async (deviceId: number, language: Language) => {
  await api.put<{ url: string }>(
    `/remote_heaters/${deviceId}/remote/language`,
    {
      language,
    }
  );
};

const getAdminDeviceList = async () => {
  const response = await api.get("/remote_devices/admin/list");
  return response.data;
};

const getAdminDevice = async (deviceId: number) => {
  const response = await api.get<AdminDevice>(`/remote_devices/${deviceId}`);
  return response.data;
};

type PostDevice = {
  serialNumber: string;
  model: number;
  kind: number;
};

const createDevice = async (device: PostDevice) => {
  const response = await api.post<PostDevice>("/remote_devices", {
    ...device,
  });
  return response.data;
};

const deleteDevice = async (deviceId: number) => {
  const response = await api.delete(`/remote_devices/${deviceId}`);
  return response.data;
};

const editDevice = async (id: number, device: PostDevice) => {
  const response = await api.put<PostDevice>(`/remote_devices/${id}`, {
    ...device,
  });
  return response.data;
};

const editDeviceOrganizations = async (id: number, organizations: number[]) => {
  const response = await api.put(`/remote_devices/${id}/organizations`, {
    organizations,
  });
  return response.data;
};

const createCoreKeysAndCert = async (
  id: number,
  certType: "iot_core" | "ovpn"
) => {
  const response = await api.post(
    `/remote_devices/${id}/keys_certs/${certType}`
  );
  return response.data;
};

const getKeysAndCert = async (id: number, certType: "iot_core" | "ovpn") => {
  const response = await api.get(
    `/remote_devices/${id}/keys_certs/${certType}`,
    {
      responseType: "arraybuffer",
    }
  );
  return response.data;
};

const importCoreKeysAndCert = async (
  id: number,
  certType: "iot_core" | "ovpn",
  file: Blob
) => {
  const formData = new FormData();
  formData.append("export", file);

  const response = await api.put(
    `/remote_devices/${id}/keys_certs/${certType}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const deleteCoreKeysAndCert = async (
  id: number,
  certType: "iot_core" | "ovpn"
) => {
  const response = await api.delete(
    `/remote_devices/${id}/keys_certs/${certType}`
  );
  return response.data;
};

const getRemoteDeviceCalendar = async (deviceId: number) => {
  const response = await api.get<HeaterCalendar[]>(
    `/remote_devices/${deviceId}/calendar`
  );
  return response.data;
};

const createRemoteDeviceCalendar = async (
  deviceId: number,
  values: NewCalendarData
) => {
  const response = await api.post(
    `/remote_devices/${deviceId}/calendar/event/remote`,
    values
  );

  return response.data;
};

const putRemoteDeviceCalendarEntry = async (
  deviceId: number,
  entryId: number,
  data: EditEntryData
) => {
  const response = await api.put(
    `/remote_devices/${deviceId}/calendar/event/${entryId}/remote`,
    data
  );

  return response.data;
};

const deleteRemoteDeviceCalendarEntry = async (
  deviceId: number,
  entryId: number,
  removeAll?: boolean
) => {
  const suffix = removeAll ? `?removeAll=true` : "";

  const response = await api.delete(
    `/remote_devices/${deviceId}/calendar/event/${entryId}/remote${suffix}`
  );

  return response.data;
};

const getHeaterErrorHistory = async (id: number) => {
  const response = await api.get<HeaterErrors>(
    `/remote_devices/${id}/errors/history`
  );
  return response.data;
};

export type ScheduleUpdatePayload = {
  remoteDeviceIds: number[];
  softwareUpdateKey: string;
  factorySettingsKey: string;
};

const scheduleUpdates = async (data: ScheduleUpdatePayload) => {
  const response = await api.post("/scheduled_updates", data);

  return response.data;
};

const getHeaterScheduledUpdates = async (id: number) => {
  const response = await api.get<HeaterScheduledUpdatesInfo[]>(
    `/scheduled_updates/remote_device/${id}`
  );
  return response.data;
};

const deleteHeaterScheduledUpdate = async (id: number) => {
  const response = await api.delete(`/scheduled_updates/${id}`);
  return response.data;
};

export default {
  getRemoteHeaterList,
  getHeaterDashboard,
  putRemoteDeviceParametersData,
  putRemoteHeatersMachineMode,
  getUpdatesByType,
  updatesUrlByType,
  startUpdateByType,
  acknowledgeError,
  resetAshLevel,
  resetTimeUntilMaintenance,
  getGuacamoleUrl,
  setLanguage,
  getAdminDeviceList,
  getAdminDevice,
  createDevice,
  deleteDevice,
  editDevice,
  editDeviceOrganizations,
  createCoreKeysAndCert,
  importCoreKeysAndCert,
  deleteCoreKeysAndCert,
  getKeysAndCert,
  getRemoteHeaterUsers,
  addRemoteHeaterUsers,
  deleteRemoteHeaterUser,
  getRemoteDeviceCalendar,
  createRemoteDeviceCalendar,
  putRemoteDeviceCalendarEntry,
  deleteRemoteDeviceCalendarEntry,
  getHeaterErrorHistory,
  scheduleUpdates,
  getHeaterScheduledUpdates,
  deleteHeaterScheduledUpdate,
};
