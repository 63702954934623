import { Language } from "models/Language";
import {
  AlarmRule,
  AlarmRulePayload,
  Organization,
  OrganizationKind,
  OrganizationUnit,
} from "models/Organization";
import { User } from "models/User";
import api from "./api";

const getOrganizations = async () => {
  const response = await api.get<Organization[]>("/organizations");

  return response.data;
};

type OrganizationPayload = {
  label: string;
  defaultLanguage: Language;
  kind: OrganizationKind;
};

const addOrganization = async (data: OrganizationPayload) => {
  const response = await api.post<Organization>("/organizations", {
    ...data,
  });

  return response.data;
};

const deleteOrganization = async (orgId: number) => {
  const response = await api.delete(`/organizations/${orgId}`);

  return response.data;
};

const editOrganization = async (orgId: number, data: OrganizationPayload) => {
  const response = await api.put<Organization>(`/organizations/${orgId}`, {
    ...data,
  });

  return response.data;
};

const getOrganizationUnits = async (orgId: number) => {
  const response = await api.get<OrganizationUnit[]>(
    `/organizations/${orgId}/units_with_details`
  );

  return response.data;
};

const getOrganizationUsers = async (orgId: number, unitId: number) => {
  const response = await api.get<{
    organizationUsers: User[];
    unitUserIds: number[];
  }>(`/organizations/${orgId}/units/${unitId}/users`);

  return response.data;
};

const setOrganizationUnitUsers = async (
  orgId: number,
  unitId: number,
  users: number[]
) => {
  const response = await api.put(
    `/organizations/${orgId}/units/${unitId}/users`,
    {
      users,
    }
  );

  return response.data;
};

const addOrganizationUnit = async (organizationId: number, label: string) => {
  const response = await api.post<OrganizationUnit>(
    `/organizations/${organizationId}/units`,
    {
      label,
      organizationId,
    }
  );

  return response.data;
};

const editOrganizationUnit = async (
  organizationId: number,
  orgUnitId: number,
  label: string
) => {
  const response = await api.put<OrganizationUnit>(
    `/organizations/${organizationId}/units/${orgUnitId}`,
    {
      label,
      organizationId,
    }
  );

  return response.data;
};

const deleteOrganizationUnit = async (
  organizationId: number,
  orgUnitId: number
) => {
  const response = await api.delete<OrganizationUnit>(
    `/organizations/${organizationId}/units/${orgUnitId}`
  );

  return response.data;
};

const getOrganizationUnitAlarmRules = async (orgId: number, unitId: number) => {
  const response = await api.get<{ data: AlarmRule[] }>(
    `/organizations/${orgId}/units/${unitId}/alarm_rules`
  );

  return response.data;
};

const addOrganizationUnitAlarmRule = async (
  orgId: number,
  unitId: number,
  rule: AlarmRulePayload
) => {
  const response = await api.post<AlarmRule>(
    `/organizations/${orgId}/units/${unitId}/alarm_rules`,
    {
      ...rule,
    }
  );

  return response.data;
};

const removeOrganizationUnitAlarmRule = async (
  orgId: number,
  unitId: number,
  alarmRuleId: number
) => {
  const response = await api.delete(
    `/organizations/${orgId}/units/${unitId}/alarm_rules/${alarmRuleId}`
  );

  return response.data;
};

const editOrganizationUnitAlarmRule = async (
  orgId: number,
  unitId: number,
  alarmRuleId: number,
  rule: AlarmRulePayload
) => {
  const response = await api.put(
    `/organizations/${orgId}/units/${unitId}/alarm_rules/${alarmRuleId}`,
    {
      ...rule,
    }
  );

  return response.data;
};

export default {
  getOrganizations,
  addOrganization,
  getOrganizationUsers,
  setOrganizationUnitUsers,
  deleteOrganization,
  editOrganization,
  getOrganizationUnits,
  addOrganizationUnit,
  editOrganizationUnit,
  deleteOrganizationUnit,
  getOrganizationUnitAlarmRules,
  addOrganizationUnitAlarmRule,
  removeOrganizationUnitAlarmRule,
  editOrganizationUnitAlarmRule,
};
