import GeneralInformationGrid from "components/heaterDetails/GeneralInformationGrid";
import MaintenanceGrid from "components/heaterDetails/maintenance/MaintenanceGrid";
import ParametersGrid from "components/heaterDetails/parameters/ParametersGrid";
import WarningsAndErrors from "components/heaterDetails/WarningsAndErrors";
import WorkModeGrid from "components/heaterDetails/WorkModeGrid";

type Props = {
  id: number;
};

function GeneralTab({ id }: Props) {
  return (
    <>
      <GeneralInformationGrid id={id} />
      <WorkModeGrid id={id} />
      <ParametersGrid id={id} />
      <MaintenanceGrid id={id} />
      <WarningsAndErrors />
    </>
  );
}

export default GeneralTab;
