import { Tooltip, Typography } from "@mui/material";
import moment from "moment";
import TooltipContent from "./TooltipContent";

function MonthEvent({ event }: any) {
  const { id, start, end } = event;
  const startTime = moment(start).format("HH:mm");
  const endTime = moment(end).format("HH:mm");

  return (
    <Tooltip title={<TooltipContent id={id} />}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "70%",
          margin: "auto",
        }}
      >
        <Typography color="textPrimary">
          {startTime}&nbsp;-&nbsp;{endTime}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default MonthEvent;
