import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  TextField,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";

import DashboardGrid from "components/DashboardGrid";
import { useFormikContext } from "formik";
import { GuideFormValues } from "models/TroubleshootingGuide";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { remoteDevicesListSelector } from "store/troubleshootingAdmin";

const useStyles = makeStyles((theme: any) => ({
  list: {
    maxHeight: "400px",
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.primary.lightest,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
    },
  },
  mainCheckbox: {
    margin: "4px 4px",
  },
  checkboxLabel: {
    color: theme.palette.text.secondary,
  },
}));

function RemoteDevicesGrid() {
  const { t } = useTranslation();
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const adminDevices = useSelector(remoteDevicesListSelector);
  const { values, setFieldValue } = useFormikContext<GuideFormValues>();
  const currentValue = useMemo(
    () => values.remoteDevices || [],
    [values.remoteDevices]
  );

  const deviceIds = useMemo(
    () => adminDevices.map((adminDevice) => adminDevice.device.id),
    [adminDevices]
  );

  const handleToggle = useCallback(
    (value: number) => () => {
      const newChecked = currentValue.includes(value)
        ? currentValue.filter((id) => id !== value)
        : [...currentValue, value];

      setFieldValue("remoteDevices", newChecked);
    },
    [currentValue, setFieldValue]
  );

  const isAllChecked = useMemo(
    () => currentValue.length === adminDevices.length,
    [currentValue.length, adminDevices.length]
  );

  const toggleAll = useCallback(() => {
    if (isAllChecked) {
      setFieldValue("remoteDevices", []);
    } else {
      setFieldValue("remoteDevices", deviceIds);
    }
  }, [isAllChecked, deviceIds, setFieldValue]);

  const filteredAdminDevices = useMemo(
    () =>
      (adminDevices || []).filter((adminDevice) => {
        const machineName = adminDevice?.parameters?.machineName?.toLowerCase();
        const serialNumber = adminDevice?.device?.serialNumber?.toLowerCase();
        const searchValueToLowerCase = searchValue?.toLowerCase() || "";

        return (
          machineName?.includes(searchValueToLowerCase) ||
          serialNumber?.includes(searchValueToLowerCase)
        );
      }),
    [adminDevices, searchValue]
  );

  return (
    <DashboardGrid
      title={t("modules.troubleshooting.grid.devices.header")}
      includeBottomDivider
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} lg={6}>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  className={styles.mainCheckbox}
                  name="remoteDevices"
                  edge="start"
                  color="primary"
                  onChange={toggleAll}
                  disableRipple
                />
              }
              label={t("modules.troubleshooting.grid.devices.label", {
                count: currentValue.length,
              }).toUpperCase()}
              labelPlacement="end"
            />
            <Divider />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              placeholder={t("modules.troubleshooting.grid.devices.search")}
              hiddenLabel
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <List className={styles.list}>
              <FormGroup>
                {filteredAdminDevices.map((adminDevice) => (
                  <ListItem
                    key={`list-${adminDevice.device.id}-${adminDevice.parameters.machineName}`}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(adminDevice.device.id)}
                      dense
                    >
                      <ListItemIcon>
                        <FormControlLabel
                          classes={{ label: styles.checkboxLabel }}
                          control={
                            <Checkbox
                              color="primary"
                              edge="start"
                              checked={
                                currentValue.indexOf(adminDevice.device.id) !==
                                -1
                              }
                              disableRipple
                            />
                          }
                          label={
                            adminDevice.parameters.machineName
                              ? `${adminDevice.parameters.machineName} - ${adminDevice.device.serialNumber}`
                              : adminDevice.device.serialNumber
                          }
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </FormGroup>
            </List>
          </Grid>
        </Grid>
      }
    />
  );
}

export default RemoteDevicesGrid;
