import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { Box, IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

type Props = {
  file: string;
  page?: number;
  onLinkClick?: (event: any, href: string) => void;
};

function PdfViewer({ file, page = 1, onLinkClick }: Props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  }, []);

  const onLoadSuccess = useCallback(
    ({ numPages: total }: PDFDocumentProxy) => {
      setNumPages(total);
      setPageNumber(page);
    },
    [page]
  );

  const onRenderSuccess = () => {
    if (!firstUpdate.current) {
      ref.current?.scrollIntoView();
    }
    firstUpdate.current = false;
  };

  const changePage = useCallback(
    (offset: number) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    []
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);
  const nextPage = useCallback(() => changePage(1), [changePage]);

  const onDocumentClick = useCallback((event: any) => {
    const {
      target: { href },
    } = event;
    if (href as string) {
      onLinkClick?.(event, href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ minHeight: 300 }}>
      <Document {...{ file, onLoadSuccess }} onClick={onDocumentClick}>
        <Box
          sx={[
            {
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            },
            () => ({
              "& .annotationLayer": {
                maxWidth: "100%",
                maxHeight: "-moz-fit-content!important",
                height: "100%!important",
              },
              "& .textLayer": {
                maxWidth: "100%",
                maxHeight: "-moz-fit-content!important",
                height: "100%!important",
              },
              "& canvas": {
                maxWidth: "100%",
                maxHeight: "-moz-fit-content!important",
                height: "100%!important",
              },
              "& .react-pdf__Page__textContent": {
                display: "none",
              },
            }),
          ]}
        >
          <TransformWrapper>
            <TransformComponent>
              <Page {...{ pageNumber, onRenderSuccess }} />
            </TransformComponent>
          </TransformWrapper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={previousPage}
            disabled={(pageNumber || 0) <= 1}
            size="large"
          >
            <ArrowCircleLeftOutlinedIcon fontSize="large" />
          </IconButton>
          <Box
            {...{ ref }}
            sx={{
              display: "flex",
              border: 1,
              borderRadius: 1,
              borderColor: "grey.300",
              py: 0.5,
              px: 1.5,
              mx: 1,
            }}
          >
            {pageNumber}
            <Box sx={{ color: "grey.300" }}>/{numPages}</Box>
          </Box>
          <IconButton
            onClick={nextPage}
            disabled={(pageNumber || 0) >= (numPages || 0)}
            size="large"
          >
            <ArrowCircleRightOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
      </Document>
    </Box>
  );
}

export default PdfViewer;
