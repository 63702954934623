import { SvgIcon } from "@mui/material";

function UKFlag() {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ fill: "none" }}>
      <g clipPath="url(#clip0_1109_5719)">
        <path
          d="M0 6.03937V8.66671H3.752L0 6.03937ZM3.10933 20.6667H8.66667V16.7754L3.10933 20.6667ZM15.3333 16.776V20.6667H20.89L15.3333 16.776ZM0 15.3334V17.9607L3.75333 15.3334H0ZM20.8913 3.33337H15.3333V7.22471L20.8913 3.33337ZM24 17.9614V15.3334H20.246L24 17.9614ZM24 8.66671V6.03937L20.2473 8.66671H24ZM8.66667 3.33337H3.10933L8.66667 7.22471V3.33337Z"
          fill="#00247D"
        />
        <path
          d="M16.7599 15.3334L23.2346 19.8674C23.5508 19.5416 23.7781 19.1401 23.8946 18.7014L19.0846 15.3334H16.7599ZM8.66661 15.3334H7.23928L0.765281 19.8667C1.11261 20.22 1.55795 20.4727 2.05728 20.59L8.66661 15.962V15.3334ZM15.3333 8.6667H16.7606L23.2346 4.13337C22.8813 3.77488 22.4335 3.52405 21.9433 3.41003L15.3333 8.03803V8.6667ZM7.23928 8.6667L0.765281 4.13337C0.44925 4.45935 0.221824 4.86073 0.104614 5.29937L4.91461 8.6667H7.23928Z"
          fill="#CF1B2B"
        />
        <path
          d="M24 14H14V20.6667H15.3333V16.776L20.89 20.6667H21.3333C21.6875 20.6668 22.0381 20.5961 22.3646 20.4588C22.6911 20.3216 22.9869 20.1205 23.2347 19.8674L16.76 15.3334H19.0847L23.8947 18.7014C23.9567 18.4767 24 18.244 24 18V17.9614L20.246 15.3334H24V14ZM0 14V15.3334H3.75333L0 17.9607V18C0 18.7274 0.292667 19.3854 0.765333 19.8667L7.23933 15.3334H8.66667V15.962L2.05733 20.5894C2.25333 20.636 2.456 20.6667 2.66667 20.6667H3.10933L8.66667 16.7754V20.6667H10V14H0ZM24 6.00004C24.0004 5.30136 23.7254 4.63068 23.2347 4.13337L16.7607 8.66671H15.3333V8.03804L21.9433 3.41004C21.7436 3.36106 21.5389 3.33533 21.3333 3.33337H20.8913L15.3333 7.22471V3.33337H14V10H24V8.66671H20.2473L24 6.03937V6.00004ZM8.66667 3.33337V7.22471L3.10933 3.33337H2.66667C2.31242 3.33322 1.96171 3.40392 1.63519 3.5413C1.30866 3.67869 1.01291 3.88 0.765333 4.13337L7.23933 8.66671H4.91467L0.104667 5.29937C0.0385201 5.52718 0.00331561 5.76285 0 6.00004L0 6.03937L3.752 8.66671H0V10H10V3.33337H8.66667Z"
          fill="#EEEEEE"
        />
        <path
          d="M14 10V3.33337H10V10H0V14H10V20.6667H14V14H24V10H14Z"
          fill="#CF1B2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1109_5719">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default UKFlag;
