import DeleteItemGrid from "components/common/DeleteItemGrid";
import { useTranslation } from "react-i18next";

type Prop = {
  isNew: boolean;
  onDelete: () => void;
};

function DeleteGrid({ isNew, onDelete }: Prop) {
  const { t } = useTranslation();

  return (
    <DeleteItemGrid
      deleteLabel={t("modules.troubleshooting.grid.delete.header")}
      deleteHelperText={t("modules.troubleshooting.grid.delete.helperText")}
      isNew={isNew}
      onDelete={onDelete}
    />
  );
}

export default DeleteGrid;
