import DeFlag from "components/icons/flags/DeFlag";
import FrFlag from "components/icons/flags/FrFlag";
import ItFlag from "components/icons/flags/ItFlag";
import UKFlag from "components/icons/flags/UKFlag";
import { TroubleshootingGuideLanguage } from "models/TroubleshootingGuide";

export const getLanguageFlag = (language: TroubleshootingGuideLanguage) => {
  switch (language) {
    case "en":
      return <UKFlag />;
    case "de":
      return <DeFlag />;
    case "it":
      return <ItFlag />;
    case "fr":
      return <FrFlag />;
    default:
      return <span>No flag</span>;
  }
};

export default {
  getLanguageFlag,
};
