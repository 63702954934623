import { Button, ButtonProps, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

type Props = ButtonProps &
  SvgIconProps & {
    icon: ReactElement;
  };

function IconBoxButton({ icon, sx, ...props }: Props) {
  return (
    <Button
      sx={{ p: 0.5, minWidth: 0, ...sx }}
      size="small"
      variant="outlined"
      {...props}
    >
      {icon}
    </Button>
  );
}

export default IconBoxButton;
