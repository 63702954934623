import { Typography } from "@mui/material";

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import DetailsHeader from "components/common/DetailsHeader";
import paths from "config/paths";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createUserDetailsByIdSelector } from "store/users";

type Props = {
  userId: number;
};

function UserDetailsHeader({ userId }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getUserFullName = useMemo(
    () => createUserDetailsByIdSelector(userId),
    [userId]
  );
  const { fullName, email } = useSelector(getUserFullName);
  const title = userId ? fullName : t("modules.users.addNew");

  const onBackClick = useCallback(() => navigate(paths.users), [navigate]);

  return (
    <DetailsHeader
      mainContent={
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Typography style={{ marginRight: "16px" }} variant="h4">
            {title}
          </Typography>
          <Typography style={{ lineHeight: "2rem" }} variant="body1">
            {email}
          </Typography>
        </div>
      }
      onBack={onBackClick}
      path={t("modules.users.title")}
      item={fullName}
      itemLabel={userId ? "" : t("modules.users.userDetails")}
    />
  );
}

export default UserDetailsHeader;
