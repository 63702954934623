import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type Props = {
  label: string;
  value: number;
  selected: boolean;
  onClick: (value: number) => void;
};

const useStyles = makeStyles((theme: any) => ({
  option: {
    display: "inline-block",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    marginRight: "8px",
    color: "#9D9DAF",
    border: "1px solid #9D9DAF",
    fontSize: "12px",
    textAlign: "center",
    lineHeight: "24px",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[50],
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

function DayOption({ label, value, selected, onClick }: Props) {
  const styles = useStyles();

  return (
    <span
      className={classNames(styles.option, {
        [styles.selected]: selected,
      })}
      role="none"
      onClick={() => onClick(value)}
    >
      {label}
    </span>
  );
}

export default DayOption;
