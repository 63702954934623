import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeviceDetailsHeader from "components/adminDevices/DeviceDetailsHeader";
import GeneralTab from "components/adminDevices/generalTab/GeneralTab";
import HeaterUsers from "components/adminDevices/heaterUsersTab/HeaterUsers";
import TabPanel from "components/common/TabsPanel";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ADMIN_DEVICES_ACTIONS } from "store/adminDevices";
import organizationsSlice from "store/organizations";
import uiSlice, {
  AdminDevicesTabs,
  getAdminDevicesSelectedTab,
} from "store/ui";

const useStyles = makeStyles((theme: any) => ({
  tabsRoot: {
    margin: "0 64px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
    },
  },
  tabRoot: {
    textTransform: "none",
    minWidth: "73px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  selected: {
    backgroundColor: "white",
    padding: "0 16px",
  },
  indicator: {
    height: 0,
  },
}));

type Props = {
  isNew: boolean;
};

function EditDevice({ isNew }: Props) {
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const id = deviceId && Number(deviceId) ? +deviceId : 0;
  const styles = useStyles();
  const selectedTab = useSelector(getAdminDevicesSelectedTab);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_: unknown, newValue: any) => {
      dispatch(uiSlice.actions.setAdminDevicesSelectedTab(newValue));
    },
    [dispatch]
  );

  const tabsClasses = {
    root: styles.tabsRoot,
    indicator: styles.indicator,
  };

  const tabClasses = {
    root: styles.tabRoot,
    selected: styles.selected,
  };

  useEffect(() => {
    dispatch(organizationsSlice.actions.init());
    if (id) {
      dispatch({
        type: ADMIN_DEVICES_ACTIONS.GET_DEVICE,
        payload: {
          deviceId: id,
        },
      });
    }
  }, [dispatch, id]);

  return (
    <div>
      <DeviceDetailsHeader deviceId={id} />
      <Tabs
        value={selectedTab}
        textColor="primary"
        classes={tabsClasses}
        onChange={handleChange}
      >
        <Tab classes={tabClasses} label={t("modules.devices.tabs.general")} />
        {!isNew && (
          <Tab
            classes={tabClasses}
            label={t("modules.devices.tabs.heaterUsers")}
          />
        )}
      </Tabs>
      <TabPanel value={selectedTab} index={AdminDevicesTabs.General} hasFooter>
        <GeneralTab id={id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={AdminDevicesTabs.HeaterUsers}>
        <HeaterUsers deviceId={id} />
      </TabPanel>
    </div>
  );
}

export default EditDevice;
