import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import uiSlice, { isDrawerOpenSelector } from "store/ui";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import NavItems from "components/NavItems";
import Logo from "components/Logo";
import CustomHidden from "./common/CustomHidden";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    padding: "20px 10px 60px 10px",
  },
  iconButton: {
    marginRight: "8px",
  },
}));

function Sidebar() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(isDrawerOpenSelector);

  const onClose = useCallback(
    () => dispatch(uiSlice.actions.setIsDrawerOpen(false)),
    [dispatch]
  );

  return (
    <CustomHidden hideType="up" size="md">
      <Drawer
        anchor="left"
        onClose={onClose}
        open={isDrawerOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 284,
          },
        }}
      >
        <div className={styles.header}>
          <IconButtonWrapper
            icon={<CloseIcon className={styles.iconButton} />}
            onClick={onClose}
          />
          <Logo />
        </div>
        <NavItems />
      </Drawer>
    </CustomHidden>
  );
}

export default Sidebar;
