import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomHidden from "components/common/CustomHidden";
import getComparator, { Order } from "components/common/tableHelpers";
import {
  defaultRowsPerPage,
  getFormatedDate,
  rowsPerPageOptions,
} from "helpers";
import { FactorySettings } from "models/Update";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFactorySettingsTypeById } from "store/updates";
import ManageUpdateMenu from "./ManageUpdateMenu";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes = "s3Key" | "type" | "updatedAt";

type Props = {
  updates: FactorySettings[];
};

function FactorySettingsTable({ updates }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("s3Key");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const typesById = useSelector(getFactorySettingsTypeById);

  const headCells = useMemo(
    () => [
      {
        id: "s3Key" as OrderByTypes,
        label: t(`modules.factorySettings.table.name`).toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },

      {
        id: "type" as OrderByTypes,
        label: t(`modules.factorySettings.table.type`).toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },

      {
        id: "updatedAt" as OrderByTypes,
        label: t(`modules.factorySettings.table.lastModified`).toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const mappedValues = useMemo(
    () =>
      updates.map((item) => ({
        ...item,
        type: typesById[item.typeId]?.label,
      })),
    [typesById, updates]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),
    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) =>
              headCell.smallHidden ? (
                <CustomHidden size="xs" hideType="only">
                  <TableCell
                    key={headCell.id}
                    className={headCell.className}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </CustomHidden>
              ) : (
                <TableCell
                  key={headCell.id}
                  className={headCell.className}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((update) => (
              <TableRow
                key={`row-${update.s3Key}-${update.updatedAt}-${update.id}`}
              >
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">{update.s3Key}</Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography variant="body1">
                    {typesById[(update as FactorySettings).typeId]?.label ||
                      "-"}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography variant="body1">
                    {getFormatedDate(update.updatedAt)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <ManageUpdateMenu id={update.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={mappedValues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default FactorySettingsTable;
