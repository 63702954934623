import {
  CalendarEventFormValues,
  CalendarEventRepetition,
  RepeatOptions,
  RepetitionFrequencyType,
} from "models/Calendar";
import ParametersData from "models/Parameters";
import moment, { Moment } from "moment";

export const dayOptions: { value: number; label: string }[] = [
  {
    value: 0,
    label: "M",
  },
  {
    value: 1,
    label: "T",
  },
  {
    value: 2,
    label: "W",
  },
  {
    value: 3,
    label: "T",
  },
  {
    value: 4,
    label: "F",
  },
  {
    value: 5,
    label: "S",
  },
  {
    value: 6,
    label: "S",
  },
];

// TBD translations;
export const repeatOptions: { value: RepeatOptions; label: string }[] = [
  {
    value: "none",
    label: "Doesn’t repeat",
  },
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "workingDays",
    label: "Mon to Fri",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

// TBD translations;
export const frequencyTypeOptions: {
  value: RepetitionFrequencyType;
  label: string;
}[] = [
  {
    value: "days",
    label: "Daily",
  },
  {
    value: "weeks",
    label: "Weekly",
  },
];

export const initialRepetitionValue: CalendarEventRepetition = {
  frequency: 1,
  frequencyType: "days",
  weekdays: [false, false, false, false, false, false, false],
  endsAfter: 2,
  endsOn: moment(new Date()).format("YYYY-MM-DD HH:mm"),
  updateAll: false,
};

export const initialValues: CalendarEventFormValues = {
  id: 0,
  from: moment(new Date()).format("YYYY-MM-DD HH:mm"),
  to: moment(new Date()).format("YYYY-MM-DD HH:mm"),
  targetRoomTemperature: ParametersData.TargetRoomTemperature.min,
  targetHotAirTemperature: ParametersData.TargetHotAirTemperature.min,
  targetPowerHotAirFan: ParametersData.HotAirFanPower.min,
  modeHotAirFan: false,
  additionalCooling: false,
  repeatOption: "none",
  endType: "endsAfter",
  ...initialRepetitionValue,
};

export const getStartAndEndDateFormated = (
  startDate: Moment,
  endDate: Moment
) => {
  const startFormated = startDate.format("YYYY-MM-DD HH:mm");
  const endFormated = endDate.format("YYYY-MM-DD HH:mm");

  return { startFormated, endFormated };
};

export const getCurrentStartAndEndDateFormated = (
  startDate: Moment,
  endDate: Moment
) => {
  const startTimeSlot = moment().add(1, "hour").startOf("hour");
  const hourDiff = startTimeSlot.diff(moment().startOf("day"), "hour");

  startDate.add(hourDiff, "hour");
  endDate.subtract(1, "day").add(hourDiff + 1, "hour");

  return getStartAndEndDateFormated(startDate, endDate);
};
