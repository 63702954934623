import {
  Checkbox,
  List,
  Grid,
  ListItemIcon,
  ListItemButton,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ListItem from "@mui/material/ListItem";
import DashboardGrid from "components/DashboardGrid";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { organizationsListSelector } from "store/organizations";

const useStyles = makeStyles((theme: any) => ({
  list: {
    maxHeight: "400px",
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.primary.lightest,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
    },
  },
  mainCheckbox: {
    margin: "4px 4px",
  },
  checkboxLabel: {
    color: theme.palette.text.secondary,
  },
}));

type Prop = {
  setFieldValue: any;
  currentValue: number[];
};

function OrganizationsGrid({ setFieldValue, currentValue }: Prop) {
  const { t } = useTranslation();
  const styles = useStyles();
  const organizations = useSelector(organizationsListSelector);

  const organizationIds = useMemo(
    () => organizations.map((org) => org.id),
    [organizations]
  );

  const handleToggle = useCallback(
    (value: number) => () => {
      const currentIndex = currentValue.indexOf(value);
      const newChecked = [...currentValue];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setFieldValue("organizations", newChecked);
    },
    [currentValue, setFieldValue]
  );

  const isAllChecked = useMemo(
    () => currentValue.length === organizations.length,
    [currentValue.length, organizations.length]
  );

  const toggleAll = useCallback(() => {
    if (isAllChecked) {
      setFieldValue("organizations", []);
    } else {
      setFieldValue("organizations", organizationIds);
    }
  }, [isAllChecked, organizationIds, setFieldValue]);

  return (
    <DashboardGrid
      title={t("modules.devices.grid.organization.header")}
      includeBottomDivider
      content={
        <Grid container spacing={2}>
          <Grid item xs={7} lg={6}>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  className={styles.mainCheckbox}
                  name="organizations"
                  edge="start"
                  color="primary"
                  checked={isAllChecked}
                  onChange={toggleAll}
                  disableRipple
                />
              }
              label={t("modules.devices.grid.organization.label").toUpperCase()}
              labelPlacement="end"
            />
            <Divider />
          </Grid>
          <Grid item xs={12} md={7}>
            <List className={styles.list}>
              <FormGroup>
                {organizations.map((org) => (
                  <ListItem key={`list-${org.id}-${org.label}`} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(org.id)}
                      dense
                    >
                      <ListItemIcon>
                        <FormControlLabel
                          classes={{ label: styles.checkboxLabel }}
                          control={
                            <Checkbox
                              color="primary"
                              edge="start"
                              checked={currentValue.indexOf(org.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                            />
                          }
                          label={org.label}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </FormGroup>
            </List>
          </Grid>
        </Grid>
      }
    />
  );
}

export default OrganizationsGrid;
