import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import { Device, Heater, HeaterCommon } from "models/Heater";
import { HeaterParametersProperties } from "models/Parameters";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { MQTT_ACTIONS } from "store/mqtt";

type HeaterState = {
  initializing: boolean;
  heaters: Record<number, Heater>;
  heatersCommonByDeviceId: Record<number, HeaterCommon>;
  error?: string;
};

type HeaterBulkAttributes = Pick<
  Heater,
  "processState" | "machineName" | "targetRoomTemperature" | "softwareVersion"
>;

type DeviceBulkAttributes = Pick<Device, "serialNumber" | "remoteAccess">;

type BulkUpdatePayload = {
  serialNumber: string;
  heater: HeaterBulkAttributes;
  device: DeviceBulkAttributes;
};

type DeviceConnectionPayload = {
  serialNumber: string;
  isConnected: boolean;
};

const initialState: HeaterState = {
  initializing: true,
  heaters: {},
  heatersCommonByDeviceId: {},
};

const heaterSlice = createSlice({
  name: "heater",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<Heater[]>) => {
      const heatersMap = payload.reduce(
        (prev, current) => ({
          ...prev,
          [current.device.id]: current,
        }),
        {}
      ) as Record<number, Heater>;

      state.heaters = heatersMap;
      state.heatersCommonByDeviceId = omit(heatersMap, "device");

      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    updateErrorCount: (
      state,
      { payload }: PayloadAction<{ serialNumber: string; errorCount: number }>
    ) => {
      const { serialNumber, errorCount } = payload;
      const heater = Object.values(state.heaters).find(
        (item: Heater) => item.device.serialNumber === serialNumber
      );

      if (heater) {
        state.heaters[heater.device.id].errorCount = errorCount;
      }
    },
    updateWarningCount: (
      state,
      { payload }: PayloadAction<{ serialNumber: string; warningCount: number }>
    ) => {
      const { serialNumber, warningCount } = payload;
      const heater = Object.values(state.heaters).find(
        (item: Heater) => item.device.serialNumber === serialNumber
      );

      if (heater) {
        state.heaters[heater.device.id].warningCount = warningCount;
      }
    },
    updateParameter: (
      state,
      {
        payload,
      }: PayloadAction<{
        serialNumber: string;
        property: HeaterParametersProperties;
        value: number;
      }>
    ) => {
      const { serialNumber, property, value } = payload;
      const heater = Object.values(state.heaters).find(
        (item: Heater) => item.device.serialNumber === serialNumber
      );

      if (heater) {
        state.heaters[heater.device.id][property] = value;
      }
    },
    setDeviceConnection: (
      state,
      { payload }: PayloadAction<DeviceConnectionPayload>
    ) => {
      const { serialNumber, isConnected } = payload;
      const heater = Object.values(state.heaters).find(
        (item: Heater) => item.device.serialNumber === serialNumber
      );

      if (heater) {
        state.heaters[heater.device.id].device.connected = isConnected;
      }
    },
    bulkUpdateHeater: (
      state,
      { payload }: PayloadAction<BulkUpdatePayload>
    ) => {
      const { serialNumber, heater, device } = payload;
      const updatedHeater = Object.values(state.heaters).find(
        (item: Heater) => item.device.serialNumber === serialNumber
      );

      if (updatedHeater) {
        const { id } = updatedHeater.device;
        const {
          softwareVersion,
          processState,
          machineName,
          targetRoomTemperature,
        } = heater;
        const { remoteAccess } = device;

        state.heaters[id].processState = processState;
        state.heaters[id].machineName = machineName;
        state.heaters[id].softwareVersion = softwareVersion;
        state.heaters[id].targetRoomTemperature = targetRoomTemperature;
        state.heaters[id].device.serialNumber = serialNumber;
        state.heaters[id].device.remoteAccess = remoteAccess;
      }
    },
  },
});

function heaterSelector(state: { [heaterSlice.name]: HeaterState }) {
  return state[heaterSlice.name];
}

export const heaterListSelector = createSelector(
  heaterSelector,
  (state: HeaterState) => Object.values(state.heaters)
);

export const heaterListDevicesSelector = createSelector(
  heaterListSelector,
  (heaters: Heater[]) => heaters.map((heater) => heater.device)
);

export const createHeaterSoftwareVersion = (id: number) =>
  createSelector(
    heaterSelector,
    (state: HeaterState) => state.heaters[id]?.softwareVersion || "-"
  );

export const heaterCommonByDeviceIdSelector = createSelector(
  heaterSelector,
  (state: HeaterState) => state.heatersCommonByDeviceId
);

function* handleInit(): Generator {
  try {
    const heaterList = (yield call(
      heaterService.getRemoteHeaterList
    )) as Heater[];
    yield put(heaterSlice.actions.initSuccess(heaterList));
  } catch (err) {
    yield put(heaterSlice.actions.initError("some error"));
  }
}

function* handleSetDeviceConnection(
  action: PayloadAction<DeviceConnectionPayload>
): Generator {
  try {
    yield put(heaterSlice.actions.setDeviceConnection(action.payload));
  } catch (err) {
    yield put(heaterSlice.actions.initError("Something went wrong..."));
  }
}

function* watchSetDeviceConnection() {
  yield takeLatest(
    MQTT_ACTIONS.SET_DEVICE_CONNECTION,
    handleSetDeviceConnection
  );
}

function* watchInit() {
  yield takeLatest(heaterSlice.actions.init, handleInit);
}

export function* heaterWatcher() {
  yield all([fork(watchInit), fork(watchSetDeviceConnection)]);
}

export default heaterSlice;
