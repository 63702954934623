import moment from "moment";

export const getFormatedDate = (date: string) =>
  moment(date).format("DD. MM. YYYY. HH:mm:ss");

export const getFormatedDateForSearch = (date: string) =>
  moment(date).format("DD.MM.YYYY.HH:mm:ss");

const remoteLabel = "remote:";

export const getFullName = (
  firstName: string,
  lastName: string,
  email: string
) => {
  if (!firstName || !lastName) {
    if (email && email.includes(remoteLabel)) {
      return email?.replace(remoteLabel, "");
    }
    return email;
  }

  return `${firstName} ${lastName}`;
};

export const getLastModifiedMessage = (
  firstName: string = "",
  lastName: string = "",
  email: string = "",
  date: string = ""
) => `${getFullName(firstName, lastName, email)}, ${getFormatedDate(date)}`;

export const trimString = (value: string) => value.replace(/  +/g, " ").trim();

export const getFirstLetterToUpperCase = (label: string) =>
  label.charAt(0).toUpperCase() + label.slice(1);

export const defaultRowsPerPage = 25;
export const rowsPerPageOptions = [10, 25, 50, 100];

/**
 * Returns true if the data in the heater is considered old, i.e it might
 * indicate that the heater is offline without having broadcasted that
 * (e.g, in the case of a Internet connectivity loss).
 * The actual time for when data is considered old is purely provisional,
 * for now it's set to 1 hour (since scrapes happen every 10 min).
 */
export const isHeaterDataOld = (lastDataUpdate: Date | undefined): boolean =>
  (lastDataUpdate &&
    moment(lastDataUpdate).isBefore(moment().subtract(1, "hour"))) ??
  false;

export default {
  getFormatedDate,
  getLastModifiedMessage,
  trimString,
  getFirstLetterToUpperCase,
  isHeaterDataOld,
};
