import CustomHidden from "components/common/CustomHidden";
import DashboardViewToggle from "./DashboardViewToggle";
import DashboardViewMenu from "./DashboardViewMenu";

function DashboardViewSelection() {
  return (
    <>
      <CustomHidden size="sm" hideType="down">
        <DashboardViewToggle />
      </CustomHidden>
      <CustomHidden size="sm" hideType="up">
        <DashboardViewMenu />
      </CustomHidden>
    </>
  );
}

export default DashboardViewSelection;
