import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { User, UserCredentials, UserInfo, UserRole } from "models/User";
import authService, { LoginResponse } from "services/auth";
import moment from "moment";
import mqttSlice, { MQTT_ACTIONS } from "store/mqtt";
import { ErrorData } from "models/Error";
import { navItems } from "components/common/navItems";
import { Path } from "react-router-dom";
import paths from "config/paths";
import storageKey from "config/storageKey";

type AuthState = {
  initializing: boolean;
  user: User;
  credentials: UserCredentials;
  isLoggedIn: boolean;
  navigateTo?: string | Path;
  error?: string;
  refreshPasswordError?: string;
};

export const AUTH_ACTIONS = {
  LOGOUT: "LOGOUT",
  REFRESH: "REFRESH",
  REFRESH_PASSWORD: "REFRESH_PASSWORD",
};

const initialState: AuthState = {
  initializing: false,
  user: {
    id: 0,
    username: localStorage.getItem("username") || "",
    firstName: "",
    lastName: "",
    accessLevel: Number(localStorage.getItem("accessLevel")),
    requiresPasswordReset: false,
  },
  credentials: {
    mqttUrl: localStorage.getItem("mqttUrl") || null,
    accessToken: localStorage.getItem("accessToken") || null,
  },
  isLoggedIn: !!localStorage.getItem("accessToken"),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<UserInfo>) => {
      state.initializing = true;
      state.user = {
        ...state.user,
        username: payload.username,
      };
      state.isLoggedIn = false;
    },
    loginSuccess: (state, { payload }: PayloadAction<LoginResponse>) => {
      state.initializing = false;
      state.user = {
        ...payload.user,
      };
      state.credentials = payload.credentials;
      state.isLoggedIn = true;
      const toubleshootingUrl = localStorage.getItem(
        storageKey.troubleshootingPath
      );
      state.navigateTo = toubleshootingUrl
        ? JSON.parse(toubleshootingUrl)
        : paths.dashboard;
    },
    loginError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
      state.isLoggedIn = false;
    },
    refreshPasswordError: (state, { payload }: PayloadAction<string>) => {
      state.refreshPasswordError = payload;
    },
    refreshPasswordSuccess: (state) => {
      state.user.requiresPasswordReset = false;
    },
    updateCredentials: (state, { payload }: PayloadAction<UserCredentials>) => {
      state.credentials = payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.credentials = initialState.credentials;
      state.user = initialState.user;
      state.error = "";
      state.navigateTo = undefined;
    },
    setNavigateTo: (state) => {
      state.navigateTo = undefined;
    },
  },
});

function authSelector(state: { [authSlice.name]: AuthState }) {
  return state[authSlice.name];
}

export const isAuthenticatedSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.isLoggedIn
);

export const currentUserSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.user
);

export const userRequiresPasswordReset = createSelector(
  currentUserSelector,
  (user) => user.requiresPasswordReset
);

const userOrganizationSelector = createSelector(
  currentUserSelector,
  (user) => user.organizations || []
);

const userOrganizationIdsSelector = createSelector(
  userOrganizationSelector,
  (organizations) => organizations?.map((org) => org.id.toString())
);

const currentUserOrgSelector = createSelector(
  userOrganizationIdsSelector,
  (orgIds) => {
    let userOrgIds = [];

    if (orgIds.length === 0) {
      userOrgIds = localStorage.getItem("orgIds")?.split("") || [];
    } else {
      userOrgIds = orgIds;
    }

    return userOrgIds;
  }
);

export const currentUserOrgAsSetSelector = createSelector(
  currentUserOrgSelector,
  (orgIds) => new Set(orgIds.map((id) => +id))
);

export const navigateToSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.navigateTo
);

export const mqttUrlSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.credentials?.mqttUrl || ""
);

export const authErrorSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.error
);

export const refreshPasswordErrorSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.refreshPasswordError
);

export const usernameSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.user?.username
);

export const accessLevelSelector = createSelector(
  authSelector,
  (authState: AuthState) => authState.user?.accessLevel
);

export const getAvailableNavItems = createSelector(
  accessLevelSelector,
  (accessLevel) =>
    navItems.filter((navItem) => navItem.access.includes(accessLevel))
);

const updateEnabledAccessLevels = [-1, 20, 30];
export const isUpdateEnabledSelector = createSelector(
  accessLevelSelector,
  (accessLevel) => updateEnabledAccessLevels.includes(accessLevel)
);

export const isRemoteActionEnabledSelector = createSelector(
  accessLevelSelector,
  (accessLevel) => accessLevel !== 0
);

export const isOrganizationActionEnabled = createSelector(
  accessLevelSelector,
  (accessLevel) => accessLevel === UserRole.CloudAdmin
);

function* handleLogout(): Generator {
  yield call(authService.logout);
  yield put(authSlice.actions.logout());
  yield put({ type: MQTT_ACTIONS.STOP_CONNECTION });
}

function* watchLogut() {
  yield takeLatest(AUTH_ACTIONS.LOGOUT, handleLogout);
}

function* handleLogin({
  payload: { username, password },
}: PayloadAction<UserInfo>): Generator {
  try {
    const loginResponse = (yield call(authService.login, {
      username,
      password,
    })) as LoginResponse;

    const time = moment();

    localStorage.setItem("mqttUrl", loginResponse.credentials.mqttUrl || "");
    localStorage.setItem("username", loginResponse.user.username);
    localStorage.setItem("accessLevel", `${loginResponse.user.accessLevel}`);
    localStorage.setItem(
      "accessToken",
      loginResponse.credentials.accessToken || ""
    );
    localStorage.setItem("tmstp", `${time}`);
    const ids = loginResponse.user.organizations?.map((org) => org.id);
    localStorage.setItem("orgIds", `${ids}`);

    yield put(authSlice.actions.loginSuccess(loginResponse));
  } catch (err) {
    yield call(authService.logout);

    if (axios.isAxiosError(err)) {
      const { code } = err.response?.data as ErrorData;

      if (code === 102) {
        const status = err.response?.status;
        yield put(authSlice.actions.loginError(`${status}`));
      }
    }

    if (!(err instanceof Error)) {
      yield put(authSlice.actions.loginError("Unknown error occured"));
    }
  }
}

function* watchLogin() {
  yield takeLatest(authSlice.actions.login, handleLogin);
}

function* handleRefreshToken(): Generator {
  const credentials = (yield call(authService.refreshToken)) as UserCredentials;
  yield put(authSlice.actions.updateCredentials(credentials));

  if (credentials) {
    const time = moment();
    localStorage.setItem("tmstp", `${time}`);
    localStorage.setItem("mqttUrl", `${credentials.mqttUrl}`);
    localStorage.setItem("accessToken", `${credentials.accessToken}`);
  }

  yield put({ type: MQTT_ACTIONS.STOP_CONNECTION });
  yield put(mqttSlice.actions.init());
}

function* watchRefreshToken() {
  yield takeEvery(AUTH_ACTIONS.REFRESH, handleRefreshToken);
}

function* handleRefreshPassword(action: PayloadAction<string>): Generator {
  try {
    const response = (yield call(
      authService.resetPassword,
      action.payload
    )) as AxiosResponse;

    if (response.status === 204) {
      yield put(authSlice.actions.refreshPasswordSuccess());
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const { code } = err.response?.data as ErrorData;

      if (code === 102) {
        const status = err.response?.status;
        yield put(authSlice.actions.refreshPasswordError(`${status}`));
      }
    }

    if (!(err instanceof Error)) {
      yield put(authSlice.actions.loginError("Unknown error occured"));
    }
  }
}

function* watchRefreshPassword() {
  yield takeEvery(AUTH_ACTIONS.REFRESH_PASSWORD, handleRefreshPassword);
}

export function* authWatcher() {
  yield all([
    fork(watchLogin),
    fork(watchLogut),
    fork(watchRefreshToken),
    fork(watchRefreshPassword),
  ]);
}

export default authSlice;
