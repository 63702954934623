import { Button, Grid, Typography } from "@mui/material";
import ModalWrapper from "components/common/ModalWrapper";
import DashboardGrid from "components/DashboardGrid";
import useDeleteModal from "hooks/useDeleteModal";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_DEVICES_ACTIONS,
  createHasDeviceCertData,
} from "store/adminDevices";

type Prop = {
  deviceId: number;
  certType: "ovpn" | "iot_core";
  title: string;
  editLabel: string;
  addLabel: string;
};

const buttonStyle = {
  marginRight: "20px",
  textTransform: "none",
  marginBottom: "20px",
} as any;

function CertificateGrid({
  deviceId,
  certType,
  title,
  editLabel,
  addLabel,
}: Prop) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File>();
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const getHasDeviceCert = useMemo(
    () => createHasDeviceCertData(deviceId, certType),
    [certType, deviceId]
  );
  const hasCertData = useSelector(getHasDeviceCert);

  const onGet = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.GET_CERTIFICATE,
      payload: {
        id: deviceId,
        certType,
      },
    });
  }, [certType, deviceId, dispatch]);

  const onCreate = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.CREATE_CERTIFICATE,
      payload: {
        id: deviceId,
        certType,
      },
    });
  }, [certType, deviceId, dispatch]);

  const onCloseImportModal = useCallback(() => setIsImportModalOpen(false), []);

  const onDelete = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.DELETE_CERTIFICATE,
      payload: {
        id: deviceId,
        certType,
      },
    });
    onCloseDeleteModal();
  }, [certType, deviceId, dispatch, onCloseDeleteModal]);

  const onRevokeClick = useCallback(() => {
    onOpenDeleteModal();
  }, [onOpenDeleteModal]);

  const onImportClick = useCallback(() => {
    setIsImportModalOpen(true);
  }, []);

  const onImport = useCallback(() => {
    dispatch({
      type: ADMIN_DEVICES_ACTIONS.IMPORT_CERTIFICATE,
      payload: {
        id: deviceId,
        certType,
        file,
      },
    });

    onCloseImportModal();
  }, [certType, deviceId, dispatch, file, onCloseImportModal]);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  return (
    <DashboardGrid
      title=""
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "700" }}>{title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary">
              {hasCertData ? editLabel : addLabel}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {hasCertData ? (
              <>
                <Button
                  style={buttonStyle}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={onGet}
                >
                  {t("modules.devices.grid.certificate.buttons.export")}
                </Button>
                <Button
                  style={buttonStyle}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={onImportClick}
                >
                  {t("modules.devices.grid.certificate.buttons.import")}
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    marginBottom: "20px",
                    color: "inherit",
                  }}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={onRevokeClick}
                >
                  {t("modules.devices.grid.certificate.buttons.revoke")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={buttonStyle}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={onCreate}
                >
                  {t("modules.devices.grid.certificate.buttons.create")}
                </Button>
                <Button
                  style={{ textTransform: "none", marginBottom: "20px" }}
                  size="small"
                  variant="outlined"
                  onClick={onImportClick}
                >
                  {t("modules.devices.grid.certificate.buttons.import")}
                </Button>
              </>
            )}
          </Grid>
          <ModalWrapper
            isOpen={isDeleteModalOpen}
            onCancelAction={onCloseDeleteModal}
            onOkAction={onDelete}
            title={t("modules.devices.grid.certificate.buttons.revoke")}
            contentLabel={t("modules.devices.grid.certificate.revokeMessage")}
            okLabel={t("buttons.delete")}
            cancelLabel={t("buttons.close")}
          />
          <ModalWrapper
            isOpen={isImportModalOpen}
            onCancelAction={onCloseImportModal}
            onOkAction={onImport}
            title={t("modules.devices.grid.certificate.buttons.import")}
            contentLabel=""
            okLabel={t("buttons.save")}
            cancelLabel={t("buttons.close")}
            content={<input type="file" onChange={onFileChange} />}
          />
        </Grid>
      }
    />
  );
}

export default CertificateGrid;
