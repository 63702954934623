import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getAvailableNavItems } from "store/auth";
import uiSlice from "store/ui";
import { getNavItemIcon } from "./common/navItems";

const useStyles = makeStyles((theme: any) => ({
  linkContainer: {
    marginLeft: "20px",
    flexGrow: 1,
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },
  active: {
    color: theme.palette.primary.main,
    "& $icon": {
      color: theme.palette.primary.dark,
    },
  },
  icon: {
    marginTop: "5px",
    marginRight: "5px",
  },
}));

type CustomNavItemProp = {
  title: string;
  url: string;
  icon: any;
  onClick: any;
};

function CustomNavItem({ title, url, icon, onClick }: CustomNavItemProp) {
  const styles = useStyles();

  return (
    <ListItem disablePadding divider>
      <ListItemButton onClick={onClick}>
        <ListItemText>
          <NavLink
            to={url}
            className={({ isActive }) =>
              isActive ? classNames(styles.link, styles.active) : styles.link
            }
          >
            {icon}
            {title}
          </NavLink>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

function NavItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  const availableNavItems = useSelector(getAvailableNavItems);

  const onListItemClick = useCallback(
    (path: string) => {
      navigate(path);
      dispatch(uiSlice.actions.setIsDrawerOpen(false));
    },
    [dispatch, navigate]
  );

  const renderChildren = useCallback(
    (parentUrl: string, children: any[]) =>
      children.map((item) => (
        <CustomNavItem
          key={item.url}
          url={`${parentUrl}/${item.url}`}
          title={t(`modules.${item.titleKey}.title`)}
          onClick={() => onListItemClick(`${parentUrl}/${item.url}`)}
          icon={getNavItemIcon(parentUrl, styles.icon)}
        />
      )),
    [onListItemClick, styles.icon, t]
  );

  return (
    <List>
      {availableNavItems.map((item) =>
        item.children ? (
          renderChildren(item.url, item.children)
        ) : (
          <CustomNavItem
            key={item.url}
            url={item.url}
            title={t(`modules.${item.titleKey}.title`)}
            onClick={() => onListItemClick(item.url)}
            icon={getNavItemIcon(item.url, styles.icon)}
          />
        )
      )}
    </List>
  );
}

export default NavItems;
