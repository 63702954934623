import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Typography,
} from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { getFullName, getFormatedDate } from "helpers";
import { ProcessState, WorkMode } from "models/Heater";
import ParametersData from "models/Parameters";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  enableDashboardActionsSelector,
  dashboardItemSerialNumber,
  getLastWorkModeActivitySelector,
} from "store/dashboard";
import {
  dashboardProcessState,
  createIsParameterUpdatingSelector,
  createParameterCurrentUpdateHasErrorSelector,
  acknowledgeErrorId,
  PARAMETERS_ACTIONS,
} from "store/dashboardParameters";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { isRemoteActionEnabledSelector } from "store/auth";

type Props = {
  id: number;
};

function WorkModeGrid({ id }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const enableDashboardActions = useSelector(enableDashboardActionsSelector);
  const processState = useSelector(dashboardProcessState);
  const activity = useSelector(getLastWorkModeActivitySelector);
  const isRemoteActionEnabled = useSelector(isRemoteActionEnabledSelector);

  const fullName = getFullName(
    activity?.firstName,
    activity?.lastName,
    activity?.email
  );
  const date = getFormatedDate(activity?.timestamp);

  const getIsWorkModeParamUpdating = useMemo(
    () =>
      createIsParameterUpdatingSelector(
        serialNumber,
        ParametersData.ProcessState.id
      ),
    [serialNumber]
  );

  const getIsAckErrorParamUpdating = useMemo(
    () => createIsParameterUpdatingSelector(serialNumber, acknowledgeErrorId),
    [serialNumber]
  );

  const getHasParameterError = useMemo(
    () =>
      createParameterCurrentUpdateHasErrorSelector(
        serialNumber,
        ParametersData.ProcessState.id
      ),
    [serialNumber]
  );

  const getHasAckErrorParameterError = useMemo(
    () =>
      createParameterCurrentUpdateHasErrorSelector(
        serialNumber,
        ParametersData.ProcessState.id
      ),
    [serialNumber]
  );

  const isParamUpdating = useSelector(getIsWorkModeParamUpdating);
  const isAckErrorUpdating = useSelector(getIsAckErrorParamUpdating);
  const isUpdating = isParamUpdating || isAckErrorUpdating;
  const parameterUpdateError = useSelector(getHasParameterError);
  const ackErrorUpdateError = useSelector(getHasAckErrorParameterError);
  const hasError = parameterUpdateError || ackErrorUpdateError;
  const showStopAction =
    processState === ProcessState.CalendarIdle ||
    processState === ProcessState.Ventilation ||
    processState === ProcessState.NormalMode;

  const onWorkModeChange = useCallback(
    (value: number) => {
      dispatch({
        type: PARAMETERS_ACTIONS.SET_WORK_MODE,
        payload: { deviceId: id, serialNumber, value },
      });
    },
    [dispatch, id, serialNumber]
  );

  const onAcknowledgeError = useCallback(
    () =>
      dispatch({
        type: PARAMETERS_ACTIONS.ACKNOWLEDGE_ERROR,
        payload: { deviceId: id, serialNumber },
      }),
    [dispatch, id, serialNumber]
  );

  const buttonGroup = useMemo(
    () => (
      <ButtonGroup
        disabled={!enableDashboardActions}
        variant="outlined"
        color="primary"
      >
        {processState === ProcessState.NotInitialized && (
          <Button
            onClick={() => onWorkModeChange(WorkMode.startInitialization)}
          >
            {t(`mode.${WorkMode.startInitialization || 0}`)}
          </Button>
        )}

        {(processState === ProcessState.IdleReady ||
          processState === ProcessState.Shutdown) && (
          <Button onClick={() => onWorkModeChange(WorkMode.startNormalMode)}>
            {t(`mode.${WorkMode.startNormalMode || 0}`)}
          </Button>
        )}

        {(processState === ProcessState.IdleReady ||
          processState === ProcessState.Shutdown) && (
          <Button onClick={() => onWorkModeChange(WorkMode.startCalendarMode)}>
            {t(`mode.${WorkMode.startCalendarMode || 0}`)}
          </Button>
        )}

        {processState === ProcessState.IdleReady && (
          <Button
            onClick={() => onWorkModeChange(WorkMode.startVentilationMode)}
          >
            {t(`mode.${WorkMode.startVentilationMode || 0}`)}
          </Button>
        )}

        {showStopAction && (
          <Button
            onClick={() =>
              onWorkModeChange(WorkMode.stopManualCalendarOrVentilationMode)
            }
          >
            {t(`mode.${WorkMode.stopManualCalendarOrVentilationMode || 0}`)}
          </Button>
        )}

        {processState === ProcessState.IdleControledErrorActive && (
          <Button onClick={onAcknowledgeError}>
            {t("mode.acknowledgeError")}
          </Button>
        )}
      </ButtonGroup>
    ),
    [
      enableDashboardActions,
      onAcknowledgeError,
      onWorkModeChange,
      processState,
      showStopAction,
      t,
    ]
  );

  return (
    <DashboardGrid
      title={t("mode.header")}
      titleExtra={
        <>
          {isUpdating ? (
            <CircularProgress
              style={{ marginLeft: "20px", marginTop: "5px" }}
            />
          ) : (
            <Typography style={{ fontSize: "12px" }} color="textSecondary">
              {t("modules.dashboard.grid.parameters.lastModifiedBy")}
              &nbsp; {fullName || "-"}, &nbsp;
              {date}
              <br />
              {t("modules.dashboard.grid.parameters.previousWorkMode")}: &nbsp;
              {t(`mode.${WorkMode[activity?.lastModifiedKey] || 0}`)}
            </Typography>
          )}

          {hasError && (
            <SyncProblemIcon color="error" style={{ marginTop: "5px" }} />
          )}
        </>
      }
      includeBottomDivider
      content={
        <FormControl>
          <Typography variant="h5">
            {t(`processState.${processState || 0}`)}
          </Typography>
          <br />
          {isRemoteActionEnabled && buttonGroup}
        </FormControl>
      }
    />
  );
}

export default WorkModeGrid;
