import ParameterGridItem from "components/heaterDetails/parameters/ParameterGridItem";
import { t } from "i18next";
import ParametersData, { ParametersType } from "models/Parameters";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createParamActivitySelector,
  dashboardItemSerialNumber,
  enableDashboardActionsSelector,
  getEarlierSetParamId,
} from "store/dashboard";
import dashboardParametersSlice, {
  createIsParameterUpdatingSelector,
  createParameterCurrentUpdateHasErrorSelector,
  dashboardHotAirFanPower,
} from "store/dashboardParameters";

type Props = {
  onParameterClick: (value: number, type: ParametersType) => void;
};

function TargetHotAirTemperatureItem({ onParameterClick }: Props) {
  const dispatch = useDispatch();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const enableDashboardActions = useSelector(enableDashboardActionsSelector);
  const hotAirFanPower = useSelector(dashboardHotAirFanPower);
  const { id } = ParametersData.HotAirFanPower;
  const earlierSetParamId = useSelector(getEarlierSetParamId);
  const fadeOut = id === earlierSetParamId;

  const getIsParamUpdating = useMemo(
    () => createIsParameterUpdatingSelector(serialNumber, id),
    [id, serialNumber]
  );

  const getActivity = useMemo(() => createParamActivitySelector(id), [id]);

  const getHasParameterError = useMemo(
    () => createParameterCurrentUpdateHasErrorSelector(serialNumber, id),
    [id, serialNumber]
  );

  const isParamUpdating = useSelector(getIsParamUpdating);
  const activity = useSelector(getActivity);
  const parameterUpdateError = useSelector(getHasParameterError);

  const onTargetHotAirFanPower = useCallback(
    (value: number) => onParameterClick(value, "HotAirFanPower"),
    [onParameterClick]
  );

  const onValueChange = useCallback(
    (value: number) => {
      dispatch(dashboardParametersSlice.actions.setHotAirFanPower(value));
    },
    [dispatch]
  );

  return (
    <ParameterGridItem
      title={t("modules.dashboard.grid.parameters.targetHotAirFanPower")}
      inputLabel={ParametersData.HotAirFanPower.unit}
      value={hotAirFanPower}
      onValueChange={onValueChange}
      min={ParametersData.HotAirFanPower.min}
      max={ParametersData.HotAirFanPower.max}
      activity={activity}
      onClick={onTargetHotAirFanPower}
      actionDisabled={!enableDashboardActions}
      actionInProgress={isParamUpdating}
      actionFailed={parameterUpdateError}
      fadeOut={fadeOut}
    />
  );
}

export default TargetHotAirTemperatureItem;
