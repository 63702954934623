import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import mapKeys from "lodash/mapKeys";

import { Group } from "models/Group";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import groupsService from "services/group";

export const GROUP_ACTIONS = {
  ADD_GROUP: "ADD_GROUP",
  DELETE_GROUP: "DELETE_GROUP",
  EDIT_GROUP: "EDIT_GROUP",
  UPDATE_GROUP_MEMBERS: "UPDATE_GROUP_MEMBERS",
};

type GroupState = {
  initializing: boolean;
  groups: Record<number, Group>;
  error?: string;
};

const initialState: GroupState = {
  initializing: false,
  groups: {},
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    init: (state) => {
      state.initializing = true;
    },
    initSuccess: (state, { payload }: PayloadAction<Group[]>) => {
      state.groups = mapKeys(payload, (group) => group.id);
      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    addGroupSuccess: (
      state,
      { payload }: PayloadAction<Omit<Group, "devices">>
    ) => {
      state.groups[payload.id] = {
        ...payload,
        devices: [],
      };
    },
    deleteGroupSucces: (state, { payload }: PayloadAction<number>) => {
      state.groups = omit(state.groups, payload);
    },
    editGroupSucces: (
      state,
      { payload }: PayloadAction<{ groupId: number; label: string }>
    ) => {
      const { groupId, label } = payload;
      state.groups[groupId].label = label;
    },
  },
});

function groupSelector(state: { [groupSlice.name]: GroupState }) {
  return state[groupSlice.name];
}

export const groupsByIdSelector = createSelector(
  groupSelector,
  (state: GroupState) => state.groups
);

export const createGroupNameByIdSelector = (groupId: number) =>
  createSelector(
    groupsByIdSelector,
    (groupsById) => groupsById[groupId]?.label
  );

export const groupsSelector = createSelector(
  groupsByIdSelector,
  (groupsById: Record<number, Group>) => Object.values(groupsById)
);

export const groupNamesSelector = createSelector(
  groupsSelector,
  (groups: Group[]) => groups.map((group) => group.label)
);

function* handleAddGroup(action: PayloadAction<{ label: string }>): Generator {
  try {
    const { label } = action.payload;

    const group = (yield call(groupsService.addGroup, label)) as Omit<
      Group,
      "devices"
    >;
    yield put(groupSlice.actions.addGroupSuccess(group));
  } catch (error) {
    yield put(groupSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddGroup() {
  yield takeLatest(GROUP_ACTIONS.ADD_GROUP, handleAddGroup);
}

function* handleDeleteGroup(
  action: PayloadAction<{ groupId: number }>
): Generator {
  try {
    const { groupId } = action.payload;
    yield call(groupsService.deleteGroup, groupId);
    yield put(groupSlice.actions.deleteGroupSucces(groupId));
  } catch (error) {
    yield put(groupSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteGroup() {
  yield takeLatest(GROUP_ACTIONS.DELETE_GROUP, handleDeleteGroup);
}

function* handleEditGroup(
  action: PayloadAction<{ groupId: number; label: string }>
): Generator {
  try {
    const { groupId, label } = action.payload;
    yield call(groupsService.editGroup, groupId, label);

    yield put(groupSlice.actions.editGroupSucces({ groupId, label }));
  } catch (error) {
    yield put(groupSlice.actions.initError("Something went wrong..."));
  }
}

function* watchEditGroup() {
  yield takeLatest(GROUP_ACTIONS.EDIT_GROUP, handleEditGroup);
}

function* handleUpdateGroupMembers(
  action: PayloadAction<{
    groupId: number;
    add: number[];
    remove: number[];
  }>
): Generator {
  try {
    const { groupId, add, remove } = action.payload;

    if (add.length === 0) {
      yield call(groupsService.updateGroupMembership, groupId, add, remove);
    } else {
      yield call(groupsService.updateGroupMembership, groupId, add, remove);
    }

    yield put(groupSlice.actions.init());
  } catch (error) {
    yield put(groupSlice.actions.initError("Something went wrong..."));
  }
}

function* watchUpdateGroupMembers() {
  yield takeLatest(
    GROUP_ACTIONS.UPDATE_GROUP_MEMBERS,
    handleUpdateGroupMembers
  );
}

function* handleInit(): Generator {
  try {
    const groups = (yield call(groupsService.getGroups)) as Group[];
    yield put(groupSlice.actions.initSuccess(groups));
  } catch (err) {
    yield put(groupSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(groupSlice.actions.init, handleInit);
}

export function* groupWatcher() {
  yield all([
    fork(watchInit),
    fork(watchAddGroup),
    fork(watchDeleteGroup),
    fork(watchEditGroup),
    fork(watchUpdateGroupMembers),
  ]);
}

export default groupSlice;
