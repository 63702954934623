import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomHidden from "components/common/CustomHidden";
import getComparator, { Order } from "components/common/tableHelpers";
import Badge from "components/icons/Badge";
import DeviceModelIcon from "components/icons/DeviceModel";
import {
  defaultRowsPerPage,
  rowsPerPageOptions,
  isHeaterDataOld,
} from "helpers";
import { DeviceModel, Heater } from "models/Heater";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ACTIONS } from "store/dashboard";
import { getDashboardBatchUpdateMode } from "store/ui";
import UpdatesDrawer from "./UpdatesDrawer";
import ConnectivityStatusIcon from "../icons/ConnectivityStatusIcon";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
  cell: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

type Props = {
  heaters: Heater[];
  remoteDeviceIds: number[];
  onCheckboxChange: (deviceId: number) => void;
  onSelectAll: () => void;
  onClearAll: () => void;
};

type OrderByTypes =
  | "machineName"
  | "modelLabel"
  | "processStateLabel"
  | "targetRoomTemperature"
  | "softwareVersion";

function HeatersTable({
  heaters,
  remoteDeviceIds,
  onCheckboxChange,
  onSelectAll,
  onClearAll,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("softwareVersion");
  const batchMode = useSelector(getDashboardBatchUpdateMode);

  const onRowClick = useCallback((id: number) => navigate(`${id}`), [navigate]);

  const headCells = useMemo(
    () => [
      {
        id: "machineName" as OrderByTypes,
        label: t("modules.dashboard.table.machineName").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
      {
        id: "modelLabel" as OrderByTypes,
        label: t("modules.dashboard.table.model").toUpperCase(),
        className: styles.headCell,
        smallHidden: false,
      },
      {
        id: "processStateLabel" as OrderByTypes,
        label: t("modules.dashboard.table.workMode").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
        width: "200px",
      },
      {
        id: "targetRoomTemperature" as OrderByTypes,
        label: t("modules.dashboard.table.temperature").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "softwareVersion" as OrderByTypes,
        label: t("modules.dashboard.table.software").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const mappedValues = useMemo(
    () =>
      heaters.map((heater) => ({
        deviceId: heater.device.id,
        machineName: heater.machineName,
        connected: heater.device.connected,
        modelLabel: DeviceModel[heater.device.model],
        processStateLabel: t(`processState.${heater.processState || 0}`),
        targetRoomTemperature: heater.targetRoomTemperature,
        softwareVersion: heater.softwareVersion,
        warningCount: heater.warningCount,
        errorCount: heater.errorCount,
        isPotentiallyOffline: isHeaterDataOld(heater.lastDataUpdate),
      })),
    [heaters, t]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),

    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const onSave = useCallback(
    (softwareUpdateKey: string, factorySettingsKey: string) => {
      dispatch({
        type: DASHBOARD_ACTIONS.SCHEDULE_UPDATES,
        payload: {
          remoteDeviceIds,
          softwareUpdateKey,
          factorySettingsKey,
        },
      });
      onClearAll();
    },
    [dispatch, onClearAll, remoteDeviceIds]
  );

  return (
    <TableContainer component={Paper}>
      <UpdatesDrawer onSave={onSave} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {batchMode === "count" && (
                <Checkbox
                  checked={heaters.length === remoteDeviceIds.length}
                  onChange={() => onSelectAll()}
                />
              )}
            </TableCell>
            {headCells.map((headCell) =>
              headCell.smallHidden ? (
                <CustomHidden key={headCell.id} size="xs" hideType="only">
                  <TableCell
                    className={headCell.className}
                    sortDirection={orderBy === headCell.id ? order : false}
                    width={headCell.width}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </CustomHidden>
              ) : (
                <TableCell
                  key={`${headCell.id} - ${headCell.label}`}
                  className={headCell.className}
                  sortDirection={orderBy === headCell.id ? order : false}
                  width={headCell.width}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((heater) => (
              <TableRow
                key={`row-${heater.machineName}-${heater.deviceId}`}
                hover
              >
                <TableCell>
                  {batchMode === "count" && (
                    <Checkbox
                      checked={remoteDeviceIds.includes(heater.deviceId)}
                      onChange={() => onCheckboxChange(heater.deviceId)}
                    />
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  scope="heater"
                  onClick={() => onRowClick(heater.deviceId)}
                >
                  <div className={styles.cell} style={{ width: "200px" }}>
                    <ConnectivityStatusIcon
                      status={{
                        isConnected: heater.connected,
                        isPotentiallyOffline: heater.isPotentiallyOffline,
                      }}
                    />
                    <Typography variant="body1">
                      {heater.machineName}
                    </Typography>
                    {heater.errorCount > 0 && (
                      <Badge type="error" count={heater.errorCount} />
                    )}
                    {heater.warningCount > 0 && (
                      <Badge type="warning" count={heater.warningCount} />
                    )}
                  </div>
                </TableCell>
                <TableCell onClick={() => onRowClick(heater.deviceId)}>
                  <DeviceModelIcon model={heater.modelLabel} />
                </TableCell>
                <CustomHidden size="sm" hideType="down">
                  <TableCell onClick={() => onRowClick(heater.deviceId)}>
                    <Typography variant="body1">
                      {heater.processStateLabel}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => onRowClick(heater.deviceId)}>
                    <Typography variant="body1">
                      {heater.targetRoomTemperature}{" "}
                      <span style={{ fontSize: "14px" }}>&#x2103;</span>
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => onRowClick(heater.deviceId)}>
                    <Typography variant="body1">
                      {heater.softwareVersion}
                    </Typography>
                  </TableCell>
                </CustomHidden>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedValues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default HeatersTable;
