import { makeStyles } from "@mui/styles";
import { useCallback, useState, type MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "components/common/ModalWrapper";
import { USERS_ACTIONS } from "store/users";
import { useDispatch } from "react-redux";
import useDeleteModal from "hooks/useDeleteModal";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
  alert: {
    margin: "10px 0",
  },
}));

type Props = {
  userId: number;
};

function ManageUserMenu({ userId }: Props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onEditClick = useCallback(
    () => navigate(`${userId}`),
    [navigate, userId]
  );

  const onDeleteUser = useCallback(() => {
    dispatch({
      type: USERS_ACTIONS.DELETE_USER,
      payload: {
        userId,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, onCloseDeleteModal, userId]);

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onEditClick}>
          <Typography>{t("modals.users.edit")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenDeleteModal();
            handleClose();
          }}
        >
          <Typography color="error">{t("modals.users.delete")}</Typography>
        </MenuItem>
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDeleteUser}
        title={t("modals.users.delete")}
        contentLabel={t("modals.users.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default ManageUserMenu;
