import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { HeaterScheduledUpdatesInfo } from "../models/Heater";

export const HEATER_SCHEDULED_UPDATES_ACTIONS = {
  GET_HEATER_SCHEDULED_UPDATES: "GET_HEATER_SCHEDULED_UPDATES",
  DELETE_HEATER_SCHEDULED_UPDATE: "DELETE_HEATER_SCHEDULED_UPDATE",
};

type HeaterScheduledUpdatesState = {
  initializing: boolean;
  data: HeaterScheduledUpdatesInfo[];
  error?: string;
};

const initialState: HeaterScheduledUpdatesState = {
  initializing: false,
  data: [],
};

const heaterScheduledUpdatesSlice = createSlice({
  name: "heaterScheduledUpdates",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<number>) => {
      state.initializing = true;
      state.data = [];
    },
    initSuccess: (
      state,
      { payload }: PayloadAction<HeaterScheduledUpdatesInfo[]>
    ) => {
      state.data = payload;
      state.initializing = false;
    },
    deleteSuccess: (
      state,
      { payload }: PayloadAction<{ updateId: number }>
    ) => {
      const { updateId } = payload;
      state.data = state.data.filter((item) => item.id !== updateId);
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
  },
});

function heaterScheduledUpdatesSelector(state: {
  [heaterScheduledUpdatesSlice.name]: HeaterScheduledUpdatesState;
}) {
  return state[heaterScheduledUpdatesSlice.name];
}

export const heaterScheduledUpdatesListSelector = createSelector(
  heaterScheduledUpdatesSelector,
  (state: HeaterScheduledUpdatesState) => state.data
);

function* handleDelete(action: PayloadAction<{ updateId: number }>): Generator {
  try {
    const { updateId } = action.payload;
    yield call(heaterService.deleteHeaterScheduledUpdate, updateId);

    yield put(heaterScheduledUpdatesSlice.actions.deleteSuccess({ updateId }));
  } catch (error) {
    yield put(
      heaterScheduledUpdatesSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchDelete() {
  yield takeLatest(
    HEATER_SCHEDULED_UPDATES_ACTIONS.DELETE_HEATER_SCHEDULED_UPDATE,
    handleDelete
  );
}

function* handleInit(action: PayloadAction<number>): Generator {
  try {
    const deviceId = action.payload;
    const response = (yield call(
      heaterService.getHeaterScheduledUpdates,
      deviceId
    )) as HeaterScheduledUpdatesInfo[];

    yield put(heaterScheduledUpdatesSlice.actions.initSuccess(response));
  } catch (err) {
    yield put(
      heaterScheduledUpdatesSlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchInit() {
  yield takeLatest(heaterScheduledUpdatesSlice.actions.init, handleInit);
}

export function* heaterScheduledUpdatesWatcher() {
  yield all([fork(watchInit), fork(watchDelete)]);
}

export default heaterScheduledUpdatesSlice;
