import { DeviceModel } from "./Heater";
import { Language } from "./Language";

export enum TroubleshootingGuideType {
  ErrorGuideline = "errorGuideline",
  WarningGuideline = "warningGuideline",
  ElectricalSchematic = "electricalSchematic",
  OperatingManual = "operatingManual",
  BOM = "bom",
}

export type TroubleshootingGuideLanguage = Language;

export type TroubleshootingGuide = {
  id: number;
  type: TroubleshootingGuideType;
  language: TroubleshootingGuideLanguage;
  deviceModel: DeviceModel;
  code: number | null;
  s3Key: string;
  createdAt: Date;
  updatedAt: Date;
  remoteDevices?: number[];
};

export type GuideFormValues = {
  type: TroubleshootingGuideType;
  language: TroubleshootingGuideLanguage;
  deviceModel: DeviceModel;
  code: number;
  update: string;
  fileName: string;
  remoteDevices?: number[];
};
