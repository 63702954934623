import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import LanguageSelectField from "components/fields/LanguageSelectField";
import { useFormikContext } from "formik";
import { getFirstLetterToUpperCase } from "helpers";
import { DeviceModel } from "models/Heater";
import {
  GuideFormValues,
  TroubleshootingGuideType,
} from "models/TroubleshootingGuide";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isEdit: boolean;
};

function GeneralInformationGrid({ isEdit }: Props) {
  const { t } = useTranslation();
  const { handleChange, values, setFieldValue } =
    useFormikContext<GuideFormValues>();
  const troubleshootingGuideTypes = Object.values(TroubleshootingGuideType);
  const deviceModels = [DeviceModel.G200];
  const isCodeVisible =
    values.type === TroubleshootingGuideType.ErrorGuideline ||
    values.type === TroubleshootingGuideType.WarningGuideline;

  return (
    <DashboardGrid
      title={t("modules.troubleshooting.grid.generalInformation.header")}
      includeBottomDivider
      content={
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Typography>
              {getFirstLetterToUpperCase(
                t("modules.troubleshooting.grid.generalInformation.type")
              )}
            </Typography>
            <Select
              disabled={isEdit}
              name="type"
              variant="outlined"
              size="small"
              fullWidth
              required
              value={values.type}
              onChange={handleChange}
            >
              {troubleshootingGuideTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`modules.troubleshooting.types.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography>
              {getFirstLetterToUpperCase(
                t("modules.troubleshooting.grid.generalInformation.language")
              )}
            </Typography>
            <LanguageSelectField
              name="language"
              currentValue={values.language}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4} />
          <Grid item xs={6} md={4}>
            <Typography>
              {getFirstLetterToUpperCase(
                t("modules.troubleshooting.grid.generalInformation.deviceModel")
              )}
            </Typography>
            <Select
              disabled={isEdit}
              size="small"
              name="deviceModel"
              variant="outlined"
              fullWidth
              required
              value={values.deviceModel}
              onChange={handleChange}
            >
              {deviceModels.map((model) => (
                <MenuItem key={model} value={model}>
                  {DeviceModel[model]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {isCodeVisible && (
            <Grid item xs={6} md={4}>
              <Typography>
                {getFirstLetterToUpperCase(
                  t("modules.troubleshooting.grid.generalInformation.code")
                )}
              </Typography>
              <TextField
                disabled={isEdit}
                size="small"
                type="number"
                name="code"
                value={values.code}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
          <Grid md={4} />
          {!isEdit && (
            <Grid item xs={12} md={8}>
              <Typography>
                {getFirstLetterToUpperCase(
                  t("modules.troubleshooting.grid.generalInformation.file")
                )}
              </Typography>
              <input
                type="file"
                name="update"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const file = event.currentTarget.files?.[0] || null;
                  if (file) {
                    setFieldValue("fileName", file.name);
                    setFieldValue("update", file);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
}
export default GeneralInformationGrid;
