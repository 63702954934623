import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import getComparator, { Order } from "components/common/tableHelpers";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import {
  defaultRowsPerPage,
  getFormatedDate,
  rowsPerPageOptions,
} from "helpers";
import { HeaterErrorOrWarningInfo } from "../../../models/Heater";

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
}));

type OrderByTypes = "code" | "timeOn" | "timeOff" | "timeConfirm";

type Props = {
  errors: HeaterErrorOrWarningInfo[];
};

function HeaterErrorsTable({ errors }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByTypes>("timeOn");
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const headCells = useMemo(
    () => [
      {
        id: "code" as OrderByTypes,
        label: t("modules.errorHistory.table.code").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "timeOn" as OrderByTypes,
        label: t("modules.errorHistory.table.timeOn").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "timeOff" as OrderByTypes,
        label: t("modules.errorHistory.table.timeOff").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
      {
        id: "timeConfirm" as OrderByTypes,
        label: t("modules.errorHistory.table.timeConfirm").toUpperCase(),
        className: styles.headCell,
        smallHidden: true,
      },
    ],
    [styles.headCell, t]
  );

  const handleRequestSort = useCallback(
    (property: OrderByTypes) => {
      const isAscending = orderBy === property && order === "asc";
      setOrder(isAscending ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const mappedValues = useMemo(
    () =>
      errors.map((error) => ({
        id: error.id,
        code: error.code,
        timeOn: error.timeOn,
        timeOff: error.timeOff,
        timeConfirm: error.timeConfirm,
      })),
    [errors]
  );

  const sortedValues = useMemo(
    () => mappedValues.sort(getComparator(order, orderBy)),
    [mappedValues, order, orderBy]
  );

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                className={headCell.className}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValues
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((error) => (
              <TableRow key={`row-${error.id}`}>
                <TableCell padding="checkbox" />
                <TableCell align="left">
                  <Typography variant="body1">
                    {t(`error.${error.code}`)}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {error.timeOn ? getFormatedDate(error.timeOn) : "_"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {error.timeOff ? getFormatedDate(error.timeOff) : "_"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1">
                    {error.timeConfirm
                      ? getFormatedDate(error.timeConfirm)
                      : "_"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={mappedValues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default HeaterErrorsTable;
