import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrganizationKind } from "../../models/Organization";
import { enumStringValues } from "../../utils/enum.util";

type Props = {
  name: string;
  currentValue: OrganizationKind;
  onChange: (event: SelectChangeEvent<string>) => void;
};

function OrganizationKindSelectField({ name, currentValue, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <Select
      size="small"
      name={name}
      variant="outlined"
      fullWidth
      required
      value={currentValue}
      onChange={onChange}
      renderValue={(value: string) => t(`organizationKind.${value}`)}
    >
      {enumStringValues(OrganizationKind).map((kind) => (
        <MenuItem key={kind} value={kind}>
          {t(`organizationKind.${kind}`)}
        </MenuItem>
      ))}
    </Select>
  );
}

export default OrganizationKindSelectField;
