import MaintenanceGridItem from "components/heaterDetails/maintenance/MaintenanceGridItem";
import ParametersData from "models/Parameters";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardItemSerialNumber,
  getLastHoursTillMaintenanceActivitySelector,
} from "store/dashboard";
import {
  PARAMETERS_ACTIONS,
  createIsParameterUpdatingSelector,
  dashboardMaintenanceInterval,
  dashboardTimePredictionTillNextService,
} from "store/dashboardParameters";

type Props = {
  id: number;
};

function TimeUntilMaintenance({ id }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activity = useSelector(getLastHoursTillMaintenanceActivitySelector);
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const maintanceInterval = useSelector(dashboardMaintenanceInterval);
  const timePredictionTillNextMaintenance = useSelector(
    dashboardTimePredictionTillNextService
  );
  const period = maintanceInterval - timePredictionTillNextMaintenance;

  const getIsTimeUntilMaintenanceUpdating = useMemo(
    () =>
      createIsParameterUpdatingSelector(
        serialNumber,
        ParametersData.HoursTillMaintenance.id
      ),
    [serialNumber]
  );

  const isTimeUntilMaintenanceUpdating = useSelector(
    getIsTimeUntilMaintenanceUpdating
  );

  const maintancePercentage = useMemo(() => {
    if (period > 0) {
      return (period / maintanceInterval) * 100;
    }
    return 100;
  }, [maintanceInterval, period]);

  const onResetTimeUntilMaintenance = useCallback(
    () =>
      dispatch({
        type: PARAMETERS_ACTIONS.RESET_TIME_UNTIL_MAINTENANCE,
        payload: { deviceId: id, serialNumber },
      }),
    [dispatch, id, serialNumber]
  );

  return (
    <MaintenanceGridItem
      title={t("modules.dashboard.grid.maintenance.timeUntilMaintenance")}
      valueLabel={`${timePredictionTillNextMaintenance} ${t(
        "modules.dashboard.grid.maintenance.remaining"
      )}`}
      value={maintancePercentage}
      actionInProgress={isTimeUntilMaintenanceUpdating}
      activity={activity}
      onClick={onResetTimeUntilMaintenance}
      showProgressBar={false}
    />
  );
}

export default TimeUntilMaintenance;
