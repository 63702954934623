import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  linkButton: {
    textTransform: "none",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "150px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "70px",
    },
  },
  text: {
    width: "310px",
    textAlign: "center",
    fontSize: "20px",
  },
}));

type Props = {
  icon: ReactElement;
  emptyListText: string;
  isAddEnabled?: boolean;
  onOpen: () => void;
};

function EmptyList({
  icon,
  emptyListText,
  isAddEnabled = true,
  onOpen,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.emptyContainer}>
      {icon}
      {isAddEnabled && (
        <p className={styles.text}>
          {emptyListText}

          <Button
            className={styles.linkButton}
            color="primary"
            variant="text"
            onClick={onOpen}
          >
            {t("buttons.addOneNow")}
          </Button>
        </p>
      )}
    </div>
  );
}

export default EmptyList;
