import { SvgIcon, useTheme } from "@mui/material";
import { useMemo } from "react";

type Props = {
  type?: string;
};

function GridViewIcon({ type }: Props) {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (type) {
      case "active":
        return theme.palette.grey["900"];
      case "inactive":
        return theme.palette.grey["300"];
      default:
        return theme.palette.grey["900"];
    }
  }, [theme.palette.grey, type]);

  return (
    <SvgIcon viewBox="0 0 16 16" sx={{ fill: "none" }}>
      <path
        d="M6.5 0.5H0.5V6.5H6.5V0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 0.5H9.5V6.5H15.5V0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.5H0.5V15.5H6.5V9.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 9.5H9.5V15.5H15.5V9.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default GridViewIcon;
