import { Tabs, Tab, Box } from "@mui/material";
import PdfViewer from "components/common/PdfViewer";
import DeviceDashboardHeader from "components/heaterDetails/DeviceDashboardHeader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "components/common/TabsPanel";
import paths from "config/paths";
import { useDispatch, useSelector } from "react-redux";
import troubleshootingSlice, {
  troubleshootingDeviceIdSelector,
  troubleshootingUrlsSelector,
} from "store/troubleshooting";
import dashboardSlice from "store/dashboard";
import { getLanguage } from "store/ui";
import { useSearchParams } from "react-router-dom";
import {
  TroubleshootingCodeType,
  TroubleshootingGuideTypeFile,
} from "models/Troubleshooting";
import { get, indexOf, keys, last } from "lodash";
import useTabStyles from "./useTabStyles";

function Troubleshooting() {
  const [queryParameters] = useSearchParams();
  const styles = useTabStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceId = useSelector(troubleshootingDeviceIdSelector) || 0;
  const files: TroubleshootingGuideTypeFile | undefined = useSelector(
    troubleshootingUrlsSelector
  );
  const language = useSelector(getLanguage);

  const serialNumber = queryParameters.get("serialNumber");
  const codeType = queryParameters.get("type") as TroubleshootingCodeType;
  const code = Number(queryParameters.get("code"));
  const activeTab = queryParameters.get("tab");
  const tabPage = queryParameters.get("page");

  const selectedTabIndex = (guideType: string | null) =>
    guideType ? indexOf(keys(files), guideType, 0) : 0;
  const [selectedTab, setSelectedTab] = useState(
    selectedTabIndex(activeTab) ?? 0
  );
  const [page, setPage] = useState<number | undefined>(
    tabPage ? Number(tabPage) : undefined
  );

  useEffect(() => {
    if (deviceId) {
      dispatch(dashboardSlice.actions.init(+deviceId));
    }
  }, [dispatch, deviceId]);

  useEffect(() => {
    if (serialNumber) {
      dispatch(
        troubleshootingSlice.actions.init({
          serialNumber,
          language,
          codeType,
          code,
        })
      );
    }
  }, [dispatch, language, code, codeType, serialNumber]);

  const tabsClasses = {
    root: styles.tabsRoot,
    indicator: styles.indicator,
  };

  const tabClasses = {
    root: styles.tabRoot,
    selected: styles.selected,
  };

  const handleChange = useCallback((_: unknown, newValue: any) => {
    setSelectedTab(newValue);
    setPage(1);
  }, []);

  const onLinkClick = useCallback(
    (event: any, href: string) => {
      if (!href.includes(paths.troubleshootingDeeplink)) {
        return;
      }
      event?.stopPropagation();
      event?.nativeEvent.stopImmediatePropagation();
      event?.preventDefault();
      const url = new URL(href);
      const searchPage = Number(url.searchParams.get("page"));
      const guideType: string | null = last(url.pathname.split("/")) as string;

      setSelectedTab(selectedTabIndex(guideType));
      setPage(searchPage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab]
  );

  return (
    <div>
      <DeviceDashboardHeader {...{ deviceId }} editable={false} />
      <Tabs
        value={selectedTab}
        textColor="primary"
        variant="scrollable"
        classes={tabsClasses}
        scrollButtons="auto"
        onChange={handleChange}
      >
        {keys(files).map((key) => (
          <Tab
            key={`troubleshooting-tab-${key}`}
            label={t(`modules.troubleshooting.tabs.${key}`)}
            classes={tabClasses}
          />
        ))}
      </Tabs>
      {keys(files).map((key, index) => (
        <TabPanel
          key={`troubleshooting-tabpanel-${key}`}
          value={selectedTab}
          index={index}
          hasBackground
        >
          <Box>
            {index === selectedTab && (
              <PdfViewer file={get(files, key)} {...{ onLinkClick, page }} />
            )}
          </Box>
        </TabPanel>
      ))}
    </div>
  );
}

export default Troubleshooting;
