import { Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dashboardBadgeInfo,
  dashboardItemConnectivityStatus,
  dashboardItemSerialNumber,
} from "store/dashboard";

import DetailsHeader from "components/common/DetailsHeader";
import DeviceInfo from "components/troubleshooting/DeviceInfo";
import Badge from "components/icons/Badge";
import paths from "config/paths";
import { useTranslation } from "react-i18next";
import TargetMachineNameItem from "./parameters/TargetMachineNameItem";
import ConnectivityStatusChip from "../icons/ConnectivityStatusChip";

type Props = {
  deviceId: number;
  editable?: boolean;
};

function DeviceDashboardHeader({ deviceId, editable = true }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const connectivityStatus = useSelector(dashboardItemConnectivityStatus);
  const { errorCount, warningCount } = useSelector(dashboardBadgeInfo);

  const onBackClick = useCallback(() => navigate(paths.dashboard), [navigate]);

  return (
    <DetailsHeader
      mainContent={
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <TargetMachineNameItem {...{ deviceId, editable }} />
          {!editable && (
            <Box>
              {errorCount > 0 && <Badge type="error" count={errorCount} />}
              {warningCount > 0 && (
                <Badge type="warning" count={warningCount} />
              )}
            </Box>
          )}
          <ConnectivityStatusChip status={connectivityStatus} />
          {!editable && (
            <>
              <Box sx={{ flexGrow: 1, ml: 1 }} />
              <Tooltip
                title={<DeviceInfo {...{ deviceId }} />}
                enterTouchDelay={0}
                placement="bottom-end"
                leaveTouchDelay={8000}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "white",
                      boxShadow: 1,
                      "& .MuiTooltip-arrow": {
                        color: "white",
                      },
                    },
                  },
                }}
              >
                <Box sx={{ display: "flex", p: 1, mr: -1 }}>
                  <InfoIcon color="error" />
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      }
      onBack={onBackClick}
      path={t("modules.dashboard.title")}
      item={serialNumber}
    />
  );
}

export default DeviceDashboardHeader;
