import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import MaintenanceBar from "components/heaterDetails/maintenance/MaintenanceBar";
import { getLastModifiedMessage } from "helpers";
import { ActivityRecordBase } from "models/Heater";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isRemoteActionEnabledSelector } from "store/auth";
import { enableDashboardActionsSelector } from "store/dashboard";

type Props = {
  title: string;
  valueLabel: string;
  value: number;
  actionInProgress: boolean;
  activity: ActivityRecordBase | null;
  onClick: () => void;
  showProgressBar?: boolean;
};

function MaintenanceGridItem({
  title,
  valueLabel,
  value,
  actionInProgress,
  activity,
  onClick,
  showProgressBar = true,
}: Props) {
  const { t } = useTranslation();
  const isRemoteActionEnabled = useSelector(isRemoteActionEnabledSelector);
  const enableDashboardActions = useSelector(enableDashboardActionsSelector);
  const lastModifiedMessage = getLastModifiedMessage(
    activity?.firstName,
    activity?.lastName,
    activity?.email,
    activity?.timestamp
  );

  return (
    <>
      <Grid item xs={12} md={7}>
        <MaintenanceBar
          title={title}
          valueLabel={valueLabel}
          value={value}
          showProgressBar={showProgressBar}
        />
      </Grid>

      <Grid item xs={12} />

      {activity && (
        <Grid item xs={9.5} sm={10.5} md={6} lg={6.2}>
          <Typography color="textSecondary" style={{ fontSize: "12px" }}>
            {t("modules.dashboard.grid.parameters.lastModifiedBy")}
            &nbsp;
            {lastModifiedMessage}
          </Typography>
        </Grid>
      )}

      {isRemoteActionEnabled && (
        <Grid item xs={2} sm={1} md={0.5}>
          {actionInProgress ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={!enableDashboardActions}
              color="primary"
              variant="outlined"
              onClick={onClick}
            >
              {t("buttons.reset")}
            </Button>
          )}
        </Grid>
      )}
    </>
  );
}

export default MaintenanceGridItem;
