import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  guacamoleLoadingSelector,
  guacamoleUrlSelector,
} from "store/dashboard";

const useStyles = makeStyles(() => ({
  container: {
    padding: "40px",
    minHeight: "200px",
  },
  iframe: {
    width: "100%",
    height: "480px",
  },
  alert: {
    margin: "20px 0",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "32px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {
  initGuacamole: () => void;
};

function PanelTab({ initGuacamole }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const url = useSelector(guacamoleUrlSelector);
  const loading = useSelector(guacamoleLoadingSelector);
  const showNoPanel = !url && !loading;

  return (
    <Paper className={styles.container}>
      {showNoPanel && (
        <div className={styles.header}>
          <Button
            onClick={initGuacamole}
            size="small"
            color="primary"
            variant="contained"
            startIcon={<ReplayIcon />}
          >
            {t("buttons.refresh")}
          </Button>
        </div>
      )}
      {showNoPanel && (
        <Alert
          className={styles.alert}
          variant="outlined"
          severity="info"
          color="warning"
        >
          <Typography>{t("warningEndErrors.noPanel")}</Typography>
        </Alert>
      )}
      {url && <iframe title="Panel" src={url} className={styles.iframe} />}
      {loading && !url && (
        <div className={styles.loader}>
          <CircularProgress size={60} />
        </div>
      )}
    </Paper>
  );
}

export default PanelTab;
