import { Box, CircularProgress, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState, FocusEvent } from "react";
import {
  createParamActivitySelector,
  dashboardItemConnectivityStatus,
  dashboardItemSerialNumber,
} from "store/dashboard";

import {
  createIsParameterUpdatingSelector,
  dashboardMachineName,
} from "store/dashboardParameters";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconBoxButton from "components/common/IconBoxButton";
import useParameterDispatch from "hooks/useParameterDispatch";
import ParametersData from "models/Parameters";

const useStyles = makeStyles(() => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 0,
      },
      "&:hover": {
        backgroundColor: "lightgray",
      },
      "&:hover fieldset": {
        borderWidth: 0,
      },
      "&.Mui-focused": {
        backgroundColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        "&.MuiOutlinedInput-notchedOutline": {
          borderWidth: 1,
        },
      },
      "&.Mui-disabled:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));

/* eslint-disable */
enum EditModeAction {
  Decline = "DECLINE",
  Accept = "ACCEPT",
}
/* eslint-enable */

type Props = {
  deviceId: number;
  editable?: boolean;
};

function TargetMachineNameItem({ deviceId, editable }: Props) {
  const styles = useStyles();
  const serialNumber = useSelector(dashboardItemSerialNumber);
  const machineName = useSelector(dashboardMachineName);
  const connectivityStatus = useSelector(dashboardItemConnectivityStatus);
  const { updateParameter } = useParameterDispatch(deviceId, serialNumber);
  const { id } = ParametersData.MachineName;
  const [isEditMode, setEditMode] = useState(false);
  const [textValue, setTextValue] = useState("");

  const getActivity = useMemo(() => createParamActivitySelector(id), [id]);
  const activity = useSelector(getActivity);

  const getIsParamUpdating = useMemo(
    () => createIsParameterUpdatingSelector(serialNumber, id),
    [id, serialNumber]
  );
  const isParamUpdating = useSelector(getIsParamUpdating);

  const onEditBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const originMachineName = activity?.currentValue ?? machineName;

    if (event.relatedTarget?.id === `${deviceId}${EditModeAction.Decline}`) {
      setTextValue(originMachineName);
    } else if (textValue !== originMachineName) {
      updateParameter(textValue, "MachineName");
    }

    setEditMode(false);
  };

  useEffect(() => {
    setTextValue(machineName);
  }, [machineName]);

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        size="small"
        className={styles.textField}
        variant="outlined"
        value={textValue}
        autoComplete="off"
        sx={{
          maxWidth: { xs: 140, md: 170 },
        }}
        InputProps={{
          sx: { typography: { xs: "h5", md: "h4" } },
          disabled: !connectivityStatus.isConnected || !editable,
        }}
        onFocus={() => setEditMode(true)}
        onBlur={(event) => onEditBlur(event)}
        onChange={(event) => setTextValue(event.target.value)}
      />
      {isEditMode && (
        <Box sx={{ ml: 1, mt: 4, display: "flex" }}>
          <IconBoxButton
            id={`${deviceId}${EditModeAction.Accept}`}
            sx={{ mr: 0.5 }}
            icon={<CheckIcon fontSize="small" />}
            color="secondary"
          />
          <IconBoxButton
            id={`${deviceId}${EditModeAction.Decline}`}
            icon={<CloseIcon fontSize="small" />}
          />
        </Box>
      )}
      {isParamUpdating && <CircularProgress sx={{ ml: 1 }} size={30} />}
    </Box>
  );
}

export default TargetMachineNameItem;
