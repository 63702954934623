import { makeStyles } from "@mui/styles";
import {
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles((theme: any) => ({
  labelWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  root: {
    height: "15px !important",
    borderRadius: "100px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.background.default,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
  },
  success: {
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
  },
  warning: {
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
  },
  error: {
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
  successLabel: {
    color: theme.palette.success.main,
  },
  warningLabel: {
    color: theme.palette.warning.main,
  },
  errorLabel: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  title: string;
  valueLabel: string;
  value: number;
  showProgressBar?: boolean;
};

function MaintenanceBar({ title, valueLabel, value, showProgressBar }: Props) {
  const styles = useStyles();
  const isSuccess = value <= 60;
  const isWarning = value > 60;
  const isError = value > 85;

  return (
    <div>
      <div className={styles.labelWrapper}>
        <Typography color="textSecondary" variant="subtitle2">
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          className={classNames({
            [styles.successLabel]: isSuccess,
            [styles.warningLabel]: isWarning,
            [styles.errorLabel]: isError,
          })}
        >
          {valueLabel}
        </Typography>
      </div>
      {showProgressBar && (
        <LinearProgress
          classes={{ root: styles.root }}
          className={classNames({
            [styles.success]: isSuccess,
            [styles.warning]: isWarning,
            [styles.error]: isError,
          })}
          variant="determinate"
          value={value}
        />
      )}
    </div>
  );
}

export default MaintenanceBar;
