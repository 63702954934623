import DeleteItemGrid from "components/common/DeleteItemGrid";
import { useTranslation } from "react-i18next";

type Prop = {
  orgId: number;
  onDelete: () => void;
};

function DeleteGrid({ orgId, onDelete }: Prop) {
  const { t } = useTranslation();
  const isNew = orgId === 0;

  return (
    <DeleteItemGrid
      deleteLabel={t("modules.organizations.grid.delete.header")}
      deleteHelperText={t("modules.organizations.grid.delete.helperText")}
      isNew={isNew}
      onDelete={onDelete}
    />
  );
}

export default DeleteGrid;
