import { Box, Button, Typography } from "@mui/material";
import Badge from "components/icons/Badge";
import paths from "config/paths";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dashboardBadgeInfo } from "store/dashboard";
import {
  dashboardCurrentTargetRoomTemperature,
  dashboardProcessState,
} from "store/dashboardParameters";

type Props = {
  deviceId: number;
};

function DeviceInfo({ deviceId }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentTargetRoomTemperature = useSelector(
    dashboardCurrentTargetRoomTemperature
  );
  const processState = useSelector(dashboardProcessState);
  const { errorCount, warningCount } = useSelector(dashboardBadgeInfo);

  const onButtonClick = useCallback(
    () => navigate(`${paths.dashboard}/${deviceId}`),
    [navigate, deviceId]
  );

  const sxInfoTitle = {
    typography: "subtitle2",
    color: "grey.500",
    textTransform: "capitalize",
  };

  return (
    <Box sx={{ p: 1.2 }}>
      <Box
        sx={{ letterSpacing: 2, typography: "overline", mb: 0.5 }}
        color="text.disabled"
      >
        {t("modules.troubleshooting.tooltip.deviceInfo")}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box sx={sxInfoTitle}>{t("modules.dashboard.table.temperature")}</Box>
          <Typography sx={{ mb: 2 }} variant="subtitle1" color="textSecondary">
            {currentTargetRoomTemperature}
            <span style={{ fontSize: "14px" }}> &#x2103;</span>
          </Typography>
          <Badge withLabel type="error" count={errorCount} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 9,
          }}
        >
          <Box sx={sxInfoTitle}>{t("modules.dashboard.table.workMode")}</Box>
          <Typography sx={{ mb: 2 }} variant="subtitle1" color="textSecondary">
            {t(`processState.${processState || 0}`)}
          </Typography>
          <Badge withLabel type="warning" count={warningCount} />
        </Box>
      </Box>
      <Button
        variant="text"
        sx={{
          p: 0,
          m: 0,
          mt: 2,
          textTransform: "capitalize",
        }}
        onClick={onButtonClick}
      >
        {t("modules.troubleshooting.tooltip.deviceDetails")}
      </Button>
    </Box>
  );
}

export default DeviceInfo;
