import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import ModalWrapper from "components/common/ModalWrapper";
import useDeleteModal from "hooks/useDeleteModal";
import { useCallback, useState, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isOrganizationActionEnabled } from "store/auth";
import { ORGANIZATIONS_ACTIONS } from "store/organizations";

const useStyles = makeStyles((theme: any) => ({
  icon: {
    color: theme.palette.grey["900"],
  },
}));

type Props = {
  orgId: number;
};

function MenageOrganizationMenu({ orgId }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActionEnabled = useSelector(isOrganizationActionEnabled);
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget),
    []
  );

  const onEditClick = useCallback(
    () => navigate(`${orgId}`),
    [navigate, orgId]
  );

  const onDelete = useCallback(() => {
    dispatch({
      type: ORGANIZATIONS_ACTIONS.DELETE_ORGANIZATION,
      payload: {
        orgId,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, orgId, onCloseDeleteModal]);

  return (
    <>
      <IconButtonWrapper
        onClick={handleClick}
        icon={<MoreVertIcon className={styles.icon} />}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onEditClick}>
          <Typography>{t("modals.organizations.edit")}</Typography>
        </MenuItem>
        {isActionEnabled && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                onOpenDeleteModal();
                handleClose();
              }}
            >
              <Typography color="error">
                {t("modals.organizations.delete")}
              </Typography>
            </MenuItem>
          </>
        )}
      </Menu>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDelete}
        title={t("modals.organizations.delete")}
        contentLabel={t("modals.organizations.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
    </>
  );
}

export default MenageOrganizationMenu;
