import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import sortBy from "lodash/sortBy";
import { HeaterErrorOrWarningInfo } from "models/Heater";
import { put, takeLatest, all, fork } from "redux-saga/effects";
import dashboardSlice from "store/dashboard";
import heaterSlice from "store/heater";
import { MQTT_ACTIONS } from "store/mqtt";

type WarningState = {
  warnings: HeaterErrorOrWarningInfo[];
};

const initialState: WarningState = {
  warnings: [],
};

const dashboardWarningSlice = createSlice({
  name: "dashboardWarning",
  initialState,
  reducers: {
    loadWarnings: (
      state,
      { payload }: PayloadAction<HeaterErrorOrWarningInfo[]>
    ) => {
      state.warnings = payload;
    },
  },
});

function dashboardWarningSelector(state: {
  [dashboardWarningSlice.name]: WarningState;
}) {
  return state[dashboardWarningSlice.name];
}

export const dashboardSortedWarnings = createSelector(
  dashboardWarningSelector,
  (state) => sortBy(state?.warnings, "timeOn").reverse()
);

function* handleUpdateWarnings(
  action: PayloadAction<{
    serialNumber: string;
    warnings: HeaterErrorOrWarningInfo[];
  }>
): Generator {
  try {
    const { serialNumber, warnings } = action.payload;
    yield put(dashboardWarningSlice.actions.loadWarnings(warnings));
    yield put(
      heaterSlice.actions.updateWarningCount({
        serialNumber,
        warningCount: warnings.length,
      })
    );
  } catch (err) {
    yield put(dashboardSlice.actions.initError("Something went wrong..."));
  }
}

function* watchUpdateWarnings() {
  yield takeLatest(MQTT_ACTIONS.UPDATE_WARNINGS, handleUpdateWarnings);
}

export function* dashboarWarningsWatcher() {
  yield all([fork(watchUpdateWarnings)]);
}

export default dashboardWarningSlice;
