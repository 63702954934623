import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConnectionIcon from "components/icons/ConnectionIcon";
import DeviceModelIcon from "components/icons/DeviceModel";
import ManageGroupDeviceMenu from "components/groups/ManageGroupDeviceMenu";
import { Device, DeviceModel } from "models/Heater";
import { useCallback, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { heaterCommonByDeviceIdSelector } from "store/heater";
import { defaultRowsPerPage, rowsPerPageOptions } from "helpers";

type Props = {
  groupId: number;
  devices: Device[];
};

const useStyles = makeStyles((theme: any) => ({
  headCell: {
    fontSize: "11px",
    lineHeight: "16px",
    color: theme.palette.text.disabled,
    letterSpacing: "2px",
  },
  bodyCell: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  firstCell: {
    display: "flex",
    alignItems: "center",
  },
}));

function GroupDeviceTable({ groupId, devices }: Props) {
  const { t } = useTranslation();
  const styles = useStyles();
  const heaterCommonByDeviceId = useSelector(heaterCommonByDeviceIdSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={styles.headCell}>
              {t("modules.dashboard.table.machineName").toUpperCase()}
            </TableCell>
            <TableCell className={styles.headCell}>
              {t("modules.dashboard.table.model").toUpperCase()}
            </TableCell>
            <TableCell />
            <TableCell className={styles.headCell}>
              {t("modules.dashboard.table.workMode").toUpperCase()}
            </TableCell>
            <TableCell />
            <TableCell className={styles.headCell}>
              {t("modules.dashboard.table.temperature").toUpperCase()}
            </TableCell>
            <TableCell className={styles.headCell}>
              {t("modules.dashboard.table.software").toUpperCase()}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {devices
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((device) => (
              <TableRow key={device.id}>
                <TableCell className={styles.bodyCell} width={10}>
                  {device.connected ? (
                    <ConnectionIcon type="success" />
                  ) : (
                    <ConnectionIcon type="error" />
                  )}
                </TableCell>
                <TableCell className={styles.bodyCell}>
                  {heaterCommonByDeviceId[device.id]?.machineName}
                </TableCell>
                <TableCell className={styles.bodyCell}>
                  <DeviceModelIcon model={DeviceModel[device.model]} />
                </TableCell>
                <TableCell />
                <TableCell className={styles.bodyCell}>
                  {t(
                    `processState.${
                      heaterCommonByDeviceId[device.id]?.processState || 0
                    }`
                  )}
                </TableCell>
                <TableCell />
                <TableCell className={styles.bodyCell}>
                  {heaterCommonByDeviceId[device.id]?.targetRoomTemperature}{" "}
                  <span style={{ fontSize: "14px" }}>&#x2103;</span>
                </TableCell>
                <TableCell className={styles.bodyCell}>
                  {heaterCommonByDeviceId[device.id]?.softwareVersion}
                </TableCell>
                <TableCell width={20}>
                  <ManageGroupDeviceMenu
                    groupId={groupId}
                    deviceId={device.id}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={devices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
}

export default GroupDeviceTable;
