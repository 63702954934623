import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  type: "error" | "warning" | "success" | "default";
  count: number;
  withLabel?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 10px",
    width: "24px",
    height: "24px",
    borderRadius: "100px",
    color: theme.palette.grey[50],
    margin: "0 8px",
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  default: {
    border: `1px solid ${theme.palette.text.disabled}`,
    color: theme.palette.text.disabled,
  },
}));

function Badge({ type, count, withLabel }: Props) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      {withLabel && (
        <Typography
          sx={{ letterSpacing: 1 }}
          variant="overline"
          color="textSecondary"
        >
          {t(`buttons.${type}`)}
        </Typography>
      )}
      <span
        className={classNames(styles.content, {
          [styles.warning]: type === "warning" && count > 0,
          [styles.success]: type === "success",
          [styles.error]: type === "error" && count > 0,
          [styles.default]: type === "default" || count === 0,
        })}
      >
        {count}
      </span>
    </Box>
  );
}

export default Badge;
