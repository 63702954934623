import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import heaterService from "services/heater";
import { HeaterErrorOrWarningInfo, HeaterErrors } from "../models/Heater";

export const HEATER_ERRORS_HISTORY_ACTIONS = {
  GET_HEATER_ERRORS_HISTORY: "GET_HEATER_ERRORS_HISTORY",
};

type HeaterErrorHistoryState = {
  initializing: boolean;
  data: HeaterErrorOrWarningInfo[];
  error?: string;
};

const initialState: HeaterErrorHistoryState = {
  initializing: false,
  data: [],
};

const heaterErrorHistorySlice = createSlice({
  name: "heaterErrorHistory",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<number>) => {
      state.initializing = true;
      state.data = [];
    },
    initSuccess: (
      state,
      { payload }: PayloadAction<HeaterErrorOrWarningInfo[]>
    ) => {
      state.data = payload;
      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
  },
});

function heaterErrorHistorySelector(state: {
  [heaterErrorHistorySlice.name]: HeaterErrorHistoryState;
}) {
  return state[heaterErrorHistorySlice.name];
}

export const heaterErrorHistoryListSelector = createSelector(
  heaterErrorHistorySelector,
  (state: HeaterErrorHistoryState) => state.data
);

function* handleInit(action: PayloadAction<number>): Generator {
  try {
    const deviceId = action.payload;
    const response = (yield call(
      heaterService.getHeaterErrorHistory,
      deviceId
    )) as HeaterErrors;

    yield put(heaterErrorHistorySlice.actions.initSuccess(response.data));
  } catch (err) {
    yield put(
      heaterErrorHistorySlice.actions.initError("Something went wrong...")
    );
  }
}

function* watchInit() {
  yield takeLatest(heaterErrorHistorySlice.actions.init, handleInit);
}

export function* heaterErrorHistoryWatcher() {
  yield all([fork(watchInit)]);
}

export default heaterErrorHistorySlice;
