import DashboardGrid from "components/DashboardGrid";
import { useTranslation } from "react-i18next";
import UnitList from "./UnitList";

function OrgUnitGrid() {
  const { t } = useTranslation();

  return (
    <DashboardGrid
      title={t("modules.organizations.grid.orgUnit.header")}
      includeBottomDivider
      content={<UnitList />}
    />
  );
}

export default OrgUnitGrid;
