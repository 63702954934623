import { HeaterConnectivityStatus } from "../../models/Heater";
import ConnectionIcon from "./ConnectionIcon";

type Props = {
  status: HeaterConnectivityStatus;
};

function ConnectivityStatusIcon({
  status: { isConnected, isPotentiallyOffline },
}: Props) {
  return !isConnected ? (
    <ConnectionIcon type="error" />
  ) : isPotentiallyOffline ? (
    <ConnectionIcon type="warning" />
  ) : (
    <ConnectionIcon type="success" />
  );
}

export default ConnectivityStatusIcon;
