import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormFooter from "components/common/FormFooter";
import ModalWrapper from "components/common/ModalWrapper";
import paths from "config/paths";
import { Formik } from "formik";
import { trimString } from "helpers";
import useDeleteModal from "hooks/useDeleteModal";
import { Language } from "models/Language";
import {
  OrganizationKind,
  OrganizationUnitWithDetails,
} from "models/Organization";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { isOrganizationActionEnabled } from "store/auth";
import {
  ORGANIZATIONS_ACTIONS,
  createOrgInitialFormValues,
  organizationNamesSelector,
} from "store/organizations";
import DeleteGrid from "./DeleteGrid";
import GeneralInformationGrid from "./GeneralInformationGrid";
import OrgDetailsHeader from "./OrgDetailHeader";
import OrgUnitGrid from "./OrgUnitGrid";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "calc(100vh - 384px)",
    padding: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 20px",
      minHeight: "calc(100vh - 300px)",
    },
  },
}));

function EditOrganization() {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { t } = useTranslation();
  const isActionEnabled = useSelector(isOrganizationActionEnabled);
  const id = orgId && Number(orgId) ? +orgId : 0;
  const names = useSelector(organizationNamesSelector);
  const getInitialValues = useMemo(() => createOrgInitialFormValues(id), [id]);
  const initialValues = useSelector(getInitialValues);
  const { isDeleteModalOpen, onCloseDeleteModal, onOpenDeleteModal } =
    useDeleteModal();
  const [refreshKey, setRefreshKey] = useState(0);
  const isNew = id === 0;

  const onBack = useCallback(() => navigate(paths.organizations), [navigate]);

  useEffect(() => setRefreshKey((prevKey) => prevKey + 1), [initialValues]);

  useEffect(() => {
    if (!isActionEnabled && isNew) {
      onBack();
    }
  }, [isActionEnabled, isNew, onBack]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: ORGANIZATIONS_ACTIONS.GET_ORG_DATA,
        payload: {
          orgId: id,
        },
      });
    }
  }, [dispatch, id]);

  const onSubmit = useCallback(
    (data: {
      label: string;
      defaultLanguage: Language;
      kind: OrganizationKind;
      units: Partial<OrganizationUnitWithDetails>[];
    }) => {
      if (isNew) {
        dispatch({
          type: ORGANIZATIONS_ACTIONS.ADD_ORGANIZATION,
          payload: {
            label: trimString(data.label),
            defaultLanguage: data.defaultLanguage,
            kind: data.kind,
            units: data.units,
          },
        });
      } else {
        dispatch({
          type: ORGANIZATIONS_ACTIONS.EDIT_ORGANIZATION,
          payload: {
            orgId: id,
            label: trimString(data.label),
            defaultLanguage: data.defaultLanguage,
            kind: data.kind,
            units: data.units,
          },
        });
      }
    },
    [dispatch, id, isNew]
  );

  const isNameInvalid = useCallback(
    (label: string) =>
      names.includes(trimString(label)) && initialValues.label !== label,
    [initialValues.label, names]
  );

  const onDelete = useCallback(() => {
    dispatch({
      type: ORGANIZATIONS_ACTIONS.DELETE_ORGANIZATION,
      payload: {
        orgId,
      },
    });
    onCloseDeleteModal();
  }, [dispatch, orgId, onCloseDeleteModal]);

  return (
    <div>
      <OrgDetailsHeader orgId={id} onBack={onBack} />
      <Formik
        key={refreshKey}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={styles.paper}>
              <GeneralInformationGrid
                isNameInvalid={isNameInvalid(values.label)}
              />
              <br />
              <OrgUnitGrid />
              <br />
              {isActionEnabled && (
                <DeleteGrid orgId={id} onDelete={onOpenDeleteModal} />
              )}
            </Paper>
            <FormFooter
              id={id}
              onCancel={onBack}
              saveDisabled={
                !dirty || isNameInvalid(values.label) || values.label === ""
              }
            />
          </form>
        )}
      </Formik>
      <ModalWrapper
        isOpen={isDeleteModalOpen}
        onCancelAction={onCloseDeleteModal}
        onOkAction={onDelete}
        title={t("modals.organizations.delete")}
        contentLabel={t("modals.organizations.deleteSubtitle")}
        okLabel={t("buttons.delete")}
        cancelLabel={t("buttons.close")}
      />
      <Outlet />
    </div>
  );
}

export default EditOrganization;
