import { Grid } from "@mui/material";
import DashboardGrid from "components/DashboardGrid";
import { useTranslation } from "react-i18next";
import AshLevel from "components/heaterDetails/maintenance/AshLevel";
import TimeUntilMaintenance from "components/heaterDetails/maintenance/TimeUntilMaintenance";

type Props = {
  id: number;
};

function MaintenanceGrid({ id }: Props) {
  const { t } = useTranslation();

  return (
    <DashboardGrid
      title={t("modules.dashboard.grid.maintenance.header")}
      includeBottomDivider
      content={
        <>
          <AshLevel id={id} />
          <Grid item xs={12} />
          <TimeUntilMaintenance id={id} />
        </>
      }
    />
  );
}

export default MaintenanceGrid;
