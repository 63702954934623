import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import {
  FactorySettings,
  FactorySettingType,
  SoftwareUpdate,
  UpdateType,
} from "models/Update";
import moment from "moment";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import updatesService from "services/updates";

export const UPDATE_ACTIONS = {
  INIT_FACTORY_SETTING_TYPE: "INIT_FACTORY_SETTING_TYPE",
  ADD_FACTORY_SETTING_TYPE: "ADD_FACTORY_SETTING_TYPE",
  DELETE_FACTORY_SETTING_TYPE: "DELETE_FACTORY_SETTING_TYPE",
  ADD_FACTORY_SETTING: "ADD_FACTORY_SETTING",
  ADD_SOFTWARE_UPDATE: "ADD_SOFTWARE_UPDATE",
  DELETE_UPDATE: "DELETE_UPDATE",
};

type UpdatesState = {
  initializing: boolean;
  softwareUpdates: Record<string, SoftwareUpdate>;
  factorySettings: Record<string, FactorySettings>;
  factorySettingsTypes: Record<string, FactorySettingType>;
  error?: string;
};

const initialState: UpdatesState = {
  initializing: false,
  softwareUpdates: {},
  factorySettings: {},
  factorySettingsTypes: {},
};

const updatesSlice = createSlice({
  name: "updates",
  initialState,
  reducers: {
    init: (state, { payload }: PayloadAction<{ type: UpdateType }>) => {
      state.initializing = true;
    },
    initSoftwareUpdateSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        updatesList: SoftwareUpdate[];
      }>
    ) => {
      state.softwareUpdates = mapKeys(
        payload.updatesList,
        (update) => update.key
      );
      state.initializing = false;
    },
    initFactorySettingsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        updatesList: FactorySettings[];
      }>
    ) => {
      state.factorySettings = mapKeys(
        payload.updatesList,
        (update) => update.id
      );
      state.initializing = false;
    },
    initError: (state, { payload }: PayloadAction<string>) => {
      state.initializing = false;
      state.error = payload;
    },
    addSoftwareUpdateSuccess: (
      state,
      { payload }: PayloadAction<{ update: SoftwareUpdate }>
    ) => {
      const { update } = payload;
      const { key } = update;
      state.softwareUpdates[key] = update;
    },
    addFactorySettingSuccess: (
      state,
      { payload }: PayloadAction<{ update: FactorySettings }>
    ) => {
      const { update } = payload;
      const { id } = update;
      state.factorySettings[id] = update;
    },
    deleteUpdateSucces: (
      state,
      { payload }: PayloadAction<{ type: UpdateType; key: string }>
    ) => {
      const { type, key } = payload;
      state[type] = omit(state[type], key);
    },
    initFactorySettingsTypeSuccess: (
      state,
      { payload }: PayloadAction<{ types: FactorySettingType[] }>
    ) => {
      state.factorySettingsTypes = mapKeys(payload.types, (type) => type.id);
    },
    addFactorySettingTypeSuccess: (
      state,
      { payload }: PayloadAction<{ type: FactorySettingType }>
    ) => {
      const { type } = payload;
      state.factorySettingsTypes[type.id] = type;
    },
    deleteFactorySettingTypeSucces: (
      state,
      { payload }: PayloadAction<{ id: number }>
    ) => {
      const { id } = payload;
      state.factorySettingsTypes = omit(state.factorySettingsTypes, id);
    },
  },
});

function updatesSelector(state: { [updatesSlice.name]: UpdatesState }) {
  return state[updatesSlice.name];
}

export const getFactorySettingsTypeById = createSelector(
  updatesSelector,
  (state: UpdatesState) => state.factorySettingsTypes
);

export const getFactorySettingsTypes = createSelector(
  getFactorySettingsTypeById,
  (byId) => Object.values(byId)
);
export const getFactorySettingsTypesAsSelectOptions = createSelector(
  getFactorySettingsTypes,
  (types) =>
    types.map((type) => ({ value: type.id.toString(), label: type.label }))
);

export const factorySettingTypeLabelsSelector = createSelector(
  getFactorySettingsTypes,
  (types: FactorySettingType[]) =>
    types.map((type) => type.label.toLocaleLowerCase())
);

export const getSoftwareUpdates = createSelector(
  updatesSelector,
  (state: UpdatesState) => Object.values(state.softwareUpdates)
);

export const getFactorySettings = createSelector(
  updatesSelector,
  (state: UpdatesState) => Object.values(state.factorySettings)
);

function* handleAddFactorySetting(
  action: PayloadAction<{ newFile: File; type: UpdateType; typeId: number }>
): Generator {
  try {
    const { newFile, type, typeId } = action.payload;

    const factorySetting = yield call(
      updatesService.addSoftwareUpdate,
      type,
      newFile,
      typeId
    );

    if (factorySetting) {
      yield put(
        updatesSlice.actions.addFactorySettingSuccess({
          update: {
            ...(factorySetting as FactorySettings),
            s3Key: newFile.name,
            typeId,
          },
        })
      );
    }
  } catch (error) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddFactorySetting() {
  yield takeLatest(UPDATE_ACTIONS.ADD_FACTORY_SETTING, handleAddFactorySetting);
}

function* handleAddSoftwareUpdate(
  action: PayloadAction<{ newFile: File; type: UpdateType }>
): Generator {
  try {
    const { newFile, type } = action.payload;

    yield call(updatesService.addSoftwareUpdate, type, newFile);

    yield put(
      updatesSlice.actions.addSoftwareUpdateSuccess({
        update: {
          key: newFile.name,
          lastModified: moment(new Date()).format(),
        },
      })
    );
  } catch (error) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddSoftwareUpdate() {
  yield takeLatest(UPDATE_ACTIONS.ADD_SOFTWARE_UPDATE, handleAddSoftwareUpdate);
}

function* handleDeleteUpdate(
  action: PayloadAction<{ key: string; type: UpdateType }>
): Generator {
  try {
    const { key, type } = action.payload;

    if (type === "factorySettings") {
      yield call(updatesService.deleteFactorySetting, key);
    } else {
      yield call(updatesService.deleteSoftwareUpdate, key);
    }

    yield put(
      updatesSlice.actions.deleteUpdateSucces({
        type,
        key,
      })
    );
  } catch (error) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteSoftwareUpdate() {
  yield takeLatest(UPDATE_ACTIONS.DELETE_UPDATE, handleDeleteUpdate);
}

function* handleInitFactorySettingType(): Generator {
  try {
    const types = (yield call(
      updatesService.getFactorySettingTypes
    )) as FactorySettingType[];

    yield put(
      updatesSlice.actions.initFactorySettingsTypeSuccess({
        types,
      })
    );
  } catch (err) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInitFactorySettingTypes() {
  yield takeLatest(
    UPDATE_ACTIONS.INIT_FACTORY_SETTING_TYPE,
    handleInitFactorySettingType
  );
}
function* handleAddFactorySettingType(
  action: PayloadAction<{ label: string }>
): Generator {
  try {
    const { label } = action.payload;

    const type = (yield call(
      updatesService.addFactorySettingType,
      label
    )) as FactorySettingType;

    yield put(updatesSlice.actions.addFactorySettingTypeSuccess({ type }));
  } catch (error) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchAddFactorySettingType() {
  yield takeLatest(
    UPDATE_ACTIONS.ADD_FACTORY_SETTING_TYPE,
    handleAddFactorySettingType
  );
}

function* handleDeleteFactorySettingType(
  action: PayloadAction<{ id: number }>
): Generator {
  try {
    const { id } = action.payload;
    yield call(updatesService.deleteFactorySettingType, id);

    yield put(
      updatesSlice.actions.deleteFactorySettingTypeSucces({
        id,
      })
    );
  } catch (error) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchDeleteFactorySettingType() {
  yield takeLatest(
    UPDATE_ACTIONS.DELETE_FACTORY_SETTING_TYPE,
    handleDeleteFactorySettingType
  );
}

function* handleInit(action: PayloadAction<{ type: UpdateType }>): Generator {
  try {
    const { type } = action.payload;
    const isFactorySettings = type === "factorySettings";

    if (isFactorySettings) {
      yield put({
        type: UPDATE_ACTIONS.INIT_FACTORY_SETTING_TYPE,
      });
    }

    const updatesList = yield call(updatesService.getUpdates, type);

    if (isFactorySettings) {
      yield put(
        updatesSlice.actions.initFactorySettingsSuccess({
          updatesList: updatesList as FactorySettings[],
        })
      );
    } else {
      yield put(
        updatesSlice.actions.initSoftwareUpdateSuccess({
          updatesList: updatesList as SoftwareUpdate[],
        })
      );
    }
  } catch (err) {
    yield put(updatesSlice.actions.initError("Something went wrong..."));
  }
}

function* watchInit() {
  yield takeLatest(updatesSlice.actions.init, handleInit);
}

export function* softwareUpdatesWatcher() {
  yield all([
    fork(watchInit),
    fork(watchAddFactorySetting),
    fork(watchDeleteSoftwareUpdate),
    fork(watchAddSoftwareUpdate),
    fork(watchInitFactorySettingTypes),
    fork(watchAddFactorySettingType),
    fork(watchDeleteFactorySettingType),
  ]);
}

export default updatesSlice;
