import { SvgIcon } from "@mui/material";

type Props = {
  className?: string;
};

function AdminDevicesIcon({ className = "" }: Props) {
  return (
    <SvgIcon className={className}>
      <path d="M5.683 0.0677938C5.61325 0.0270441 5.53467 0.0038 5.45397 5.06534e-05C5.37328 -0.00369869 5.29288 0.0121584 5.21966 0.0462649C5.14643 0.0803714 5.08256 0.131712 5.03351 0.195892C4.98446 0.260073 4.95168 0.335183 4.938 0.414794C4.73801 1.57258 4.2619 2.6651 3.55 3.59979C3.30383 3.89552 3.03646 4.17292 2.75 4.42979C2.03812 4.97439 1.45543 5.66962 1.04364 6.46572C0.631844 7.26182 0.401126 8.13911 0.367996 9.03479C0.369987 10.0319 0.636166 11.0106 1.13943 11.8713C1.6427 12.732 2.36507 13.444 3.233 13.9348C3.30802 13.9773 3.39276 13.9997 3.479 13.9998C3.60576 14.0001 3.7279 13.9523 3.82073 13.8659C3.91355 13.7796 3.97012 13.6612 3.979 13.5348C4.00092 13.207 4.09383 12.8878 4.2513 12.5995C4.40878 12.3111 4.62705 12.0604 4.891 11.8648C5.43518 11.5099 5.88644 11.0299 6.207 10.4648C6.70024 10.8354 7.11501 11.3003 7.42726 11.8324C7.7395 12.3646 7.94301 12.9534 8.026 13.5648C8.03652 13.6461 8.06685 13.7235 8.11433 13.7903C8.1618 13.8571 8.22496 13.9111 8.29825 13.9478C8.37154 13.9844 8.45271 14.0025 8.53462 14.0004C8.61653 13.9983 8.69667 13.9761 8.768 13.9358C9.63592 13.445 10.3583 12.733 10.8616 11.8723C11.3648 11.0116 11.631 10.0329 11.633 9.03579C11.632 5.44679 9.575 2.34779 5.683 0.0677938Z" />
    </SvgIcon>
  );
}

export default AdminDevicesIcon;
