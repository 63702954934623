import { Grid, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactNode } from "react";
import CustomHidden from "./common/CustomHidden";

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginBottom: "60px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
    },
  },
  innerContainer: {
    padding: "0 16px !important",
    margin: "0 30px !important",
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
      marginLeft: "20px !important",
      marginRight: "0 !important",
    },
  },
}));

type Props = {
  title: string;
  titleExtra?: ReactNode;
  content: ReactNode;
  includeBottomDivider?: boolean;
};

function DashboardGrid({
  title,
  titleExtra,
  content,
  includeBottomDivider = false,
}: Props) {
  const styles = useStyles();
  return (
    <Grid className={styles.container} container spacing={{ sm: 2, xs: 2 }}>
      <CustomHidden hideType="down" size="md">
        <Grid item xs={3}>
          <Typography color="textSecondary" variant="h6">
            {title}
          </Typography>
          {titleExtra}
        </Grid>
        <Divider orientation="vertical" flexItem />
      </CustomHidden>
      <Grid
        className={styles.innerContainer}
        item
        xs={12}
        sm
        container
        rowSpacing={{ md: 2, xs: 3 }}
      >
        <CustomHidden hideType="up" size="md">
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="h6">
              {title}
            </Typography>
          </Grid>
        </CustomHidden>
        {content}
        <CustomHidden hideType="up" size="md">
          <Grid item xs={12}>
            {titleExtra}
          </Grid>
        </CustomHidden>
        <CustomHidden hideType="up" size="md">
          {includeBottomDivider && (
            <Grid item xs={12}>
              <Divider flexItem />
            </Grid>
          )}
        </CustomHidden>
      </Grid>
    </Grid>
  );
}

export default DashboardGrid;
