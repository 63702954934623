import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField, InputAdornment } from "@mui/material";
import { ChangeEvent, useCallback, useState, type MouseEvent } from "react";

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: any;
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

function PasswordField({
  name,
  placeholder,
  value,
  onChange,
  size,
  error = true,
  helperText = "",
  required = true,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    []
  );

  return (
    <TextField
      name={name}
      size={size}
      type={showPassword ? "text" : "password"}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={onChange}
      hiddenLabel
      required={required}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{
                color: "#9D9DAF",
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {showPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordField;
