export enum ActivityCode {
  resetAshLevel = 3004,
  resetHoursTillMaintenance = 3007,
  extendingParameterData = 1000,
  startInitialization = 4000,
  startNormalMode = 4004,
  startCalendarMode = 4005,
  startVentilationMode = 4006,
  stopManualCalendarOrVentilationMode = 4007,
}

export const maintenanceActivityCodes = [
  ActivityCode.resetAshLevel,
  ActivityCode.resetHoursTillMaintenance,
];

export const workModeActivityCodes = [
  ActivityCode.startInitialization,
  ActivityCode.stopManualCalendarOrVentilationMode,
  ActivityCode.startNormalMode,
  ActivityCode.startCalendarMode,
  ActivityCode.startVentilationMode,
];

export type MqttUserActivityMessage = {
  activityCode: number;
  firstName: string;
  lastName: string;
  email: string;
  extendedCode: number;
  id: number;
  source: number;
  timestamp: number;
  valueFrom: string;
  valueTo: string;
};

export type MqttConfirm = "0" | "10" | "20";
