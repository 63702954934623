import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import heaterScheduledUpdatesSlice, {
  heaterScheduledUpdatesListSelector,
} from "store/heaterScheduledUpdates";
import ScheduledUpdatesTable from "./scheduledUpdates/ScheduledUpdatesTable";

type Props = {
  deviceId: number;
};

function ScheduledUpdatesTab({ deviceId }: Props) {
  const dispatch = useDispatch();
  const updates = useSelector(heaterScheduledUpdatesListSelector);

  useEffectOnce(() => {
    dispatch(heaterScheduledUpdatesSlice.actions.init(deviceId));
  });

  return <ScheduledUpdatesTable updates={updates} />;
}

export default ScheduledUpdatesTab;
