import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import DownloadingIcon from "@mui/icons-material/Downloading";
import PendingIcon from "@mui/icons-material/Pending";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import SoftwareUpdateIcon from "components/icons/SoftwareUpdateIcon";
import { MqttConfirm } from "models/Mqtt";
import IconButtonWrapper from "components/common/IconButtonWrapper";
import { UpdateType } from "models/Update";

const useStyles = makeStyles((theme: any) => ({
  content: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  disabledIcon: {
    opacity: "0.5 !important",
  },
}));

type Props = {
  title: string;
  type: UpdateType;
  isUpdateEnabled: boolean;
  enableDashboardActions: boolean;
  updateInProgress: boolean;
  confirm: MqttConfirm | null;
  onGetUpdatesClick: () => void;
};

function UpdateDetails({
  title,
  type,
  isUpdateEnabled,
  enableDashboardActions,
  updateInProgress,
  confirm,
  onGetUpdatesClick,
}: Props) {
  const styles = useStyles();
  const disabled = !enableDashboardActions || updateInProgress;

  return (
    <Typography className={styles.content} variant="body1">
      {isUpdateEnabled && type === "softwareUpdates" && (
        <>
          {title}
          <IconButtonWrapper
            onClick={onGetUpdatesClick}
            disabled={disabled}
            icon={
              <SoftwareUpdateIcon
                className={classNames({
                  [styles.disabledIcon]: disabled,
                })}
              />
            }
          />
        </>
      )}
      {isUpdateEnabled && type !== "softwareUpdates" && (
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={onGetUpdatesClick}
          disabled={disabled}
          sx={{
            textTransform: "none",
            marginRight: "8px",
          }}
        >
          {title}
        </Button>
      )}
      {updateInProgress && <PendingIcon />}
      {confirm === "0" && <DownloadingIcon color="success" />}
      {confirm === "10" && <SyncProblemIcon color="error" />}
      {confirm === "20" && <DesktopAccessDisabledIcon color="error" />}
    </Typography>
  );
}
export default UpdateDetails;
