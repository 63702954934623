import { useTranslation } from "react-i18next";
import CertificateGrid from "./CertificateGrid";

type Prop = {
  deviceId: number;
};

function OpenVPNCertificateGrid({ deviceId }: Prop) {
  const { t } = useTranslation();

  return (
    <CertificateGrid
      deviceId={deviceId}
      certType="ovpn"
      title={t("modules.devices.grid.certificate.openVPN")}
      editLabel={t("modules.devices.grid.certificate.editOpenVPN")}
      addLabel={t("modules.devices.grid.certificate.createOpenVPN")}
    />
  );
}

export default OpenVPNCertificateGrid;
