import { Box, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BrowserUpdatedRoundedIcon from "@mui/icons-material/BrowserUpdatedRounded";
import EmptyList from "components/common/EmptyList";
import ModalWrapper from "components/common/ModalWrapper";
import PageHeader from "components/common/PageHeader";
import { getFormatedDateForSearch } from "helpers";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import updatesSlice, {
  getFactorySettings,
  getFactorySettingsTypesAsSelectOptions,
  UPDATE_ACTIONS,
} from "store/updates";
import FactorySettingsTable from "components/factorySettings/Table";
import useContentStyles from "./useContentStyles";

const useStyles = makeStyles(() => ({
  select: {
    margin: "20px 0",
  },
}));

function FactorySettings() {
  const contentStyles = useContentStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updates = useSelector(getFactorySettings);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const options = useSelector(getFactorySettingsTypesAsSelectOptions);
  const [factoryType, setFactoryType] = useState(options[0]?.value);

  useEffect(() => {
    dispatch(updatesSlice.actions.init({ type: "factorySettings" }));
  }, [dispatch]);

  useEffect(() => {
    setFactoryType(options[0]?.value);
  }, [options]);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    []
  );

  const filteredUpdates = useMemo(
    () =>
      updates.filter(
        (update) =>
          update.s3Key.toLowerCase().includes(searchValue.toLowerCase()) ||
          getFormatedDateForSearch(update.updatedAt).includes(searchValue)
      ),
    [searchValue, updates]
  );

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    setFile(null);
  }, []);

  const onAdd = useCallback(() => setIsModalOpen(true), []);

  const onAddUpdate = useCallback(() => {
    dispatch({
      type: UPDATE_ACTIONS.ADD_FACTORY_SETTING,
      payload: {
        newFile: file,
        type: "factorySettings",
        typeId: +factoryType,
      },
    });
    onClose();
  }, [dispatch, file, onClose, factoryType]);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  return (
    <Box className={contentStyles.content}>
      <PageHeader
        title={t(`modules.factorySettings.title`)}
        addLabel={t(`modules.factorySettings.addNew`)}
        onAdd={onAdd}
        enableSearch
        searchPlaceholder={t(`modules.factorySettings.placeholder`)}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      {updates.length > 0 && <FactorySettingsTable updates={filteredUpdates} />}
      {updates.length === 0 && (
        <EmptyList
          icon={
            <BrowserUpdatedRoundedIcon
              color="disabled"
              style={{ fontSize: "150px" }}
            />
          }
          emptyListText={t(`modules.factorySettings.empty`)}
          onOpen={onAdd}
        />
      )}
      <ModalWrapper
        isOpen={isModalOpen}
        onCancelAction={onClose}
        onOkAction={onAddUpdate}
        title={t(`modals.factorySettings.addNewTitle`)}
        content={
          <>
            <Typography color="textSecondary">
              {t("modals.factorySettings.type")}
            </Typography>
            <Select
              className={styles.select}
              label="Type"
              name="role"
              variant="standard"
              value={factoryType}
              fullWidth
              onChange={(event) => setFactoryType(event.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <input type="file" onChange={onFileChange} />
          </>
        }
        okLabel={t("buttons.add")}
        isOkDisabled={!file}
        cancelLabel={t("buttons.close")}
      />
    </Box>
  );
}

export default FactorySettings;
